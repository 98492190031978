import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'ngx-webstorage';
import { PrivacyPolicyDialogComponent } from './privacy-policy-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PrivacySettingsService {
  constructor(
    private dialog: MatDialog,
    private localStorage: LocalStorageService,
  ) {
    if (!this.localStorage.retrieve('acceptCookie')) {
      const dialogRef = this.dialog.open(PrivacyPolicyDialogComponent, {
        panelClass: 'privacy-dialog-panel',
        height: '480px',
        width: '590px',
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.localStorage.store('acceptCookie', true);
          if (result.analytics) {
            globalThis?._paq?.push(...[['setConsentGiven'], ['rememberConsentGiven']]);
            return;
          }
          this.optOutUser();
        }
      });
    }
  }

  private optOutUser() {
    globalThis?._paq?.push([
      function () {
        if (this.hasConsent()) {
          globalThis?._paq?.push(['forgetConsentGiven']);
        }
      },
    ]);
  }
}
