const translationPrefixPassenger = 'administration.buyPackage.package.passenger.';
const translationPrefixCrew = 'administration.buyPackage.package.crew.';
const translationPrefixExcellence = 'administration.buyPackage.package.excellence.';

export interface SelectedLicensePackageDetails {
  id?: number;
  amountUsers?: number;
  totalPrice?: number;
  selectedLicensePackage?: LicensePackage;
  subscriptionTemplateID?: number;
  referenceCode?: string;
}

export interface LicensePackage {
  readonly id: number;
  readonly packageName: string;
  readonly title: string;
  readonly description: string;
  readonly priceMonthly: number;
  readonly priceYearly: number;
  readonly featureList: string[];
}

export const licensePackages: LicensePackage[] = [
  {
    id: 1,
    packageName: 'passenger',
    title: translationPrefixPassenger + 'title',
    description: translationPrefixPassenger + 'description',
    priceMonthly: 2.15,
    priceYearly: 1.97,
    featureList: [translationPrefixPassenger + 'featureList.task'],
  },
  {
    id: 2,
    packageName: 'crew',
    title: translationPrefixCrew + 'title',
    description: translationPrefixCrew + 'description',
    priceMonthly: 41.35,
    priceYearly: 37.9,
    featureList: [
      translationPrefixPassenger + 'featureList.document',
      translationPrefixPassenger + 'featureList.process',
      translationPrefixPassenger + 'featureList.dashboard',
      translationPrefixPassenger + 'featureList.strategy',
      translationPrefixPassenger + 'featureList.goal',
      translationPrefixPassenger + 'featureList.kpi',
      translationPrefixCrew + 'featureList.role',
      translationPrefixCrew + 'featureList.organigram',
      translationPrefixCrew + 'featureList.risk',
      translationPrefixCrew + 'featureList.checklist',
      translationPrefixCrew + 'featureList.decision',
      translationPrefixCrew + 'featureList.wiki',
      translationPrefixCrew + 'featureList.retro',
    ],
  },
  // {
  //     id: 3,
  //     packageName: 'excellence',
  //     title: translationPrefixExcellence + 'title',
  //     description: translationPrefixExcellence + 'description',
  //     priceMonthly: 29.30,
  //     priceYearly: 27.90,
  //     featureList: [
  //         translationPrefixExcellence + 'featureList.audit',
  //         translationPrefixExcellence + 'featureList.complaint',
  //         translationPrefixExcellence + 'featureList.supplier',
  //         translationPrefixExcellence + 'featureList.management',
  //         translationPrefixExcellence + 'featureList.qm',
  //     ],
  // },
];

export enum OdooPaymentCycles {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export const odooPaymentTranslations = {
  [OdooPaymentCycles.Monthly]: 'odoo.form.createSubscription.payment.cycle.monthly',
  [OdooPaymentCycles.Yearly]: 'odoo.form.createSubscription.payment.cycle.yearly',
};

export const paymentCycleIndexes = {
  [OdooPaymentCycles.Monthly]: 1,
  [OdooPaymentCycles.Yearly]: 2,
};
