import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, filter, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as CAT from './catalog.actions';
import * as fromRoot from '../../../../../../app.reducer';
import { CatalogService } from '../catalog.service';
import { ActionWithPayload } from '../../../../../../shared';
import { Catalog } from '../catalog.model';

import { IRestResponse, IRestError } from 'app/core/models/rest.model';
import { CatalogLibraryService } from '../../catalog-library.service';
import { BridgeBoxConfigurationService } from '../../bridge-boxconfiguration.service';
import { TranslateService } from '@ngx-translate/core';
import { filterToString } from './catalog.helper';
import { FilterModuleName } from '../../../filter/filter-modules-list';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable()
export class CatalogEffects {
  loadCatsNextPage$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(
        CAT.TRY_GET_CATALOG_NEXT_PAGE,
        CAT.ADD_CATALOG_CHILDREN_SUCCESS,
        CAT.RESET_CATALOG_LIST_VIEW,
        CAT.SELECT_CATALOG_PARENT_SUCCESS,
        CAT.TRY_GET_SORTED_CATALOGS,
        CAT.FIND_CATALOG_LIBRARY_ITEM_SUCCESS,
        CAT.NAVIGATE_TO_BREADCRUMB_ITEM_SUCCESS,
      ),
      withLatestFrom(
        this.store$.pipe(select(fromRoot.getSearchText(FilterModuleName.CATALOG))),
        this.store$.select(fromRoot.getCatalogSortedQueryParams).pipe(
          map((sortData) => ({
            ...sortData,
            sort: filterToString(sortData.sort, this.translate.currentLang),
          })),
        ),
        this.store$.select(fromRoot.getCatalogRootCatalog),
        this.store$.select(fromRoot.getCatalogLibraryItem),
      ),
      switchMap(([action, searchText, queryParams, rootCatalog, libItem]) =>
        this.catalogService.query(queryParams, searchText, rootCatalog, libItem?.componentType).pipe(
          map((res: IRestResponse) => ({
            type: CAT.GET_CATALOG_NEXT_PAGE_SUCCESS,
            payload: res,
          })),
          catchError((error: IRestError) => of({ type: CAT.GET_CATALOG_NEXT_PAGE_ERROR, payload: error })),
        ),
      ),
    ); },
  );

  searchCats$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_GET_SEARCHED_CATALOG_LIST),
      withLatestFrom(
        this.store$.pipe(select(fromRoot.getActiveFilter(FilterModuleName.CATALOG))),
        this.store$.pipe(select(fromRoot.getSearchText(FilterModuleName.CATALOG))),
        this.store$.select(fromRoot.getCatalogSortedQueryParams).pipe(
          map((sortData) => ({
            sortData,
            sort: filterToString(sortData.sort, this.translate.currentLang),
          })),
        ),
        this.store$.select(fromRoot.getCatalogRootCatalog),
        this.store$.select(fromRoot.getCatalogLibraryItem),
      ),
      switchMap(([action, filter, searchText, queryParams, rootCatalog, libItem]) => {
        if (searchText == '' && (filter as any)?.content == null) {
          return this.catalogService.query(queryParams, searchText, rootCatalog, libItem?.componentType).pipe(
            map((res: IRestResponse) => ({
              type: CAT.GET_CATALOG_NEXT_PAGE_SUCCESS,
              payload: res,
            })),
            catchError((error: IRestError) => of({ type: CAT.GET_CATALOG_NEXT_PAGE_ERROR, payload: error })),
          );
        }
        return this.catalogService
          .queryFlat(queryParams, {
            searchText: searchText ? searchText.toString() : null,
            name: (filter as any)?.content?.name,
            description: (filter as any)?.content?.description,
            type: libItem?.componentType,
          })
          .pipe(
            map((res: Catalog[]) => ({
              type: CAT.GET_SEARCHED_CATALOG_LIST_SUCCESS,
              payload: res,
            })),
            catchError((error: IRestError) =>
              of({
                type: CAT.GET_SEARCHED_CATALOG_LIST_ERROR,
                payload: error,
              }),
            ),
          );
      }),
    ); },
  );

  setCatalogLibraryItem$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_FIND_CATALOG_LIBRARY_ITEM),
      map((action: ActionWithPayload<string>) => action.payload),
      switchMap((catalogType) => {
        return this.catalogLibraryService.getCatalogByType(catalogType).pipe(
          map((res) => ({
            type: CAT.FIND_CATALOG_LIBRARY_ITEM_SUCCESS,
            payload: res,
          })),
          catchError(() => of({ type: CAT.FIND_CATALOG_LIBRARY_ITEM_ERROR })),
        );
      }),
    ); },
  );

  addCat$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_ADD_CATALOG),
      map((action: ActionWithPayload<Catalog>) => action.payload),
      concatLatestFrom(() => this.store$.select(fromRoot.getCatalogRootCatalog)),
      switchMap(([newCatalog, rootCatalog]) => {
        if (newCatalog?.parent?.id !== rootCatalog?.id) {
          return of({
            type: CAT.TRY_ADD_CATALOG_CHILDREN,
            payload: newCatalog,
          });
        }

        return this.catalogService.create(newCatalog).pipe(
          map((res: IRestResponse<Catalog>) => ({
            type: CAT.ADD_CATALOG_SUCCESS,
            payload: res,
          })),
          catchError(() => of({ type: CAT.ADD_CATALOG_ERROR })),
        );
      }),
    ); },
  );

  addCatSuccess$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(CAT.ADD_CATALOG_SUCCESS),
        tap(() =>
          this.matSnackBar.open(this.translate.instant('catalog.snackBar.createSuccess'), 'OK', {
            horizontalPosition: 'right',
            duration: 3000,
          }),
        ),
      ); },
    { dispatch: false },
  );

  addCatChildren$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_ADD_CATALOG_CHILDREN),
      map((action: ActionWithPayload<Catalog>) => action.payload),
      switchMap((payload) =>
        this.catalogService.create(payload).pipe(
          map((res: IRestResponse<Catalog>) => ({
            type: CAT.ADD_CATALOG_CHILDREN_SUCCESS,
            payload: res,
          })),
          catchError(() => of({ type: CAT.ADD_CATALOG_CHILDREN_ERROR })),
        ),
      ),
    ); },
  );

  selectCatalogParent$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_SELECT_CATALOG_PARENT),
      withLatestFrom(
        this.store$.select(fromRoot.getCatalogRootCatalog),
        this.store$.select(fromRoot.getCatalogSelectedCatalog),
      ),
      switchMap(([, rootCatalog, selectedCatalog]) => {
        if (!rootCatalog && !selectedCatalog) return of({ type: CAT.RETURN_TO_SETTINGS });
        if (!rootCatalog && selectedCatalog) return of({ type: CAT.DESELECT_CATALOG });

        return this.catalogService.find(rootCatalog.id).pipe(
          map(
            (res: IRestResponse<Catalog>) => ({
              type: CAT.SELECT_CATALOG_PARENT_SUCCESS,
              payload: res.data,
            }),
            catchError(() => of({ type: CAT.SELECT_CATALOG_PARENT_ERROR })),
          ),
        );
      }),
    ); },
  );

  returnToSettings$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(CAT.RETURN_TO_SETTINGS, CAT.NAVIGATE_OUTSIDE_CATALOGS),
        tap(() => this.router.navigate(['master-data'], { queryParamsHandling: 'preserve'} )),
      ); },
    { dispatch: false },
  );

  findCat$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_NAVIGATE_TO_BREADCRUMB_ITEM),
      map((action: ActionWithPayload<string>) => action.payload),
      switchMap((catalogId) =>
        this.catalogService.find(catalogId).pipe(
          map((res: IRestResponse<Catalog>) => ({
            type: CAT.NAVIGATE_TO_BREADCRUMB_ITEM_SUCCESS,
            payload: res.data,
          })),
          catchError(() => of({ type: CAT.NAVIGATE_TO_BREADCRUMB_ITEM_ERROR })),
        ),
      ),
    ); },
  );

  loadCatsChildrenNextPage$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_GET_CATALOG_CHILDREN_NEXT_PAGE, CAT.TRY_FIND_CATALOG_CHILDREN),
      withLatestFrom(
        this.store$.select(fromRoot.getCatalogSelectedCatalog),
        this.store$.select(fromRoot.getCatalogSortParams),
        this.store$.select(fromRoot.getCatalogLibraryItem),
        // TODO add search text criteria when querying children
        this.store$.select(fromRoot.getCatalogSearchText),
      ),
      switchMap(([action, catalog, queryParams, libItem, searchText]) =>
        this.catalogService.queryChildren(queryParams, catalog?.id, libItem).pipe(
          map((res: IRestResponse<Catalog[]>) => ({
            type: CAT.GET_CATALOG_CHILDREN_NEXT_PAGE_SUCCESS,
            payload: res,
          })),
          catchError(() => of({ type: CAT.GET_CATALOG_CHILDREN_NEXT_PAGE_ERROR })),
        ),
      ),
    ); },
  );

  updateCat$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_UPDATE_CATALOG),
      map((action: ActionWithPayload<Catalog>) => action.payload),
      switchMap((catalog) =>
        this.catalogService.update(catalog).pipe(
          map((res: IRestResponse<Catalog>) => ({
            type: CAT.UPDATE_CATALOG_SUCCESS,
            payload: res,
          })),
          catchError(() => of({ type: CAT.UPDATE_CATALOG_ERROR })),
        ),
      ),
    ); },
  );

  updateCatSuccess$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(CAT.UPDATE_CATALOG_SUCCESS),
        tap(() =>
          this.matSnackBar.open(this.translate.instant('catalog.snackBar.updateSuccess'), 'OK', {
            horizontalPosition: 'right',
            duration: 3000,
          }),
        ),
      ); },
    { dispatch: false },
  );

  dragCat$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_DRAG_CATALOG),
      map(
        (
          action: ActionWithPayload<{
            targetFolderId: string;
            catalogId: string;
          }>,
        ) => action.payload,
      ),
      switchMap((dragData) =>
        this.catalogService.changeFolder(dragData).pipe(
          map((res) => ({ type: CAT.DRAG_CATALOG_SUCCESS, payload: res })),
          catchError(() => of({ type: CAT.DRAG_CATALOG_ERROR })),
        ),
      ),
    ); },
  );

  updateBridge$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_UPDATE_BRIDGE),
      map((action: ActionWithPayload<Catalog>) => action.payload),
      switchMap((bridge) =>
        this.bridgeBoxConfigurationService.sendBoxConfiguration(bridge).pipe(
          map((res) => ({ type: CAT.UPDATE_BRIDGE_SUCCESS, payload: res })),
          catchError(() => of({ type: CAT.UPDATE_BRIDGE_ERROR })),
        ),
      ),
    ); },
  );

  loadCatalogChildrenAfterDragging$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_DRAG_CATALOG),
      map(
        (
          action: ActionWithPayload<{
            targetFolderId: string;
            catalogId: string;
          }>,
        ) => action.payload,
      ),
      withLatestFrom(
        this.store$.select(fromRoot.getCatalogRootCatalog),
        this.store$.select(fromRoot.getCatalogSelectedCatalog),
        this.store$.select(fromRoot.getCatalogSortParams),
        this.store$.select(fromRoot.getCatalogLibraryItem),
        this.store$.select(fromRoot.getCatalogSearchText),
      ),
      switchMap(([action, rootCatalog, selectedCatalog, queryParams, libItem, searchText]) => {
        const catalog = selectedCatalog || rootCatalog || null;
        if (catalog?.id === action.targetFolderId) {
          return this.catalogService.queryChildren(queryParams, action.targetFolderId, libItem).pipe(
            map((res: IRestResponse<Catalog[]>) => ({
              type: CAT.GET_CATALOG_CHILDREN_NEXT_PAGE_SUCCESS,
              payload: res,
            })),
            catchError(() => of({ type: CAT.GET_CATALOG_CHILDREN_NEXT_PAGE_ERROR })),
          );
        }
        return of({ type: CAT.DRAG_CATALOG_ERROR });
      }),
    ); },
  );

  removeCat$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_REMOVE_CATALOG),
      concatLatestFrom(() => this.store$.select(fromRoot.getCatalogSelectedCatalog)),
      switchMap(([action, catalog]) =>
        this.catalogService.delete(catalog.id).pipe(
          map(() => ({ type: CAT.REMOVE_CATALOG_SUCCESS })),
          catchError(() => of({ type: CAT.REMOVE_CATALOG_ERROR })),
        ),
      ),
    ); },
  );

  removeCatSuccess$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(CAT.REMOVE_CATALOG_SUCCESS),
        tap(() =>
          this.matSnackBar.open(this.translate.instant('catalog.snackBar.removeSuccess'), 'OK', {
            horizontalPosition: 'right',
            duration: 3000,
          }),
        ),
      ); },
    { dispatch: false },
  );

  loadCatalogChildren$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(
        CAT.SELECT_CATALOG,
        CAT.SELECT_CHILD_CATALOG,
        CAT.SELECT_CATALOG_PARENT_SUCCESS,
        CAT.NAVIGATE_TO_BREADCRUMB_ITEM_SUCCESS,
        CAT.OPEN_CHILD_LIST_VIEW,
      ),
      map((action: ActionWithPayload<Catalog>) => action.payload),
      filter((catalog) => catalog?.hasChildren),
      map((catalog) => {
        return { type: CAT.TRY_FIND_CATALOG_CHILDREN, payload: catalog.id };
      }),
    ); },
  );

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private catalogService: CatalogService,
    private router: Router,
    private catalogLibraryService: CatalogLibraryService,
    private bridgeBoxConfigurationService: BridgeBoxConfigurationService,
    private store$: Store<fromRoot.State>,
    private matSnackBar: MatSnackBar,
  ) {}
}
