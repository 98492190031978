import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewImageB64Component } from './view-image-b64.component';

@Component({
  selector: 'view-image-b64-dialog',
  template: ' <view-image-b64 [base64Content]="data.base64Content"></view-image-b64> ',
  styles: [''],
  standalone: true,
  imports: [ViewImageB64Component],
})
export class ViewImageB64DialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { base64Content: any }) {}
}
