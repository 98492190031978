<div class="confirm-password-change-dialog">
  <h2 class="mat-h2" mat-dialog-title class="fuseUser-header">
    {{ 'global.words.changePassword' | translate }}
  </h2>

  <mat-dialog-content>
    <h4>{{ 'global.form.passwordSuccessfullyChanged' | translate }}</h4>
  </mat-dialog-content>

  <mat-dialog-actions>
    <!--   <button mat-button mat-dialog-close>Cancel</button> -->
    <button
      mat-raised-button
      color="primary"
      class="submit-button float-center confirm-password-change-button"
      aria-label="Ok"
      [mat-dialog-close]="true">
      OK
    </button>
  </mat-dialog-actions>
</div>
