import { Action } from '@ngrx/store';
import { ActionWithPayload } from 'app/shared';
import { ProcessProcessDiagram } from '../../../model/process-diagram.model';

export const TRY_GET_BREADCRUMB_DIAGRAM_CHILDREN_LIST =
  '[Process Diagram Selection Breadcrumb Item] Load page from breadcrumb diagram.';
export const TRY_GET_DIAGRAM_CHILDREN_LIST = '[Process Diagram Selection List Item] Load page from selected diagram.';
export const TRY_GET_PARENT_DIAGRAM_CHILDREN_LIST =
  '[Process Diagram Selection Effects] Load page for selected parent diagram.';
export const TRY_GET_DIAGRAM_LIST_NEXT_PAGE = '[Process Diagram Selection List Scroll End] Try load next page.';
export const GET_DIAGRAM_LIST_NEXT_PAGE_SUCCESS = '[Process Diagram Selection API Response] Load next page success.';
export const GET_DIAGRAM_LIST_NEXT_PAGE_ERROR = '[Process Diagram Selection API Response] Load next page error.';
export const RESTART_STATE = '[Process Diagram Selection On Destroy] Restart state';

export class RestartState implements Action {
  readonly type = RESTART_STATE;
}

export class TryGetBreadcrumbDiagramChildrenList implements ActionWithPayload<string> {
  readonly type = TRY_GET_BREADCRUMB_DIAGRAM_CHILDREN_LIST;
  constructor(public payload: string) {}
}

export class TryGetDiagramChildrenList implements ActionWithPayload<string> {
  readonly type = TRY_GET_DIAGRAM_CHILDREN_LIST;
  constructor(public payload: string) {}
}

export class TryGetParentDiagramChildrenList implements ActionWithPayload<string> {
  readonly type = TRY_GET_PARENT_DIAGRAM_CHILDREN_LIST;
  constructor(public payload: string) {}
}

export class TryGetDiagramListNextPage implements Action {
  readonly type = TRY_GET_DIAGRAM_LIST_NEXT_PAGE;
}

export class GetDiagramListNextPageSuccess implements ActionWithPayload<ProcessProcessDiagram[]> {
  readonly type = GET_DIAGRAM_LIST_NEXT_PAGE_SUCCESS;
  constructor(public payload: ProcessProcessDiagram[]) {}
}

export class GetDiagramListNextPageError implements Action {
  readonly type = GET_DIAGRAM_LIST_NEXT_PAGE_ERROR;
}

export type PDSActions =
  | TryGetBreadcrumbDiagramChildrenList
  | TryGetParentDiagramChildrenList
  | TryGetDiagramChildrenList
  | TryGetDiagramListNextPage
  | GetDiagramListNextPageSuccess
  | GetDiagramListNextPageError
  | RestartState;
