import { OdooSubscription } from 'app/core/components/odoo/odoo-subscription/odoo-subscription.model';
import {
  LicensePackage,
  licensePackages,
  OdooPaymentCycles,
  paymentCycleIndexes,
  SelectedLicensePackageDetails,
} from '../license-upgrade.model';

import {
  UserActions,
  SET_ODOO_SUBSCRIPTION,
  SET_ODOO_SUBSCRIPTION_SUCCESS,
  SET_ODOO_SUBSCRIPTION_ERROR,
  SET_SELECTED_LICENSE_PACKAGE,
  SET_AMOUNT_USERS,
  SET_PAYMENT_CYCLE,
} from './license-upgrade.actions';

export interface State {
  odooSubscription: OdooSubscription;
  loading: boolean;
  licensePackageList: LicensePackage[];
  paymentCycle: OdooPaymentCycles;
  selectedLicensePackageDetails: SelectedLicensePackageDetails;
}

const initialState: State = {
  odooSubscription: null,
  loading: false,
  licensePackageList: licensePackages,
  paymentCycle: OdooPaymentCycles.Monthly,
  selectedLicensePackageDetails: null,
};

export function licenseUpgradeReducer(state = initialState, action: UserActions): State {
  switch (action.type) {
    case SET_ODOO_SUBSCRIPTION:
      return {
        ...state,
        odooSubscription: action.payload,
        loading: true,
      };
    case SET_ODOO_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        selectedLicensePackageDetails: {
          ...state.selectedLicensePackageDetails,
          referenceCode: action.payload?.referenceCode,
        },
        loading: false,
      };
    case SET_SELECTED_LICENSE_PACKAGE:
      return {
        ...state,
        selectedLicensePackageDetails: {
          ...state.selectedLicensePackageDetails,
          selectedLicensePackage: action.payload,
        },
      };
    case SET_AMOUNT_USERS:
      return {
        ...state,
        selectedLicensePackageDetails: {
          ...state.selectedLicensePackageDetails,
          amountUsers: action.payload,
        },
      };
    case SET_PAYMENT_CYCLE:
      return {
        ...state,
        paymentCycle: action.payload,
        selectedLicensePackageDetails: {
          ...state.selectedLicensePackageDetails,
          subscriptionTemplateID: paymentCycleIndexes[action.payload],
        },
      };
    case SET_ODOO_SUBSCRIPTION_ERROR:
    default:
      return {
        ...state,
        loading: false,
      };
  }
}

export const getOdooSubscription = (state: State) => state.odooSubscription;
export const getOdooSubscriptionLoading = (state: State) => state.loading;
export const getLicensePackages = (state: State) => state.licensePackageList;
export const getPaymentCycle = (state: State) => state.paymentCycle;
export const getSelectedLicensePackageDetails = (state: State) => state.selectedLicensePackageDetails;
