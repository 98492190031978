<div id="error-not-licensed">
  <div class="content">


    <div class="message">{{ 'error.notLicensed' | translate }}</div>
    <br />

    <a class="back-link" [routerLink]="'/login'">{{ 'error.backToLogin' | translate }}</a>
  </div>
</div>
