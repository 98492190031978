import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from './errorhandler.service';

export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(public errorHandler: ErrorHandlerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || request.url === 'api/account') return throwError(error);

        console.log('HTTP Interceptor - Caught error: ', error);
        if (error.error instanceof ErrorEvent) {
          // client-side error
          this.errorHandler.handleClientSideError(error);
        } else if (error.status !== 200) {
          // server-side error
          this.errorHandler.handleServerSideError(error);
          // if (error.status > 0) {
          //     return throwError(error);
          // }
        }
        return throwError(error);
      }),
    );
  }
}
