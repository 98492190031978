import { Routes } from '@angular/router';

import { Error403Component } from './main/content/apps/errors/403/error-403.component';
import { FuseError404Component } from './main/content/apps/errors/404/error-404.component';
import { FuseErrorNotLicensedComponent } from './main/content/apps/errors/not-licensed/error-not-licensed.component';
import { IsUnauthenticatedAccessService } from './shared/auth/unauthenticated-access-service';

export const appRoutes: Routes = [
  {
    path: 'accessdenied',
    component: Error403Component,
    data: {
      authorities: [],
      pageTitle: 'error.title',
      error403: true,
    },
  },
  {
    path: 'notlicensed',
    component: FuseErrorNotLicensedComponent,
    data: {
      authorities: [],
      pageTitle: 'error.title',
      errorNotLicensed: true,
    },
  },
  {
    path: '',
    loadChildren: () => import('./main/main.route').then((m) => m.MAIN_ROUTES),
    providers: [],
  },
  {
    path: '',
    loadChildren: () => import('./main/content/apps/authentication/authentication.routes').then((m) => m.authRoutes),
    canLoad: [IsUnauthenticatedAccessService],
  },
  {
    path: '**',
    component: FuseError404Component,
    data: {
      pageTitle: 'error.title',
    },
  },
];
