import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { HttpClient } from '@angular/common/http';
import { ApplicationConfig } from './application-data.model';
import { delay, map, retryWhen, switchMap, take, tap, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { LicenseService } from '../administration/license/license.service';
import { AdministrationUserManagerService } from '../administration/user-manager';
import { UserOptionsService } from './user-options.service';
import { setLanguageParameters, User } from 'app/shared';
import { LicenseDetails, ProductLine } from 'app/shared/license/licensedetails.model';
import { GlobalOptions } from '../administration/global-options/global-options.model';
import { UserOptions } from './user-options.model';

import * as PROFILE from 'app/main/content/apps/profile/store/profile.actions';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { ThemeService } from 'app/theme.service';
import { GlobalOptionsService } from '../administration/global-options/global-options.service';

const MAX_RETRY_ATTEMPTS = 32;
const RETRY_WAIT = 1000;

@Injectable({
   providedIn: 'root',
})
export class AppDataService {
   private _appConfig: ApplicationConfig;

   constructor(
      private http: HttpClient,
      private userService: AdministrationUserManagerService,
      private licenseService: LicenseService,
      private userOptionsService: UserOptionsService,
      private globalOptionsService: GlobalOptionsService,
      private sessionStorage: SessionStorageService,
      private localStorage: LocalStorageService,
      private store: Store,
      private themeService: ThemeService,
   ) {}

   get appData(): ApplicationConfig {
      return this._appConfig;
   }

   getApplicationData(): Observable<any> {
      return this.http.get<ApplicationConfig>(`${SERVER_API_URL}/api/tenant/application-config`).pipe(
         switchMap(appConfig => {
            const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
            this.themeService.productLine = appConfig.productLine;

            if (!token) return of([appConfig, null]);

            return forkJoin([
               this.userService.getCurrentUser().pipe(
                  map(({ data }) => data),
                  catchError(() => of(null)),
               ),
               this.licenseService.getLicense().pipe(
                  map(({ data }) => data),
                  catchError(() => of(null)),
               ),
               this.userOptionsService.getOptionsForCurrentUser().pipe(
                  map(({ data }) => data),
                  catchError(() => of(null)),
               ),
               this.globalOptionsService.find().pipe(catchError(() => of(null))),
            ]).pipe(map(([user, license, userOptions, globalOptions]: [User, LicenseDetails, UserOptions, GlobalOptions]) => [appConfig, { user, license, userOptions, globalOptions }]));
         }),
         catchError(err => {
            throw new Error();
         }),
         retryWhen(errors => errors.pipe(delay(RETRY_WAIT), take(MAX_RETRY_ATTEMPTS))),
         tap(([config, initData]) => {
            this._appConfig = config;
            this.initMatomoScript(config?.matomoAddress);
            this.store.dispatch(new PROFILE.SetApplicationData(config));
            if (initData && initData.user) {
               this.themeService.theme = initData.userOptions.options?.theme;
               this.store.dispatch(new PROFILE.SetDataOnInit(initData));
               const multilangEnabled = initData.license?.license?.features.multilanguage.enabled;
               setLanguageParameters(multilangEnabled);
            }
         }),
      );
   }

   private initMatomoScript(matomoAddress: string): void {
      if (!matomoAddress) return;

      const slash = matomoAddress[matomoAddress.length - 1] === '/' ? '' : '/';

      const _paq = (globalThis._paq = globalThis._paq || []);

      _paq.push(...[['trackPageView'], ['enableLinkTracking'], ['requireConsent'], ['setTrackerUrl', matomoAddress + slash + 'matomo.php'], ['setSiteId', '1']]);

      const matomoScript = document.createElement('script');
      matomoScript.type = 'text/javascript';
      matomoScript.async = true;
      matomoScript.src = matomoAddress + slash + 'matomo.js';
      document.body.append(matomoScript);
   }
}
