import { Component, Input } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton, TranslateModule, NgIf],
})
export class ConfirmDialogComponent {
  @Input() public confirmMessage = 'global.confirmation-dialog.default';
  @Input() public title = 'global.confirmation-dialog.title';
  @Input() public trueButton = 'global.confirmation-dialog.title';
  @Input() public falseButton = 'entity.action.cancel';
  @Input() public disableFalseButton = false;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}
}
