import { Observable, tap } from 'rxjs';

import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpResponseBase,
  } from '@angular/common/http';

import { Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { TokenIssued } from 'app/main/content/apps/profile/store/profile.actions';


export class AuthIssuedAtTimeInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: HttpResponseBase) => {
          //TODO : create const for statusTet
          if ((!(err instanceof HttpErrorResponse) || err.status !== 401)) return;
          if(err.error.message != 'Token issued') return;
          const store: Store = this.injector.get(Store);
          store.dispatch(new TokenIssued());
    
        },
      ),
    );
  }
}





