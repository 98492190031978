import { produce } from 'immer';
import { Part } from '../model/part.model';
import { PartActions } from './part.actions';
import * as PA from './part.actions';
import { Textstore } from 'app/shared/lang-dialog/textstore.model';

export interface State {
  parts: Part[];
  loading: boolean;
  selectedPartId: string;
  page: number;
  creatingPart: Part;
}

const initialState: State = {
  parts: [],
  loading: false,
  selectedPartId: null,
  page: 0,
  creatingPart: null,
};

export const partReducer = produce((draft: State, action: PartActions) => {
  switch (action.type) {
    case PA.DESELECT_PART:
      draft.selectedPartId = null;

      return;
    case PA.TRY_GET_FILTERED_PART_LIST:
    case PA.TRY_GET_PART_LIST:
      draft.page = initialState.page;
      draft.parts = initialState.parts;
      draft.selectedPartId = initialState.selectedPartId;

      return;
    case PA.GET_PARTS_SUCCESS:
      draft.loading = false;
      if (action.payload?.length > 0) draft.page += 1;
      draft.parts.push(...action.payload);

      return;
    case PA.START_PART_CREATION:
      draft.selectedPartId = null;
      draft.creatingPart = {
        name: Textstore.withInitatedLanguages(),
        description: Textstore.withInitatedLanguages(),
        documentList: [],
        number: '',
        revisionNumber: '',
        unit: null,
      };

      return;
    case PA.CREATE_PART_SUCCESS:
      draft.loading = false;
      draft.parts.unshift(action.payload);
      draft.creatingPart = null;
      draft.selectedPartId = action.payload.id;

      return;
    case PA.FIND_PART_SUCCESS:
      draft.loading = false;
      draft.creatingPart = null;

      const foundPartIndex = draft.parts.findIndex((part) => part.id === action.payload.id);
      if (foundPartIndex < 0) return;

      draft.parts[foundPartIndex] = action.payload;
      draft.selectedPartId = action.payload.id;

      return;
    case PA.UPDATE_PART_SUCCESS:
      draft.loading = false;

      const changedPartIndex = draft.parts.findIndex((part) => part.id === action.payload.id);
      if (changedPartIndex < 0) return;

      draft.parts[changedPartIndex] = action.payload;

      return;
    case PA.REMOVE_SELECTED_PART_SUCCESS:
      draft.loading = false;
      draft.parts = draft.parts.filter((part) => part.id !== action.payload);

      return;
    case PA.RESET_STATE:
      Object.assign(draft, initialState);
      return;
    case PA.TRY_GET_NEXT_PART_PAGE:
    case PA.TRY_CREATE_PART:
    case PA.TRY_UPDATE_PART:
    case PA.TRY_REMOVE_SELECTED_PART:
    case PA.TRY_FIND_PART:
      draft.loading = true;

      return;
    case PA.GET_PARTS_ERROR:
    case PA.CREATE_PART_ERROR:
    case PA.UPDATE_PART_ERROR:
    case PA.REMOVE_SELECTED_PART_ERROR:
    case PA.FIND_PART_ERROR:
      draft.loading = false;
  }
}, initialState);

export const getParts = (state: State) => state.parts;
export const getPartsLoading = (state: State) => state.loading;
export const getSelectedPartId = (state: State) => state.selectedPartId;
export const getPartsPage = (state: State) => state.page;
export const getPartBeingCreated = (state: State) => state.creatingPart;
