import { Action } from '@ngrx/store';
import { ActionWithPayload } from '../../../../../../shared/action.payload';
import { Catalog } from '../catalog.model';
import { IRestResponse, IRestError } from 'app/core/models/rest.model';
import { CatalogView } from '../catalog-views';

export const TRY_FIND_CATALOG_LIBRARY_ITEM = '[Catalog Api Route change] Try find catalog library item';
export const FIND_CATALOG_LIBRARY_ITEM_SUCCESS = '[Catalog Api response] Find catalog library item success';
export const FIND_CATALOG_LIBRARY_ITEM_ERROR = '[Catalog Api response] Find catalog library item error';

export const TRY_GET_SEARCHED_CATALOG_LIST = '[Catalog search input] Try get searched catalogs';
export const GET_SEARCHED_CATALOG_LIST_SUCCESS = '[Catalog Api response] Get searched catalogs success';
export const GET_SEARCHED_CATALOG_LIST_ERROR = '[Catalog Api response] Get searched catalogs error';
export const TRY_GET_CATALOG_NEXT_PAGE = '[CAT] Try get cat next page';
export const GET_CATALOG_NEXT_PAGE_SUCCESS = '[CAT] Get cat next page success';
export const GET_CATALOG_NEXT_PAGE_ERROR = '[CAT] Get cat next page error';

export const TRY_FIND_CATALOG = '[CAT] Try find cat';
export const FIND_CATALOG_SUCCESS = '[CAT] Find cat success';
export const FIND_CATALOG_ERROR = '[CAT] Find cat error';

export const TRY_FIND_CATALOG_CHILDREN = '[CAT] Try find cat children';
export const TRY_GET_CATALOG_CHILDREN_NEXT_PAGE = '[CAT] Try get cat children next page';
export const GET_CATALOG_CHILDREN_NEXT_PAGE_SUCCESS = '[CAT] Get cat children next page success';
export const GET_CATALOG_CHILDREN_NEXT_PAGE_ERROR = '[CAT] Get cat children next page error';
export const CATALOG_HAS_NO_CHILDREN = '[Catalog effects effects] Skip children loading';

export const TRY_ADD_CATALOG = '[CAT] Try Add cat';
export const ADD_CATALOG_SUCCESS = '[CAT] Add cat success';
export const ADD_CATALOG_ERROR = '[CAT] Add cat error';

export const TRY_ADD_CATALOG_CHILDREN = '[Catalog details submit form] Try add cat children.';
export const ADD_CATALOG_CHILDREN_SUCCESS = '[Catalog details Api response] Add cat children success.';
export const ADD_CATALOG_CHILDREN_ERROR = '[Catalog details Api response] Add cat children error.';

export const TRY_UPDATE_CATALOG = '[CAT] Try Update cat';
export const UPDATE_CATALOG_SUCCESS = '[CAT] Update cat success';
export const UPDATE_CATALOG_ERROR = '[CAT] Update cat error';

export const TRY_REMOVE_CATALOG = '[CAT] TRY Remove cat';
export const REMOVE_CATALOG_SUCCESS = '[CAT] Remove cat success';
export const REMOVE_CATALOG_ERROR = '[CAT] Remove cat error';

export const SELECT_CHILD_CATALOG = '[Catalog children list item] Select child cat';
export const SELECT_CATALOG = '[CAT] Set selected cat';

export const TRY_GET_SORTED_CATALOGS = '[Catalog Header] Try get sorted catalogs';

export const SET_DISPLAY_MODE = '[CAT] Set display mode';

export const SET_SELECTED_CATALOG_NEW = '[CAT] Set selected catalog for creation';
export const SET_SELECTED_CATALOG_NEW_FROM_SELECTED = '[CAT] Set selected catalog for creation from selected';

export const RESET_CATALOG_LIST_VIEW = '[Catalog Home Button Click] Reset cat list view';

export const NAVIGATE_OUTSIDE_CATALOGS = '[Catalogs navigate back button] Reroute back to catalogs';

export const TRY_SELECT_CATALOG_PARENT = '[Catalog mobile back button] Try select parent catalog.';
export const RETURN_TO_SETTINGS = '[Catalog mobile back button] Return to settings';
export const DESELECT_CATALOG = '[Catalog mobile back button] Deselect Catlaog';
export const SELECT_CATALOG_PARENT_SUCCESS = '[Catalog mobile back button] Select parent catalog success.';
export const SELECT_CATALOG_PARENT_ERROR = '[Catalog mobile back button] Select parent catalog error.';

export const OPEN_CHILD_LIST_VIEW = '[Catalog children list view] Open child list view';

export const TRY_NAVIGATE_TO_BREADCRUMB_ITEM = '[Catalog breadcrumb item] Try navigate to breadcrumb item';
export const NAVIGATE_TO_BREADCRUMB_ITEM_SUCCESS = '[Catalog Api response] Navigate to breadcrumb item success';
export const NAVIGATE_TO_BREADCRUMB_ITEM_ERROR = '[Catalog Api response] Navigate to breadcrumb item error';

export const TRY_DRAG_CATALOG = '[Drag catalog] Try to drag the catalog to another location';
export const DRAG_CATALOG_SUCCESS = '[Drag catalog] Dragging catalog success';
export const DRAG_CATALOG_ERROR = '[Drag catalog] Dragging catalog error';

export const TRY_UPDATE_BRIDGE = '[Update BRIDGE] Try to update BRIDGE Configurations';
export const UPDATE_BRIDGE_SUCCESS = '[Update BRIDGE] Update BRIDGE Configurations success';
export const UPDATE_BRIDGE_ERROR = '[Update BRIDGE] Update BRIDGE Configurations error';

export class TryNavigateToBreadcrumbItem implements ActionWithPayload<string> {
  readonly type = TRY_NAVIGATE_TO_BREADCRUMB_ITEM;
  constructor(public payload: string) {}
}

export class NavigateToBreadcrumbItemSuccess implements ActionWithPayload<Catalog> {
  readonly type = NAVIGATE_TO_BREADCRUMB_ITEM_SUCCESS;
  constructor(public payload: Catalog) {}
}

export class NavigateToBreadcrumbItemError implements Action {
  readonly type = NAVIGATE_TO_BREADCRUMB_ITEM_ERROR;
}

export class TryGetSearchedCatList implements Action {
  readonly type = TRY_GET_SEARCHED_CATALOG_LIST;
}

export class GetSearchedCatListSuccess implements ActionWithPayload<Catalog[]> {
  readonly type = GET_SEARCHED_CATALOG_LIST_SUCCESS;
  constructor(public payload: Catalog[]) {}
}

export class GetSearchedCatListError implements Action {
  readonly type = GET_SEARCHED_CATALOG_LIST_ERROR;
}

export class NavigateOutsideCatalogs implements Action {
  readonly type = NAVIGATE_OUTSIDE_CATALOGS;
}

export class TrySelectCatalogParent implements Action {
  readonly type = TRY_SELECT_CATALOG_PARENT;
}

export class ReturnToSettings implements Action {
  readonly type = RETURN_TO_SETTINGS;
}

export class DeselectCatalog implements Action {
  readonly type = DESELECT_CATALOG;
}

export class SelectCatalogParentSuccess implements ActionWithPayload<Catalog> {
  readonly type = SELECT_CATALOG_PARENT_SUCCESS;
  constructor(public payload: Catalog) {}
}

export class SelectCatalogParentError implements Action {
  readonly type = SELECT_CATALOG_PARENT_ERROR;
}

export class SelectCatalogChild implements ActionWithPayload<Catalog> {
  readonly type = SELECT_CHILD_CATALOG;
  constructor(public payload: Catalog) {}
}

export class ResetCatalogListView implements Action {
  readonly type = RESET_CATALOG_LIST_VIEW;
}

export class CatalogHasNoChildren implements Action {
  readonly type = CATALOG_HAS_NO_CHILDREN;
}

export class StartChildCatalogCreation implements ActionWithPayload<'leaf' | 'folder'> {
  readonly type = SET_SELECTED_CATALOG_NEW;
  constructor(public payload: 'leaf' | 'folder') {}
}

export class StartCatalogCreation implements ActionWithPayload<'leaf' | 'folder'> {
  readonly type = SET_SELECTED_CATALOG_NEW_FROM_SELECTED;
  constructor(public payload: 'leaf' | 'folder') {}
}

export class TryGetSortedCatalogs
  implements
    ActionWithPayload<{
      label: string;
      value: string;
      isTextstore: boolean;
      direction: 'asc' | 'desc';
    }>
{
  readonly type = TRY_GET_SORTED_CATALOGS;
  constructor(
    public payload: {
      label: string;
      value: string;
      isTextstore: boolean;
      direction: 'asc' | 'desc';
    },
  ) {}
}

export class TrySetCatalogLibraryItem implements ActionWithPayload<string> {
  readonly type = TRY_FIND_CATALOG_LIBRARY_ITEM;
  constructor(public payload: string) {}
}
export class SetCatalogLibraryItemSuccess implements ActionWithPayload<any> {
  readonly type = FIND_CATALOG_LIBRARY_ITEM_SUCCESS;
  constructor(public payload: any) {}
}

export class OpenChildListView implements ActionWithPayload<Catalog> {
  readonly type = OPEN_CHILD_LIST_VIEW;
  constructor(public payload: Catalog) {}
}

export class SetCatalogLibraryItemError implements Action {
  readonly type = FIND_CATALOG_LIBRARY_ITEM_ERROR;
}

export class TryGetNextCatalogPage implements Action {
  readonly type = TRY_GET_CATALOG_NEXT_PAGE;
}
export class GetCatNextPageSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_CATALOG_NEXT_PAGE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class GetCatNextPageError implements Action {
  readonly type = GET_CATALOG_NEXT_PAGE_ERROR;
}

export class TryFindCatChildren implements ActionWithPayload<string> {
  readonly type = TRY_FIND_CATALOG_CHILDREN;
  constructor(public payload: string) {}
}

export class TryGetChildrenCatalogNextPage implements Action {
  readonly type = TRY_GET_CATALOG_CHILDREN_NEXT_PAGE;
}

export class GetCatChildrenNextPageSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_CATALOG_CHILDREN_NEXT_PAGE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}

export class GetCatChildrenNextPageError implements ActionWithPayload<IRestError> {
  readonly type = GET_CATALOG_CHILDREN_NEXT_PAGE_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryAddCatalog implements ActionWithPayload<Catalog> {
  readonly type = TRY_ADD_CATALOG;
  constructor(public payload: Catalog) {}
}
export class AddCatSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = ADD_CATALOG_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class AddCatError implements ActionWithPayload<IRestError> {
  readonly type = ADD_CATALOG_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryUpdateCatalog implements ActionWithPayload<Catalog> {
  readonly type = TRY_UPDATE_CATALOG;
  constructor(public payload: Catalog) {}
}

export class UpdateCatSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = UPDATE_CATALOG_SUCCESS;
  constructor(public payload: IRestResponse) {}
}

export class UpdateCatError implements ActionWithPayload<IRestError> {
  readonly type = UPDATE_CATALOG_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryRemoveCatalog implements Action {
  readonly type = TRY_REMOVE_CATALOG;
}

export class RemoveCatSuccess implements Action {
  readonly type = REMOVE_CATALOG_SUCCESS;
}
export class RemoveCatError implements ActionWithPayload<IRestError> {
  readonly type = REMOVE_CATALOG_ERROR;
  constructor(public payload: IRestError) {}
}

export class SelectCatalog implements ActionWithPayload<Catalog> {
  readonly type = SELECT_CATALOG;
  constructor(public payload: Catalog) {}
}

export class TryAddCatChildren implements ActionWithPayload<Catalog> {
  readonly type = TRY_ADD_CATALOG_CHILDREN;
  constructor(public payload: Catalog) {}
}

export class AddCatChildrenSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = ADD_CATALOG_CHILDREN_SUCCESS;
  constructor(public payload: IRestResponse) {}
}

export class AddCatChildrenError implements Action {
  readonly type = ADD_CATALOG_CHILDREN_ERROR;
}

export class SetDisplayMode implements ActionWithPayload<CatalogView> {
  readonly type = SET_DISPLAY_MODE;
  constructor(public payload: CatalogView) {}
}

export class TryDragCatalog implements ActionWithPayload<{ targetFolderId: string; catalogId: string }> {
  readonly type = TRY_DRAG_CATALOG;
  constructor(public payload: { targetFolderId: string; catalogId: string }) {}
}

export class DragCatalogSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = DRAG_CATALOG_SUCCESS;
  constructor(public payload: IRestResponse) {}
}

export class DragCatalogError implements Action {
  readonly type = DRAG_CATALOG_ERROR;
}

export class TryUpdateBridge implements ActionWithPayload<Catalog> {
  readonly type = TRY_UPDATE_BRIDGE;
  constructor(public payload: Catalog) {}
}

export class UpdateBridgeSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = UPDATE_BRIDGE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}

export class UpdateBridgeError implements Action {
  readonly type = UPDATE_BRIDGE_ERROR;
}

export type CAT_Actions =
  | TrySetCatalogLibraryItem
  | SetCatalogLibraryItemSuccess
  | TryGetSortedCatalogs
  | TryGetNextCatalogPage
  | GetCatNextPageSuccess
  | GetCatNextPageError
  | TryFindCatChildren
  | TryGetChildrenCatalogNextPage
  | GetCatChildrenNextPageSuccess
  | GetCatChildrenNextPageError
  | TryAddCatalog
  | AddCatSuccess
  | AddCatError
  | TryNavigateToBreadcrumbItem
  | NavigateToBreadcrumbItemSuccess
  | NavigateToBreadcrumbItemError
  | TryUpdateCatalog
  | UpdateCatSuccess
  | UpdateCatError
  | TryRemoveCatalog
  | RemoveCatSuccess
  | RemoveCatError
  | SelectCatalog
  | SetDisplayMode
  | StartChildCatalogCreation
  | StartCatalogCreation
  | CatalogHasNoChildren
  | ResetCatalogListView
  | NavigateOutsideCatalogs
  | TrySelectCatalogParent
  | ReturnToSettings
  | DeselectCatalog
  | SelectCatalogParentSuccess
  | SelectCatalogParentError
  | SelectCatalogChild
  | TryAddCatChildren
  | AddCatChildrenSuccess
  | AddCatChildrenError
  | TryGetSearchedCatList
  | GetSearchedCatListSuccess
  | GetSearchedCatListError
  | SetCatalogLibraryItemError
  | TryDragCatalog
  | DragCatalogSuccess
  | DragCatalogError
  | TryUpdateBridge
  | UpdateBridgeSuccess
  | UpdateBridgeError
  | OpenChildListView;
