import { produce } from 'immer';
import { GlobalOptions } from '../global-options.model';
import {
  LOAD_GLOBAL_OPTIONS_ERROR,
  LOAD_GLOBAL_OPTIONS_SUCCESS,
  RESET_STATE,
  TRY_LOAD_GLOBAL_OPTIONS,
  TRY_UPDATE_GLOBAL_OPTIONS,
  GlobalOptionsActions,
  UPDATE_GLOBAL_OPTIONS_ERROR,
  UPDATE_GLOBAL_OPTIONS_SUCCESS,
} from './global-options.actions';

export interface State {
  globalOptions: GlobalOptions;
  loading: boolean;
}

const initialState: State = {
  globalOptions: null,
  loading: false,
};

export const globalOptionsReducer = produce((state: State = initialState, action: GlobalOptionsActions) => {
  switch (action.type) {
    case TRY_UPDATE_GLOBAL_OPTIONS:
    case TRY_LOAD_GLOBAL_OPTIONS:
      return {
        loading: true,
      };
    case UPDATE_GLOBAL_OPTIONS_ERROR:
    case LOAD_GLOBAL_OPTIONS_ERROR:
      return {
        loading: false,
      };
    case LOAD_GLOBAL_OPTIONS_SUCCESS:
      return {
        ...state,
        globalOptions: action.payload,
        loading: false,
      };
    case UPDATE_GLOBAL_OPTIONS_SUCCESS:
      return {
        ...state,
        globalOptions: action.payload.options,
        loading: false,
      };
    case RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
});

export const getGlobalOptions = (state: State) => state.globalOptions;
export const getGlobalOptionsLoading = (state: State) => state.loading;
