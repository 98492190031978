import { Action } from '@ngrx/store';
import { ProcessProcessDiagram } from '../../model/process-diagram.model';
import { ActionWithPayload } from '../../../../../../../shared';
import { IRestResponse } from 'app/core/models/rest.model';
import { ProcessInstance } from 'app/main/content/apps/result/process-result/process-instance.model';

export const CLEAR_DIAGRAM_SELECTION = '[Process Diagram Detail Back Arrow] Deselect selected process diagram';

export const SELECT_DIAGRAM_AS_PARENT = '[Process Diagram list item folder double click] Select diagram as parent.';

export const TRY_GET_DIAGRAM_NEXT_PAGE = '[Process Diagram List Infinite Scroll] Try get diagram next page';
export const GET_DIAGRAM_NEXT_PAGE_SUCCESS = '[Process Diagram Api Response] Get diagram next page success';
export const GET_DIAGRAM_NEXT_PAGE_ERROR = '[Process Diagram Api Response] Get diagram next page error';

export const SET_SELECTED_DIAGRAM = '[Process Diagram Selection] Set selected process diagram';

export const TRY_ADD_DIAGRAM = '[Process Diagram Details Submit] Try Add diagram';
export const ADD_DIAGRAM_SUCCESS = '[Process Diagram Api Response] Add diagram success';
export const ADD_DIAGRAM_ERROR = '[Process Diagram Api Response] Add diagram error';

export const TOGGLE_DIAGRAM_DELETED =
  '[Process Diagram Toggle Deleted Header Button] Updated deleted flag in selected diagram';
export const TRY_UPDATE_DIAGRAM = '[Process Diagram Form Update and Toggle Deleted] Try Update diagram';
export const UPDATE_DIAGRAM_SUCCESS = '[Process Diagram Api Response] Update diagram success';
export const UPDATE_DIAGRAM_ERROR = '[Process Diagram Api Response] Update diagram error';

export const TRY_REMOVE_DIAGRAM = '[Process Diagram Details Remove Button] Try Remove diagram';
export const REMOVE_DIAGRAM_SUCCESS = '[Process Diagram Api Response] Remove diagram success';
export const REMOVE_DIAGRAM_ERROR = '[Process Diagram Api Response] Remove diagram error';

export const START_NEW_DIAGRAM_CREATION = '[Process Diagram List Add button] Start new diagram creation.';

export const TRY_ROUTE_UPDATE_DIAGRAM_DATA = '[Process Diagram Route Change] Try load route diagram';
export const ROUTE_UPDATE_DIAGRAM_DATA_SUCCESS = '[Process Diagram Api Response] Load route diagram success';
export const ROUTE_UPDATE_DIAGRAM_DATA_ERROR = '[Process Diagram Api Response] Load route diagram error';

export const TRY_LOAD_CHILDREN_LIST = '[Process Root navigation triggered] Try load children diagrams';
export const TRY_LOAD_SELECTED_DIAGRAM_LIST = '[Process Root navigation triggered] Try load selected diagram list';
export const TRY_LOAD_INITIAL_DIAGRAM_LIST = '[Process Diagram List Loading Parameters] Try load initial diagrams';
export const TRY_LOAD_SORTED_DIAGRAM_LIST = '[Process Diagram List Loading] Try load sorted diagrams';
export const TRY_LOAD_FILTERED_DIAGRAM_LIST = '[Process Diagram Filter/Search] Load filtered diagrams';

export const TRY_FIND_DIAGRAM = '[Process Diagram List Selection] Try find diagram';
export const TRY_FIND_DIAGRAM_FROM_BREADCRUMB = '[Process Diagram Breadcrumb] Try find diagram';

export const TRY_LOAD_BREADCRUMB = '[Process Diagram Routing] Try load breadcrumb';
export const LOAD_BREADCRUMB_SUCCESS = '[Process Diagram Api Response] Load breadcrumb success';
export const LOAD_BREADCRUMB_ERROR = '[Process Diagram Api Response] Load breadcrumb error';

export const SLICE_BREADCRUMB = '[Process Diagram Routing] Slice existing breadcrumb size after navigation';

export const TRY_OPEN_PROCESS_DESIGNER = '[Process Diagram table double click] Try open process designer.';
export const OPEN_PROCESS_DESIGNER_SUCCESS = '[Process Diagram Api response] Open process designer success.';
export const OPEN_PROCESS_DESIGNER_ERROR = '[Process Diagram Api Response] Open process designer error.';

export const TRY_START_PROCESS_INSTANCE = '[Process Diagram Play button] Try start process instance.';
export const START_PROCESS_INSTANCE_SUCCESS = '[Process Diagram Api response] Start process instance success.';
export const START_PROCESS_INSTANCE_CANCEL = '[Process Diagram Mat dialog] Cancel process instance creation';
export const START_PROCESS_INSTANCE_ERROR = '[Process Diagram Api Response] Start process instance error.';

export const RESET_STATE = '[Process Diagram On Destroy] Restart state';

export const START_TRACKING_ROUTE_CHANGES = '[Process Diagram On Init] Start tracking route changes.';
export const STOP_TRACKING_ROUTE_CHANGES = '[Process Diagram On Destroy] Stop tracking route changes.';

export const START_TRACKING_LANG_CHANGES = '[Process Diagram On Init] Start tracking lang changes.';
export const STOP_TRACKING_LANG_CHANGES = '[Process Diagram On Destroy] Stop tracking lang changes.';

export const ATEMPTED_NAVIGATION_TO_SAME_DIAGRAM =
  '[Process Diagram Breadcrumb/Parent Navigation to the same Diagram] Clear selected diagram';

export const UPDATE_VIEW_ONLY_MODE = '[Process Diagram init] Update view only status';
export const LOAD_VIEW_ONLY_MODE = '[Process Diagram Effects] Set if view only mode';

export const DESELECT_DIAGRAM = '[Process Diagram Effects Route to same root] Set if view only mode';

export const TRY_DRAG_DIAGRAM = '[Process Diagram Table Drop diagram on parent] Try update diagram parent';
export const DRAG_DIAGRAM_SUCCESS = '[Process Diagram Api Response] Update diagram parent success';
export const DRAG_DIAGRAM_ERROR = '[Process Diagram Api Response] Update diagram parent error';

export class TryDragDiagram implements ActionWithPayload<{ targetDiagramId: string; diagramId: string }> {
  readonly type = TRY_DRAG_DIAGRAM;
  constructor(public payload: { targetDiagramId: string; diagramId: string }) {}
}

export class DragDiagramSuccess implements ActionWithPayload<{ targetDiagramId: string; diagramId: string }> {
  readonly type = DRAG_DIAGRAM_SUCCESS;
  constructor(public payload: { targetDiagramId: string; diagramId: string }) {}
}

export class DragDiagramError implements Action {
  readonly type = DRAG_DIAGRAM_ERROR;
}

export class DeselectDiagram implements Action {
  readonly type = DESELECT_DIAGRAM;
}

export class UpdateViewOnlyMode implements Action {
  readonly type = UPDATE_VIEW_ONLY_MODE;
}

export class LoadViewOnlyMode implements ActionWithPayload<boolean> {
  readonly type = LOAD_VIEW_ONLY_MODE;
  constructor(public payload: boolean) {}
}

export class TryLoadChildrenList implements ActionWithPayload<string> {
  readonly type = TRY_LOAD_CHILDREN_LIST;
  constructor(public payload: string) {}
}

export class TryLoadSelectedDiagramList implements ActionWithPayload<string> {
  readonly type = TRY_LOAD_SELECTED_DIAGRAM_LIST;
  constructor(public payload: string) {}
}

export class StartTrackingRouteChanges implements Action {
  readonly type = START_TRACKING_ROUTE_CHANGES;
}

export class StopTrackingRouteChanges implements Action {
  readonly type = STOP_TRACKING_ROUTE_CHANGES;
}

export class StartTrackingLangChanges implements Action {
  readonly type = START_TRACKING_LANG_CHANGES;
}

export class StopTrackingLangChanges implements Action {
  readonly type = STOP_TRACKING_LANG_CHANGES;
}

export class ResetState implements Action {
  readonly type = RESET_STATE;
}

export class StartProcessInstanceSuccess implements ActionWithPayload<ProcessInstance> {
  readonly type = START_PROCESS_INSTANCE_SUCCESS;
  constructor(public payload: ProcessInstance) {}
}

export class TryToggleSelectedDiagramDeleted implements Action {
  readonly type = TOGGLE_DIAGRAM_DELETED;
}

export class TryOpenProcessDesigner implements Action {
  readonly type = TRY_OPEN_PROCESS_DESIGNER;
}
export class OpenProcessDesignerSuccess implements Action {
  readonly type = OPEN_PROCESS_DESIGNER_SUCCESS;
}
export class OpenProcessDesignerError implements Action {
  readonly type = OPEN_PROCESS_DESIGNER_ERROR;
}

export class TryStartProcessInstance implements Action {
  readonly type = TRY_START_PROCESS_INSTANCE;
}
export class StartProcessInstanceError implements Action {
  readonly type = START_PROCESS_INSTANCE_ERROR;
}
export class TryLoadBreadcrumb implements Action {
  readonly type = TRY_LOAD_BREADCRUMB;
}

export class SelectDiagramAsParent implements ActionWithPayload<string> {
  readonly type = SELECT_DIAGRAM_AS_PARENT;
  constructor(public payload: string) {}
}

export class LoadBreadcrumbSuccess implements ActionWithPayload<ProcessProcessDiagram[]> {
  readonly type = LOAD_BREADCRUMB_SUCCESS;
  constructor(public payload: ProcessProcessDiagram[]) {}
}

export class LoadBreadcrumbError implements Action {
  readonly type = LOAD_BREADCRUMB_ERROR;
}

export class TryLoadInitialDiagramList implements Action {
  readonly type = TRY_LOAD_INITIAL_DIAGRAM_LIST;
}

export class TryLoadSortedDiagramList implements Action {
  readonly type = TRY_LOAD_SORTED_DIAGRAM_LIST;
}

export class TryLoadFilteredDiagramList implements Action {
  readonly type = TRY_LOAD_FILTERED_DIAGRAM_LIST;
}

export class TryRouteUpdateDiagramData implements ActionWithPayload<string> {
  readonly type = TRY_ROUTE_UPDATE_DIAGRAM_DATA;
  constructor(public payload: string) {}
}

export class RouteUpdateDiagramDataSuccess
  implements
    ActionWithPayload<{
      diagram: ProcessProcessDiagram;
      rootDiagramId: string;
    }>
{
  readonly type = ROUTE_UPDATE_DIAGRAM_DATA_SUCCESS;
  constructor(public payload: { diagram: ProcessProcessDiagram; rootDiagramId: string }) {}
}

export class RouteUpdateDiagramDataError implements Action {
  readonly type = ROUTE_UPDATE_DIAGRAM_DATA_ERROR;
}

export class StartNewDiagramCreation implements ActionWithPayload<'folder' | 'leaf'> {
  readonly type = START_NEW_DIAGRAM_CREATION;
  constructor(public payload: 'folder' | 'leaf') {}
}

export class ClearDiagramSelection implements Action {
  readonly type = CLEAR_DIAGRAM_SELECTION;
}

export class TryGetDiagramNextPage implements Action {
  readonly type = TRY_GET_DIAGRAM_NEXT_PAGE;
}
export class GetDiagramNextPageSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_DIAGRAM_NEXT_PAGE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class GetDiagramNextPageError implements Action {
  readonly type = GET_DIAGRAM_NEXT_PAGE_ERROR;
}

export class SetSelectedProcessDiagram implements ActionWithPayload<ProcessProcessDiagram> {
  readonly type = SET_SELECTED_DIAGRAM;
  constructor(public payload: ProcessProcessDiagram) {}
}

export class TryAddDiagram implements ActionWithPayload<ProcessProcessDiagram> {
  readonly type = TRY_ADD_DIAGRAM;
  constructor(public payload: ProcessProcessDiagram) {}
}
export class AddDiagramSuccess implements ActionWithPayload<ProcessProcessDiagram> {
  readonly type = ADD_DIAGRAM_SUCCESS;
  constructor(public payload: ProcessProcessDiagram) {}
}
export class AddDiagramError implements Action {
  readonly type = ADD_DIAGRAM_ERROR;
}

export class TryUpdateDiagram implements ActionWithPayload<ProcessProcessDiagram> {
  readonly type = TRY_UPDATE_DIAGRAM;
  constructor(public payload: ProcessProcessDiagram) {}
}
export class UpdateDiagramSuccess implements ActionWithPayload<ProcessProcessDiagram> {
  readonly type = UPDATE_DIAGRAM_SUCCESS;
  constructor(public payload: ProcessProcessDiagram) {}
}
export class UpdateDiagramError implements Action {
  readonly type = UPDATE_DIAGRAM_ERROR;
}

export class TryRemoveSelectedDiagram implements Action {
  readonly type = TRY_REMOVE_DIAGRAM;
}

export class RemoveDiagramSuccess implements Action {
  readonly type = REMOVE_DIAGRAM_SUCCESS;
}
export class RemoveDiagramEror implements Action {
  readonly type = REMOVE_DIAGRAM_ERROR;
}

export class TryFindDiagram implements ActionWithPayload<string> {
  readonly type = TRY_FIND_DIAGRAM;
  constructor(public payload: string) {}
}

export class TryFindBreadcrumbDiagram implements ActionWithPayload<string> {
  readonly type = TRY_FIND_DIAGRAM_FROM_BREADCRUMB;
  constructor(public payload: string) {}
}

export class SliceBreadcrumb implements Action {
  readonly type = SLICE_BREADCRUMB;
}

export type PDActions =
  | SliceBreadcrumb
  | TryGetDiagramNextPage
  | GetDiagramNextPageSuccess
  | GetDiagramNextPageError
  | SetSelectedProcessDiagram
  | TryAddDiagram
  | AddDiagramSuccess
  | AddDiagramError
  | TryUpdateDiagram
  | UpdateDiagramSuccess
  | UpdateDiagramError
  | TryRemoveSelectedDiagram
  | RemoveDiagramSuccess
  | RemoveDiagramEror
  | TryLoadBreadcrumb
  | LoadBreadcrumbSuccess
  | LoadBreadcrumbError
  | TryFindDiagram
  | TryFindBreadcrumbDiagram
  | TryRouteUpdateDiagramData
  | RouteUpdateDiagramDataSuccess
  | RouteUpdateDiagramDataError
  | StartNewDiagramCreation
  | TryLoadInitialDiagramList
  | TryLoadSortedDiagramList
  | TryLoadFilteredDiagramList
  | ClearDiagramSelection
  | TryOpenProcessDesigner
  | OpenProcessDesignerSuccess
  | OpenProcessDesignerError
  | TryStartProcessInstance
  | StartProcessInstanceSuccess
  | StartProcessInstanceError
  | SelectDiagramAsParent
  | TryToggleSelectedDiagramDeleted
  | StartTrackingRouteChanges
  | StopTrackingRouteChanges
  | TryLoadChildrenList
  | TryLoadSelectedDiagramList
  | UpdateViewOnlyMode
  | LoadViewOnlyMode
  | DeselectDiagram
  | TryDragDiagram
  | DragDiagramSuccess
  | DragDiagramError
  | ResetState;
