/* eslint-disable max-lines-per-function */
import {
  FilterActions,
  GET_FILTER_LIST_FOR_MODULE_SUCCESS,
  GET_FILTER_LIST_FOR_MODULE_ERROR,
  SET_SELECTED_FILTER,
  DELETE_FILTER_SUCCESS,
  ADD_FILTER_SUCCESS,
  UPDATE_FILTER_SUCCESS,
  SET_SEARCH_TEXT,
  SET_ACTIVE_FILTER,
  GET_METADATA_SUCCESS,
  GET_METADATA_ERROR,
  LOAD_VIEW_ONLY_MODE,
} from './filter.actions';
import { Filter } from '../filter.model';
import { FilterNameRelated } from '../filter-modules-list';
import { FilterWidgetRelated } from '../../dashboard/widgets/widget/widget-type.model';

export interface State {
  filterMetadata: FilterNameRelated<any>;
  metaDataLoaded: boolean;
  searchText: FilterNameRelated<string>;
  selectedFilter: FilterNameRelated<Filter[] | Filter>;
  activeFilter: FilterNameRelated<Filter[] | Filter>;
  moduleFilters: FilterNameRelated<Filter[]>;
  filtersLoaded: FilterNameRelated<boolean>;
  widgetFilters: FilterWidgetRelated<Filter[]>;
  viewOnly: boolean;
}

const initialState: State = {
  filterMetadata: {},
  metaDataLoaded: false,
  searchText: {},
  selectedFilter: {},
  activeFilter: {},
  moduleFilters: {},
  filtersLoaded: {},
  widgetFilters: {},
  viewOnly: true,
};

export function filterReducer(state: State = initialState, action: FilterActions): State {
  switch (action.type) {
    case LOAD_VIEW_ONLY_MODE:
      return {
        ...state,
        viewOnly: action.payload,
      };
    case GET_METADATA_SUCCESS:
      const filterMetaData = action.payload;
      const metadataMap = {};

      filterMetaData.forEach((data) => {
        metadataMap[data.componentType] = data;
      });

      return {
        ...state,
        filterMetadata: metadataMap,
        metaDataLoaded: true,
      };
    case GET_METADATA_ERROR:
      return {
        ...state,
        filterMetadata: {},
        metaDataLoaded: false,
      };
    case SET_SEARCH_TEXT:
      const searchTextcomponentName = action.payload.filterName;
      const moduleSearchText = action.payload.text;

      return {
        ...state,
        searchText: {
          ...state.searchText,
          [searchTextcomponentName]: moduleSearchText,
        },
        selectedFilter: {
          ...state.selectedFilter,
          [searchTextcomponentName]: null,
        },
        activeFilter: {
          ...state.activeFilter,
          [searchTextcomponentName]: null,
        },
      };
    case UPDATE_FILTER_SUCCESS:
      const updatedFilterModule = action.payload.filterName;

      return {
        ...state,
        moduleFilters: {
          ...state.moduleFilters,
          [updatedFilterModule]: [
            ...state.moduleFilters[updatedFilterModule].map((filter) =>
              filter.id === action.payload.id ? action.payload : filter,
            ),
          ],
        },
      };
    case ADD_FILTER_SUCCESS:
      const addedFilterModule = action.payload.filterName;

      return {
        ...state,
        moduleFilters: {
          ...state.moduleFilters,
          [addedFilterModule]: [
            ...state.moduleFilters[addedFilterModule],
            action.payload,
          ],
        },
      };
    case DELETE_FILTER_SUCCESS:
      const deletedFilterModule = action.payload.filterName;
      const deletedId = action.payload.filterId;

      return {
        ...state,
        moduleFilters: {
          ...state.moduleFilters,
          [deletedFilterModule]: [
            ...state.moduleFilters[deletedFilterModule].filter((filter: Filter) => filter.id !== deletedId),
          ],
        },
        selectedFilter: {
          ...state.selectedFilter,
          [deletedFilterModule]:
            state.selectedFilter[deletedFilterModule] && state.selectedFilter[deletedFilterModule].id !== deletedId
              ? { ...state.selectedFilter[deletedFilterModule] }
              : null,
        },
      };
    case GET_FILTER_LIST_FOR_MODULE_SUCCESS:
      const listcomponentName = action.payload.filterName;
      const filters = action.payload.filterList;

      return {
        ...state,
        moduleFilters: {
          ...state.moduleFilters,
          [listcomponentName]: filters || [],
        },
        filtersLoaded: {
          ...state.filtersLoaded,
          [listcomponentName]: true,
        },
      };
    case SET_SELECTED_FILTER:
      const selectedcomponentName = action.payload.filterName;
      const selectedModuleFilter = {
        ...(action.payload.filter || {}),
        instanceId: action.payload.filter?.instanceId || null,
      };

      const updatedSelectedFilter = selectedModuleFilter?.instanceId ?
          state.selectedFilter[selectedcomponentName] ? state.selectedFilter[selectedcomponentName].map((filter) =>
              filter.instanceId === selectedModuleFilter.instanceId ? selectedModuleFilter : filter,
          ) : [selectedModuleFilter] : selectedModuleFilter;

      return {
        ...state,
        selectedFilter: {
          [selectedcomponentName]: updatedSelectedFilter,
        },
        searchText: {
          ...state.searchText,
          [selectedcomponentName]: !selectedModuleFilter ? state.searchText[selectedcomponentName] : '',
        },
      };
    case SET_ACTIVE_FILTER:
      const activecomponentName = action.payload.filterName;
      const activeModuleFilter = {
        ...(action.payload.filter || {}),
        instanceId: action.payload.filter?.instanceId || null,
      };

      /* If instance id is null then update the hole filter list for the component else update the filter with the instance id
      Because the filter with none instanceId have only one filter for the component
       */
        const updatedActiveFilter = activeModuleFilter?.instanceId ?
            state.activeFilter[activecomponentName] ? state.activeFilter[activecomponentName].map((filter) =>
                filter.instanceId === activeModuleFilter.instanceId ? activeModuleFilter : filter,
            ) : [activeModuleFilter] : activeModuleFilter;


      return {
        ...state,
        activeFilter: {
          [activecomponentName]: updatedActiveFilter,
        },
        searchText: {
          ...state.searchText,
          [activecomponentName]: activeModuleFilter ? state.searchText[activecomponentName] : '', // Wenn activeModuleFilter null ist, setze den Suchtext zurück
        },
      };
    case GET_FILTER_LIST_FOR_MODULE_ERROR:
      const errorcomponentName = action.payload;
      return {
        ...state,
        moduleFilters: {
          ...state.moduleFilters,
          [errorcomponentName]: [],
        },
        filtersLoaded: {
          ...state.filtersLoaded,
          [errorcomponentName]: false,
        },
      };
    default:
      return state;
  }
}

// 1. transform object to filter matrix
// 2. flatten to array
export const getFilterList = (state: State): Filter[] => {
  return Object.keys(state.moduleFilters)
    .map((filterName) => state.moduleFilters[filterName])
    .reduce((acc, val) => [...acc, ...val], []);
};

export const getModuleFilters = (state: State) => state.moduleFilters;
export const getFiltersLoaded = (state: State) => state.filtersLoaded;
export const getSelectedFilter = (state: State) => state.selectedFilter;
export const getSearchText = (state: State) => state.searchText;
export const getActiveFilter = (state: State) => state.activeFilter;
export const getMetadataLoaded = (state: State) => state.metaDataLoaded;
export const getMetadata = (state: State) => state.filterMetadata;
export const getWidgetFilter = (state: State) => state.widgetFilters;
