import { ActionWithPayload } from '../../../../../../shared';
import { IRestError } from 'app/core/models/rest.model';
import { OdooSubscription } from 'app/core/components/odoo/odoo-subscription/odoo-subscription.model';
import { LicensePackage, OdooPaymentCycles } from '../license-upgrade.model';

export const SET_ODOO_SUBSCRIPTION = '[ODOO] Set odoo subscription for current customer';
export const SET_ODOO_SUBSCRIPTION_SUCCESS = '[ODOO] Set odoo subscription for current customer success';
export const SET_ODOO_SUBSCRIPTION_ERROR = '[ODOO] Set odoo subscription for current customer error';

export const SET_SELECTED_LICENSE_PACKAGE =
  '[ODOO] Set selected license package for odoo subscription of current customer';
export const SET_AMOUNT_USERS = '[ODOO] Set amount users for odoo subscription of current customer';
export const SET_PAYMENT_CYCLE = '[ODOO] Set payment cycle for odoo subscription of current customer';

export class SetOdooSubscription implements ActionWithPayload<OdooSubscription> {
  readonly type = SET_ODOO_SUBSCRIPTION;
  constructor(public payload: OdooSubscription) {}
}
export class SetOdooSubscriptionSuccess implements ActionWithPayload<OdooSubscription> {
  readonly type = SET_ODOO_SUBSCRIPTION_SUCCESS;
  constructor(public payload: OdooSubscription) {}
}
export class SetOdooSubscriptionError implements ActionWithPayload<IRestError> {
  readonly type = SET_ODOO_SUBSCRIPTION_ERROR;
  constructor(public payload: IRestError) {}
}

export class SetSelectedLicensePackage implements ActionWithPayload<LicensePackage> {
  readonly type = SET_SELECTED_LICENSE_PACKAGE;
  constructor(public payload: LicensePackage) {}
}

export class SetAmountUsers implements ActionWithPayload<number> {
  readonly type = SET_AMOUNT_USERS;
  constructor(public payload: number) {}
}

export class SetPaymentCycle implements ActionWithPayload<OdooPaymentCycles> {
  readonly type = SET_PAYMENT_CYCLE;
  constructor(public payload: OdooPaymentCycles) {}
}

export type UserActions =
  | SetOdooSubscription
  | SetOdooSubscriptionSuccess
  | SetOdooSubscriptionError
  | SetSelectedLicensePackage
  | SetAmountUsers
  | SetPaymentCycle;
