import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as SP from './side-panel.actions';
import * as fromRoot from '../../../app.reducer';
import * as PROFILE from 'app/main/content/apps/profile/store/profile.actions';
import * as USER from 'app/main/content/apps/administration/user-manager/store/user-manager.actions';

import { NotificationZDService } from 'app/core/components/notification/notificaton.service';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { AccountService, ActionWithPayload, LoginService, User } from 'app/shared';
import { IRestResponse } from 'app/core/models/rest.model';
import { SidePanelPasswordService } from '../side-panel-password.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPasswordChangeDialogComponent } from '../side-panel-body/settings/confirm-password-change/confirm-password-change.component';
import { Router } from '@angular/router';
import { NotificationZD } from 'app/core/components/notification/notification.model';
import { DocumentStore } from 'app/main/content/apps/file-manager/document.model';
import { FileHandlerService } from 'app/main/content/apps/file-manager/file-download.service';
import { SidePanelService } from '../side-panel-service';
import { AdministrationUserManagerService } from 'app/main/content/apps/administration/user-manager';
import { JmsWebSocketConnectionVendor } from 'app/core/services/socket/jms-socket-connection-vendor.service';
import { BuildInfoService } from 'app/shared/build-info/build-info.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SidePanelEffects {
  activateNotification = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SP.ACTIVATE_NOTIFICATION),
      map((action: ActionWithPayload<string>) => action.payload),
      concatLatestFrom(() => this.store$.select(fromRoot.getSidePanelNotificationList)),
      map(([notificationId, notificationList]) => {
        const activatedNotification = notificationList.find((notification) => notification.id === notificationId);
        switch (activatedNotification.moduleName) {
          case 'Task Manager':
            return {
              type: SP.ACTIVATE_TASK_NOTFICATION,
              payload: activatedNotification,
            };
          default:
            return { type: SP.ACTIVATE_NOTIFICATION_MISSING };
        }
      }),
    ); },
  );

  toggleReadStatusOnActivation$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SP.ACTIVATE_NOTIFICATION),
      map((action: ActionWithPayload<string>) => action.payload),
      concatLatestFrom(() => this.store$.select(fromRoot.getSidePanelNotificationList)),
      switchMap(([notificationId, notificationList]) => {
        const currentNotification = notificationList.find((notification) => notification.id === notificationId);
        return this.notificationService.update({ ...currentNotification, messageRead: true }).pipe(
          map(() => {
            return {
              type: SP.TOGGLE_READ_STATUS_SUCCESS,
              payload: { notificationId, notificationStatus: true },
            };
          }),
          catchError(() => of({ type: SP.TOGGLE_READ_STATUS_ERROR })),
        );
      }),
    ); },
  );

  tryUpdateCurrentUser$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SP.TRY_UPDATE_CURRENT_USER),
      map((action: ActionWithPayload<User>) => action.payload),
      concatLatestFrom(() => this.store$.select(fromRoot.getSidePanelCurrentUser)),
      map(([userData, currentUser]) => ({
        ...currentUser,
        ...userData,
        id: currentUser.id,
        langKey: currentUser.langKey,
        authorities: currentUser.authorities,
        activated: currentUser.activated,
      })),
      switchMap((user) =>
        this.accountService.save(user).pipe(
          map((res) => ({
            type: SP.UPDATE_CURRENT_USER_SUCCESS,
            payload: user,
          })),
          catchError(() => of({ type: SP.UPDATE_CURRENT_USER_ERROR })),
        ),
      ),
    ); },
  );

  syncCurrentUserUpdate$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(USER.UPDATE_USER_SUCCESS),
      map((action: ActionWithPayload<IRestResponse<User>>) => action.payload),
      map((userRes) => userRes.data),
      concatLatestFrom(() => this.store$.select(fromRoot.getSidePanelCurrentUser)),
      filter(([user, currentUser]) => user && user.id === currentUser.id),
      map(([user, currentUser]) => user),
      map((user) => ({
        type: SP.SYNC_EXTERNAL_CURRENT_USER_UPDATES,
        payload: user,
      })),
    ); },
  );

  activateTaskNotification$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(SP.ACTIVATE_TASK_NOTFICATION),
        map((action: ActionWithPayload<NotificationZD>) => action.payload),
        map((notification) => {
          this.router.navigate(['/task', 'id', notification.entityId], {
            queryParamsHandling: 'merge',
          });
          this.sidePanelService.onCloseSidePanel.next();
        }),
      ); },
    { dispatch: false },
  );

  tryUpdateCurrentUserLanguage$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SP.TRY_UPDATE_CURRENT_USER_LANGUAGE),
      map((action: ActionWithPayload<string>) => action.payload),
      concatLatestFrom(() => this.store$.select(fromRoot.getSidePanelCurrentUser)),
      switchMap(([langKey, currentUser]) => {
        return this.accountService.save({ ...currentUser, langKey }).pipe(
          map(() => ({
            type: SP.UPDATE_CURRENT_USER_LANGUAGE_SUCCESS,
            payload: langKey,
          })),
          catchError(() => of({ type: SP.UPDATE_CURRENT_USER_LANGUAGE_ERROR })),
        );
      }),
    ); },
  );

  tryUpdateUserPassword$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SP.TRY_UPDATE_CURRENT_USER_PASSWORD),
      map(
        (
          action: ActionWithPayload<{
            oldPassword: string;
            newPassword: string;
          }>,
        ) => action.payload,
      ),
      switchMap(({ oldPassword, newPassword }) => {
        return this.passwordService.updatePassword(oldPassword, newPassword).pipe(
          map(() => ({ type: SP.UPDATE_CURRENT_USER_PASSWORD_SUCCESS })),
          catchError(() => of({ type: SP.UPDATE_CURRENT_USER_PASSWORD_ERROR })),
        );
      }),
    ); },
  );

  openDialogAfterPasswordChange$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(SP.UPDATE_CURRENT_USER_PASSWORD_SUCCESS),
        tap(() =>
          this.dialog.open(ConfirmPasswordChangeDialogComponent, {
            height: '220px',
            width: '280px',
          }),
        ),
      ); },
    { dispatch: false },
  );

  tryGetBuildInfo$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SP.TRY_GET_BUILD_INFO),
      switchMap(() => {
        return this.buildInfoService.getBuildInformation().pipe(
          map((res) => ({ type: SP.GET_BUILD_INFO_SUCCESS, payload: res })),
          catchError(() => of({ type: SP.GET_BUILD_INFO_ERROR })),
        );
      }),
    ); },
  );

  initSyncUser$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SP.INIT_USER_DATA),
      concatLatestFrom(() => this.store$.select(fromRoot.getActiveUser)),
      map(([action, user]) => ({
        type: SP.SYNC_CURRENT_USER_DATA,
        payload: user,
      })),
    ); },
  );

  toggleNotificationReadStatus$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SP.TRY_TOGGLE_READ_STATUS),
      map((action: ActionWithPayload<string>) => action.payload),
      concatLatestFrom(() => this.store$.select(fromRoot.getSidePanelNotificationList)),
      switchMap(([notificationId, notificationList]) => {
        const currentNotification = notificationList.find((notification) => notification.id === notificationId);
        const newNotificationStatus = !currentNotification.messageRead;
        return this.notificationService
          .update({
            ...currentNotification,
            messageRead: newNotificationStatus,
          })
          .pipe(
            map(() => {
              return {
                type: SP.TOGGLE_READ_STATUS_SUCCESS,
                payload: {
                  notificationId,
                  notificationStatus: newNotificationStatus,
                },
              };
            }),
            catchError(() => of({ type: SP.TOGGLE_READ_STATUS_ERROR })),
          );
      }),
    ); },
  );

  getInitNotificationList$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SP.TRY_GET_INIT_NOTIFICATION_PAGE, SP.TRY_GET_NOTIFICATION_PAGE),
      concatLatestFrom(() => this.store$.select(fromRoot.getSidePanelNotificationQueryParams)),
      switchMap(([action, queryParams]) => {
        return this.notificationService.query(queryParams).pipe(
          map((res: any) => {
            const messages = res.body as NotificationZD[];
            return {
              type: SP.GET_INIT_NOTIFICATION_PAGE_SUCCESS,
              payload: {
                notificationList: res.body,
                notificationCount: +res.headers.get('X-Total-Messages-Count'),
                unreadCount: +res.headers.get('X-Total-Unread-Count'),
              },
            };
          }),
          catchError(() => of({ type: SP.GET_INIT_NOTIFICATION_PAGE_ERROR })),
        );
      }),
    ); },
  );

  startFileUploadProcess$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SP.START_FILE_UPLOAD),
      map((action: ActionWithPayload<File>) => action.payload),
      switchMap((document) => {
        const { files, hasErrors } = this.fileUploadService.validateUploadFiles([document]);
        if (hasErrors) return of({ type: SP.START_FILE_UPLOAD_ERROR });

        return from(this.fileUploadService.prepareUploadFiles(files)).pipe(
          map((preparedFile) => ({
            type: SP.UPLOAD_USER_FILE,
            payload: preparedFile[0],
          })),
          catchError(() =>
            of({
              type: SP.UPLOAD_USER_FILE_ERROR,
            }),
          ),
        );
      }),
    ); },
  );

  updateNotificationList$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SP.TRY_UPDATE_NOTIFICATION_LIST),
      concatLatestFrom(() => this.store$.select(fromRoot.getSidePanelNotificationQueryParams)),
      switchMap(([action, queryParams]) => {
        return this.notificationService.query(queryParams).pipe(
          map((res) => {
            const messages = res.body as NotificationZD[];
            return {
              type: SP.UPDATE_NOTIFICATION_LIST_SUCCESS,
              payload: {
                notificationList: res.body,
                notificationCount: +res.headers.get('X-Total-Messages-Count'),
                unreadCount: +res.headers.get('X-Total-Unread-Count'),
              },
            };
          }),
          catchError(() => of({ type: SP.UPDATE_NOTIFICATION_LIST_ERROR })),
        );
      }),
    ); },
  );

  uploadFile$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SP.UPLOAD_USER_FILE),
      map((action: ActionWithPayload<DocumentStore>) => action.payload),
      switchMap((document) =>
        this.userService.updateProfilePicture(document).pipe(
          map((res) => ({
            type: SP.UPLOAD_USER_FILE_SUCCESS,
            payload: res.data,
          })),
          catchError(() =>
            of({
              type: SP.UPLOAD_USER_FILE_ERROR,
            }),
          ),
        ),
      ),
    ); },
  );

  logOutUser$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(SP.LOG_OUT_CURRENT_USER),
        map(() => {
          this.loginService.logout();
          this.sidePanelService.onCloseSidePanel.next();
          this.socketVendor.disconnect();
        }),
      ); },
    { dispatch: false },
  );

  logOutUserClearData$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(SP.LOG_OUT_CURRENT_USER),
        tap(() => {
          this.store$.dispatch(new PROFILE.ClearProfileData());
        }),
      ); },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationZDService,
    private accountService: AccountService,
    private passwordService: SidePanelPasswordService,
    private fileUploadService: FileHandlerService,
    private loginService: LoginService,
    private sidePanelService: SidePanelService,
    private userService: AdministrationUserManagerService,
    private buildInfoService: BuildInfoService,
    private translate: TranslateService,
    private store$: Store<fromRoot.State>,
    private dialog: MatDialog,
    private router: Router,
    private socketVendor: JmsWebSocketConnectionVendor,
  ) {}
}
