import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from 'app/app.constants';
import { Observable } from 'rxjs';
import { GlobalOptions, TranslationsOptions } from './global-options.model';

@Injectable({ providedIn: 'root' })
export class GlobalOptionsService {
  private resourceUrl = SERVER_API_URL + 'api/options/tenant';

  constructor(private http: HttpClient) {}

  save(options: GlobalOptions): Observable<GlobalOptions> {
    return this.http.post<GlobalOptions>(this.resourceUrl, options);
  }

  findTranslationsOptions(): Observable<TranslationsOptions> {
    return this.http.get<TranslationsOptions>(this.resourceUrl + '/translations-options');
  }

  find(): Observable<GlobalOptions> {
    return this.http.get<GlobalOptions>(this.resourceUrl);
  }
}
