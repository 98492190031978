import { Action } from '@ngrx/store';
import { ActionWithPayload } from 'app/shared';
import { Tool } from '../model/tool.model';

export const TRY_GET_TOOL_LIST = '[Tool on init] Try get tool list';
export const TRY_GET_FILTERED_TOOL_LIST = '[Tool filter change] Try get tool list';
export const TRY_GET_NEXT_TOOL_PAGE = '[Tool list on scroll] Try get next tool page';
export const GET_TOOLS_SUCCESS = '[API Response] Get tools success';
export const GET_TOOLS_ERROR = '[API Response Error] Get tools error';

export const TRY_FIND_TOOL = '[Tool list item click] Try get tool';
export const FIND_TOOL_SUCCESS = '[API Response] Get tool success';
export const FIND_TOOL_ERROR = '[API Response Error] Get tool error';

export const TRY_UPDATE_TOOL = '[Tool details form update] Try update tool';
export const UPDATE_TOOL_SUCCESS = '[API Response] Update tool success';
export const UPDATE_TOOL_ERROR = '[API Response Error] Update tool error';

export const START_TOOL_CREATION = '[Add button click] Start user creation';
export const TRY_CREATE_TOOL = '[Tool details form submit] Try create tool';
export const CREATE_TOOL_SUCCESS = '[API Response] Create tool success';
export const CREATE_TOOL_ERROR = '[API Response Error] Create tool error';

export const TRY_REMOVE_SELECTED_TOOL = '[Remove tool button] Try remove tool';
export const REMOVE_SELECTED_TOOL_SUCCESS = '[API Response] Remove tool success';
export const REMOVE_SELECTED_TOOL_ERROR = '[API Response Error] Remove tool error';

export const DESELECT_TOOL = '[Tool Details Back Arrow] Clear tool selection';

export const RESET_STATE = '[Tool on destroy] Restart state';

export class DeselectTool implements Action {
  readonly type = DESELECT_TOOL;
}

export class TryGetToolList implements Action {
  readonly type = TRY_GET_TOOL_LIST;
}

export class TryGetFilteredToolList implements Action {
  readonly type = TRY_GET_FILTERED_TOOL_LIST;
}

export class TryGetNextToolPage implements Action {
  readonly type = TRY_GET_NEXT_TOOL_PAGE;
}

export class GetToolsSuccess implements ActionWithPayload<Tool[]> {
  readonly type = GET_TOOLS_SUCCESS;
  constructor(public payload: Tool[]) {}
}

export class GetToolsError implements Action {
  readonly type = GET_TOOLS_ERROR;
}

export class TryFindTool implements ActionWithPayload<string> {
  readonly type = TRY_FIND_TOOL;
  constructor(public payload: string) {}
}

export class FindToolSuccess implements ActionWithPayload<Tool> {
  readonly type = FIND_TOOL_SUCCESS;
  constructor(public payload: Tool) {}
}

export class FindToolError implements Action {
  readonly type = FIND_TOOL_ERROR;
}

export class TryUpdateTool implements ActionWithPayload<Tool> {
  readonly type = TRY_UPDATE_TOOL;
  constructor(public payload: Tool) {}
}

export class UpdateToolSuccess implements ActionWithPayload<Tool> {
  readonly type = UPDATE_TOOL_SUCCESS;
  constructor(public payload: Tool) {}
}

export class UpdateToolError implements Action {
  readonly type = UPDATE_TOOL_ERROR;
}

export class StartToolCreation implements Action {
  readonly type = START_TOOL_CREATION;
}

export class TryCreateTool implements ActionWithPayload<Tool> {
  readonly type = TRY_CREATE_TOOL;
  constructor(public payload: Tool) {}
}

export class CreateToolSuccess implements ActionWithPayload<Tool> {
  readonly type = CREATE_TOOL_SUCCESS;
  constructor(public payload: Tool) {}
}

export class CreateToolError implements Action {
  readonly type = CREATE_TOOL_ERROR;
}

export class TryRemoveSelectedTool implements Action {
  readonly type = TRY_REMOVE_SELECTED_TOOL;
}

export class RemoveSelectedToolSuccess implements ActionWithPayload<string> {
  readonly type = REMOVE_SELECTED_TOOL_SUCCESS;
  constructor(public payload: string) {}
}

export class RemoveSelectedToolError implements Action {
  readonly type = REMOVE_SELECTED_TOOL_ERROR;
}

export class ResetState implements Action {
  readonly type = RESET_STATE;
}

export type ToolActions =
  | TryGetToolList
  | TryGetFilteredToolList
  | TryGetNextToolPage
  | GetToolsSuccess
  | GetToolsError
  | TryFindTool
  | FindToolSuccess
  | FindToolError
  | TryUpdateTool
  | UpdateToolSuccess
  | UpdateToolError
  | StartToolCreation
  | TryCreateTool
  | CreateToolSuccess
  | CreateToolError
  | TryRemoveSelectedTool
  | RemoveSelectedToolSuccess
  | RemoveSelectedToolError
  | DeselectTool
  | ResetState;
