<div class="privacy-dialog-wrapper">
  <h1 mat-dialog-title class="privacy-dialog-title">
    {{ 'global.menu.privacyPolicy.title' | translate }}
  </h1>
  <div mat-dialog-content class="privacy-dialog-text">
    <div class="privacy-dialog-text-description">
      {{ 'global.menu.privacyPolicy.description' | translate }}
    </div>
    <div class="analytics-description-text">
      {{ 'global.menu.analyticsPolicy.description' | translate }}
    </div>
  </div>

  <div class="analytics-policy-wrapper">
    <div class="analytics-text">
      <form [formGroup]="form">
        <div class="checkbox-wrapper">
          <mat-checkbox formControlName="essential"
            >{{ 'global.menu.privacyPolicy.essentialCookies' | translate }}
          </mat-checkbox>
          <mat-checkbox formControlName="analytics"
            >{{ 'global.menu.privacyPolicy.analyiticsCookies' | translate }}
          </mat-checkbox>
        </div>
      </form>
    </div>
  </div>

  <div class="dialog-buttons-wrapper" mat-dialog-actions>
    <button
      [mat-dialog-close]="true"
      (click)="confirm()"
      mat-raised-button
      color="primary"
      type="button"
      class="option-button accept-button">
      {{ 'global.words.accept' | translate }}
    </button>
  </div>

  <div class="external-links">
    <a href="https://pickert.odoo.com/impressum-agb" target="_blank">{{
      'global.menu.privacyPolicy.link1Text' | translate
    }}</a>
    <a href="https://pickert.odoo.com/dsgvo" target="_blank">{{ 'global.menu.privacyPolicy.link2Text' | translate }}</a>
  </div>
</div>
