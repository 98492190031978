import { Component, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgStyle } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatDialogClose } from '@angular/material/dialog';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss'],
  standalone: true,
  imports: [MatIconButton, MatDialogClose, MatIcon, NgStyle],
})
export class ViewImageComponent implements OnDestroy {
  @Input() hideClose = false;
  @Input() maxHeight = 'calc(100vh - 72px)';
  @Input() maxWidth = 'min(100%, calc(100vw - 36px))';

  url: SafeResourceUrl;
  private _blobUrl: string;

  constructor(private sanitizer: DomSanitizer) {}

  get blobUrl() {
    return this._blobUrl;
  }
  @Input() set blobUrl(blobUrl: string) {
    this._blobUrl = blobUrl;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
  }

  revokeBlobUrl() {
    URL.revokeObjectURL(this.blobUrl);
  }

  ngOnDestroy(): void {
    URL.revokeObjectURL(this.blobUrl);
  }
}
