import { LANGUAGE_OPTIONS } from '..';

export function isTextstore(value): value is Textstore {
  return (
    value?.translations &&
    typeof value === 'object' &&
    Object.keys(value).length === 1 &&
    typeof value?.translations === 'object'
  );
}
export class Textstore {
  static withInitatedLanguages(text?: string): Textstore {
    return new Textstore(Object.fromEntries(LANGUAGE_OPTIONS.map((lang) => [lang, new TextModel(text ?? '', false)])));
  }

  withLanguage(lang: string, text: string) {
    this.translations[lang] = new TextModel(text, true);
    return this;
  }

  constructor(public translations: { [key: string]: TextModel } = {}) {}
}

export class TextModel {
  constructor(
    public text?: string,
    public translated?: boolean,
  ) {}
}
