import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable()
export class SidenavHelperService {
  private sidenavInstances: Record<string | number, MatSidenav> = {};

  setSidenav(id: string | number, instance: MatSidenav) {
    this.sidenavInstances[id] = instance;
  }

  getSidenav(id: string | number) {
    return this.sidenavInstances[id];
  }
}
