import { Action } from '@ngrx/store';

import { User, ActionWithPayload } from 'app/shared';
import { IRestResponse, IRestError } from 'app/core/models/rest.model';
import { BoardSearchCriteria } from '../../dashboard/board/models/board-search-criteria.model';
import { ApplicationConfig } from '../application-data.model';
import { LicenseDetails } from 'app/shared/license/licensedetails.model';
import { UserOptions } from '../user-options.model';
import { GlobalOptions } from '../../administration/global-options/global-options.model';
import { Filter } from '../../filter/filter.model';

export const SET_DATA_ON_INIT = '[Profile On Logged in User App Init] Set user and global related data.';
export const SET_DATA_ON_LOGIN = '[Profile On User Successful Login] Set user and global related data.';

export const SET_ACTIVE_USER = '[Profile On user edited from external state] Set active user';
export const RESET_ACTIVE_USER = '[PROFILE] Reset active user';

export const SET_SELECTED_LANGUAGE = '[PROFILE] Set selected language';

export const SET_JWT_TOKEN = '[PROFILE App Init] Set JWT.';

export const SET_APPLICATION_DATA = '[PROFILE] Set application data';


export const UPDATE_ACTIVE_USER_OPTIONS = '[PROFILE] Update options for the active user options';
export const UPDATE_ACTIVE_USER_OPTIONS_SUCCESS = '[PROFILE] Update options for the active user - Success';
export const UPDATE_ACTIVE_USER_OPTIONS_ERROR = '[PROFILE] Update options for the active user - Error';

export const UPDATE_ACTIVE_USER_WALKTHROUGH_OPTIONS = '[PROFILE] Update walkthrough options for the active user';
export const RESET_ACTIVE_USER_WALKTHROUGH_OPTIONS = '[PROFILE] Reset active user walkthrough options';
export const HIDE_ACTIVE_USER_WALKTHROUGH = '[PROFILE] Hide walkthrough for active user';

export const TRY_UPDATE_DASHBOARD_CRITERIA = '[Dashboard Search Criteria Change] Try save dashboard criteria';
export const UPDATE_DASHBOARD_CRITERIA_SUCCESS = '[Dashboard Search Criteria Change] Save dashboard criteria success';
export const UPDATE_DASHBOARD_CRITERIA_ERROR = '[Dashboard Search Criteria Change] Save dashboard criteria error';

export const CLEAR_PROFILE_DATA = '[Profile On User Logout] Clear profile data on user logout';

export const UPDATE_GLOBAL_OPTIONS = '[Global Options Updated] Replace global options object';

export const TOKEN_TIMEOUT = '[Request attempt] Request attempted with a timed out token.';
export const TOKEN_INVALID = '[Request attempt] Request attempted with an invalid token.';
export const TOKEN_ISSUED = '[Request attempt] Request attempted with an invalid token.';


export const TRY_UPDATE_ACTIVE_USER_FILTERS = '[PROFILE] Try Update active user filters';
export const UPDATE_ACTIVE_USER_FILTERS_SUCCESS = '[PROFILE] Update active user filters - Success';
export const UPDATE_ACTIVE_USER_FILTERS_ERROR = '[PROFILE] Update active user filters - Error';
export const REMOVE_ACTIVE_USER_FILTER = '[PROFILE] Remove active user filter';


export const TRY_UPDATE_NAVIGATION_OPTIONS = '[PROFILE] Try Update navigation options for the active user';
export const UPDATE_NAVIGATION_OPTIONS_SUCCESS = '[PROFILE] Update navigation options for the active user';
export const UPDATE_NAVIGATION_OPTIONS_SUCCESS_ERROR = '[PROFILE] Try Update global options for the active user';


export class TokenTimeout implements Action {
  readonly type = TOKEN_TIMEOUT;
}

export class TokenInvalid implements Action {
  readonly type = TOKEN_INVALID;
}

export class TokenIssued implements Action {
  readonly type = TOKEN_ISSUED;
}


export class SetDataOnLogin implements ActionWithPayload<{ user: User; license: LicenseDetails; userOptions: UserOptions; globalOptions: GlobalOptions;}>{
  readonly type = SET_DATA_ON_LOGIN;
  constructor(
    public payload: {
      user: User;
      license: LicenseDetails;
      userOptions: UserOptions;
      globalOptions: GlobalOptions;
    },
  ) {}
}

export class SetDataOnInit implements ActionWithPayload<{ user: User; license: LicenseDetails; userOptions: UserOptions; globalOptions: GlobalOptions;}>{
  readonly type = SET_DATA_ON_INIT;
  constructor(
    public payload: {
      user: User;
      license: LicenseDetails;
      userOptions: UserOptions;
      globalOptions: GlobalOptions;
    },
  ) {}
}

//UPDATE_NAVIGATION_OPTIONS
export class TryUpdateNavigationOptions implements ActionWithPayload<Record<string, boolean>> {
  readonly type = TRY_UPDATE_NAVIGATION_OPTIONS;
  constructor(public payload: Record<string, boolean>) {}
}

export class UpdateNavigationOptionsSuccess implements ActionWithPayload<Record<string, boolean>> {
  readonly type = UPDATE_NAVIGATION_OPTIONS_SUCCESS;
  constructor(public payload: Record<string, boolean>) {}
}

export class UpdateNavigationOptionsError implements Action {
  readonly type = UPDATE_NAVIGATION_OPTIONS_SUCCESS_ERROR;
}
export class UpdateGlobalOptions implements ActionWithPayload<GlobalOptions> {
  readonly type = UPDATE_GLOBAL_OPTIONS;
  constructor(public payload: GlobalOptions) {}
}

export class TryUpdateDashboardSearchCriteria implements ActionWithPayload<BoardSearchCriteria> {
  readonly type = TRY_UPDATE_DASHBOARD_CRITERIA;
  constructor(public payload: BoardSearchCriteria) {}
}

export class UpdateDashboardSearchCriteriaSuccess implements Action {
  readonly type = TRY_UPDATE_DASHBOARD_CRITERIA;
}

export class UpdateDashboardSearchCriteriaError implements Action {
  readonly type = TRY_UPDATE_DASHBOARD_CRITERIA;
}

/* Active user */
export class SetActiveUser implements ActionWithPayload<User> {
  readonly type = SET_ACTIVE_USER;
  constructor(public payload: User) {}
}

export class ResetActiveUser implements Action {
  readonly type = RESET_ACTIVE_USER;
}

export class SetApplicationData implements ActionWithPayload<ApplicationConfig> {
  readonly type = SET_APPLICATION_DATA;
  constructor(public payload: ApplicationConfig) {}
}

export class SetJwtToken implements ActionWithPayload<string> {
  readonly type = SET_JWT_TOKEN;
  constructor(public payload: string) {}
}
export class UpdateActiveUserOptions implements ActionWithPayload<any> {
  readonly type = UPDATE_ACTIVE_USER_OPTIONS;
  constructor(public payload: any) {}
}

export class TryUpdateActiveUserFilters implements ActionWithPayload<Filter> {
  readonly type = TRY_UPDATE_ACTIVE_USER_FILTERS;
  constructor(public payload: Filter) {}
}

export class UpdateActiveUserFiltersSuccess implements ActionWithPayload<Filter> {
  readonly type = UPDATE_ACTIVE_USER_FILTERS_SUCCESS;
  constructor(public payload: Filter) {}
}

export class UpdateActiveUserFiltersError implements ActionWithPayload<IRestError> {
  readonly type = UPDATE_ACTIVE_USER_FILTERS_ERROR;
  constructor(public payload: IRestError) {}
}

export class RemoveActiveUserFilter implements ActionWithPayload<Filter> {
  readonly type = REMOVE_ACTIVE_USER_FILTER;
  constructor(public payload: Filter) {}
}

export class UpdateActiveUserOptionsSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = UPDATE_ACTIVE_USER_OPTIONS_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class UpdateActiveUserOptionsError implements ActionWithPayload<IRestError> {
  readonly type = UPDATE_ACTIVE_USER_OPTIONS_ERROR;
  constructor(public payload: IRestError) {}
}

export class UpdateActiveUserWalkthroughOptions implements ActionWithPayload<{ moduleName: string; completed: boolean }>{
  readonly type = UPDATE_ACTIVE_USER_WALKTHROUGH_OPTIONS;
  constructor(public payload: { moduleName: string; completed: boolean }) {}
}

export class ResetActiveUserWalkthroughOptions implements Action {
  readonly type = RESET_ACTIVE_USER_WALKTHROUGH_OPTIONS;
}

export class HideActiveUserWalkthroughOptions implements Action {
  readonly type = HIDE_ACTIVE_USER_WALKTHROUGH;
}

export class ClearProfileData implements Action {
  readonly type = CLEAR_PROFILE_DATA;
}

export type ProfileActions =
  | TokenTimeout
  | TokenInvalid
  | SetActiveUser
  | ResetActiveUser
  | SetJwtToken
  | UpdateActiveUserOptions
  | UpdateActiveUserOptionsSuccess
  | UpdateActiveUserOptionsError
  | TryUpdateNavigationOptions
  | UpdateNavigationOptionsSuccess
  | UpdateNavigationOptionsError
  | TryUpdateActiveUserFilters
  | UpdateActiveUserFiltersSuccess
  | UpdateActiveUserFiltersError
  | RemoveActiveUserFilter
  | UpdateActiveUserWalkthroughOptions
  | ResetActiveUserWalkthroughOptions
  | HideActiveUserWalkthroughOptions
  | SetApplicationData
  | ClearProfileData
  | UpdateGlobalOptions
  | SetDataOnLogin
  | SetDataOnInit;
