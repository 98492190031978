import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, of, combineLatest } from 'rxjs';

import { SERVER_API_URL } from '../../../../app.constants';
import { DocumentStore, FileData } from './document.model';
import { createRequestOption } from '../../../../shared';
import { FileCriteria } from './file-manager.criteria';
import { map, catchError } from 'rxjs/operators';
import { IRestResponse, RestResponse, IRestError } from 'app/core/models/rest.model';
import { RestUtils } from 'app/core/utils/rest.utils';
import { Filter } from '../filter/filter.model';
import { AppUtils } from 'app/core/utils/app.utils';

/**
 * defines methods used by multiple module components as well as common data and utility
 */
@Injectable({
   providedIn: 'root'
})
export class FileManagerService {
   onFilesChanged = new BehaviorSubject({});
   onFileSelected = new BehaviorSubject({});

   files: DocumentStore[];

   private resourceUrl = SERVER_API_URL + 'api/document-module/documents';

   constructor(private http: HttpClient) {}

   create(documents: DocumentStore[]): Observable<any> {
      return this.http.post<DocumentStore[]>(this.resourceUrl, documents).pipe(
         map(res => {
            const result: any = res;
            const response: IRestResponse = new RestResponse();
            response.totalItems = 1;
            response.data = result.map(document => Object.assign(new DocumentStore(), document));
            response.totalItems = response.data.length;
            return response;
         }),
         catchError(err => {
            const error: IRestError = RestUtils.formRestErrorObject(err);
            return throwError(error);
         })
      );
   }

   update(document: DocumentStore): Observable<IRestResponse<DocumentStore>> {
      return this.http.put<DocumentStore>(this.resourceUrl, document).pipe(
         map(res => {
            const response: IRestResponse = new RestResponse();
            response.totalItems = 1;
            response.data = Object.assign(new DocumentStore(), res);
            return response;
         }),
         catchError(err => {
            const error: IRestError = RestUtils.formRestErrorObject(err);
            return throwError(error);
         })
      );
   }

   findAllDocumentsByFileType(documentType: string): Observable<DocumentStore[]> {
      return this.query(null, { content: { documentType } }).pipe(map(res => res.data));
   }

   find(id: string): Observable<IRestResponse<DocumentStore>> {
      return combineLatest([
         this.http.get(`${this.resourceUrl}/${id}`),
         this.http.get(`${this.resourceUrl}/download/${id}`, {
            responseType: 'arraybuffer'
         })
      ]).pipe(
         map(([res, doc]) => {
            const response = new RestResponse<DocumentStore>();
            response.totalItems = 1;
            response.data = Object.assign(new DocumentStore(), res);
            if (response.data?.currentVersion?.fileData) {
               response.data.currentVersion.fileData.doc = AppUtils.arrayBufferToBase64(doc);
            }

            return response;
         }),
         catchError(err => {
            const error: IRestError = RestUtils.formRestErrorObject(err);
            return throwError(error);
         })
      );
   }

   findDocumentsByIds(documentIds: string[]): Observable<DocumentStore[]> {
      if (!documentIds?.length) return of([]);

      return this.http.post<DocumentStore[]>(this.resourceUrl + '/list', [...new Set(documentIds)]);
   }

   query(req?: any, activeFilter?: Filter, searchText?: string, parent?: DocumentStore): Observable<IRestResponse<DocumentStore[]>> {
      const fileCriteria = new FileCriteria();
      fileCriteria.searchText = '';
      if (searchText !== '') {
         fileCriteria.searchText = searchText;
         fileCriteria.setActiveFilter(activeFilter?.content ?? {});
      } else if (activeFilter) {
         fileCriteria.setActiveFilter(activeFilter.content);
         fileCriteria.searchText = null;
      }
      fileCriteria.parent = parent ? parent.id : null;

      const reqParams = createRequestOption(req);
      return this.http
         .post(this.resourceUrl + '/filter', fileCriteria, {
            observe: 'response',
            params: reqParams
         })
         .pipe(
            map(res => {
               const result: any = res.body;

               const response: IRestResponse = new RestResponse();
               response.totalItems = +res.headers.get('X-Total-Count');
               response.data = result.map(document => Object.assign(new DocumentStore(), document));

               return response;
            }),
            catchError(err => {
               const error: IRestError = RestUtils.formRestErrorObject(err);
               return throwError(error);
            })
         );
   }

   delete(id: string): Observable<any> {
      return this.http.delete(`${this.resourceUrl}/${id}`).pipe(
         map(() => {
            const response: IRestResponse = new RestResponse();
            return response;
         }),
         catchError(err => {
            const error: IRestError = RestUtils.formRestErrorObject(err);
            return throwError(error);
         })
      );
   }

   findDocumentHistory(req, documentId: string) {
      const reqParams = createRequestOption(req);

      return this.http.post(`${this.resourceUrl}/history/${documentId}`, {}, { params: reqParams, observe: 'response' }).pipe(
         map((res: any) => {
            const response: IRestResponse = new RestResponse();
            response.totalItems = +res.headers.get('X-Total-Count');
            response.data = res.body;
            return response;
         }),
         catchError(err => {
            const error: IRestError = RestUtils.formRestErrorObject(err);
            return throwError(error);
         })
      );
   }

   openLink(doc: DocumentStore) {
      const link = document.createElement('a');
      if (doc.currentVersion.fileData.name.includes('://')) link.href = doc.currentVersion.fileData.name;
      else link.href = 'https://' + doc.currentVersion.fileData.name;
      link.setAttribute('target', '_blank');
      document.body.append(link);
      link.dispatchEvent(new MouseEvent('click'));
      link.remove();
   }

   undo(documentHistoryId: string) {
      return this.http.get(`${this.resourceUrl}/undo/${documentHistoryId}`).pipe(
         map(res => {
            const response: IRestResponse = new RestResponse();
            response.totalItems = 1;
            response.data = Object.assign(new DocumentStore(), res);
            response.data['restoredDocument'] = true;
            return response;
         }),
         catchError(err => {
            const error: IRestError = RestUtils.formRestErrorObject(err);
            return throwError(error);
         })
      );
   }

   addNewDocumentVersion(documentId: string, fileData: FileData) {
      return this.http.post(`${this.resourceUrl}/new-version/${documentId}`, fileData).pipe(
         map((res: any) => {
            const response: IRestResponse = new RestResponse();
            response.totalItems = 1;
            response.data = Object.assign(new DocumentStore(), res);
            return response;
         }),
         catchError(err => {
            const error: IRestError = RestUtils.formRestErrorObject(err);
            return throwError(error);
         })
      );
   }

   changeFolder(changeData: { targetFolderId: string; documentId: string }) {
      return this.http.post(this.resourceUrl + '/change-folder', changeData);
   }
}
