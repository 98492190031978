import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  template: `
    <div class="doc-wrapper">
      @if (!ifLoaded) {
        <div class="ms-wrapper">
          <mat-spinner></mat-spinner>
        </div>
      }
      <iframe [hidden]="!ifLoaded" #if [src]="url"></iframe>

      @if (!hideClose && ifLoaded) {
        <button mat-icon-button class="mat-icon-button" mat-dialog-close autoplay>
          <mat-icon>close</mat-icon>
        </button>
      }
    </div>
  `,
  styles: [
    `
      .doc-wrapper {
        position: relative;
        min-height: 200px;
        min-height: 200px;
      }

      .ms-wrapper {
        height: 200px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      iframe {
        height: min(90vh, 90vw);
        width: min(90vh, 90vw);
      }

      button {
        position: absolute;
        right: 48px;
        top: 14px;
        background: #353a48;
      }
    `,
  ],
  standalone: true,
  imports: [MatProgressSpinner, MatIconButton, MatDialogClose, MatIcon],
})
export class NgxDocPreviewDialogComponent implements OnInit, OnDestroy {
  @Input() hideClose = false;
  url: SafeResourceUrl;
  ifLoaded = false;

  @ViewChild('if', { static: true }) iframe: ElementRef<HTMLIFrameElement>;
  private baseDocPreviewUrl = 'https://docs.google.com/gview?url=';
  private apiPath = '/api/document-module/documents/download/';
  private reloadInterval: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.iframe.nativeElement.onload = () => {
      this.ifLoaded = true;
      clearInterval(this.reloadInterval);
    };

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.baseDocPreviewUrl + window.origin + this.apiPath + this.data.id + '&embedded=true',
    );
    this.reloadInterval = setInterval(() => {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.baseDocPreviewUrl + window.origin + this.apiPath + this.data.id + '&embedded=true',
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.iframe.nativeElement.onload = null;
    if (this.reloadInterval) clearInterval(this.reloadInterval);
  }
}
