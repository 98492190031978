<div id="view-content-wrapper">
  <div id="content-container">
    @if (thumbnailUrl) {
      <img #thumbCont (load)="revokeThumbnail()" [src]="thumbnailUrl" />
    }
    <audio #audioCont controls>
      <source [src]="url" />
    </audio>

    @if (!hideClose) {
      <button mat-icon-button class="mat-icon-button" mat-dialog-close autoplay>
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>
</div>
