import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SERVER_API_URL } from '../../../../../app.constants';
import { createRequestOption } from '../../../../../shared';
import { User, UserInfo } from '../../../../../shared/user/user.model';
import { UserCriteria } from './user-manager.criteria';
import { IRestResponse, RestResponse, IRestError } from 'app/core/models/rest.model';
import { RestUtils } from 'app/core/utils/rest.utils';
import { DocumentStore } from '../../file-manager/document.model';
import { Authority } from '../roles/role.model';

@Injectable({ providedIn: 'root' })
export class AdministrationUserManagerService {
  // Please remove this
  onNewSearch = new Subject<void>();

  private resourceUrl = SERVER_API_URL + 'api/user';

  constructor(private http: HttpClient) {}

  updateProfilePicture(imageFile: DocumentStore) {
    return this.http.post<UserInfo>('api/account/update-profile-picture', imageFile).pipe(
      map((res) => {
        const response: IRestResponse = new RestResponse();
        response.totalItems = 1;
        response.data = Object.assign(new UserInfo(), res);
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  create(user: User): Observable<any> {
    return this.http.post<User>(this.resourceUrl, user).pipe(
      map((res) => {
        const response: IRestResponse = new RestResponse();
        response.totalItems = 1;
        response.data = Object.assign(new User(), res);
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  /**
   * Update a user in a database.
   */
  update(user: User): Observable<any> {
    return this.http.put(this.resourceUrl, user).pipe(
      map((res) => {
        const response: IRestResponse = new RestResponse();
        response.totalItems = 1;
        response.data = Object.assign(new User(), res);
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  /**
   * Returns a specific user.
   */
  find(id: string): Observable<any> {
    return this.http.get(`${SERVER_API_URL}api/user/${id}`).pipe(
      map((res) => {
        const response: IRestResponse = new RestResponse();
        response.totalItems = 1;
        response.data = Object.assign(new User(), res);
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  query(req?: any, searchText?: string, criteria?: Partial<UserCriteria>): Observable<RestResponse<User[]>> {
    const userCriteria = new UserCriteria();
    userCriteria.setActiveFilter(searchText, criteria);
    const reqParams = createRequestOption(req);

    return this.http
      .post(this.resourceUrl + '/filter', userCriteria, {
        params: reqParams,
        observe: 'response',
      })
      .pipe(
        map((res) => {
          const result: any = res.body;

          const response: IRestResponse = new RestResponse();
          response.totalItems = +res.headers.get('X-Total-Count');
          response.data = result.map((user) => Object.assign(new User(), user));

          return response;
        }),
        catchError((err) => {
          const error: IRestError = RestUtils.formRestErrorObject(err);
          return throwError(error);
        }),
      );
  }

  /**
   * delete by id
   */
  delete(id: string): Observable<any> {
    return this.http.delete(`${this.resourceUrl}/${id}`).pipe(
      map(() => {
        const response: IRestResponse = new RestResponse();
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  checkIfLoginExists(login: string): Observable<any> {
    return this.http.get(`${this.resourceUrl + '/check'}/${login}`);
  }

  checkIfEmailExists(userId: string, email: string): Observable<any> {
    return this.http.get(this.resourceUrl + '/check/email', {
      params: { userId, email },
    });
  }

  getCurrentUser(): Observable<RestResponse<User>> {
    return combineLatest([this.http.get<User>(SERVER_API_URL + 'api/account'), this.getGuiAuthorities()]).pipe(
      map(([res, guiAuthorities]) => {
        res.authorities = guiAuthorities;
        const result: any = res;
        const response = new RestResponse<User>();
        response.totalItems = 1;
        response.data = Object.assign(new User(), result);
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  getGuiAuthorities(): Observable<Authority[]> {
    return this.http.get(SERVER_API_URL + 'api/user/gui-authorities').pipe(catchError((err) => []));
  }
}
