import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, Injector, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { HammerModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule, Actions } from '@ngrx/effects';
import { StoreRouterConnectingModule, MinimalRouterStateSerializer } from '@ngrx/router-store';
import { StoreModule, Store } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { NgxWebstorageModule, LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { LAYOUT_ROUTES } from './app-routing.module';
import { DEBUG_INFO_ENABLED } from './app.constants';
import { reducers } from './app.reducer';
import { NotificationZDService } from './core/components/notification/notificaton.service';
import { SidenavHelperService } from './core/directives/mat-sidenav-helper/mat-sidenav-helper.service';
import { AuthExpiredInterceptor } from './core/interceptor/auth-expired.interceptor';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { ErrorHandlerInterceptor } from './core/interceptor/errorhandler.interceptor';
import { ErrorHandlerService } from './core/interceptor/errorhandler.service';
import { NotificationInterceptor } from './core/interceptor/notification.interceptor';
import { CustomErrorHandler } from './core/services/custom-error-handler.service';
import { GlobalOptionsService } from './main/content/apps/administration/global-options/global-options.service';
import { AppDataService } from './main/content/apps/profile/app-data.service';
import { ProfileEffects } from './main/content/apps/profile/store/profile.effects';
import { VerticalNavbarService } from './main/navbar/navbar-vertical.service';
import { LoginService, AccountService, AuthServerProvider } from './shared';
import { ThemeService } from './theme.service';
import { Observable } from 'rxjs';
import { TranslationCacheLoader } from './core/services/translation-cache.loader';
import { FilterEffects } from './main/content/apps/filter/store/filter.effects';
import { SidePanelEffects } from './main/side-panel/store/side-panel.effects';
import { UserManagerDetailsService } from './main/content/apps/administration/user-manager/user-manager-details/user-manager-details.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

import { NgxEchartsModule } from 'ngx-echarts';
import { LineChart, PieChart, BarChart, GaugeChart, CandlestickChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent, LegendComponent, LegendScrollComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import * as echarts from 'echarts/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CatalogEffectsDialog } from './main/content/apps/catalog/catalog/store-dialog/catalog-dialog.effects';
import { CatalogEffects } from './main/content/apps/catalog/catalog/store/catalog.effects';
import { DocumentEffects } from './main/content/apps/file-manager/store/file-manager.effects';
import { DateFormatPipe } from './core/pipes/dateFormat.pipe';
import { AppGlobalConstants } from './core/components/global-constants/global-constants';
import { AuthIssuedAtTimeInterceptor } from './core/interceptor/auth-iat.interceptor';
import { GlobalOptionsEffects } from './main/content/apps/administration/global-options/store/global-options.effects';

function appLoadFactory(config: AppDataService): () => Observable<unknown> {
   return () => config.getApplicationData();
}

function HttpLoaderFactory(http: HttpClient, globalOptionsService: GlobalOptionsService): TranslationCacheLoader {
   return new TranslationCacheLoader(http, globalOptionsService);
}

echarts.use([
   TitleComponent,
   TooltipComponent,
   GridComponent,
   GaugeChart,
   PieChart,
   LineChart,
   BarChart,
   CandlestickChart,
   CanvasRenderer,
   LegendScrollComponent,
   LegendComponent,
]);

export const appConfig: ApplicationConfig = {
   providers: [
      importProvidersFrom(
         RouterModule.forRoot(LAYOUT_ROUTES, { useHash: true }),
         ServiceWorkerModule.register('../sw.js'),
         NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-' }),
         BrowserAnimationsModule,
         TranslateModule.forRoot({
            loader: {
               provide: TranslateLoader,
               useFactory: HttpLoaderFactory,
               deps: [HttpClient, GlobalOptionsService],
            },
         }),
         NgxEchartsModule.forRoot({ echarts }),
         EffectsModule.forRoot([ProfileEffects, FilterEffects, DocumentEffects, SidePanelEffects, CatalogEffectsDialog, CatalogEffects, GlobalOptionsEffects]),
         StoreModule.forRoot(reducers, {
            runtimeChecks: {
               strictStateImmutability: true,
               strictActionImmutability: true,
               strictStateSerializability: false,
               strictActionSerializability: false,
               strictActionTypeUniqueness: true,
            },
         }),
         StoreRouterConnectingModule.forRoot({
            serializer: MinimalRouterStateSerializer,
            stateKey: 'router',
         }),
         DateFormatPipe,
         DatePipe,
         // should be enabled only in dev enviroment
         ...(DEBUG_INFO_ENABLED
            ? [
                 StoreDevtoolsModule.instrument({
                    name: 'ZD-DevTools',
                    maxAge: 40, // Retains last 10 states
                    logOnly: false,
                 }),
              ]
            : []),
         HammerModule,
         HttpClientModule,
      ),
      LoginService,
      AccountService,
      AuthServerProvider,
      provideNativeDateAdapter(),
      { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
      { provide: MAT_DATE_FORMATS, useValue: AppGlobalConstants.DATE_FORMATS },
      { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
      { provide: LOCALE_ID, useValue: 'de' },
      { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
      TranslatePipe,
      DatePipe,
      Title,
      VerticalNavbarService,
      SidenavHelperService,
      NotificationZDService,
      Actions,
      Store,
      ThemeService,
      ErrorHandlerService,
      UserManagerDetailsService,
      {
         provide: HTTP_INTERCEPTORS,
         useClass: AuthInterceptor,
         multi: true,
         deps: [Injector, LocalStorageService, SessionStorageService],
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: AuthIssuedAtTimeInterceptor,
         multi: true,
         deps: [Injector, LocalStorageService, SessionStorageService, AuthServerProvider],
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: AuthExpiredInterceptor,
         multi: true,
         deps: [Injector, Router],
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: ErrorHandlerInterceptor,
         multi: true,
         deps: [ErrorHandlerService],
      },
      {
         provide: APP_INITIALIZER,
         useFactory: appLoadFactory,
         multi: true,
         deps: [AppDataService],
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: NotificationInterceptor,
         multi: true,
         deps: [Injector],
      },
      {
         provide: ErrorHandler,
         useClass: CustomErrorHandler,
      },
      provideAnimations(),
   ],
};
