/* eslint-disable max-lines-per-function */
import { produce } from 'immer';
import { InspectionEquipment } from '../model/inspection-equipment.model';
import { InspectionEquipmentActions } from './inspection-equipment.actions';
import * as IEA from './inspection-equipment.actions';
import { AppUtils } from 'app/core/utils/app.utils';
import { InspectionEquipmentView } from '../inspection-view';

export interface State {
  inspectionEquipments: InspectionEquipment[];
  loading: boolean;
  selectedInspectionEquipmentId: string;
  page: number;
  creatingInspectionEquipment: InspectionEquipment;
  displayMode: 'inspectionEquipment-detail' | 'inspectionEquipment-data';
}

const initialState: State = {
  inspectionEquipments: [],
  loading: false,
  selectedInspectionEquipmentId: null,
  page: 0,
  creatingInspectionEquipment: null,
  displayMode: InspectionEquipmentView.DATA,
};

export const inspectionEquipmentReducer = produce((draft: State, action: InspectionEquipmentActions) => {
  switch (action.type) {
    case IEA.DESELECT_INSPECTION_EQUIPMENT:
      draft.creatingInspectionEquipment = null;
      draft.displayMode = InspectionEquipmentView.DATA;
      break;

    case IEA.TRY_GET_FILTERED_INSPECTION_EQUIPMENT_LIST:
    case IEA.TRY_GET_INSPECTION_EQUIPMENT_LIST:
      draft.page = initialState.page;
      draft.inspectionEquipments = initialState.inspectionEquipments;
      break;

    case IEA.GET_INSPECTION_EQUIPMENTS_SUCCESS:
      draft.loading = false;
      if (action.payload?.length > 0) draft.page += 1;
      draft.inspectionEquipments.push(...action.payload);
      break;

    case IEA.START_INSPECTION_EQUIPMENT_CREATION:
      draft.selectedInspectionEquipmentId = AppUtils.generateGUID();
      draft.creatingInspectionEquipment = new InspectionEquipment();
      draft.displayMode = InspectionEquipmentView.DETAIL;
      break;

    case IEA.CREATE_INSPECTION_EQUIPMENT_SUCCESS:
      draft.loading = false;
      draft.inspectionEquipments.unshift(action.payload);
      draft.creatingInspectionEquipment = null;
      draft.displayMode = InspectionEquipmentView.DATA;
      break;

    case IEA.UPDATE_INSPECTION_EQUIPMENT_SUCCESS:
      draft.loading = false;
      const changedInspectionEquipmentIndex = draft.inspectionEquipments.findIndex(
        (inspectionEquipment) => inspectionEquipment.id === action.payload.id,
      );
      if (changedInspectionEquipmentIndex >= 0) {
        draft.inspectionEquipments[changedInspectionEquipmentIndex] = action.payload;
      }
      break;

    case IEA.REMOVE_SELECTED_INSPECTION_EQUIPMENT_SUCCESS:
      draft.loading = false;
      draft.inspectionEquipments = draft.inspectionEquipments.filter(
        (inspectionEquipment) => inspectionEquipment.id !== action.payload,
      );
      draft.displayMode = InspectionEquipmentView.DATA;
      break;

    case IEA.INSPECTION_EQUIPMENT_EMPTY_ROUTE_CHANGE:
      draft.creatingInspectionEquipment
        ? (draft.displayMode = InspectionEquipmentView.DETAIL)
        : (draft.displayMode = InspectionEquipmentView.DATA);
      draft.selectedInspectionEquipmentId = initialState.selectedInspectionEquipmentId;
      break;

    case IEA.INSPECTION_EQUIPMENT_ROUTE_CHANGE:
      draft.selectedInspectionEquipmentId = action.payload;
      draft.displayMode = InspectionEquipmentView.DATA;
      draft.creatingInspectionEquipment = null;
      break;

    case IEA.FIND_MISSING_INSPECTION_EQUIPMENT:
      draft.loading = true;
      break;

    case IEA.FIND_MISSING_INSPECTION_EQUIPMENT_SUCCESS:
      draft.loading = false;
      draft.inspectionEquipments.find((inspectionEquipment) => inspectionEquipment.id === action.payload.id);
      break;

    case IEA.RESET_STATE:
      Object.assign(draft, initialState);
      break;

    case IEA.TRY_GET_NEXT_INSPECTION_EQUIPMENT_PAGE:
      draft.loading = true;
    case IEA.TRY_CREATE_INSPECTION_EQUIPMENT:
    case IEA.TRY_UPDATE_INSPECTION_EQUIPMENT:
    case IEA.TRY_REMOVE_SELECTED_INSPECTION_EQUIPMENT:
      break;

    case IEA.SET_DISPLAY_MODE:
      draft.displayMode = action.payload;
      break;
    case IEA.TRY_RELEASED_INSPECTION_EQUIPMENT:
    case IEA.TRY_LOCK_INSPECTION_EQUIPMENT:
      draft.loading = true;
      break;
    case IEA.RELEASED_INSPECTION_EQUIPMENT_SUCCESS:
    case IEA.LOCK_INSPECTION_EQUIPMENT_SUCCESS:
      draft.loading = false;
      draft.inspectionEquipments = draft.inspectionEquipments.map((inspectionEquipment) => {
        if (inspectionEquipment.id === action.payload.id) {
          return action.payload;
        }
        return inspectionEquipment;
      });
      break;

    default:
    // handle default case
  }
}, initialState);

export const getInspectionEquipments = (state: State) => state.inspectionEquipments;
export const getInspectionEquipmentsLoading = (state: State) => state.loading;
export const getSelectedInspectionEquipmentId = (state: State) => state.selectedInspectionEquipmentId;
export const getInspectionEquipmentsPage = (state: State) => state.page;
export const getInspectionEquipmentBeingCreated = (state: State) => state.creatingInspectionEquipment;
export const getInspectionEquipmentDisplayMode = (state: State) => state.displayMode;
