/*
    Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
    They are written in English to avoid character encoding issues (not a perfect solution)
*/

export enum Language {
  EN = 'en',
  DE = 'de',
}

export const LANGUAGE_OPTIONS: string[] = [Language.EN, Language.DE];

export let LANGUAGES: string[] = [Language.EN, Language.DE];

export let DEFAULT_LANGUAGE = Language.DE;

export function setLanguageParameters(multilang: boolean) {
  LANGUAGES = multilang ? LANGUAGE_OPTIONS : [DEFAULT_LANGUAGE];
  DEFAULT_LANGUAGE = multilang ? Language.EN : Language.DE;
}
