/* eslint-disable max-lines-per-function */
import {
  PDActions,
  ADD_DIAGRAM_SUCCESS,
  ADD_DIAGRAM_ERROR,
  UPDATE_DIAGRAM_ERROR,
  REMOVE_DIAGRAM_ERROR,
  UPDATE_DIAGRAM_SUCCESS,
  REMOVE_DIAGRAM_SUCCESS,
  SET_SELECTED_DIAGRAM,
  TRY_FIND_DIAGRAM,
  TRY_GET_DIAGRAM_NEXT_PAGE,
  GET_DIAGRAM_NEXT_PAGE_SUCCESS,
  GET_DIAGRAM_NEXT_PAGE_ERROR,
  TRY_LOAD_FILTERED_DIAGRAM_LIST,
  ROUTE_UPDATE_DIAGRAM_DATA_SUCCESS,
  TRY_ROUTE_UPDATE_DIAGRAM_DATA,
  ROUTE_UPDATE_DIAGRAM_DATA_ERROR,
  LOAD_BREADCRUMB_SUCCESS,
  TRY_LOAD_BREADCRUMB,
  SLICE_BREADCRUMB,
  TRY_FIND_DIAGRAM_FROM_BREADCRUMB,
  CLEAR_DIAGRAM_SELECTION,
  RESET_STATE,
  TRY_LOAD_SORTED_DIAGRAM_LIST,
  TRY_LOAD_INITIAL_DIAGRAM_LIST,
  TRY_OPEN_PROCESS_DESIGNER,
  OPEN_PROCESS_DESIGNER_ERROR,
  OPEN_PROCESS_DESIGNER_SUCCESS,
  TRY_LOAD_CHILDREN_LIST,
  TRY_LOAD_SELECTED_DIAGRAM_LIST,
  LOAD_VIEW_ONLY_MODE,
  DESELECT_DIAGRAM,
  TRY_DRAG_DIAGRAM,
  DRAG_DIAGRAM_SUCCESS,
  DRAG_DIAGRAM_ERROR,
  START_PROCESS_INSTANCE_SUCCESS,
  START_PROCESS_INSTANCE_ERROR,
} from './process-diagram.actions';

import { ProcessProcessDiagram } from '../../model/process-diagram.model';
import { ITEMS_PER_PAGE } from '../../../../../../../shared';
import { produce } from 'immer';
import { Textstore } from 'app/shared/lang-dialog/textstore.model';

export interface State {
  processDiagramList: ProcessProcessDiagram[];
  selectedProcessDiagram: ProcessProcessDiagram;
  rootProcessDiagramId: string;
  loading: boolean;
  sortOrder: 'asc' | 'desc';
  queryParams: { page: number; size: number; sort: string[] };
  breadcrumb: { id: string; parentId: string; name: Textstore }[];
  viewOnly: boolean;
}

const initialState: State = {
  processDiagramList: [],
  selectedProcessDiagram: null,
  rootProcessDiagramId: undefined,
  loading: false,
  sortOrder: 'asc',
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: ['is_parent,desc', 'id,desc'],
  },
  breadcrumb: [],
  viewOnly: true,
};

export const processDiagramReducer = produce((draft: State, action: PDActions): void => {
  switch (action.type) {
    case RESET_STATE:
      Object.assign(draft, initialState);

      return;
    case DRAG_DIAGRAM_SUCCESS:
      draft.processDiagramList = draft.processDiagramList.filter((diagram) => diagram.id !== action.payload.diagramId);
      draft.loading = false;

      return;
    case LOAD_VIEW_ONLY_MODE:
      draft.viewOnly = action.payload;

      return;
    case ROUTE_UPDATE_DIAGRAM_DATA_SUCCESS:
      draft.loading = false;

      draft.selectedProcessDiagram = action.payload.diagram;
      const lastIndex = Math.max(draft.breadcrumb.length - 1, 0);
      const newBcItem = {
        id: action.payload?.diagram?.id,
        parentId: action.payload?.diagram?.parentId,
        name: action.payload?.diagram?.name,
      };
      if (action.payload.diagram == null) return;
      if (action.payload.diagram.parentId !== draft.breadcrumb[lastIndex]?.id) draft.breadcrumb[lastIndex] = newBcItem;
      else draft.breadcrumb.push(newBcItem);

      return;
    case SLICE_BREADCRUMB:
      const lastDiagramId = draft.selectedProcessDiagram?.id || draft.rootProcessDiagramId;
      const diagramBCIndex = draft.breadcrumb.findIndex((bcd) => bcd.id === lastDiagramId);

      draft.breadcrumb = draft.breadcrumb.slice(0, diagramBCIndex + 1);

      return;

    case TRY_LOAD_CHILDREN_LIST:
      draft.rootProcessDiagramId = action.payload;
      draft.loading = true;
      draft.processDiagramList = [];
      draft.queryParams.page = 0;
      draft.selectedProcessDiagram = null;

      const bcIndex = draft.breadcrumb.findIndex((bc) => bc.id === action.payload);
      draft.breadcrumb = draft.breadcrumb.slice(0, bcIndex != null ? bcIndex + 1 : 0);

      return;
    case DESELECT_DIAGRAM:
      draft.selectedProcessDiagram = null;
      draft.breadcrumb.pop();

      return;
    case TRY_LOAD_SELECTED_DIAGRAM_LIST:
      draft.rootProcessDiagramId = action.payload;
      draft.loading = true;
      draft.processDiagramList = [];
      draft.queryParams.page = 0;

      return;
    case TRY_LOAD_BREADCRUMB:
      draft.breadcrumb = [];

      return;
    case LOAD_BREADCRUMB_SUCCESS:
      // Without Required wrapper ts will complain because id is optional in process diagram
      draft.breadcrumb = action.payload as Required<ProcessProcessDiagram>[];

      return;
    case TRY_LOAD_INITIAL_DIAGRAM_LIST:
      draft.loading = true;
      draft.processDiagramList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = initialState.sortOrder;

      return;
    case TRY_LOAD_SORTED_DIAGRAM_LIST:
      draft.loading = true;
      draft.sortOrder = draft.sortOrder === 'asc' ? 'desc' : 'asc';
      draft.processDiagramList = [];
      draft.queryParams.page = 0;

      return;
    case TRY_LOAD_FILTERED_DIAGRAM_LIST:
      draft.selectedProcessDiagram = null;
      draft.processDiagramList = [];

      return;
    case CLEAR_DIAGRAM_SELECTION:
      draft.selectedProcessDiagram = null;

      return;
    case TRY_OPEN_PROCESS_DESIGNER:
      draft.loading = true;

      return;
    case OPEN_PROCESS_DESIGNER_SUCCESS:
    case OPEN_PROCESS_DESIGNER_ERROR:
      draft.loading = false;

      return;
    case TRY_FIND_DIAGRAM:
    case TRY_FIND_DIAGRAM_FROM_BREADCRUMB:
      draft.selectedProcessDiagram = null;

      return;
    case TRY_GET_DIAGRAM_NEXT_PAGE:
    case TRY_DRAG_DIAGRAM:
    case TRY_OPEN_PROCESS_DESIGNER:
      draft.loading = true;

      return;
    case TRY_ROUTE_UPDATE_DIAGRAM_DATA:
      draft.loading = true;
      if (draft.processDiagramList.every((diagram) => diagram.id !== action.payload)) {
        draft.queryParams.page = 0;
      }

      return;
    case ROUTE_UPDATE_DIAGRAM_DATA_ERROR:
    case GET_DIAGRAM_NEXT_PAGE_ERROR:
    case ADD_DIAGRAM_ERROR:
    case UPDATE_DIAGRAM_ERROR:
    case REMOVE_DIAGRAM_ERROR:
    case DRAG_DIAGRAM_ERROR:
    case OPEN_PROCESS_DESIGNER_SUCCESS:
    case OPEN_PROCESS_DESIGNER_ERROR:
    case START_PROCESS_INSTANCE_SUCCESS:
    case START_PROCESS_INSTANCE_ERROR:
      draft.loading = false;

      return;
    case GET_DIAGRAM_NEXT_PAGE_SUCCESS:
      draft.loading = false;
      draft.processDiagramList.push(
        ...action.payload.data.filter((diagram) => !draft.processDiagramList.map((d) => d.id).includes(diagram.id)),
      );
      if (action.payload.data.length > 0) draft.queryParams.page += 1;

      return;
    case SET_SELECTED_DIAGRAM:
      draft.selectedProcessDiagram = action.payload;

      return;
    case ADD_DIAGRAM_SUCCESS:
      draft.processDiagramList.unshift(action.payload);
      draft.selectedProcessDiagram = action.payload;

      return;
    case UPDATE_DIAGRAM_SUCCESS:
      draft.processDiagramList =
        draft.selectedProcessDiagram?.deleted === action.payload?.deleted
          ? draft.processDiagramList.map((item) => (item.id === action.payload.id ? action.payload : item))
          : draft.processDiagramList.filter((item) => item.id !== action.payload.id);

      draft.selectedProcessDiagram =
        draft.selectedProcessDiagram?.id !== action.payload.id
          ? draft.selectedProcessDiagram
          : draft.selectedProcessDiagram?.deleted === action.payload?.deleted
            ? action.payload
            : null;

      // if edited diagram is in breadcrumb it will always be last element
      if (action.payload.id === draft.breadcrumb[draft.breadcrumb.length - 1]?.id) {
        draft.breadcrumb[draft.breadcrumb.length - 1] = {
          id: action.payload.id,
          parentId: action.payload.parentId,
          name: action.payload.name,
        };
      }

      return;
    case REMOVE_DIAGRAM_SUCCESS:
      draft.processDiagramList = draft.processDiagramList.filter((item) => draft.selectedProcessDiagram.id !== item.id);
      draft.selectedProcessDiagram = null;

      
  }
}, initialState);

export const getProcessDiagramList = (state: State) => state.processDiagramList;

export const getQueryParams = (state: State) => state.queryParams;

export const getSelectedProcessDiagram = (state: State) => state.selectedProcessDiagram;

export const getProcessDiagramLoading = (state: State) => state.loading;

export const getRootProcessDiagramId = (state: State) => state.rootProcessDiagramId;

export const getProcessDiagramCurrentSortOrder = (state: State) => state.sortOrder;

export const getProcessDiagramBreadcrumb = (state: State) => state.breadcrumb;

export const getViewOnlyMode = (state: State) => state.viewOnly;
