import { Action } from '@ngrx/store';

import { User } from '../../../../../../shared/user/user.model';
import { ActionWithPayload } from '../../../../../../shared';
import { IRestResponse, IRestError } from 'app/core/models/rest.model';
import { Role } from '../../roles/role.model';

export const SET_SELECTED_USER_EDIT = '[User Manager List Item] Set selected user edit';
export const START_USER_CREATION = '[User Manager List Add Button] Set selected user new';
export const DESELECT_USER = '[User Manager Header Deselect Button] Deselect user';

export const TRY_GET_INITIAL_USER_LIST = '[User Manager Init] Try get user list';
export const TRY_GET_SEARCHED_USERS = '[User Manager Search Bar] Set searched users';
export const TRY_GET_USER_NEXT_PAGE = '[User Manager List Scroll] Try get user next page';
export const GET_USER_NEXT_PAGE_SUCCESS = '[User Manager Api Response] Try get user next page success';
export const GET_USER_NEXT_PAGE_ERROR = '[User Manager Api Response] Try get user next page error';

export const TRY_GET_AUTHORITIES_LIST = '[User Manager Initialization] Try get authorities list.';
export const GET_AUTHORITIES_LIST_SUCCESS = '[User Manager Api Response] Get authorities list success.';
export const GET_AUTHORITIES_LIST_ERROR = '[User Manager Api Response] Get authorities list error.';

export const TRY_ADD_USER = '[User Manager Details Form Submit] Try add user';
export const ADD_USER_SUCCESS = '[User Manager Api Response] Add user success';
export const ADD_USER_ERROR = '[User Manager Api Response] Add user error';

export const TRY_UPDATE_USER = '[User Manager Form Update] Try update selected user';
export const UPDATE_USER_SUCCESS = '[User Manager Api Response] Update selected user success';
export const UPDATE_USER_ERROR = '[User Manager Api Response] Update selected user error';

export const TRY_REMOVE_SELECTED_USER = '[User Manager Header Remove Button] Try Remove selected user';
export const REMOVE_SELECTED_USER_SUCCESS = '[User Manager Api Response] Remove selected user success';
export const REMOVE_SELECTED_USER_ERROR = '[User Manager Api Response] Remove selected user error';

export const TRY_GET_ALL_ROLES = '[User Manager Init] Try get all roles';
export const TRY_GET_ALL_ROLES_SUCCESS = '[User Manager Init] Try get all roles success';
export const TRY_GET_ALL_ROLES_ERROR = '[User Manager Init] Try get all roles error';

export const SYNC_EXTERNAL_CURRENT_USER_UPDATES =
  '[Current User State outside of User manager] Sync current user data.';

export class SyncExternalUserUpdates implements ActionWithPayload<User> {
  readonly type = SYNC_EXTERNAL_CURRENT_USER_UPDATES;
  constructor(public payload: User) {}
}

export class TryGetAuthoritiesList implements Action {
  readonly type = TRY_GET_AUTHORITIES_LIST;
}

export class GetAuthoritiesListSuccess implements ActionWithPayload<string[]> {
  readonly type = GET_AUTHORITIES_LIST_SUCCESS;
  constructor(public payload: string[]) {}
}

export class GetAuthoritiesListError implements Action {
  readonly type = GET_AUTHORITIES_LIST_ERROR;
}

export class DeselectUser implements Action {
  readonly type = DESELECT_USER;
}

export class SetSelectedUser implements ActionWithPayload<string> {
  readonly type = SET_SELECTED_USER_EDIT;
  constructor(public payload: string) {}
}

export class StartUserCreation implements Action {
  readonly type = START_USER_CREATION;
}

export class TryGetUserNextPage implements Action {
  readonly type = TRY_GET_USER_NEXT_PAGE;
}

export class TryGetUserList implements Action {
  readonly type = TRY_GET_INITIAL_USER_LIST;
}

export class GetUserNextPageSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_USER_NEXT_PAGE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}

export class GetUserNextPageError implements ActionWithPayload<IRestError> {
  readonly type = GET_USER_NEXT_PAGE_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryAddUser implements ActionWithPayload<User> {
  readonly type = TRY_ADD_USER;
  constructor(public payload: User) {}
}

export class AddUserSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = ADD_USER_SUCCESS;
  constructor(public payload: IRestResponse) {}
}

export class AddUserError implements ActionWithPayload<IRestError> {
  readonly type = ADD_USER_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryUpdateSelectedUser implements ActionWithPayload<User> {
  readonly type = TRY_UPDATE_USER;
  constructor(public payload: User) {}
}
export class UpdateSelectedUserSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = UPDATE_USER_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class UpdateSelectedUserError implements ActionWithPayload<IRestError> {
  readonly type = UPDATE_USER_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryRemoveSelectedUser implements Action {
  readonly type = TRY_REMOVE_SELECTED_USER;
}

export class RemoveSelectedUserSuccess implements Action {
  readonly type = REMOVE_SELECTED_USER_SUCCESS;
}

export class RemoveSelectedUserError implements ActionWithPayload<IRestError> {
  readonly type = REMOVE_SELECTED_USER_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryGetSearchedUsers implements ActionWithPayload<string> {
  readonly type = TRY_GET_SEARCHED_USERS;
  constructor(public payload: string) {}
}

export class TryGetAllRoles implements Action {
  readonly type = TRY_GET_ALL_ROLES;
  constructor() {}
}

export class TryGetAllRolesSuccess implements ActionWithPayload<Role[]> {
  readonly type = TRY_GET_ALL_ROLES_SUCCESS;
  constructor(public payload: Role[]) {}
}

export class TryGetAllRolesError implements Action {
  readonly type = TRY_GET_ALL_ROLES_ERROR;
  constructor() {}
}

export type UserActions =
  | SetSelectedUser
  | StartUserCreation
  | TryGetUserNextPage
  | GetUserNextPageSuccess
  | GetUserNextPageError
  | TryAddUser
  | AddUserSuccess
  | AddUserError
  | TryUpdateSelectedUser
  | UpdateSelectedUserSuccess
  | UpdateSelectedUserError
  | TryRemoveSelectedUser
  | RemoveSelectedUserSuccess
  | RemoveSelectedUserError
  | TryGetSearchedUsers
  | TryGetUserList
  | TryGetAuthoritiesList
  | GetAuthoritiesListSuccess
  | GetAuthoritiesListError
  | SyncExternalUserUpdates
  | DeselectUser
  | TryGetAllRoles
  | TryGetAllRolesSuccess
  | TryGetAllRolesError;
