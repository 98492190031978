/* eslint-disable max-lines-per-function */
import { produce } from 'immer';
import { KPIProcessDiagram, DiagramStep } from '../../../process/model/process-diagram.model';
import {
  ADD_KPI_STEP_ERROR,
  ADD_KPI_STEP_SUCCESS,
  DELETE_SELECTED_STEP_SUCCESS,
  FIND_DIAGRAM_SUCCESS,
  LOAD_STEP_ERROR,
  LOAD_STEP_SUCCESS,
  RENAME_SELECTED_STEP_ERROR,
  RENAME_SELECTED_STEP_SUCCESS,
  RESET_DESIGNER,
  TRY_SET_SELECTED_STEP,
  DESELECT_STEP,
  SET_LOADED_STEP_SELECTED,
  TRY_FIND_DIAGRAM,
  TRY_RENAME_SELECTED_STEP,
  TRY_UPDATE_STEP_ORDER,
  UPDATE_DIAGRAM_SELECTION_SUCCESS,
  TRY_UPDATE_DIAGRAM_SELECTION,
  UPDATE_DIAGRAM_SELECTION_ERROR,
  TRY_LOAD_MISSING_STEP,
  KPIDesignerActions,
  LOAD_VIEW_ONLY_MODE,
  START_ADDING_STEP,
  CANCEL_ADDING_STEP,
  UPDATE_DIAGRAM_SUCCESS,
  ADD_CHARACTERISTIC_SUCCESS,
  REMOVE_CHARACTERISTIC_SUCCESS,
  UPDATE_CHARACTERISTIC_SUCCESS,
  TRY_ADD_CHARACTERISTIC,
  TRY_REMOVE_CHARACTERISTIC,
  TRY_UPDATE_CHARACTERISTIC,
  ADD_CHARACTERISTIC_ERROR,
  REMOVE_CHARACTERISTIC_ERROR,
  UPDATE_CHARACTERISTIC_ERROR,
  UPDATE_STEP_HEADER_DATA_SUCCESS,
  UPDATE_STEP_HEADER_DATA_ERROR,
  TRY_UPDATE_STEP_HEADER_DATA,
} from './kpi-designer.actions';

export interface State {
  selectedKPIDiagram: KPIProcessDiagram;
  loading: boolean;
  selectedStep: DiagramStep;
  loadedSteps: { [stepId: string]: any };
  viewOnly: boolean;
  creatingStep: boolean;
}

const initialState: State = {
  loadedSteps: {},
  selectedKPIDiagram: null,
  loading: false,
  selectedStep: null,
  viewOnly: true,
  creatingStep: false,
};

export const kpiDesignerReducer = produce((draft: State, action: KPIDesignerActions): void => {
  switch (action.type) {
    case ADD_CHARACTERISTIC_SUCCESS:
      if (draft.selectedStep.id !== action.payload.stepId) return;

      draft.selectedStep.characteristics.push(action.payload.char);
      draft.loading = false;

      const addSi = draft.selectedKPIDiagram.steps.findIndex((step) => step.id === action.payload.stepId);
      draft.selectedKPIDiagram.steps[addSi].characteristics.push(action.payload.char);

      return;
    case UPDATE_CHARACTERISTIC_SUCCESS:
      if (draft.selectedStep.id !== action.payload.stepId) return;

      draft.selectedStep.characteristics = draft.selectedStep.characteristics.map((char) =>
        char.id === action.payload.char.id ? action.payload.char : char,
      );
      draft.loading = false;

      const updateSi = draft.selectedKPIDiagram.steps.findIndex((step) => step.id === action.payload.stepId);
      draft.selectedKPIDiagram.steps[updateSi].characteristics = draft.selectedStep.characteristics.map((char) =>
        char.id === action.payload.char.id ? action.payload.char : char,
      );

      return;
    case REMOVE_CHARACTERISTIC_SUCCESS:
      if (draft.selectedStep.id !== action.payload.stepId) return;

      draft.selectedStep.characteristics = draft.selectedStep.characteristics.filter(
        (char) => char.id !== action.payload.charId,
      );
      draft.loading = false;

      const rmSi = draft.selectedKPIDiagram.steps.findIndex((step) => step.id === action.payload.stepId);
      draft.selectedKPIDiagram.steps[rmSi].characteristics = draft.selectedStep.characteristics.filter(
        (char) => char.id !== action.payload.charId,
      );

      return;
    case UPDATE_STEP_HEADER_DATA_SUCCESS:
      if (draft.selectedStep.id !== action.payload.stepId) return;

      draft.selectedStep.headerData = action.payload.headerData;
      draft.loading = false;

      const headSi = draft.selectedKPIDiagram.steps.findIndex((step) => step.id === action.payload.stepId);
      draft.selectedKPIDiagram.steps[headSi].headerData = action.payload.headerData;

      return;
    case START_ADDING_STEP:
      draft.creatingStep = true;

      return;
    case CANCEL_ADDING_STEP:
      draft.creatingStep = false;

      return;
    case LOAD_VIEW_ONLY_MODE:
      draft.viewOnly = action.payload;

      return;
    case UPDATE_DIAGRAM_SELECTION_SUCCESS:
      draft.loading = false;
      draft.selectedKPIDiagram = action.payload.diagram;

      return;
    case TRY_SET_SELECTED_STEP:
      draft.selectedStep = null;

      return;
    case DESELECT_STEP:
      draft.selectedStep = null;

      return;
    case RESET_DESIGNER:
      draft.loadedSteps = {};
      draft.selectedKPIDiagram = null;
      draft.loading = false;
      draft.selectedStep = null;

      return;
    case ADD_KPI_STEP_ERROR:
    case LOAD_STEP_ERROR:
    case RENAME_SELECTED_STEP_ERROR:
    case UPDATE_DIAGRAM_SELECTION_ERROR:
    case ADD_CHARACTERISTIC_ERROR:
    case UPDATE_CHARACTERISTIC_ERROR:
    case REMOVE_CHARACTERISTIC_ERROR:
    case UPDATE_STEP_HEADER_DATA_ERROR:
      draft.loading = false;

      return;
    case TRY_LOAD_MISSING_STEP:
    case TRY_FIND_DIAGRAM:
    case TRY_RENAME_SELECTED_STEP:
    case TRY_UPDATE_DIAGRAM_SELECTION:
    case TRY_ADD_CHARACTERISTIC:
    case TRY_UPDATE_CHARACTERISTIC:
    case TRY_REMOVE_CHARACTERISTIC:
    case TRY_UPDATE_STEP_HEADER_DATA:
      draft.loading = true;

      return;
    case FIND_DIAGRAM_SUCCESS:
      draft.loading = false;
      draft.loadedSteps = {};
      draft.selectedKPIDiagram = {
        ...new KPIProcessDiagram(),
        ...action.payload,
      };

      return;
    case DELETE_SELECTED_STEP_SUCCESS:
      draft.selectedStep = draft.selectedStep?.id === action.payload ? null : draft.selectedStep;
      draft.selectedKPIDiagram.steps = draft.selectedKPIDiagram.steps.filter((step) => action.payload !== step.id);

      return;
    case ADD_KPI_STEP_SUCCESS:
      draft.selectedKPIDiagram.steps.push(action.payload);
      draft.loadedSteps[action.payload.id] = true;

      return;
    case RENAME_SELECTED_STEP_SUCCESS:
      const changedStepIndex = draft.selectedKPIDiagram.steps.findIndex((step) => step.id === action.payload.id);
      draft.selectedStep = { ...action.payload };
      draft.loading = false;
      draft.selectedKPIDiagram?.steps.splice(changedStepIndex, 1, action.payload);

      return;
    case SET_LOADED_STEP_SELECTED:
      draft.selectedStep = draft.selectedKPIDiagram.steps.find((step) => step.id === action.payload);

      return;
    case LOAD_STEP_SUCCESS:
      const stepIndex = draft.selectedKPIDiagram.steps.findIndex((step) => step.id === action.payload.id);
      draft.selectedStep = { ...action.payload };
      draft.loadedSteps[action.payload.id] = true;
      draft.selectedKPIDiagram?.steps.splice(stepIndex, 1, action.payload);
      draft.loading = false;

      return;
    case TRY_UPDATE_STEP_ORDER:
      const newStepList = action.payload.map((stepId) =>
        draft.selectedKPIDiagram.steps.find((step) => step.id === stepId),
      );
      draft.selectedKPIDiagram.steps = newStepList;

      return;
    case UPDATE_DIAGRAM_SUCCESS:
      draft.selectedKPIDiagram = action.payload;
      draft.selectedStep = draft.selectedStep;
      draft.loadedSteps = {};
      
  }
}, initialState);

export const getSelectedKPIDesigner = (state: State) => state.selectedKPIDiagram;

export const getKPIDesignerLoading = (state: State) => state.loading;

export const getKPIDiagramSelectedStep = (state: State) => state.selectedStep;

export const getKPIDiagramLoadedSteps = (state: State) => state.loadedSteps;

export const getKPIDiagramViewMode = (state: State) => state.viewOnly;

export const getKPIDiagramCreatingStep = (state: State) => state.creatingStep;
