import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { SERVER_API_URL } from '../../app.constants';

export interface ReleaseInfo {
  id?: string;
  version: string;
  date: string;
  description: string;
  actions: ReleaseInfoAction[];
}

export interface ReleaseInfoAction {
  dev: string;
  type: string;
  issue?: string;
  text: string;
}

@Injectable({ providedIn: 'root' })
export class SidePanelService {
  readonly onOpenSidePanel: Subject<void> = new Subject();
  readonly onCloseSidePanel: Subject<void> = new Subject();
  private resourceUrl = SERVER_API_URL + 'api/release-info';

  constructor(private http: HttpClient) {}

 getReleaseInfo(): Observable<ReleaseInfo[]> {
    return this.http.get<ReleaseInfo[]>(this.resourceUrl);
  }
}
