import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { GlobalOptionsService } from 'app/main/content/apps/administration/global-options/global-options.service';
import { Observable, switchMap } from 'rxjs';

const cacheDuration = 60;

const headers = new HttpHeaders({
  'Cache-Control': 'max-age=' + cacheDuration,
});

export class TranslationCacheLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private globalOptionsService: GlobalOptionsService,
    public prefix = '/i18n/',
    public suffix = '.json',
  ) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    return this.globalOptionsService.findTranslationsOptions().pipe(
      switchMap(({ translationsStyle }) => {
        return this.http.get(`${this.prefix}${lang}-${translationsStyle.toLocaleLowerCase()}${this.suffix}`, {
          headers,
        });
      }),
    );
  }
}
