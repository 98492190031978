// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.

import { environment } from './environments/environment';

// If you change the values in the webpack config files, you need to re run webpack to update the application
export const VERSION = process.env.VERSION;
export const DEBUG_INFO_ENABLED = environment.debug;
export const SERVER_API_URL = process.env.SERVER_API_URL;
export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;
