/* eslint-disable max-lines-per-function */
import {
  UserActions,
  SET_SELECTED_USER_EDIT,
  START_USER_CREATION,
  TRY_ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  TRY_UPDATE_USER,
  UPDATE_USER_SUCCESS,
  REMOVE_SELECTED_USER_SUCCESS,
  TRY_GET_SEARCHED_USERS,
  TRY_GET_USER_NEXT_PAGE,
  GET_USER_NEXT_PAGE_SUCCESS,
  GET_USER_NEXT_PAGE_ERROR,
  TRY_GET_INITIAL_USER_LIST,
  DESELECT_USER,
  TRY_REMOVE_SELECTED_USER,
  UPDATE_USER_ERROR,
  SYNC_EXTERNAL_CURRENT_USER_UPDATES,
  TRY_GET_ALL_ROLES_SUCCESS,
  TRY_GET_ALL_ROLES_ERROR,
} from './user-manager.actions';

import { ITEMS_PER_PAGE } from '../../../../../../shared';
import { User } from '../../../../../../shared/user/user.model';
import { Role } from '../../roles/role.model';

export interface State {
  userList: User[];
  selectedUser: User;
  editMode: boolean;
  searchText: string;
  loading: boolean;
  queryParams: { page: number; size: number; sort: string };
  authorities: string[];
  currentUserUpdate: User;
  allRoles: Role[];
}

const initialState: State = {
  userList: [],
  selectedUser: null,
  editMode: true,
  searchText: '',
  loading: false,
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: 'id,desc',
  },
  authorities: [],
  currentUserUpdate: null,
  allRoles: [],
};

export function userReducer(state = initialState, action: UserActions): State {
  switch (action.type) {
    case SYNC_EXTERNAL_CURRENT_USER_UPDATES:
      return {
        ...state,
        currentUserUpdate: action.payload,
        selectedUser: state.selectedUser?.id === action.payload.id ? action.payload : state.selectedUser,
      };
    case SET_SELECTED_USER_EDIT:
      return {
        ...state,
        selectedUser: state.userList.find((user) => user.id === action.payload),
        editMode: true,
      };
    case START_USER_CREATION:
      return {
        ...state,
        selectedUser: { ...new User(), activated: false, langKey: 'de' },
        editMode: false,
      };
    case GET_USER_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        userList: [...state.userList, ...action.payload.data],
        queryParams:
          action.payload.data.length > 0
            ? { ...state.queryParams, page: state.queryParams.page + 1 }
            : state.queryParams,
        loading: false,
      };
    case ADD_USER_ERROR:
    case GET_USER_NEXT_PAGE_ERROR:
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case TRY_ADD_USER:
    case TRY_UPDATE_USER:
    case TRY_GET_USER_NEXT_PAGE:
    case TRY_REMOVE_SELECTED_USER:
      return {
        ...state,
        loading: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        userList: [action.payload.data, ...state.userList],
        selectedUser: action.payload.data,
        editMode: true,
        loading: false,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userList: state.userList.map((item) => (item.id === action.payload.data.id ? action.payload.data : item)),
        selectedUser:
          state.selectedUser && state.selectedUser.id === action.payload.data.id
            ? action.payload.data
            : state.selectedUser,
        loading: false,
      };
    case REMOVE_SELECTED_USER_SUCCESS:
      return {
        ...state,
        userList: state.userList.filter((item) => state.selectedUser && state.selectedUser.id !== item.id),
        selectedUser: null,
        loading: false,
      };
    case TRY_GET_INITIAL_USER_LIST:
      return {
        ...state,
        queryParams: initialState.queryParams,
        loading: true,
        userList: [],
        selectedUser: null,
        editMode: true,
      };
    case TRY_GET_SEARCHED_USERS:
      return {
        ...state,
        searchText: action.payload,
        queryParams: { ...state.queryParams, page: 0 },
        loading: true,
        userList: [],
        selectedUser: null,
        editMode: true,
      };
    case DESELECT_USER:
      return {
        ...state,
        selectedUser: null,
        editMode: true,
      };
    case TRY_GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        allRoles: action.payload,
        loading: false,
      };
    case TRY_GET_ALL_ROLES_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export const getUserList = (state: State) => state.userList;

export const getSelectedUser = (state: State) => state.selectedUser;

export const getEditMode = (state: State) => state.editMode;

export const getSearchText = (state: State) => state.searchText;

export const getQueryParams = (state: State) => state.queryParams;

export const getUserLoading = (state: State) => state.loading;

export const getAuthorityList = (state: State) => state.authorities;

export const getCurrentUserUpdate = (state: State) => state.currentUserUpdate;

export const getAllRoles = (state: State) => state.allRoles;
