/* eslint-disable max-lines-per-function */
import {
  SCActions,
  TRY_GET_DASHBOARD_LIST,
  GET_DASHBOARD_LIST_SUCCESS,
  GET_DASHBOARD_LIST_ERROR,
  UPDATE_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD_ERROR,
  TRY_FIND_DASHBOARD,
  FIND_DASHBOARD_SUCCESS,
  FIND_DASHBOARD_ERROR,
  SET_CONFIG_MODE,
  TRY_REMOVE_WIDGET,
  TRY_ADD_WIDGET,
  TRY_UPDATE_GRID_POSITION,
  UNLOAD_DASHBOARD_LIST,
  TRY_UPDATE_WIDGET_DETAILS,
  DESELECT_DASHBOARD,
  LOAD_VIEW_ONLY_MODE,
} from './strategy-canvas.actions';

import { Dashboard } from '../../board/models/board.model';

export interface State {
  dashboardList: Dashboard[];
  selectedDashboard: Dashboard;
  loading: boolean;
  configMode: boolean;
  viewOnly: boolean;
}

const initialState: State = {
  dashboardList: [],
  selectedDashboard: null,
  loading: false,
  configMode: false,
  viewOnly: true,
};

export function strategyCanvasReducer(state: State = initialState, action: SCActions): State {
  switch (action.type) {
    case LOAD_VIEW_ONLY_MODE:
      return {
        ...state,
        viewOnly: action.payload,
      };
    case TRY_UPDATE_WIDGET_DETAILS:
      const widgetIndex = state.selectedDashboard.widgets.findIndex(
        (wid) => wid.instanceId === action.payload.instanceId,
      );
      const configUpdateDashboard = {
        ...state.selectedDashboard,
        widgets: [
          ...state.selectedDashboard.widgets.slice(0, widgetIndex),
          {
            ...state.selectedDashboard.widgets[widgetIndex],
            values: action.payload.config,
          },
          ...state.selectedDashboard.widgets.slice(widgetIndex + 1),
        ],
      };

      return {
        ...state,
        selectedDashboard: configUpdateDashboard,
        dashboardList: state.dashboardList.map((board) =>
          configUpdateDashboard.id === board.id ? configUpdateDashboard : board,
        ),
      };
    case TRY_ADD_WIDGET:
      const widgetData = action.payload;
      const updatedBoard = state.selectedDashboard && {
        ...state.selectedDashboard,
        widgets: [...state.selectedDashboard.widgets, ...widgetData],
      };

      return {
        ...state,
        loading: true,
        selectedDashboard: updatedBoard,
        dashboardList: state.dashboardList.map((board) => (updatedBoard.id === board.id ? updatedBoard : board)),
      };
    case TRY_REMOVE_WIDGET:
      const widgetId = action.payload;
      const widgetRemovedBoard = state.selectedDashboard && {
        ...state.selectedDashboard,
        widgets: state.selectedDashboard.widgets.filter((widget) => widget.instanceId !== widgetId),
      };

      return {
        ...state,
        loading: true,
        selectedDashboard: widgetRemovedBoard,
        dashboardList: state.dashboardList.map((board) =>
          widgetRemovedBoard?.id === board.id ? widgetRemovedBoard : board,
        ),
      };
    case TRY_UPDATE_GRID_POSITION:
      const gridsterPositions = action.payload;

      const updatedDashboard = state.selectedDashboard && {
        ...state.selectedDashboard,
        widgets: state.selectedDashboard.widgets.map((widget, i) => ({
          ...widget,
          gridsterData: {
            x: gridsterPositions[i].x,
            y: gridsterPositions[i].y,
            rows: gridsterPositions[i].rows,
            cols: gridsterPositions[i].cols,
          },
        })),
      };

      return {
        ...state,
        selectedDashboard: updatedDashboard,
        loading: true,
        dashboardList: state.dashboardList.map((board) =>
          updatedDashboard?.id === board.id ? updatedDashboard : board,
        ),
      };
    case SET_CONFIG_MODE:
      return {
        ...state,
        configMode: !state.viewOnly && action.payload,
      };
    case DESELECT_DASHBOARD:
      return {
        ...state,
        selectedDashboard: null,
      };
    case TRY_GET_DASHBOARD_LIST:
      return {
        ...state,
        dashboardList: initialState.dashboardList,
        loading: true,
      };
    case GET_DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        dashboardList: [...action.payload],
        loading: false,
      };
    case UPDATE_DASHBOARD_SUCCESS:
    case UPDATE_DASHBOARD_ERROR:
    case FIND_DASHBOARD_ERROR:
    case UPDATE_DASHBOARD_ERROR:
    case GET_DASHBOARD_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };
    case TRY_FIND_DASHBOARD:
      return {
        ...state,
        selectedDashboard: state.selectedDashboard
          ? {
              ...state.selectedDashboard,
              widgets: [],
            }
          : null,
        loading: true,
      };
    case FIND_DASHBOARD_SUCCESS:
      const foundDashboard = action.payload;
      return {
        ...state,
        dashboardList: state.dashboardList.map((board) =>
          foundDashboard && foundDashboard.id === board.id ? { ...foundDashboard } : board,
        ),
        selectedDashboard: foundDashboard ? { ...foundDashboard, widgets: foundDashboard.widgets || [] } : null,
        loading: false,
        configMode: !state.viewOnly && (!foundDashboard.widgets?.length || state.configMode),
      };
    case UNLOAD_DASHBOARD_LIST:
      return {
        ...state,
        loading: false,
        dashboardList: [],
        selectedDashboard: null,
        viewOnly: true,
      };
    default:
      return state;
  }
}

export const getDashboardList = (state: State) => state.dashboardList;

export const getSelectedDashboard = (state: State) => state.selectedDashboard;

export const getLoading = (state: State) => state.loading;

export const getConfigMode = (state: State) => state.configMode;

export const getViewOnlyMode = (state: State) => state.viewOnly;
