import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxDocPreviewDialogComponent } from '../doc-preview-dialog/doc-preview-dialog.component';
import { Subscription } from 'rxjs';
import { ViewDialogComponent } from '../view-image-dialog/view-image-dialog.component';
import { FileManagerService } from 'app/main/content/apps/file-manager/file-manager.service';
import { AppUtils } from 'app/core/utils/app.utils';
import { ViewAudioDialogComponent } from '../view-audio/view-audio-dialog.component';
import { ViewIFrameDialogComponent } from '../view-iframe/view-iframe-dialog.component';
import { ViewImageB64DialogComponent } from '../view-image-b64/view-image-b64-dialog.component';

const ngxPreviewType = 'file';
const textContentType = 'text-content';
const imagePreviewType = 'image';
const videoPreviewType = 'video';
const audioPreviewType = 'audio';
const linkPreviewType = 'link';

const unknownPreviewType = 'unknown';

@Injectable({
  providedIn: 'root',
})
export class FilePreviewService {
  private dialogRef: MatDialogRef<any>;
  private previewSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private fileService: FileManagerService,
  ) {}

  previewFile(fileId: string, fileType?: string) {
    this.dialogRef?.close();
    this.previewSubscription?.unsubscribe();

    if (!fileType || unknownPreviewType === fileType.toLowerCase()) {
      this.previewWithoutFileType(fileId);
      return;
    }

    if (fileType === videoPreviewType) {
      this.previewSubscription = this.fileService.find(fileId).subscribe((res) => {
        const blob = AppUtils.b64toBlob(res.data?.currentVersion?.fileData?.doc, {
          type: res.data?.currentVersion?.fileData?.docContentType,
        });
        const blobUrl = URL.createObjectURL(blob);
      });
      return;
    }

    if (fileType === audioPreviewType) {
      this.previewSubscription = this.fileService.find(fileId).subscribe((res) => {
        const blob = AppUtils.b64toBlob(res.data?.currentVersion?.fileData?.doc, {
          type: res.data?.currentVersion?.fileData?.docContentType,
        });
        const blobUrl = URL.createObjectURL(blob);
        const blobThumbnail = AppUtils.b64toBlob(res.data?.currentVersion?.fileData?.thumbnail, { type: 'image/jpg' });
        const thumbnailBlobUrl = URL.createObjectURL(blobThumbnail);
        this.dialogRef = this.dialog.open(ViewAudioDialogComponent, {
          maxWidth: 'calc(100vh - 72px)',
          maxHeight: 'calc(100vh - 72px)',
          data: { blobUrl, thumbnailBlobUrl },
        });
      });
      return;
    }
    if (fileType === imagePreviewType) {
      this.previewSubscription = this.fileService.find(fileId).subscribe((res) => {
        if (res.data?.currentVersion?.fileData?.docContentType.includes('svg')) {
          this.dialogRef = this.dialog.open(ViewImageB64DialogComponent, {
            maxWidth: 'calc(100vh - 72px)',
            maxHeight: 'calc(100vh - 72px)',
            data: {
              base64Content:
                'data:' +
                res.data?.currentVersion?.fileData?.docContentType +
                ';base64,' +
                res.data?.currentVersion?.fileData?.doc,
            },
          });
          return;
        }
        const blob = AppUtils.b64toBlob(res.data?.currentVersion?.fileData?.doc, {
          type: res.data?.currentVersion?.fileData?.docContentType,
        });
        const blobUrl = URL.createObjectURL(blob);
        this.dialogRef = this.dialog.open(ViewDialogComponent, {
          data: { blobUrl },
        });
      });
      return;
    }

    if (fileType === textContentType) {
      this.previewSubscription = this.fileService.find(fileId).subscribe((res) => {
        this.dialogRef = this.dialog.open(ViewIFrameDialogComponent, {
          data: {
            src: 'data:text/html;base64,' + res.data?.currentVersion?.fileData?.doc,
          },
        });
      });
      return;
    }

    if (fileType.includes(ngxPreviewType)) {
      this.dialogRef = this.dialog.open(NgxDocPreviewDialogComponent, {
        maxWidth: 'calc(100vh - 72px)',
        maxHeight: 'calc(100vh - 72px)',
        minWidth: 200,
        minHeight: 200,
        data: { id: fileId },
      });
      return;
    }

    this.previewSubscription = this.fileService.find(fileId).subscribe((res) => {
      if (!res.data?.currentVersion?.fileData?.thumbnail) return;
      const blob = AppUtils.b64toBlob(res.data?.currentVersion?.fileData?.thumbnail, { type: 'image/jpg' });
      const blobUrl = URL.createObjectURL(blob);
      this.dialogRef = this.dialog.open(ViewDialogComponent, {
        maxWidth: 'calc(100vh - 72px)',
        maxHeight: 'calc(100vh - 72px)',
        data: { blobUrl },
      });
    });
  }

  private previewWithoutFileType(fileId: string) {
    this.previewSubscription = this.fileService.find(fileId).subscribe((res) => {
      if (res.data?.currentVersion?.fileData?.type === linkPreviewType) {
        this.dialogRef = this.dialog.open(ViewIFrameDialogComponent, {
          data: { src: res.data?.currentVersion?.fileData?.name },
        });
        return;
      }

      if (res.data?.currentVersion?.fileData?.type === audioPreviewType) {
        const blob = AppUtils.b64toBlob(res.data?.currentVersion?.fileData?.doc, {
          type: res.data?.currentVersion?.fileData?.docContentType,
        });
        const blobUrl = URL.createObjectURL(blob);
        const blobThumbnail = AppUtils.b64toBlob(res.data?.currentVersion?.fileData?.thumbnail, { type: 'image/jpg' });
        const thumbnailBlobUrl = URL.createObjectURL(blobThumbnail);
        this.dialogRef = this.dialog.open(ViewAudioDialogComponent, {
          data: { blobUrl, thumbnailBlobUrl },
        });
        return;
      }

      if (res.data?.currentVersion?.fileData?.type === imagePreviewType) {
        if (res.data?.currentVersion?.fileData?.docContentType.includes('svg')) {
          this.dialogRef = this.dialog.open(ViewImageB64DialogComponent, {
            data: {
              base64Content:
                'data:' +
                res.data?.currentVersion?.fileData?.docContentType +
                ';base64,' +
                res.data?.currentVersion?.fileData?.doc,
            },
          });
          return;
        }
        const blob = AppUtils.b64toBlob(res.data?.currentVersion?.fileData?.doc, {
          type: res.data?.currentVersion?.fileData?.docContentType,
        });
        const blobUrl = URL.createObjectURL(blob);
        this.dialogRef = this.dialog.open(ViewDialogComponent, {
          data: { blobUrl },
        });
        return;
      }

      if (res.data?.currentVersion?.fileData?.type.includes(ngxPreviewType)) {
        this.dialogRef = this.dialog.open(NgxDocPreviewDialogComponent, {
          minWidth: 200,
          minHeight: 200,
          data: { id: fileId },
        });
        return;
      }

      if (res.data?.currentVersion?.fileData?.thumbnail) {
        const blob = AppUtils.b64toBlob(res.data?.currentVersion?.fileData?.thumbnail, { type: 'image/jpg' });
        const blobUrl = URL.createObjectURL(blob);
        this.dialogRef = this.dialog.open(ViewDialogComponent, {
          data: { blobUrl },
        });
      }
    });
  }

  private openPreviewImageDialog(fileId) {
    this.dialogRef = this.dialog.open(ViewDialogComponent, {
      maxWidth: '100vw',
      data: { id: fileId },
    });
  }
}
