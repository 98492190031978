import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorHandlerService {
  public onlineFlag = navigator.onLine;

  constructor(
    public snackBar: MatSnackBar,
    public translate: TranslateService,
    private zone: NgZone,
  ) {}

  public handleClientSideError(error: any) {
    const consoleRrrorMessage = `Error: ${error.error.message}`;
    console.error('Client HTTP Error Message: ', consoleRrrorMessage);

    // Only Error code 0 message ('Connection error') currently exists and it is defined as a default one
    // Do not display client side error messages for now
    // this.translateAndDisplayErrorMessage('error.rest.0', 0);
  }

  public handleServerSideError(error: any) {
    const consoleRrrorMessage = `Error Code: ${error.status} (${error.statusText})`;
    console.error('Server HTTP Error Message: ', consoleRrrorMessage);

    const translationPath =
      !this.onlineFlag && !error.status
        ? 'error.rest.998' // Fictive error code 998 - 'Internet connection lost'
        : 'error.rest.' + String(error.status); // Error message will depend on the received error code

    // The authentication error message will not be shown on the Login page due to CanActivate guard issue
    // if (this.shouldIgnoreError(error)) return;
    // this.translateAndDisplayErrorMessage(translationPath, error.status);
  }

  public translateAndDisplayErrorMessage(translationPath: string, errorStatus: number) {
    let errorMessage = '';
    this.translate.get(translationPath).subscribe((translation: string) => {
      if (translation.includes(translationPath)) {
        // Translation not found
        errorMessage = this.getDefaultErrorMessage(errorStatus);
      } else {
        // Translation exists
        errorMessage = this.translate.instant(translationPath);
      }
      this.showError(errorMessage);
    });
  }

  public showError(errorMessage: string, durationTime = 3000) {
    // this.zone.run(() => {
    //     this.snackBar.openFromComponent(CustomErrorHandlerSnackBarComponent, {
    //         data: {
    //             'errorMessage': errorMessage,
    //         },
    //         duration: durationTime,
    //         verticalPosition: 'top',
    //         horizontalPosition: 'center',
    //     });
    // });
  }

  public getDefaultErrorMessage(errorStatus: number): string {
    console.error('HTTP Error Handler - No translation data is found. A predefined error message is shown !!!');
    switch (errorStatus) {
      case 0:
        return "Connection error - Couldn't connect to the server";
      case 200:
        return 'Session expired. Please log in again';
      case 400:
        return 'Something is wrong. A server could not process your request';
      case 401:
        return 'Unauthorized access';
      case 403:
        return 'Forbidden access';
      case 404:
        return 'Requested data not found';
      case 500:
        return 'Server error';
      case 504:
        return 'Server temporarily unavailable';
      default:
        return 'Unknown error. Please call support center';
    }
  }

  private shouldIgnoreError(error): boolean {
    return (
      (error.status === 404 && error.url.includes('account')) ||
      error.url.includes('check') ||
      (error.status === 401 && error.url.includes('license')) ||
      (error.status === 200 && !error.message.includes('parsing')) ||
      error.status === 500 ||
      error.status === 0
    );
  }
}
