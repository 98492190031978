import { Action } from '@ngrx/store';
import { Task, TaskHistoryEntry } from '../task.model';
import { ActionWithPayload } from '../../../../../shared';
import { IRestResponse, IRestError } from 'app/core/models/rest.model';
import { OrderBy } from 'app/core/components/header-bar/header-buttons/order-by-list-button/order-by.model';
import { CommentData } from 'app/core/components/comments/comment.model';
import { BoardSearchCriteria } from '../../dashboard/board/models/board-search-criteria.model';
import { ScheduledJob } from '@zero/angular-ui/zero-scheduler';

export const DESELECT_TASK = '[Task Manager Header Back Arrow] Deselect current task';
export const UNSET_SELECTED_TASK = '[Task Manager Effects] Try find null task';

export const SET_SELECTED_TASK_NEW = '[TM Add Button] Set selected task new';

export const TRY_GET_FILTERED_TASK_LIST = '[TM] Try get filtered task list';
export const TRY_GET_ORDERED_TASK_LIST = '[TM] Try get ordered task list';
export const TRY_GET_TASK_LIST = '[TM] Try get task list';
export const GET_TASK_LIST_SUCCESS = '[TM] Get task list success';
export const GET_TASK_LIST_ERROR = '[TM] Get task list erorr';

export const TRY_GET_TASK_NEXT_PAGE = '[TM] Try get task next page';
export const GET_TASK_NEXT_PAGE_SUCCESS = '[TM] Try get task next page success';
export const GET_TASK_NEXT_PAGE_ERROR = '[TM] Try get task next page error';

export const TRY_ADD_TASK = '[TM] Try Add task';
export const ADD_TASK_SUCCESS = '[TM] Add task success';
export const ADD_TASK_ERROR = '[TM] Add task error';

export const TRY_UPDATE_TASK = '[TM] Try Update task';
export const UPDATE_TASK_SUCCESS = '[TM] Update task success';
export const UPDATE_TASK_ERROR = '[TM] Update task error';
export const SKIP_TASK_UPDATE = '[TM] Skip task update';

export const TRY_MOVE_TASK_TO_DELETED = '[TM] TRY move task to deleted';
export const MOVE_TASK_TO_DELETED_SUCCESS = '[TM] Move task to deleted success';

export const TRY_REMOVE_TASK = '[TM] TRY Remove task';
export const REMOVE_TASK_SUCCESS = '[TM] Remove task success';
export const REMOVE_TASK_ERROR = '[TM] Remove task error';

export const FIND_TASK_SUCCESS = '[TM] Find task success';
export const TRY_FIND_TASK = '[TM] Try find task';
export const FIND_TASK_ERROR = '[TM] Find task error';

export const CLOSE_TM = '[TM] On close';
export const CLOSE_TM_DONE = '[TM] Close actions done';

export const CLEAR_TASK_LIST = '[TM] Clear task list';

export const UPDATE_TASK_FIELDS = '[TM Socket Response] Update task fields';

export const TRY_FIND_TASK_HISTORY = '[TM Task History] Try fetch task history';
export const FIND_TASK_HISTORY_SUCCESS = '[TM Api Response] Fetch task history success';
export const FIND_TASK_HISTORY_ERROR = '[TM Api Response] Fetch task history error';

export const CLEAR_HISTORY_QUERY_PARAMS = '[TM Task History] Clear history query params';
export const CLEAR_HISTORY_LIST = '[TM Task History] Clear history list';

export const CANCEL_TASK_SUBSCRIPTION = '[TM Socket] Cancel task subscription';

export const RESET_TASK_MANAGER = '[TM Component] Reset state.';

export const TRY_UPDATE_COMMENTS = '[TM Task Detail Comments] Try update comments';
export const UPDATE_COMMENTS_SUCCESS = '[TM Api Response] Update comments success';
export const UPDATE_COMMENTS_ERROR = '[TM Api Response] Update comments error';

export const TRY_UNDO_TASK = '[TM Task History] Try undo task';
export const UNDO_TASK_SUCCESS = '[TM Api Response] Undo task success';
export const UNDO_TASK_ERROR = '[TM Api Response] Undo task error';

export const LISTEN_TO_CREATED_TASKS = '[TM Socket] Listen to created tasks';
export const STOP_LISTENING_TO_CREATED_TASKS = '[TM Socket] Listen to created tasks';

export const LISTEN_TO_DELETED_TASKS = '[TM Socket] Listen to created tasks';
export const STOP_LISTENING_TO_DELETED_TASKS = '[TM Socket] Listen to created tasks';

export const ADD_RECIEVED_TASK = '[TM Socket] Add recieved task';
export const DELETE_RECIEVED_TASK = '[TM Socket] Remove task by id';

export const TRY_UPDATE_EFFECTIVENESS_COMMENTS = '[TM] Try update effectivness comments';
export const UPDATE_EFFECTIVENESS_COMMENTS_SUCCESS = '[TM] Update effectivness comments success';
export const UPDATE_EFFECTIVENESS_COMMENTS_ERROR = '[TM] Update effectivness comments error';

export const TRY_NAVIGATE_TO_TASK_SOURCE = '[Task Manager Details Source Link Button] Try navigate to the task source.';
export const TRY_NAVIGATE_TO_DASHBOARD_FROM_TASK_SOURCE =
  '[Task Manager Effects] Navigate to a dashboard from a task source.';
export const NAVIGATE_TO_PROJECT_FROM_TASK_SOURCE = '[Task Manager Effects] Navigate to a project from a task source.';
export const NAVIGATE_TO_DASHBOARD_FROM_TASK_SOURCE_SUCCESS =
  '[Task Manager Api Response] Navigate to a dashboard from a task source success.';
export const NAVIGATE_TO_DASHBOARD_FROM_TASK_SOURCE_ERROR =
  '[Task Manager Api Response] ] Navigate to a dashboard from a task source error.';

export const HIDE_PRIVATED_TASK = '[Task Manager On task update] Hide private task if doesnt belong to user.';

export const TRY_LOAD_SCHEDULED_JOB = '[Task Manager Effects] Try load scheduled job for the selected task';
export const LOAD_SCHEDULED_JOB_SUCCESS =
  '[Task Manager Api Response] Load scheduled job for the selected task success';
export const LOAD_SCHEDULED_JOB_ERROR = '[Task Manager Api Response] Load scheduled job for the selected task error';

export const TRY_SCHEDULE_TASK = '[Task Manager Scheduled Checkbox True] Try schedule task';
export const SCHEDULE_TASK_SUCCESS = '[Task Manager Api Response] Schedule task success';
export const SCHEDULE_TASK_ERROR = '[Task Manager Api Response] Schedule task error';

export const TRY_UPDATE_SCHEDULE_JOB = '[Task Manager Scheduled Form Update] Try update scheduled job';
export const UPDATE_SCHEDULE_JOB_SUCCESS = '[Task Manager Api Response] Update scheduled job success';
export const UPDATE_SCHEDULE_JOB_ERROR = '[Task Manager Api Response] Update scheduled job error';

export const TRY_PAUSE_JOB = '[Task Manager Scheduled Checkbox false] Try pause scheduled job';
export const PAUSE_JOB_SUCCESS = '[Task Manager Api Response] Pause scheduled job success';
export const PAUSE_JOB_ERROR = '[Task Manager Api Response] Pause scheduled job error';

export class UnsetSelectedTask implements Action {
  readonly type = UNSET_SELECTED_TASK;
}

export class TryPauseScheduleTask implements Action {
  readonly type = TRY_PAUSE_JOB;
}

export class PauseScheduleTaskSuccess implements ActionWithPayload<ScheduledJob> {
  readonly type = PAUSE_JOB_SUCCESS;
  constructor(public payload: ScheduledJob) {}
}
export class PauseScheduleTaskError implements Action {
  readonly type = PAUSE_JOB_ERROR;
}
export class TryUpdateScheduledJob implements ActionWithPayload<ScheduledJob> {
  readonly type = TRY_UPDATE_SCHEDULE_JOB;
  constructor(public payload: ScheduledJob) {}
}

export class UpdateScheduledJobSuccess implements ActionWithPayload<ScheduledJob> {
  readonly type = UPDATE_SCHEDULE_JOB_SUCCESS;
  constructor(public payload: ScheduledJob) {}
}
export class UpdateScheduledJobError implements Action {
  readonly type = UPDATE_SCHEDULE_JOB_ERROR;
}
export class TryScheduleTask implements Action {
  readonly type = TRY_SCHEDULE_TASK;
}

export class ScheduleTaskSuccess implements ActionWithPayload<ScheduledJob> {
  readonly type = SCHEDULE_TASK_SUCCESS;
  constructor(public payload: ScheduledJob) {}
}
export class ScheduleTaskError implements Action {
  readonly type = SCHEDULE_TASK_ERROR;
}

export class TryLoadScheduledJob implements Action {
  readonly type = TRY_LOAD_SCHEDULED_JOB;
}

export class LoadScheduledJobSuccess implements ActionWithPayload<ScheduledJob> {
  readonly type = LOAD_SCHEDULED_JOB_SUCCESS;
  constructor(public payload: ScheduledJob) {}
}
export class LoadScheduledJobError implements Action {
  readonly type = LOAD_SCHEDULED_JOB_ERROR;
}

export class HidePrivatedTask implements Action {
  readonly type = HIDE_PRIVATED_TASK;
}

export class TryNavigateToTaskSource implements Action {
  readonly type = TRY_NAVIGATE_TO_TASK_SOURCE;
}

export class TryNavigateToDashboardFromTaskSource implements ActionWithPayload<{ criteria: BoardSearchCriteria }> {
  readonly type = TRY_NAVIGATE_TO_DASHBOARD_FROM_TASK_SOURCE;
  constructor(public payload: { criteria: BoardSearchCriteria }) {}
}

export class TryNavigateToProjectFromTaskSource implements ActionWithPayload<{ projectId: string }> {
  readonly type = NAVIGATE_TO_PROJECT_FROM_TASK_SOURCE;
  constructor(public payload: { projectId: string }) {}
}
export class NavigateToTaskSourceSuccess implements Action {
  readonly type = NAVIGATE_TO_DASHBOARD_FROM_TASK_SOURCE_SUCCESS;
}
export class NavigateToTaskSourceError implements Action {
  readonly type = NAVIGATE_TO_DASHBOARD_FROM_TASK_SOURCE_ERROR;
}

export class TryUpdateEffectivnessComments implements ActionWithPayload<CommentData> {
  readonly type = TRY_UPDATE_EFFECTIVENESS_COMMENTS;
  constructor(public payload: CommentData) {}
}

export class UpdateEffectivnessCommentsSuccess implements ActionWithPayload<{ id: string; comments: CommentData[] }> {
  readonly type = UPDATE_EFFECTIVENESS_COMMENTS_SUCCESS;
  constructor(public payload: { id: string; comments: CommentData[] }) {}
}
export class UpdateEffectivnessCommentsError implements Action {
  readonly type = UPDATE_EFFECTIVENESS_COMMENTS_ERROR;
}

export class AddRecievedTask implements ActionWithPayload<Task> {
  readonly type = ADD_RECIEVED_TASK;
  constructor(public payload: Task) {}
}

export class DeleteRecievedTask implements ActionWithPayload<string> {
  readonly type = DELETE_RECIEVED_TASK;
  constructor(public payload: string) {}
}

export class StartListeningToCreatedTasks {
  readonly type = LISTEN_TO_CREATED_TASKS;
}

export class StopListeningToCreatedTasks {
  readonly type = STOP_LISTENING_TO_CREATED_TASKS;
}

export class StartListeningToDeletedTasks {
  readonly type = LISTEN_TO_DELETED_TASKS;
}

export class StopListeningToDeletedTasks {
  readonly type = STOP_LISTENING_TO_DELETED_TASKS;
}

export class TryUndoTask implements ActionWithPayload<Omit<Task, 'comments'>> {
  readonly type = TRY_UNDO_TASK;
  constructor(public payload: Omit<Task, 'comments'>) {}
}
export class UndoTaskSuccess implements ActionWithPayload<Omit<Task, 'comments'>> {
  readonly type = UNDO_TASK_SUCCESS;
  constructor(public payload: Omit<Task, 'comments'>) {}
}
export class UpdateCommentsSuccess implements ActionWithPayload<{ id: string; comments: CommentData[] }> {
  readonly type = UPDATE_COMMENTS_SUCCESS;
  constructor(public payload: { id: string; comments: CommentData[] }) {}
}
export class UpdateCommentsError implements Action {
  readonly type = UPDATE_COMMENTS_ERROR;
}

export class UndoTaskError implements Action {
  readonly type = UNDO_TASK_ERROR;
}

export class TryUpdateComments implements ActionWithPayload<CommentData> {
  readonly type = TRY_UPDATE_COMMENTS;
  constructor(public payload: CommentData) {}
}

export class SkipTaskUpdate implements Action {
  readonly type = SKIP_TASK_UPDATE;
}

export class ResetTaskManager implements Action {
  readonly type = RESET_TASK_MANAGER;
}

export class ClearTaskHistoryList implements Action {
  readonly type = CLEAR_HISTORY_LIST;
}

export class ClearHistoryQueryParams implements Action {
  readonly type = CLEAR_HISTORY_QUERY_PARAMS;
}

export class CancelTaskSubscription implements Action {
  readonly type = CANCEL_TASK_SUBSCRIPTION;
}

export class TryFindTaskHistory implements ActionWithPayload<string> {
  readonly type = TRY_FIND_TASK_HISTORY;
  constructor(public payload: string) {}
}

export class FindTaskHistorySuccess implements ActionWithPayload<IRestResponse<TaskHistoryEntry[]>> {
  readonly type = FIND_TASK_HISTORY_SUCCESS;
  constructor(public payload: IRestResponse<TaskHistoryEntry[]>) {}
}

export class UpdateTaskFields implements ActionWithPayload<Partial<Task>> {
  readonly type = UPDATE_TASK_FIELDS;
  constructor(public payload: Partial<Task>) {}
}

export class ClearTaskList implements Action {
  readonly type = CLEAR_TASK_LIST;
}

export class DeselectTask implements Action {
  readonly type = DESELECT_TASK;
}

export class SetSelectedTaskNew implements Action {
  readonly type = SET_SELECTED_TASK_NEW;
}

/* get task list */
export class TryGetTaskList implements Action {
  readonly type = TRY_GET_TASK_LIST;
}

export class TryGetFiltredTaskList implements Action {
  readonly type = TRY_GET_FILTERED_TASK_LIST;
}

export class TryGetOrderedTaskList implements ActionWithPayload<OrderBy> {
  readonly type = TRY_GET_ORDERED_TASK_LIST;
  constructor(public payload: OrderBy) {}
}

export class GetTaskListSuccess implements ActionWithPayload<IRestResponse<Task[]>> {
  readonly type = GET_TASK_LIST_SUCCESS;
  constructor(public payload: IRestResponse<Task[]>) {}
}
export class GetTaskListErorr implements ActionWithPayload<IRestError> {
  readonly type = GET_TASK_LIST_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryGetTaskNextPage implements Action {
  readonly type = TRY_GET_TASK_NEXT_PAGE;
}
export class GetTaskNextPageSuccess implements ActionWithPayload<IRestResponse<Task[]>> {
  readonly type = GET_TASK_NEXT_PAGE_SUCCESS;
  constructor(public payload: IRestResponse<Task[]>) {}
}
export class GetTaskNextPageError implements ActionWithPayload<IRestError> {
  readonly type = GET_TASK_NEXT_PAGE_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryAddTask implements Action {
  readonly type = TRY_ADD_TASK;
  constructor(public payload: Task) {}
}
export class AddTaskSuccess implements ActionWithPayload<Task> {
  readonly type = ADD_TASK_SUCCESS;
  constructor(public payload: Task) {}
}
export class AddTaskErorr implements ActionWithPayload<IRestError> {
  readonly type = ADD_TASK_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryUpdateTask implements ActionWithPayload<Task> {
  readonly type = TRY_UPDATE_TASK;
  constructor(public payload: Task) {}
}
export class UpdateTaskSuccess implements ActionWithPayload<{ [field: string]: any }> {
  readonly type = UPDATE_TASK_SUCCESS;
  constructor(public payload: { [field: string]: any }) {}
}
export class UpdateTaskError implements ActionWithPayload<IRestError> {
  readonly type = UPDATE_TASK_ERROR;
  constructor(public payload: IRestError) {}
}
export class TryMoveTaskToDeleted implements ActionWithPayload<Task> {
  readonly type = TRY_MOVE_TASK_TO_DELETED;
  constructor(public payload: Task) {}
}
export class MoveTaskToDeletedSuccess implements ActionWithPayload<Task> {
  readonly type = MOVE_TASK_TO_DELETED_SUCCESS;
  constructor(public payload: Task) {}
}
export class TryRemoveTask implements ActionWithPayload<Task> {
  readonly type = TRY_REMOVE_TASK;
  constructor(public payload: Task) {}
}
export class RemoveTaskSuccess implements ActionWithPayload<string> {
  readonly type = REMOVE_TASK_SUCCESS;
  constructor(public payload: string) {}
}

export class RemoveTaskError implements ActionWithPayload<IRestError> {
  readonly type = REMOVE_TASK_ERROR;
  constructor(public payload: IRestError) {}
}
export class TryFindTask implements ActionWithPayload<string> {
  readonly type = TRY_FIND_TASK;
  constructor(public payload: string) {}
}
export class FindTaskSuccess implements ActionWithPayload<Task> {
  readonly type = FIND_TASK_SUCCESS;
  constructor(public payload: Task) {}
}
export class FindTaskError implements ActionWithPayload<IRestError> {
  readonly type = FIND_TASK_ERROR;
  constructor(public payload: IRestError) {}
}

export type TMActions =
  | DeselectTask
  | SetSelectedTaskNew
  | TryGetTaskList
  | GetTaskListSuccess
  | GetTaskListErorr
  | TryGetFiltredTaskList
  | TryAddTask
  | AddTaskSuccess
  | AddTaskErorr
  | TryUpdateEffectivnessComments
  | UpdateEffectivnessCommentsSuccess
  | UpdateEffectivnessCommentsError
  | TryUpdateTask
  | UpdateTaskSuccess
  | UpdateTaskError
  | TryMoveTaskToDeleted
  | MoveTaskToDeletedSuccess
  | TryRemoveTask
  | RemoveTaskSuccess
  | RemoveTaskError
  | TryGetTaskNextPage
  | GetTaskNextPageSuccess
  | GetTaskNextPageError
  | TryFindTask
  | FindTaskSuccess
  | FindTaskError
  | ClearTaskList
  | UpdateTaskFields
  | TryFindTaskHistory
  | FindTaskHistorySuccess
  | ClearHistoryQueryParams
  | ClearTaskHistoryList
  | CancelTaskSubscription
  | ResetTaskManager
  | SkipTaskUpdate
  | TryUndoTask
  | UndoTaskSuccess
  | UndoTaskError
  | UpdateCommentsSuccess
  | UpdateCommentsError
  | TryUpdateComments
  | StartListeningToCreatedTasks
  | StopListeningToCreatedTasks
  | StartListeningToDeletedTasks
  | StopListeningToDeletedTasks
  | AddRecievedTask
  | DeleteRecievedTask
  | TryGetOrderedTaskList
  | TryNavigateToTaskSource
  | TryNavigateToProjectFromTaskSource
  | TryNavigateToDashboardFromTaskSource
  | NavigateToTaskSourceSuccess
  | NavigateToTaskSourceError
  | HidePrivatedTask
  | TryLoadScheduledJob
  | LoadScheduledJobSuccess
  | LoadScheduledJobError
  | TryScheduleTask
  | ScheduleTaskSuccess
  | ScheduleTaskError
  | TryUpdateScheduledJob
  | UpdateScheduledJobSuccess
  | UpdateScheduledJobError
  | TryPauseScheduleTask
  | PauseScheduleTaskSuccess
  | PauseScheduleTaskError
  | UnsetSelectedTask;
