/* eslint-disable max-lines-per-function */
import { OrderBy } from 'app/core/components/header-bar/header-buttons/order-by-list-button/order-by.model';
import { AppUtils } from 'app/core/utils/app.utils';
import { ProjectTaskSourceData, Task, TaskDragDTO, TaskStatus } from '../../task.model';
import {
  ADD_RECEIVED_SCRUMBOARD_TASK,
  ADD_SCRUMBOARD_TASK_SUCCESS,
  APPLY_DRAG_UPDATE,
  ARCHIVE_DONE_TASKS_SUCCESS,
  ARCHIVE_RELEASED_TASKS_SUCCESS,
  DESELECT_TASK,
  DRAG_TASK_ERROR,
  DRAG_TASK_SUCCESS,
  FIND_MISSING_DRAGGED_TASK_SUCCESS,
  FIND_TASK_SUCCESS,
  GET_SCRUMBOARD_TASKS_ERROR,
  GET_SCRUMBOARD_TASKS_SUCCESS,
  TOGGLE_TASK_DELETED_ERROR,
  REMOVE_RECEIVED_SCRUMBOARD_TASKS,
  RESET_SCRUMBOARD_TASKS,
  SBActions,
  SKIP_TASK_UPDATE_FROM_SCRUMBOARD,
  START_TASK_CREATION,
  TRY_DRAG_TASK,
  TRY_GET_SCRUMBOARD_TASKS,
  TRY_UPDATE_COMMENTS_FROM_SCRUMBOARD,
  TRY_UPDATE_TASK_FROM_SCRUMBOARD,
  UPDATE_COMMENTS_FROM_SCRUMBOARD_ERROR,
  UPDATE_COMMENTS_FROM_SCRUMBOARD_SUCCESS,
  UPDATE_EFFECTIVENESS_COMMENTS_SUCCESS,
  UPDATE_TASK_ERROR_FROM_SCRUMBOARD,
  UPDATE_TASK_FIELDS_FROM_SCRUMBOARD,
  UPDATE_TASK_SUCCESS_FROM_SCRUMBOARD,
  LOAD_SCHEDULED_JOB_SUCCESS,
  SCHEDULE_TASK_SUCCESS,
  UPDATE_SCHEDULE_JOB_SUCCESS,
  START_SCRUMBOARD_WITH_PROJECT,
  CLEAR_SCRUMBOARD_PROJECT,
  TRY_GET_ORDERED_SCRUMBOARD_TASKS,
  TRY_GET_FILTERED_SCRUMBOARD_TASKS,
  TRY_GET_SEARCHED_SCRUMBOARD_TASKS,
  TRY_GET_INITIAL_SCRUMBOARD_TASKS,
  TRY_REMOVE_TASK,
  REMOVE_TASK_ERROR,
  TOGGLE_TASK_DELETED_SUCCESS,
  GET_SCRUMBOARD_TASK_COUNT,
} from './scrumboard.actions';

import { produce } from 'immer';
import { TaskWorkflow } from '../../task.workflow';
import { Project } from '../../../project-manager/model/project.model';
import { Textstore } from 'app/shared/lang-dialog/textstore.model';
import { UserInfo } from 'app/shared';
import { FileData } from '../../../file-manager/document.model';
import { ScheduledJob } from '@zero/angular-ui/zero-scheduler';

export interface State {
  selectedTask: Task;
  editMode: boolean;
  awaitingDragResponse: boolean;
  orderBy: OrderBy;
  totalItemsCount: number;
  formSubscriptionId: string;
  lastTaskChanges: Partial<Task>;
  updating: 'initial' | 'updating' | 'failed' | 'updated';
  scrumboardTasks: Partial<{ [status in TaskStatus]: Task[] }>;
  scrumboardTaskPage: Partial<{ [status in TaskStatus]: number }>;
  scrumboardTasksLoading: Partial<{ [status in TaskStatus]: boolean }>;
  loading: boolean;
  lastTaskDragData: TaskDragDTO;
  scheduledJob: ScheduledJob;
  sourceProject: Project;
}

const initialState: State = {
  selectedTask: null,
  editMode: false,
  orderBy: null,
  formSubscriptionId: AppUtils.generateGUID(),
  lastTaskChanges: null,
  updating: 'initial',
  scrumboardTasks: Object.fromEntries(TaskWorkflow.scrumboardColumnStatuses.map((status) => [status, []])),
  scrumboardTaskPage: Object.fromEntries(TaskWorkflow.scrumboardColumnStatuses.map((status) => [status, 0])),
  scrumboardTasksLoading: Object.fromEntries(TaskWorkflow.scrumboardColumnStatuses.map((status) => [status, false])),
  loading: false,
  awaitingDragResponse: false,
  lastTaskDragData: null,
  scheduledJob: null,
  sourceProject: null,
  totalItemsCount: null,
};

export const scrumboardReducer = produce((draft: State, action: SBActions) => {
  switch (action.type) {
    case UPDATE_EFFECTIVENESS_COMMENTS_SUCCESS:
      if (action.payload.id === draft.selectedTask?.id) {
        draft.updating = 'updated';
        draft.selectedTask.effectivenessAnalysis.comments = action.payload.comments;
      }

      return;
    case RESET_SCRUMBOARD_TASKS:
      draft.scrumboardTasks = initialState.scrumboardTasks;
      draft.scrumboardTasksLoading = initialState.scrumboardTasksLoading;
      draft.loading = initialState.loading;
      draft.scheduledJob = null;
      draft.sourceProject = null;

      return;
    case START_TASK_CREATION:
      draft.selectedTask = new Task();
      if (draft.sourceProject) {
        draft.selectedTask.responsible = new UserInfo(
          draft.sourceProject?.responsible?.id,
          draft.sourceProject?.responsible?.firstName,
          draft.sourceProject?.responsible?.lastName,
          draft.sourceProject?.responsible?.email,
          draft.sourceProject?.responsible?.tenantId,
          draft.sourceProject?.responsible?.tenantName,
          new FileData(
            draft.sourceProject.responsible?.imageFileData?.id,
            draft.sourceProject.responsible?.imageFileData?.name,
            draft.sourceProject.responsible?.imageFileData?.type,
            draft.sourceProject.responsible?.imageFileData?.size,
            draft.sourceProject.responsible?.imageFileData?.extension,
            draft.sourceProject.responsible?.imageFileData?.doc,
            draft.sourceProject.responsible?.imageFileData?.thumbnail,
            draft.sourceProject.responsible?.imageFileData?.docContentType,
            draft.sourceProject.responsible?.imageFileData?.linkName,
          ),
        );
        draft.selectedTask.privateTask = draft.sourceProject?.privateProject;
        draft.selectedTask.source = 'PROJECT';
        draft.selectedTask.sourceData = new ProjectTaskSourceData(draft.sourceProject.id);
        (draft.selectedTask.sourceData as ProjectTaskSourceData).sourceText = new Textstore(
          Object.fromEntries(
            Object.entries(draft.sourceProject.name.translations).map(([lang, tm]) => [
              lang,
              { text: tm.text, translated: tm.translated },
            ]),
          ),
        );
      } else {
        draft.selectedTask.source = 'TASK';
      }

      draft.editMode = false;
      draft.updating = 'initial';
      draft.scheduledJob = null;

      return;
    case DESELECT_TASK:
      draft.selectedTask = null;
      draft.scheduledJob = null;
      draft.editMode = true;

      return;
    case CLEAR_SCRUMBOARD_PROJECT:
      draft.sourceProject = null;
      draft.scrumboardTasks = initialState.scrumboardTasks;
      draft.scrumboardTaskPage = initialState.scrumboardTaskPage;
      draft.scrumboardTasksLoading = initialState.scrumboardTasksLoading;
      draft.loading = initialState.loading;

      return;
    case SKIP_TASK_UPDATE_FROM_SCRUMBOARD:
      draft.updating = 'initial';

      return;
    case START_SCRUMBOARD_WITH_PROJECT:
      draft.sourceProject = action.payload;

      return;
    case TRY_GET_ORDERED_SCRUMBOARD_TASKS:
      draft.orderBy = action.payload;
      draft.scrumboardTasks = initialState.scrumboardTasks;
      draft.scrumboardTaskPage = initialState.scrumboardTaskPage;
      draft.scrumboardTasksLoading = initialState.scrumboardTasksLoading;
      draft.loading = initialState.loading;

      return;
    case TRY_UPDATE_TASK_FROM_SCRUMBOARD:
    case TRY_UPDATE_COMMENTS_FROM_SCRUMBOARD:
    case TRY_REMOVE_TASK:
      draft.updating = draft.editMode ? 'updating' : draft.updating;

      return;
    case REMOVE_RECEIVED_SCRUMBOARD_TASKS:
      draft.selectedTask =
        draft.selectedTask && action.payload.includes(draft.selectedTask.id) ? null : draft.selectedTask;

      Object.keys(draft.scrumboardTasks).forEach((status) => {
        draft.scrumboardTasks[status] = draft.scrumboardTasks[status].filter(
          (task) => !action.payload.includes(task.id),
        );
      });

      return;
    case ADD_RECEIVED_SCRUMBOARD_TASK:
    case ADD_SCRUMBOARD_TASK_SUCCESS:
      if (draft.scrumboardTasks[action.payload.status].some((task) => task.id === action.payload.id)) return;
      draft.scrumboardTasks[action.payload.status].unshift(action.payload);

      return;
    case FIND_MISSING_DRAGGED_TASK_SUCCESS:
      draft.scrumboardTasks[action.payload.task.status].splice(
        action.payload.dragData.newPosition,
        0,
        action.payload.task,
      );

      return;
    case TRY_DRAG_TASK:
      draft.awaitingDragResponse = true;
      return;
    case DRAG_TASK_ERROR:
      draft.awaitingDragResponse = false;
      return;
    case DRAG_TASK_SUCCESS:
      draft.awaitingDragResponse = false;
    // eslint-disable-next-line no-fallthrough
    case APPLY_DRAG_UPDATE:
      if (draft.scrumboardTasks[action.payload.newStatus].length < action.payload.newPosition) return;

      const draggedTask = Object.values(draft.scrumboardTasks)
        .flatMap((tasks) => tasks)
        .find((task) => task.id === action.payload.taskId);
      if (!draggedTask) return;

      const draggedTaskIndex = draft.scrumboardTasks[draggedTask.status].findIndex(
        (task) => task.id === draggedTask.id,
      );

      draft.scrumboardTasks[draggedTask.status].splice(draggedTaskIndex, 1);
      draft.scrumboardTasks[action.payload.newStatus].splice(action.payload.newPosition, 0, {
        ...draggedTask,
        status: action.payload.newStatus,
      });

      draft.lastTaskDragData = action.payload;

      return;
    case TRY_GET_INITIAL_SCRUMBOARD_TASKS:
    case TRY_GET_FILTERED_SCRUMBOARD_TASKS:
    case TRY_GET_SEARCHED_SCRUMBOARD_TASKS:
      draft.scrumboardTasks = initialState.scrumboardTasks;
      draft.scrumboardTaskPage = initialState.scrumboardTaskPage;
      draft.scrumboardTasksLoading = initialState.scrumboardTasksLoading;
      draft.loading = initialState.loading;

      return;
    case TRY_GET_SCRUMBOARD_TASKS:
      draft.scrumboardTasksLoading[action.payload.status] = true;
      draft.loading = true;
      return;
    case GET_SCRUMBOARD_TASKS_ERROR:
      draft.scrumboardTasksLoading[action.payload] = false;
      draft.loading = false;
      return;
    case GET_SCRUMBOARD_TASKS_SUCCESS:
      draft.scrumboardTasks[action.payload.status].push(...action.payload.tasks);
      if (action.payload.tasks?.length > 0) draft.scrumboardTaskPage[action.payload.status] += 1;
      draft.scrumboardTasksLoading[action.payload.status] = false;
      draft.loading = false;
      return;
    case GET_SCRUMBOARD_TASK_COUNT:
      draft.totalItemsCount = action.payload;
      return;
    case ARCHIVE_DONE_TASKS_SUCCESS:
      draft.scrumboardTasks['DONE'] = draft.scrumboardTasks['DONE'].filter((task) => !action.payload.includes(task.id));
      return;
    case ARCHIVE_RELEASED_TASKS_SUCCESS:
      draft.scrumboardTasks['RELEASED'] = draft.scrumboardTasks['RELEASED'].filter(
        (task) => !action.payload.includes(task.id),
      );
      return;
    case UPDATE_TASK_FIELDS_FROM_SCRUMBOARD:
      const sbTaskStatus = action.payload.status || draft.selectedTask?.status;
      const taskIndexSB = draft.scrumboardTasks[sbTaskStatus]?.findIndex((task) => task.id === action.payload.id);
      if (taskIndexSB === -1 || taskIndexSB == null) return;

      draft.selectedTask =
        action.payload.id === draft.selectedTask?.id
          ? {
              ...draft.selectedTask,
              ...action.payload,
              effectivenessAnalysis: {
                ...draft.selectedTask?.effectivenessAnalysis,
                ...(action.payload || {}),
              },
            }
          : draft.selectedTask;

      if (draft.scrumboardTasks[sbTaskStatus].every((task) => task.id !== action.payload.id)) {
        draft.scrumboardTasks[sbTaskStatus].splice(taskIndexSB, 0, {
          ...draft.scrumboardTasks[sbTaskStatus][taskIndexSB],
          ...action.payload,
          effectivenessAnalysis: {
            ...draft.scrumboardTasks[sbTaskStatus][taskIndexSB]?.effectivenessAnalysis,
            ...(action.payload || {}),
          },
        });
      }

      draft.lastTaskChanges = action.payload.id === draft.selectedTask?.id ? action.payload : draft.lastTaskChanges;

      return;
    case UPDATE_COMMENTS_FROM_SCRUMBOARD_SUCCESS:
      const taskStatusComments = draft.selectedTask?.status;

      const updateCommentsIndex = draft.scrumboardTasks[taskStatusComments].findIndex(
        (task) => task.id === action.payload.id,
      );
      if (updateCommentsIndex === -1) return { ...draft };
      draft.selectedTask.comments = [...action.payload.comments];
      draft.updating = 'updated';

      draft.scrumboardTasks[taskStatusComments].splice(updateCommentsIndex, 1, {
        ...draft.scrumboardTasks[taskStatusComments][updateCommentsIndex],
        comments: [...action.payload.comments],
      });
      return;
    case UPDATE_TASK_SUCCESS_FROM_SCRUMBOARD:
      draft.updating = 'updated';

      const taskStatus = draft.selectedTask?.status;
      draft.selectedTask =
        action.payload.id === draft.selectedTask?.id
          ? {
              ...draft.selectedTask,
              ...action.payload,
              effectivenessAnalysis: {
                ...draft.selectedTask.effectivenessAnalysis,
                ...(action.payload?.effectivenessAnalysis || {}),
              },
            }
          : draft.selectedTask;
      const updateIndexSB = draft.scrumboardTasks[taskStatus]?.findIndex((task) => task.id === draft.selectedTask.id);
      if (updateIndexSB === -1 || updateIndexSB == null) return;

      if (action.payload.status && action.payload.status !== taskStatus) {
        draft.scrumboardTasks[taskStatus] = draft.scrumboardTasks[taskStatus].filter(
          (task) => task.id !== action.payload.id,
        );
        draft.scrumboardTasks[action.payload.status].unshift({
          ...draft.selectedTask,
          ...action.payload,
          effectivenessAnalysis: {
            ...draft.selectedTask.effectivenessAnalysis,
            ...(action.payload?.effectivenessAnalysis || {}),
          },
        });

        draft.lastTaskDragData = {
          taskId: action.payload.id,
          newStatus: action.payload.status,
          newPosition: 0,
        };

        return;
      }

      draft.scrumboardTasks[taskStatus].splice(updateIndexSB, 1, {
        ...draft.scrumboardTasks[taskStatus][updateIndexSB],
        ...action.payload,
      });

      return;
    case TOGGLE_TASK_DELETED_SUCCESS:
      const tasks = Object.values(draft.scrumboardTasks).flat();
      const deletedTaskStatus = tasks.find((task) => task.id === action.payload.id)?.status;
      if (!deletedTaskStatus) return;
      const taskColumnIndex = draft.scrumboardTasks[deletedTaskStatus].findIndex(
        (task) => task.id === action.payload.id,
      );

      draft.scrumboardTasks[deletedTaskStatus].splice(taskColumnIndex, 1);

      if (draft.selectedTask?.id === action.payload.id) draft.selectedTask = null;

      return;
    case UPDATE_TASK_ERROR_FROM_SCRUMBOARD:
    case UPDATE_COMMENTS_FROM_SCRUMBOARD_ERROR:
    case TOGGLE_TASK_DELETED_ERROR:
    case REMOVE_TASK_ERROR:
      draft.updating = 'failed';

      return;

    case LOAD_SCHEDULED_JOB_SUCCESS:
    case SCHEDULE_TASK_SUCCESS:
    case UPDATE_SCHEDULE_JOB_SUCCESS:
      draft.scheduledJob = action.payload;

      return;
    case FIND_TASK_SUCCESS:
      draft.scrumboardTasks[action.payload.status] = draft.scrumboardTasks[action.payload.status].map((task: Task) =>
        task.id === action.payload.id ? action.payload : task,
      );

      draft.selectedTask = { ...new Task(), ...action.payload };
      draft.editMode = true;
  }
}, initialState);

export const getScrumboardTasks = (state: State) => state.scrumboardTasks;

export const getScrumboardTasksLoading = (state: State) => state.scrumboardTasksLoading;

export const getLoading = (state: State) => state.loading;

export const getLastTaskChanges = (state: State) => state.lastTaskChanges;

export const getTaskUpdating = (state: State) => state.updating;

export const getSelectedTask = (state: State) => state.selectedTask;

export const getTotalItemsCount = (state: State) => state.totalItemsCount;

export const getEditMode = (state: State) => state.editMode;

export const getFormSubscriptionId = (state: State) => state.formSubscriptionId;

export const getOrderBy = (state: State) => state.orderBy;

export const getLastTaskDragData = (state: State) => state.lastTaskDragData;

export const getAwaitingDragResponse = (state: State) => state.awaitingDragResponse;

export const getSelectedScheduledJob = (state: State) => state.scheduledJob;

export const getSourceProject = (state: State) => state.sourceProject;

export const getScrumboardTaskPage = (state: State) => state.scrumboardTaskPage;
