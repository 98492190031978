import { AppUtils } from 'app/core/utils/app.utils';
import { WidgetType } from '../../widgets/widget/widget-type.model';
import { widgetSizes } from './widget-size';

export class GridsterData {
  rows: number;
  cols: number;
  maxItemCols: number;
  minItemCols: number;
  maxItemRows: number;
  minItemRows: number;

  constructor(
    public instanceId: string,
    public widgetType: WidgetType,
    public x = 0,
    public y = 0,
    public rowCount?: number,
    public colCount?: number,
  ) {
    const { rows, cols, maxItemCols, maxItemRows, minItemCols, minItemRows } =
      widgetSizes[widgetType] || widgetSizes['default'];
    this.rows = rowCount || rows;
    this.cols = colCount || cols;
    this.maxItemCols = maxItemCols;
    this.maxItemRows = maxItemRows;
    this.minItemCols = minItemCols;
    this.minItemRows = minItemRows;
  }
}

export class GridsterWidgetData {
  rows: number;
  cols: number;

  constructor(
    widgetType: WidgetType,
    public x = 0,
    public y = 0,
    public rowCount?: number,
    public colCount?: number,
  ) {
    const { rows, cols } = widgetSizes[widgetType] || widgetSizes['default'];
    this.rows = rowCount || rows;
    this.cols = colCount || cols;
  }
}

export class WidgetData {
  gridsterData: GridsterWidgetData;

  constructor(
    public type?: WidgetType,
    public values: any = {},
    gridsterData?: GridsterWidgetData,
    public instanceId = AppUtils.generateGUID(),
  ) {
    this.gridsterData = gridsterData || new GridsterWidgetData(this.type);
  }
}
