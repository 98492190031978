import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { IRestResponse, RestResponse, IRestError } from '../models/rest.model';
import { catchError, map } from 'rxjs/operators';
import { RestUtils } from '../utils/rest.utils';
import { TenantRegistration } from '../models/tenant-registration.model';
import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';
import { ProcessInstance } from 'app/main/content/apps/result/process-result/process-instance.model';
import { FileData } from 'app/main/content/apps/file-manager/document.model';

@Injectable({
  providedIn: 'root',
})
export class TenantDataService {
  private resourceUrl = SERVER_API_URL + 'api/production-order-module/production-order';

  constructor(private http: HttpClient) {}

  getTenantByName(name: string): Observable<any> {
    return this.http
      .get<ProcessInstance>(this.resourceUrl + '/tenants/name', {
        params: {
          tenantName: name,
        },
        observe: 'response',
      })
      .pipe(
        map((res) => {
          const result: any = res.body;
          const response: IRestResponse = new RestResponse();
          response.totalItems = 1;
          response.data = this.convertTenantDataFromServer(result);
          return response;
        }),
        catchError((err) => {
          const error: IRestError = RestUtils.formRestErrorObject(err);
          return throwError(error);
        }),
      );
  }

  getTenantsByIds(ids: string[]): Observable<any> {
    if (!ids || !ids.length) return of([]);

    return this.http
      .get<ProcessInstance>(this.resourceUrl + '/tenants/list', {
        params: {
          tenantIds: ids,
        },
        observe: 'response',
      })
      .pipe(
        map((res) => {
          const result: any = res.body;
          const response: IRestResponse = new RestResponse();
          response.data = result.map((tenant) => this.convertTenantDataFromServer(tenant));
          response.totalItems = response.data.length;
          return response;
        }),
        catchError((err) => {
          const error: IRestError = RestUtils.formRestErrorObject(err);
          return throwError(error);
        }),
      );
  }

  getTenantLogo(): Observable<FileData> {
    return this.http.get<FileData>('api/tenant/logo');
  }

  getTenantNamesByIds(ids: string[]): Observable<any> {
    return this.http
      .get<ProcessInstance>(this.resourceUrl + '/tenants/names', {
        params: {
          tenantIds: ids,
        },
        observe: 'response',
      })
      .pipe(
        map((res) => {
          const result: any = res.body;
          const names: string[] = result;
          const response: IRestResponse = new RestResponse();
          response.totalItems = names.length ? names.length : 0;
          response.data = names;
          return response;
        }),
        catchError((err) => {
          return throwError(() => RestUtils.formRestErrorObject(err));
        }),
      );
  }

  convertTenantDataFromServer(data): TenantRegistration {
    return (
      data && {
        ...new TenantRegistration(),
        ...data,
        registrationDate: data.registrationDate ? new Date(data.registrationDate) : null,
        expiryDate: data.expiryDate ? new Date(data.expiryDate) : null,
      }
    );
  }
}
