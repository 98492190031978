import { User } from 'app/shared';
import { Catalog } from '../../catalog/catalog/catalog.model';
import { DocumentInfo } from '../../file-manager/document.model';

export class InspectionEquipment {
  constructor(
    public id?: string,
    public inspectionEquipmentNumber?: string,
    public inspectionEquipmentGroup?: Catalog,
    public name?: string,
    public fitting?: string,
    public nominalDimension?: string,
    public accuracy?: string,
    public dimension?: Catalog,
    public measuringRangeFrom?: string,
    public measuringRangeTo?: string,
    public status: Status = Status.CREATED,
    public lockingReason?: Catalog,
    public interval?: number,
    public intervalType?: IntervalType,
    public lastCalibration?: Date,
    public nextCalibration?: Date,
    public homeLocation?: Catalog,
    public hasMaintenanceAgreement?: boolean,
    public calibrationCompany?: Catalog,
    public warningTime?: number,
    public externalCalibrationPrice?: number,
    public warningIntervalType: IntervalType = IntervalType.DAYS,
    public calibrationLocation?: Catalog,
    public currentLocation?: Catalog,
    public department?: Catalog,
    public owner?: User,
    public inventoryNumber?: string,
    public documentList?: DocumentInfo[],
  ) {}
}

export class InspectionEquipmentCriteria {
  constructor(
    public id?: string,
    public inspectionEquipmentNumber?: string,
    public inspectionEquipmentGroupId?: string,
    public name?: string,
    public fitting?: string,
    public nominalDimension?: string,
    public accuracy?: string,
    public dimensionId?: string,
    public measuringRangeFrom?: string,
    public measuringRangeTo?: string,
    public statusList?: Status[],
    public lockingReasonId?: string,
    public interval?: number,
    public intervalType?: IntervalType,
    public lastCalibration?: Date,
    public nextCalibration?: Date,
    public homeLocationId?: string,
    public hasMaintenanceAgreement?: boolean,
    public calibrationCompanyId?: string,
    public warningTime?: number,
    public externalCalibrationPrice?: number,
    public warningIntervalType?: IntervalType,
    public calibrationLocationId?: string,
    public currentLocationId?: string,
    public departmentId?: string,
    public ownerId?: string,
    public inventoryNumber?: string,
    public searchText?: string,
  ) {}
}

export enum Status {
  CREATED = 'CREATED',
  RELEASED = 'RELEASED',
  BLOCKED = 'BLOCKED',
  SCRAPPED = 'SCRAPPED',
  IN_USE = 'IN_USE',
}

export enum IntervalType {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}
