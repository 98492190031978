/* eslint-disable max-lines-per-function */
import {
   ProfileActions,
   SET_ACTIVE_USER,
   RESET_ACTIVE_USER,
   UPDATE_ACTIVE_USER_OPTIONS_SUCCESS,
   CLEAR_PROFILE_DATA,
   SET_APPLICATION_DATA,
   SET_JWT_TOKEN,
   UPDATE_GLOBAL_OPTIONS,
   SET_DATA_ON_LOGIN,
   SET_DATA_ON_INIT,
   TOKEN_TIMEOUT,
   TOKEN_INVALID,
   TOKEN_ISSUED,
    UPDATE_ACTIVE_USER_FILTERS_SUCCESS,
    REMOVE_ACTIVE_USER_FILTER,
    UPDATE_NAVIGATION_OPTIONS_SUCCESS,
} from './profile.actions';

import { User, DEFAULT_LANGUAGE } from 'app/shared';
import { UPDATE_USER_SUCCESS, UserActions } from '../../administration/user-manager/store/user-manager.actions';
import { produce } from 'immer';
import { LicenseDetails, ProductLine } from 'app/shared/license/licensedetails.model';
import { UserOptions } from '../user-options.model';
import { GlobalOptions } from '../../administration/global-options/global-options.model';
import { Filter } from '../../filter/filter.model';

export interface State {
   activeUser: User;
   activeUserLicenseDetails: LicenseDetails;
   activeUserOptions: UserOptions;
   activeUserFilter: Filter[];
   lastSelectedDashboardId: string;
   globalOptions: GlobalOptions;
   activeMqAddress: string;
   mqttAddress: string;
   matomoAddress: string;
   tenantName: string;
   tenantId: string;
   jwt: string;
   reportsAddress: string;
   bridgeDownloadAddress: string;
   productLine: ProductLine;
}

const initialState: State = {
   activeUser: null,
   activeUserLicenseDetails: null,
   activeUserOptions: null,
   globalOptions: null,
   activeUserFilter: [],
   activeMqAddress: '',
   mqttAddress: '',
   lastSelectedDashboardId: '',
   matomoAddress: '',
   jwt: '',
   tenantName: null,
   tenantId: null,
   reportsAddress: null,
   bridgeDownloadAddress: null,
   productLine: null,
};

export const profileReducer = produce((draft: State, action: ProfileActions | UserActions) => {
   switch (action.type) {
      case SET_DATA_ON_INIT:
      case SET_DATA_ON_LOGIN:
         draft.globalOptions = action.payload.globalOptions;
         draft.activeUserOptions = action.payload.userOptions;
         draft.lastSelectedDashboardId = action.payload.userOptions.options.dashboardSelected;

         draft.activeUser = action.payload.user;
         draft.activeUserFilter = action.payload.userOptions.activeFilters;
         draft.activeUserLicenseDetails = action.payload.license;
         // draft.activeUserWidgetFilters = action.payload.widgetFilters;

         return;
      case UPDATE_GLOBAL_OPTIONS:
         draft.globalOptions = action.payload;

         return;
      case UPDATE_USER_SUCCESS:
         if (draft.activeUser?.id === action.payload.data?.id) {
            draft.activeUser = action.payload.data;
            const mergedAuthorities = draft.activeUser.roles.flatMap(role => role.authorities);
            draft.activeUser = {
               ...draft.activeUser,
               authorities: mergedAuthorities,
            };
         }
         return;
      case SET_JWT_TOKEN:
         draft.jwt = action.payload;
         return;
      case SET_APPLICATION_DATA:
         draft.activeMqAddress = action.payload.activeMqAddress;
         draft.mqttAddress = action.payload.mqttAddress;
         draft.matomoAddress = action.payload.matomoAddress;
         draft.tenantName = action.payload.tenantName;
         draft.tenantId = action.payload.tenantId;
         draft.reportsAddress = action.payload.reportsAddress;
         draft.bridgeDownloadAddress = action.payload.bridgeDownloadAddress;
         draft.productLine = action.payload.productLine;
         return;
      case SET_ACTIVE_USER:
         draft.activeUser = action.payload;
         return;
      case RESET_ACTIVE_USER:
         draft.activeUser = null;
         return;
      case UPDATE_ACTIVE_USER_OPTIONS_SUCCESS:
         const optionsToUpdate = action.payload.data || {};
         const newUserOptions = new UserOptions();
         if (draft.activeUserOptions === null) return;

         newUserOptions.id = draft.activeUserOptions?.id;
         newUserOptions.userId = draft.activeUserOptions?.userId;
         newUserOptions.options = {
            ...draft.activeUserOptions.options,
            ...optionsToUpdate,
         };
         newUserOptions.navigationOptions = draft.activeUserOptions.navigationOptions;


         draft.activeUserOptions = {
            ...draft.activeUserOptions,
            ...newUserOptions,
         };
         return;
      case UPDATE_NAVIGATION_OPTIONS_SUCCESS:
         const navigationOptions = action.payload;
         const userOptions = draft.activeUserOptions;

         userOptions.navigationOptions = {
            ...userOptions.navigationOptions,
            ...navigationOptions,
         };


         draft.activeUserOptions = userOptions;
         return;
      case UPDATE_ACTIVE_USER_FILTERS_SUCCESS:
         const updatedFilter = action.payload;

         const index = draft.activeUserFilter?.findIndex((filter) =>
             filter.instanceId
                 ? filter.instanceId === updatedFilter?.instanceId
                 : filter.filterName === updatedFilter.filterName,
         );
         if (index !== -1) {
            draft.activeUserFilter[index] = updatedFilter;
         } else {
            draft.activeUserFilter = [...(draft.activeUserFilter || []), updatedFilter];
         }
         return;
      case REMOVE_ACTIVE_USER_FILTER:
            draft.activeUserFilter = draft.activeUserFilter.filter((filter) => filter.id !== action.payload.id);
         return;
      case TOKEN_ISSUED:
      case TOKEN_TIMEOUT:
      case TOKEN_INVALID:
      case CLEAR_PROFILE_DATA:
         draft.jwt = null;
         draft.activeUser = null;
         draft.activeUserLicenseDetails = null;
         draft.activeUserOptions = null;
         draft.lastSelectedDashboardId = null;
         draft.globalOptions = null;
   }
}, initialState);

export const getActiveUser = (state: State) => state.activeUser;

export const getActiveUserLicenseDetails = (state: State) => state.activeUserLicenseDetails;

export const getActiveUserOptions = (state: State) => state.activeUserOptions;

export const getLastSelectedDashboardId = (state: State) => <string>state.lastSelectedDashboardId;

export const getSelectedLanguage = (state: State) => state.activeUser?.langKey || DEFAULT_LANGUAGE;

export const getJwt = (state: State) => state.jwt;

export const getTenantId = (state: State) => state.tenantId;

export const getTenantName = (state: State) => state.tenantName;

export const getActiveMqAddress = (state: State) => state.activeMqAddress;

export const getMqttAddress = (state: State) => state.mqttAddress;

export const getGlobalOptions = (state: State) => state.globalOptions;

export const getReportServiceUrl = (state: State) => state.reportsAddress;

export const getBridgeDownloadUrl = (state: State) => state.bridgeDownloadAddress;

export const getActiveUserFilter = (state: State) => state.activeUserFilter;

export const getProductLine = (state: State) => state.productLine;
