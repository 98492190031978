import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { IRestResponse, RestResponse, IRestError } from 'app/core/models/rest.model';
import { RestUtils } from 'app/core/utils/rest.utils';
import { SERVER_API_URL } from 'app/app.constants';
import { globalObject, GlobalObjectField, UserOptions } from './user-options.model';

@Injectable({ providedIn: 'root' })
export class UserOptionsService {
   private resourceUrl = SERVER_API_URL + 'api/options/user';

   constructor(private http: HttpClient) {}

   // Get options for the current user
   getOptionsForCurrentUser(): Observable<IRestResponse<UserOptions>> {
      return this.http.get(this.resourceUrl).pipe(
         map(res => {
            const result: any = res;
            const response: IRestResponse = new RestResponse();
            response.totalItems = 1;
            response.data = this.convert(result);
            return response;
         }),
         catchError(err => {
            const error: IRestError = RestUtils.formRestErrorObject(err);
            return throwError(error);
         }),
      );
   }

   // Update options for the current user
   updateOptionsForCurrentUser(userOptionsToUpdate: UserOptions): Observable<IRestResponse<UserOptions>> {
      return this.http.put<UserOptions>(this.resourceUrl, userOptionsToUpdate).pipe(
         map(res => {
            const response: IRestResponse = new RestResponse();
            response.totalItems = 1;
            response.data = res;
            return response;
         }),
         catchError(err => {
            const error: IRestError = RestUtils.formRestErrorObject(err);
            return throwError(error);
         }),
      );
   }

   updateNavigationOptionsForCurrentUser(userOptionsToUpdate: Record<string, boolean>): Observable<UserOptions> {
      return this.http.put<Record<string, boolean>>(this.resourceUrl + '/navigation-options', userOptionsToUpdate);
   }

   // Update options for the current user
   updateGlobalObjectUserOptions(userOptionsToUpdate: Partial<{[key in keyof typeof GlobalObjectField]: string | number}>): Observable<IRestResponse<{ [prop: string]: string }>> {
      return this.http.put<UserOptions>(this.resourceUrl, {[globalObject]: userOptionsToUpdate})
         .pipe(
            map(res => {
               const response: IRestResponse = new RestResponse();
               response.totalItems = 1;
               response.data = res;
               return response;
            }),
            catchError(err => {
               const error: IRestError = RestUtils.formRestErrorObject(err);
               return throwError(error);
            }),
         );
   }

   /**
    * Convert object to the class instance
    */
   private convert(userOptions: UserOptions): UserOptions {
      const copy = Object.assign(new UserOptions(), userOptions);
      return copy;
   }
}
