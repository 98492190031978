// Error status: 0-99       - Different client errors (custom defined error codes)
// Error status: 100-599    - HTTP errors
// Error status: 9xx        - Custom error codes

import { ErrorHandler, Injectable, NgZone, isDevMode } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomErrorHandlerSnackBarComponent } from '../components/custom-error-handler-snack-bar/custom-error-handler-snack-bar.component';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    public snackBar: MatSnackBar,
    public translate: TranslateService,
    private zone: NgZone,
  ) {}
  handleError(error: Error) {
    if (!(error instanceof HttpErrorResponse)) {
      if (isDevMode()) {
        console.error('Client error: ', error);
      } else {
        const consoleRrrorMessage = `Error: ${error.message}`;
        console.error('Client Error Message: ', consoleRrrorMessage);
      }

      const errMsg = error.message;
      let errorStatus = 999;

      if (errMsg.includes('Http failure response')) {
        errorStatus = 0;
      } else if (errMsg.includes('Cannot match any routes')) {
        errorStatus = 1;
      } else if (errMsg.includes('loadChunkError')) {
        errorStatus = 2;
      }

      // Do not display client errors
      if (errorStatus === 999) return;

      const translationPath = 'error.nonRest.' + String(errorStatus);
      this.translateAndDisplayErrorMessage(translationPath, errorStatus);
    } else {
      if (isDevMode()) {
        console.error('Unhandled HTTP error cought: ', error);
      } else {
        const consoleRrrorMessage = `Error: ${error.message}`;
        console.error('Unhandled HTTP error message: ', consoleRrrorMessage);
      }
    }
  }

  public translateAndDisplayErrorMessage(translationPath: string, errorStatus: number) {
    let errorMessage = '';
    this.translate.get(translationPath).subscribe((translation: string) => {
      if (translation.includes(translationPath)) {
        // Translation not found
        errorMessage = this.getDefaultErrorMessage(errorStatus);
      } else {
        // Translation exists
        errorMessage = this.translate.instant(translationPath);
      }
      this.showError(errorMessage);
    });
  }

  public showError(errorMessage: string, durationTime = 3000) {
    console.error('Translated Error Message: ', errorMessage);
    // window.alert(errorMessage);
    this.zone.run(() => {
      this.snackBar.openFromComponent(CustomErrorHandlerSnackBarComponent, {
        data: {
          errorMessage: errorMessage,
        },
        duration: durationTime,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      });
    });
  }

  public getDefaultErrorMessage(errorStatus: number): string {
    console.error('Non HTTP Error Handler - No translation data is found. A predefined error message is shown !!!');
    switch (errorStatus) {
      case 0:
        return "Connection error - Couldn't connect to the server";
      case 1:
        return 'Unknown URL address';
      case 2:
        return 'An application module can not be loaded';
      default:
        return 'Unknown error. Please call support center';
    }
  }
}
