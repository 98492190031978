import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';

import { SERVER_API_URL } from '../../../../app.constants';
import { WidgetFilter } from '../dashboard/board/models/widget-filter.model';
import { WidgetType } from '../dashboard/widgets/widget/widget-type.model';
import { IRestResponse, RestResponse, IRestError } from 'app/core/models/rest.model';
import { RestUtils } from 'app/core/utils/rest.utils';
import { UserOptions } from './user-options.model';
import { Filter } from '../filter/filter.model';


/**
 * Defines methods used by multiple module components as well as common data and utility
 */
@Injectable({
  providedIn: 'root',
})
export class UserWidgetService {
  private resourceUrl = SERVER_API_URL + 'api/options/user';
  private filterLibPath = '/assets/api/filters.json';

  constructor(private http: HttpClient) {}

  getFilterLibrary() {
    return this.http.get<any>(this.filterLibPath);
  }

  getFiltersWidget(componentType: string): Observable<WidgetFilter[]> {
    return this.http.get<WidgetFilter[]>(`${this.resourceUrl}/list/${componentType}`);
  }

  create(filter: WidgetFilter): Observable<WidgetFilter> {
    return this.http.post<WidgetFilter>(this.resourceUrl, filter);
  }

 
  getWidgetSettingsForCurrentUser(): Observable<IRestResponse<Filter>> {
    return this.http.get(this.resourceUrl).pipe(
      map((res) => {
        const result: any = res;
        const response: IRestResponse = new RestResponse();
        response.totalItems = 1;
        response.data = this.convert(result);
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  updateActiveFiltersForCurrentUser(filter: Filter) : Observable<Filter> {
    return this.http.put<Filter>(this.resourceUrl + "/activeFilters", filter);
  }

  removeActiveFilterForCurrentUser(filter: Filter) : Observable<Filter> {
    return this.http.put<Filter>(this.resourceUrl + "/activeFilters/delete", filter);
  }

  find(id: string): Observable<WidgetFilter> {
    return this.http.get<WidgetFilter>(`${this.resourceUrl}/${id}`);
  }

  delete(id: string): Observable<unknown> {
    return this.http.delete(`${this.resourceUrl}/${id}`);
  }

  getFilterWidgetType(filterList: WidgetFilter[], filterId: string): WidgetType {
    const foundFilter = filterList.find((filter) => filter.id === filterId);
    return foundFilter && foundFilter.widgetType;
  }


 private convert(userOptions: UserOptions): UserOptions {
   const copy = Object.assign(new UserOptions(), userOptions);
   return copy;
 }
}
