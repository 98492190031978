import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CatalogLibraryService {
  private readonly catLibJsonPath = '/assets/api/catalogs.json';
  private readonly catLibTemplatesJsonPath = '/assets/api/catalog-templates.json';
  private cachedCatalogs$ = new BehaviorSubject<any>(null);
  private loadCatalogs = true;
  private cachedTemplates$ = new BehaviorSubject<any>(null);
  private loadTemplates = true;

  constructor(private _http: HttpClient) {}

  getCatalogLibrary(): Observable<any[]> {
    return this.getCatalogMetadata().pipe(
      switchMap((catLibrary) =>
        this.getCatalogTemplates().pipe(
          map((templates) =>
            catLibrary.map((catMetadata) => ({
              ...catMetadata,
              templates: Object.fromEntries(
                templates
                  .filter(({ componentType }) => componentType === catMetadata.componentType)
                  /**/ .map((template) => [template.templateName, template]),
              ),
            })),
          ),
        ),
      ),
    );
  }

  getCatalogByType(type: string): Observable<any> {
    return this.getCatalogLibrary().pipe(
      map((catMetadata) => catMetadata.find(({ componentType }) => componentType === type)),
    );
  }

  private getCatalogMetadata(forceRefresh = false): Observable<any> {
    if (!forceRefresh && !this.loadCatalogs) {
      return this.cachedCatalogs$.pipe(
        filter((templates) => templates != null),
        first(),
      );
    }

    this.loadCatalogs = false;

    return this._http.get<any>(this.catLibJsonPath).pipe(
      map((res) => res.catalogs),
      tap((res) => this.cachedCatalogs$.next(res)),
      catchError((err) => {
        this.loadCatalogs = true;
        throw new Error(err);
      }),
    );
  }

  private getCatalogTemplates(forceRefresh = false): Observable<any> {
    if (!forceRefresh && !this.loadTemplates) {
      return this.cachedTemplates$.pipe(
        filter((templates) => templates != null),
        first(),
      );
    }

    this.loadTemplates = false;

    return this._http.get<any>(this.catLibTemplatesJsonPath).pipe(
      map((res) => res.templates),
      tap((res) => this.cachedTemplates$.next(res)),
      catchError((err) => {
        this.loadTemplates = true;
        throw new Error(err);
      }),
    );
  }
}
