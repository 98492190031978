import { NotificationZD } from './notification.model';

export class NotificationsCriteria extends NotificationZD {
  constructor(
    public searchText?: string,
    public creatorId?: string,
    public messageRead?: boolean,
  ) {
    super();
  }

  setActiveFilter(activeFitler?: string) {}
}
