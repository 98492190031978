/* eslint-disable no-prototype-builtins */
import { HttpParams } from '@angular/common/http';

export const createRequestOption = (req?: any): HttpParams => {
  let params: HttpParams = new HttpParams();

  if (req?.hasOwnProperty('page')) {
    params = params.append('page', req['page']);
  }

  if (req?.hasOwnProperty('size')) {
    params = params.append('size', req['size']);
  }

  if (req?.hasOwnProperty('sort')) {
    const sort = req['sort'];
    if (sort instanceof Array) {
      sort.forEach((item) => {
        params = params.append('sort', item);
      });
    } else {
      params = params.append('sort', sort);
    }
  }

  return params;
};
