import { WidgetType } from '../../widgets/widget/widget-type.model';

const defaultSize = {
  rows: 5,
  cols: 5,
  minItemRows: 4,
  minItemCols: 4,
  maxItemRows: 50,
  maxItemCols: 50,
};

export const widgetSizes: { [widgetType: string]: typeof defaultSize } = {
  [WidgetType.BATCH_NUMBER]: {
    ...defaultSize,
    minItemRows: 3,
    rows: 4,
  },
  [WidgetType.IDENTITY_CHECK]: {
    ...defaultSize,
    minItemCols: 7,
    minItemRows: 6,
    cols: 7,
    rows: 6,
  },
  [WidgetType.SYNC_STATUS]: {
    ...defaultSize,
    minItemCols: 6,
    minItemRows: 5,
    cols: 6,
    rows: 5,
  },
  [WidgetType.DOCUMENT]: {
    ...defaultSize,
    minItemRows: 2,
    minItemCols: 6,
    cols: 6,
  },
  [WidgetType.INSPECTION_PROTOCOL]: {
    ...defaultSize,
    minItemCols: 12,
    cols: 12,
  },
  [WidgetType.INSTANCE_DECISION]: {
    ...defaultSize,
    minItemCols: 10,
    cols: 10,
    minItemRows: 5,
  },
  [WidgetType.INSTANCE_STATUS]: {
    ...defaultSize,
    minItemRows: 5,
    cols: 10,
    minItemCols: 10,
  },
  [WidgetType.MY_BRIDGES]: {
    ...defaultSize,
    minItemCols: 7,
    cols: 7,
  },
  [WidgetType.STEP_DISPLAYER]: {
    ...defaultSize,
    cols: 10,
    minItemCols: 7,
    maxItemRows: 8,
    minItemRows: 2,
  },
  [WidgetType.SINGLE_PARAM]: {
    ...defaultSize,
    minItemRows: 6,
    rows: 7,
    minItemCols: 8,
    cols: 13,
  },
  [WidgetType.PROBABILITY_NET]: {
    ...defaultSize,
    minItemRows: 6,
    rows: 7,
    minItemCols: 8,
    cols: 13,
  },
  [WidgetType.HISTOGRAM]: {
    ...defaultSize,
    minItemRows: 6,
    rows: 7,
    minItemCols: 8,
    cols: 13,
  },
  [WidgetType.EDITING_CHARACTERISTIC]: {
    ...defaultSize,
    minItemCols: 9,
    cols: 9,
  },
  [WidgetType.PROCESS_INSTANCE_SELECTION]: {
    ...defaultSize,
    minItemRows: 5,
    rows: 5,
  },
  [WidgetType.INSPECTION_PROGRESS]: {
    ...defaultSize,
    minItemRows: 2,
  },
  [WidgetType.CHARACTERISTIC_NOTES]: {
    ...defaultSize,
    minItemRows: 2,
  },
  [WidgetType.TASK]: {
    ...defaultSize,
    rows: 7,
    cols: 7,
    minItemRows: 3,
    minItemCols: 3,
  },
  [WidgetType.PERSON]: {
    ...defaultSize,
    minItemRows: 2,
    minItemCols: 2,
  },
  [WidgetType.GOAL_LIST_PROGRESS]: {
    ...defaultSize,
    cols: 10,
    minItemRows: 5,
    minItemCols: 10,
  },

  [WidgetType.INPUT_CHARACTERISTIC_VALUE]: {
    ...defaultSize,
    rows: 7,
    cols: 9,
    minItemRows: 7,
    minItemCols: 9,
  },
  [WidgetType.DASHBOARD_NAVIGATION]: {
    ...defaultSize,
    rows: 3,
    cols: 4,
    minItemRows: 3,
  },
  [WidgetType.SELECT_NEXT_CHARACTERISTIC]: {
    ...defaultSize,
    rows: 3,
    cols: 4,
    minItemRows: 2,
    minItemCols: 2,
  },
  [WidgetType.SELECT_PREVIOUS_CHARACTERISTIC]: {
    ...defaultSize,
    rows: 3,
    cols: 4,
    minItemRows: 2,
    minItemCols: 2,
  },
  [WidgetType.CLOCK]: {
    ...defaultSize,
    minItemRows: 2,
    minItemCols: 2,
  },
  [WidgetType.CPK]: {
    ...defaultSize,
    rows: 4,
    cols: 5,
    minItemRows: 4,
    minItemCols: 5,
  },
  [WidgetType.NEXT_SAMPLE]: {
    ...defaultSize,
    rows: 6,
    cols: 6,
    minItemCols: 6,
    minItemRows: 6,
  },
  default: defaultSize,
};
