/* eslint-disable max-lines-per-function */
import { produce } from 'immer';
import { Textstore } from 'app/shared/lang-dialog/textstore.model';
import {
  ADD_PROJECT_ERROR,
  ADD_PROJECT_SUCCESS,
  CLEAR_PROJECT_SELECTION,
  DESELECT_PROJECT,
  DRAG_PROJECT_ERROR,
  DRAG_PROJECT_SUCCESS,
  GET_PROJECT_NEXT_PAGE_ERROR,
  GET_PROJECT_NEXT_PAGE_SUCCESS,
  LOAD_BREADCRUMB_SUCCESS,
  LOAD_VIEW_ONLY_MODE,
  REMOVE_PROJECT_ERROR,
  REMOVE_PROJECT_SUCCESS,
  RESET_STATE,
  ROUTE_UPDATE_PROJECT_DATA_ERROR,
  ROUTE_UPDATE_PROJECT_DATA_SUCCESS,
  SET_SELECTED_PROJECT,
  SLICE_BREADCRUMB,
  TRY_DRAG_PROJECT,
  TRY_FIND_PROJECT,
  TRY_FIND_PROJECT_FROM_BREADCRUMB,
  TRY_GET_PROJECT_NEXT_PAGE,
  TRY_LOAD_BREADCRUMB,
  TRY_LOAD_CHILDREN_LIST,
  TRY_LOAD_FILTERED_PROJECT_LIST,
  TRY_LOAD_INITIAL_PROJECT_LIST,
  TRY_LOAD_SELECTED_PROJECT_LIST,
  TRY_LOAD_SORTED_PROJECT_LIST,
  TRY_OPEN_PROJECT_DATA,
  TRY_ROUTE_UPDATE_PROJECT_DATA,
  UPDATE_PROJECT_ERROR,
  UPDATE_PROJECT_SUCCESS,
  ProjectActions,
  ADD_PRIVATE_PROJECT_FOR_ANOTHER_USER_SUCCESS,
  SKIP_PROJECT_DRAG,
  GET_PROJECT_NEXT_PAGE_PARENT_NOT_AVAILABLE_ERROR,
  UPDATE_PROJECT_TO_PRIVATE_FOR_ANOTHER_USER_SUCCESS,
} from './project.actions';
import { ITEMS_PER_PAGE } from 'app/shared';
import { Project } from '../model/project.model';

export interface State {
  projectList: Project[];
  selectedProject: Project;
  rootProjectId: string;
  loading: boolean;
  sortOrder: 'asc' | 'desc';
  queryParams: { page: number; size: number; sort: string[] };
  breadcrumb: { id: string; parentId: string; name: Textstore }[];
  viewOnly: boolean;
}

const initialState: State = {
  projectList: [],
  selectedProject: null,
  rootProjectId: undefined,
  loading: false,
  sortOrder: 'asc',
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: ['is_parent,desc', 'id,desc'],
  },
  breadcrumb: [],
  viewOnly: true,
};

export const projectManagementReducer = produce((draft: State, action: ProjectActions): void => {
  switch (action.type) {
    case UPDATE_PROJECT_TO_PRIVATE_FOR_ANOTHER_USER_SUCCESS:
      draft.projectList = draft.projectList.filter((pr) => pr.id !== draft.selectedProject?.id);
      draft.selectedProject = null;

      return;
    case ROUTE_UPDATE_PROJECT_DATA_ERROR:
    case GET_PROJECT_NEXT_PAGE_PARENT_NOT_AVAILABLE_ERROR:
      draft.loading = false;
      draft.selectedProject = null;

      return;
    case ADD_PRIVATE_PROJECT_FOR_ANOTHER_USER_SUCCESS:
      draft.selectedProject = null;

      return;
    case RESET_STATE:
      Object.assign(draft, initialState);

      return;
    case DRAG_PROJECT_SUCCESS:
      draft.projectList = draft.projectList.filter((diagram) => diagram.id !== action.payload.sourceProjectId);
      draft.loading = false;

      return;
    case LOAD_VIEW_ONLY_MODE:
      draft.viewOnly = action.payload;

      return;
    case ROUTE_UPDATE_PROJECT_DATA_SUCCESS:
      draft.loading = false;

      draft.selectedProject = action.payload.project;

      const lastIndex = Math.max(draft.breadcrumb.length - 1, 0);
      const newBcItem = {
        id: action.payload?.project?.id,
        parentId: action.payload?.project?.parentId,
        name: action.payload?.project?.name,
      };
      if (action.payload.project == null) return;
      if (action.payload.project.parentId !== draft.breadcrumb[lastIndex]?.id) draft.breadcrumb[lastIndex] = newBcItem;
      else draft.breadcrumb.push(newBcItem);

      return;
    case SLICE_BREADCRUMB:
      const lastDiagramId = draft.selectedProject?.id || draft.rootProjectId;
      const diagramBCIndex = draft.breadcrumb.findIndex((bcd) => bcd.id === lastDiagramId);

      draft.breadcrumb = draft.breadcrumb.slice(0, diagramBCIndex + 1);

      return;

    case TRY_LOAD_CHILDREN_LIST:
      draft.rootProjectId = action.payload;
      draft.loading = true;
      draft.projectList = [];
      draft.queryParams.page = 0;
      draft.selectedProject = null;
      const bcIndex = action.payload && draft.breadcrumb.findIndex((bc) => bc.id === action.payload);
      draft.breadcrumb = draft.breadcrumb.slice(0, bcIndex != null ? bcIndex + 1 : 0);

      return;
    case DESELECT_PROJECT:
      draft.selectedProject = null;
      draft.breadcrumb.pop();

      return;
    case TRY_LOAD_SELECTED_PROJECT_LIST:
      draft.rootProjectId = action.payload;
      draft.loading = true;
      draft.projectList = [];
      draft.queryParams.page = 0;

      return;
    case TRY_LOAD_BREADCRUMB:
      draft.breadcrumb = [];

      return;
    case LOAD_BREADCRUMB_SUCCESS:
      // Without Required wrapper ts will complain because id is optional in process diagram
      draft.breadcrumb = action.payload as Required<Project>[];

      return;

    case TRY_LOAD_INITIAL_PROJECT_LIST:
      draft.loading = true;
      draft.projectList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = initialState.sortOrder;

      return;
    case TRY_LOAD_SORTED_PROJECT_LIST:
      draft.loading = true;
      draft.projectList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = draft.sortOrder === 'asc' ? 'desc' : 'asc';

      return;
    case TRY_LOAD_FILTERED_PROJECT_LIST:
      draft.selectedProject = null;
      draft.projectList = [];

      return;
    case CLEAR_PROJECT_SELECTION:
      draft.selectedProject = null;

      return;
    case TRY_FIND_PROJECT:
    case TRY_FIND_PROJECT_FROM_BREADCRUMB:
      draft.selectedProject = null;

      return;
    case TRY_GET_PROJECT_NEXT_PAGE:
    case TRY_DRAG_PROJECT:
    case TRY_OPEN_PROJECT_DATA:
      draft.loading = true;

      return;
    case TRY_ROUTE_UPDATE_PROJECT_DATA:
      draft.loading = true;
      if (draft.projectList.every((diagram) => diagram.id !== action.payload)) {
        draft.queryParams.page = 0;
      }

      return;
    case ROUTE_UPDATE_PROJECT_DATA_ERROR:
    case GET_PROJECT_NEXT_PAGE_ERROR:
    case ADD_PROJECT_ERROR:
    case UPDATE_PROJECT_ERROR:
    case REMOVE_PROJECT_ERROR:
    case DRAG_PROJECT_ERROR:
    case SKIP_PROJECT_DRAG:
      draft.loading = false;

      return;
    case GET_PROJECT_NEXT_PAGE_SUCCESS:
      draft.loading = false;
      draft.projectList.push(
        ...action.payload.filter((diagram) => !draft.projectList.map((d) => d.id).includes(diagram.id)),
      );
      if (action.payload.length > 0) draft.queryParams.page += 1;

      return;
    case SET_SELECTED_PROJECT:
      draft.selectedProject = action.payload;

      return;
    case ADD_PROJECT_SUCCESS:
      draft.projectList.unshift(action.payload);

      return;
    case UPDATE_PROJECT_SUCCESS:
      draft.projectList = draft.projectList.map((item) => (item.id === action.payload.id ? action.payload : item));

      draft.selectedProject = draft.selectedProject?.id === action.payload.id ? action.payload : draft.selectedProject;

      // if edited diagram is in breadcrumb it will always be last element
      if (action.payload.id === draft.breadcrumb[draft.breadcrumb.length - 1]?.id) {
        draft.breadcrumb[draft.breadcrumb.length - 1] = {
          id: action.payload.id,
          parentId: action.payload.parentId,
          name: action.payload.name,
        };
      }

      return;
    case REMOVE_PROJECT_SUCCESS:
      draft.projectList = draft.projectList.filter((item) => draft.selectedProject.id !== item.id);
      draft.selectedProject = null;

      
  }
}, initialState);

export const getProjectList = (state: State) => state.projectList;

export const getProjectQueryParams = (state: State) => state.queryParams;

export const getProjectSelected = (state: State) => state.selectedProject;

export const getProjectLoading = (state: State) => state.loading;

export const getRootProjectId = (state: State) => state.rootProjectId;

export const getProjectCurrentSortOrder = (state: State) => state.sortOrder;

export const getProjectBreadcrumb = (state: State) => state.breadcrumb;

export const getProjectViewMode = (state: State) => state.viewOnly;
