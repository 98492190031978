import { Component, Input, ElementRef, ViewChild, Renderer2, AfterViewInit, OnChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MatIcon } from '@angular/material/icon';
import { MatDialogClose } from '@angular/material/dialog';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'view-image-b64',
  templateUrl: './view-image-b64.component.html',
  styleUrls: ['./view-image-b64.component.scss'],
  standalone: true,
  imports: [MatIconButton, MatDialogClose, MatIcon],
})
export class ViewImageB64Component implements AfterViewInit, OnChanges {
  @Input() base64Content: any;
  @Input() hideClose = false;
  @Input() maxHbase64Contenteight = 'min(100%, calc(100vw - 72px))';
  @Input() maxWidth = 'min(100%, calc(100vw - 36px))';

  url: SafeResourceUrl;

  imgElem: HTMLElement;

  @ViewChild('imgWrapper', { static: true })
  imgWrapper: ElementRef<HTMLVideoElement>;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.imgElem = this.renderer.createElement('img');
    this.renderer.setAttribute(this.imgElem, 'src', this.base64Content);
    this.renderer.appendChild(this.imgWrapper.nativeElement, this.imgElem);
  }

  ngOnChanges(changes) {
    if (changes?.base64Content && this.imgWrapper?.nativeElement && this.imgElem) {
      this.renderer.removeChild(this.imgWrapper.nativeElement, this.imgElem);
      this.imgElem = this.renderer.createElement('img');
      this.renderer.setAttribute(this.imgElem, 'src', this.base64Content);
      this.renderer.appendChild(this.imgWrapper.nativeElement, this.imgElem);
    }
  }
}
