import { Action } from '@ngrx/store';
import { Dashboard } from '../../board/models/board.model';
import { WidgetData, GridsterData } from '../../board/models/widget.model';

import { ActionWithPayload } from '../../../../../../shared';

export const DESELECT_DASHBOARD =
  '[Dashboard Settings On selected dashboard removed] Remove strategy canvas dashboard selection';

export const SET_CONFIG_MODE = '[SC Header Toggle Config Mode Button Click] Set config mode';

export const TRY_GET_DASHBOARD_LIST = '[SC Initialization] Try get dashboard list';
export const GET_DASHBOARD_LIST_SUCCESS = '[SC Effects API Response] Get dashboard list success';
export const GET_DASHBOARD_LIST_ERROR = '[SC Effects API Response] Get dashboard list error';

export const TRY_FIND_DASHBOARD = '[SC Route Change] Try find dashboard';
export const FIND_DASHBOARD_SUCCESS = '[SC Effects API Response] Find dashboard success';
export const FIND_DASHBOARD_ERROR = '[SC Effects API Response] Find dashboard error';

export const UNLOAD_WIDGETS_FOR_SELECTED_DASHBOARD = '[SC] Unload widgets for selected dashboard.';

export const TRY_UPDATE_WIDGET_DETAILS = '[SC Widget Form Submit] Try update current widget details';
export const TRY_ADD_WIDGET = '[SC Add Widget Menu] Try add new widget';
export const TRY_REMOVE_WIDGET = '[SC Widget Header] Try remove widget.';
export const TRY_UPDATE_GRID_POSITION = '[SC Widget Placement Change] On widget appended';
export const UPDATE_DASHBOARD_SUCCESS = '[SC Effects API Response] Update dashboard success';
export const UPDATE_DASHBOARD_ERROR = '[SC Effects API Response] Update dashboard error';

export const UNLOAD_DASHBOARD_LIST = '[SC On Destroy] Unload dashboard list';

export const UPDATE_VIEW_ONLY_MODE = '[SC init] Update view only status';
export const LOAD_VIEW_ONLY_MODE = '[SC Effects] Set if view only mode';

export const SELECT_FIRST_DASHBOARD = '[SC Menu Item Clicked] Select first dashboard';

export class TrySelectFirstDashboard implements Action {
  readonly type = SELECT_FIRST_DASHBOARD;
}

export class UpdateViewOnlyMode implements Action {
  readonly type = UPDATE_VIEW_ONLY_MODE;
}

export class LoadViewOnlyMode implements ActionWithPayload<boolean> {
  readonly type = LOAD_VIEW_ONLY_MODE;
  constructor(public payload: boolean) {}
}

export class DeselectDashboard implements Action {
  readonly type = DESELECT_DASHBOARD;
}

export class UnloadDashboards implements Action {
  readonly type = UNLOAD_DASHBOARD_LIST;
}

export class TryAddWidget implements ActionWithPayload<WidgetData[]> {
  readonly type = TRY_ADD_WIDGET;
  constructor(public payload: WidgetData[]) {}
}

export class TryUpdateGridPositions implements ActionWithPayload<GridsterData[]> {
  readonly type = TRY_UPDATE_GRID_POSITION;
  constructor(public payload: GridsterData[]) {}
}

export class TryRemoveWidget implements ActionWithPayload<string> {
  readonly type = TRY_REMOVE_WIDGET;
  constructor(public payload: string) {}
}

export class UnloadWidgetsForSelected implements Action {
  readonly type = UNLOAD_WIDGETS_FOR_SELECTED_DASHBOARD;
}

export class SetConfigMode implements ActionWithPayload<boolean> {
  readonly type = SET_CONFIG_MODE;
  constructor(public payload: boolean) {}
}

export class TryUpdateWidgetDetails implements ActionWithPayload<{ config: any; instanceId: string }> {
  readonly type = TRY_UPDATE_WIDGET_DETAILS;
  constructor(public payload: any) {}
}

export class TryGetDashboardList implements Action {
  readonly type = TRY_GET_DASHBOARD_LIST;
}
export class GetDashboardListSuccess implements ActionWithPayload<Dashboard[]> {
  readonly type = GET_DASHBOARD_LIST_SUCCESS;
  constructor(public payload: Dashboard[]) {}
}
export class GetDashboardListError implements Action {
  readonly type = GET_DASHBOARD_LIST_ERROR;
}

export class UpdateDashboardSuccess implements ActionWithPayload<Dashboard> {
  readonly type = UPDATE_DASHBOARD_SUCCESS;
  constructor(public payload: Dashboard) {}
}
export class UpdateDashboardError implements Action {
  readonly type = UPDATE_DASHBOARD_ERROR;
}

export class TryFindDashboard implements ActionWithPayload<string> {
  readonly type = TRY_FIND_DASHBOARD;
  constructor(public payload: string) {}
}
export class FindDashboardSuccess implements ActionWithPayload<Dashboard> {
  readonly type = FIND_DASHBOARD_SUCCESS;
  constructor(public payload: Dashboard) {}
}
export class FindDashboardError implements Action {
  readonly type = FIND_DASHBOARD_ERROR;
}

export type SCActions =
  | TryUpdateWidgetDetails
  | TryGetDashboardList
  | GetDashboardListSuccess
  | GetDashboardListError
  | UpdateDashboardSuccess
  | UpdateDashboardError
  | TryFindDashboard
  | FindDashboardSuccess
  | FindDashboardError
  | SetConfigMode
  | UnloadWidgetsForSelected
  | TryRemoveWidget
  | TryAddWidget
  | TryUpdateGridPositions
  | UnloadDashboards
  | DeselectDashboard
  | UpdateViewOnlyMode
  | LoadViewOnlyMode;
