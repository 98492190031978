import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

/**
 * Wraps angular date pipe. Default localization for english is i.e. April 4. 2019
 * But wanted format is 4/4/2019. For other langs default localization is ok.
 * Using the wraper avoids passing all 3 args to ng date pipe and defining and/or
 * calling dateFormat method
 */
@Pipe({
  name: 'dateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(date: Date, selectedLanguage: string, dateFormat?: string): string {
    return this.datePipe.transform(
      date,
      dateFormat || this.getDateFormat(selectedLanguage),
      undefined,
      selectedLanguage,
    );
  }

  private getDateFormat(lang: string): string {
    return lang !== 'en' ? undefined : 'MM/dd/yyyy';
  }
}
