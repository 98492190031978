import { FileData } from 'app/main/content/apps/file-manager/document.model';
import { User, UserInfo } from '../../shared';

export namespace AppUtils {
  export function isTouchDevice() {
    const touchSupport = 'ontouchstart' in window;
    const deviceAgent = navigator.userAgent.toLowerCase();
    return touchSupport || /(iphone|ipod|ipad|android|iemobile|blackberry|bada)/.test(deviceAgent);
  }

  export function generateGUID() {
    return (
      Math.floor(Date.now() / 1000).toString(16) +
      ' '.repeat(16).replace(/./g, () => Math.floor(Math.random() * 16).toString(16))
    );
  }

  export function convertUserImageURL(user: User | UserInfo) {
    if (user?.imageFileData?.thumbnail && user?.imageFileData?.docContentType) {
      return `data:${user.imageFileData.docContentType};base64,${user.imageFileData.thumbnail}`;
    }
    return '/assets/images/avatars/profile.jpg';
  }

  export function convertUserInfoImageURL(userInfo: UserInfo) {
    if (userInfo?.imageFileData?.thumbnail && userInfo?.imageFileData?.docContentType)
      { return `data:${userInfo.imageFileData.docContentType};base64,${userInfo.imageFileData.thumbnail}`; }
    return '/assets/images/avatars/profile.jpg';
  }

  export function convertTenantLogoURL(imageFileData: FileData) {
    if (!imageFileData) return;

    if (imageFileData?.doc && imageFileData.docContentType)
      { return `data:${imageFileData.docContentType};base64,${imageFileData.doc}`; }
    return '/assets/images/logos/invertable_logo.png';
  }

  export function getAttachIcon(type: string) {
    switch (type) {
      case (type?.match(/^image/) || {}).input:
        return 'image';
      case 'text/plain':
        return 'subtitles';
      case 'application/pdf':
        return 'picture_as_pdf';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'table_chart';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'description';
      case 'link':
        return 'launch';
      case 'folder':
        return 'folder';
      default:
        return 'insert_drive_file';
    }
  }

  export function toB64String(text: string) {
    return window.btoa(unescape(encodeURIComponent(text)));
  }

  export function fromB64String(b64Text: string) {
    try {
      return decodeURIComponent(escape(window.atob(b64Text)));
    } catch {
      return window.atob(b64Text);
    }
  }

  export function jwtData(jwt: string) {
    if (!jwt) return null;

    const tokenObject = JSON.parse(AppUtils.fromB64String(jwt.split('.')[1]));
    const expDate = new Date('1.1.1970');
    expDate.setSeconds(tokenObject.exp);

    //issued at time
    const iat = new Date('1.1.1970');
    iat.setSeconds(tokenObject.iat);

    return {
      ...tokenObject,
      exp: expDate,
      iat: iat,
    };
  }

  export function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  export function b64toBlob(b64Data, contentType, sliceSize?) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  export function escapeHTML(html) {
    const escape = document.createElement('textarea');
    escape.textContent = html;
    return escape.innerHTML;
  }

  export function unescapeHTML(html) {
    const escape = document.createElement('textarea');
    escape.innerHTML = html;
    return escape.textContent;
  }

  export function escapeURI(uri) {
    return encodeURIComponent(uri);
  }

  export function unescapeURI(uri) {
    return decodeURIComponent(uri);
  }
}
