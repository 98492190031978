/* eslint-disable max-lines-per-function */
import { NotificationZD } from 'app/core/components/notification/notification.model';
import { User } from 'app/shared';
import { BuildInfo } from 'app/shared/build-info/build-info.model';
import { produce } from 'immer';
import {
  CANCEL_PASSWORD_CHANGE,
  TRY_GET_INIT_NOTIFICATION_PAGE,
  TRY_GET_NOTIFICATION_PAGE,
  TRY_TOGGLE_READ_STATUS,
  GET_INIT_NOTIFICATION_PAGE_ERROR,
  GET_INIT_NOTIFICATION_PAGE_SUCCESS,
  SidePanelActions,
  START_PASSWORD_CHANGE,
  SYNC_CURRENT_USER_DATA,
  UPDATE_CURRENT_USER_LANGUAGE_SUCCESS,
  UPDATE_CURRENT_USER_PASSWORD_SUCCESS,
  TOGGLE_READ_STATUS_SUCCESS,
  TOGGLE_READ_STATUS_ERROR,
  UPLOAD_USER_FILE_SUCCESS,
  UPDATE_CURRENT_USER_SUCCESS,
  SYNC_EXTERNAL_CURRENT_USER_UPDATES,
  UPDATE_NOTIFICATION_LIST_SUCCESS,
  TRY_UPDATE_NOTIFICATION_LIST,
  UPDATE_NOTIFICATION_LIST_ERROR,
  TRY_UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_ERROR,
  GET_BUILD_INFO_SUCCESS,
} from './side-panel.actions';

export interface State {
  currentUser: User;
  notificationList: NotificationZD[];
  notificationQueryParams: { page: number; sort: string; size: number };
  userUpdate: User;
  loadingUserUpdate: boolean;
  loadingNotifications: boolean;
  notificationCount: number;
  unreadNotificationCount: number;
  updatingPassword: boolean;
  buildInfo: BuildInfo;
}

const initialState: State = {
  currentUser: null,
  userUpdate: null,
  notificationList: [],
  loadingUserUpdate: false,
  loadingNotifications: false,
  notificationQueryParams: {
    page: 0,
    size: 20,
    sort: 'id,desc',
  },
  notificationCount: 0,
  unreadNotificationCount: 0,
  updatingPassword: false,
  buildInfo: null,
};

export const sidePanelReducer = produce((draft: State, action: SidePanelActions) => {
  switch (action.type) {
    case SYNC_EXTERNAL_CURRENT_USER_UPDATES:
      draft.userUpdate = { ...action.payload };
      draft.currentUser = { ...action.payload };

      return;
    case UPDATE_CURRENT_USER_SUCCESS:
      draft.currentUser = {
        ...draft.currentUser,
        ...action.payload,
        imageFileData: draft.currentUser.imageFileData,
      };
      draft.loadingUserUpdate = false;

      return;
    case UPDATE_CURRENT_USER_ERROR:
      draft.loadingUserUpdate = false;

      return;
    case UPDATE_CURRENT_USER_LANGUAGE_SUCCESS:
      draft.currentUser = {
        ...draft.currentUser,
        langKey: action.payload,
      };

      return;
    case TRY_UPDATE_CURRENT_USER:
      draft.loadingUserUpdate = true;

      return;
    case START_PASSWORD_CHANGE:
      draft.updatingPassword = true;

      return;
    case CANCEL_PASSWORD_CHANGE:
    case UPDATE_CURRENT_USER_PASSWORD_SUCCESS:
      draft.updatingPassword = false;

      return;
    case SYNC_CURRENT_USER_DATA:
      draft.currentUser = { ...action.payload };

      return;
    case TRY_GET_INIT_NOTIFICATION_PAGE:
      draft.notificationQueryParams = initialState.notificationQueryParams;
      draft.loadingNotifications = true;

      return;
    case TRY_GET_NOTIFICATION_PAGE:
      draft.loadingNotifications = true;

      return;
    case GET_INIT_NOTIFICATION_PAGE_SUCCESS:
      draft.notificationList = [
        ...draft.notificationList,
        ...action.payload.notificationList.filter(
          ({ id }) => !draft.notificationList.map(({ id: oid }) => oid).includes(id),
        ),
      ];
      draft.notificationCount = action.payload.notificationCount;
      draft.unreadNotificationCount = action.payload.unreadCount;
      draft.loadingNotifications = false;
      draft.notificationQueryParams =
        action.payload.notificationList.length > 0
          ? {
              ...draft.notificationQueryParams,
              page: draft.notificationQueryParams.page + 1,
            }
          : draft.notificationQueryParams;

      return;
    case GET_INIT_NOTIFICATION_PAGE_ERROR:
      draft.loadingNotifications = false;

      return;
    case TRY_TOGGLE_READ_STATUS:
      draft.loadingNotifications = true;

      return;
    case TOGGLE_READ_STATUS_SUCCESS: {
      draft.loadingNotifications = false;
      const currentNotification = draft.notificationList.find(
        (notification) => notification.id === action.payload.notificationId,
      );
      if (action.payload.notificationStatus !== currentNotification.messageRead) {
        draft.unreadNotificationCount -= action.payload.notificationStatus ? 1 : -1;
      }

      currentNotification.messageRead = action.payload.notificationStatus;

      return;
    }
    case TOGGLE_READ_STATUS_ERROR:
      draft.loadingNotifications = false;

      return;

    case UPLOAD_USER_FILE_SUCCESS:
      draft.currentUser = action.payload;

      return;

    case TRY_UPDATE_NOTIFICATION_LIST:
      draft.loadingNotifications = true;

      return;

    case UPDATE_NOTIFICATION_LIST_SUCCESS:
      draft.notificationList = [
        ...draft.notificationList,
        ...action.payload.notificationList.filter(
          ({ id }) => !draft.notificationList.map(({ id: oid }) => oid).includes(id),
        ),
      ];
      draft.notificationCount = action.payload.notificationCount;
      draft.unreadNotificationCount = action.payload.unreadCount;
      draft.loadingNotifications = false;
      draft.notificationQueryParams =
        action.payload.notificationList.length > 0
          ? {
              ...draft.notificationQueryParams,
              page: draft.notificationQueryParams.page + 1,
            }
          : draft.notificationQueryParams;
      return;

    case UPDATE_NOTIFICATION_LIST_ERROR:
      draft.loadingNotifications = false;

      return;
    case GET_BUILD_INFO_SUCCESS:
      draft.buildInfo = action.payload;
  }
}, initialState);

export const getNotificationQueryParams = (state: State) => state.notificationQueryParams;

export const getNotificationList = (state: State) => state.notificationList;

export const getLoadingNotifications = (state: State) => state.loadingNotifications;

export const getUserUpdateLoading = (state: State) => state.loadingUserUpdate;

export const getNotificationCount = (state: State) => state.notificationCount;

export const getUnreadNotificationCount = (state: State) => state.unreadNotificationCount;

export const getCurrentUser = (state: State) => state.currentUser;

export const getCurrentUserUpdate = (state: State) => state.userUpdate;

export const getIsUpdatingPassword = (state: State) => state.updatingPassword;

export const getApplicationBuildInfo = (state: State) => state.buildInfo;
