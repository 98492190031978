import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { FormControlAttachNativeElementDirective } from '../../../../../../core/directives/form-control-name-native-element/form-control-name-native-element.directive';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  styleUrls: ['./privacy-policy-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    FormsModule,
    ReactiveFormsModule,
    MatCheckbox,
    FormControlAttachNativeElementDirective,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    TranslateModule,
  ],
})
export class PrivacyPolicyDialogComponent {
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PrivacyPolicyDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    {
      this.form = this.fb.group({
        essential: [{ value: true, disabled: true }],
        analytics: [false, []],
      });
    }
  }

  confirm() {
    this.dialogRef.close(this.form.value);
  }
}
