export function findSortProperty(catalogMetadata: any): {
  label: string;
  value: string;
  isTextstore: boolean;
  direction: 'asc' | 'desc';
}[] {
  return catalogMetadata?.propertyPages
    ?.flatMap((page) => page.properties)
    .filter((prop) => prop.sortable)
    .map((prop) => ({
      label: prop.key,
      value: `properties.${prop.key}`,
      direction: prop.sortable,
      isTextstore: prop.controlType === 'translatable',
    }));
}

export function findActiveSortProperty(catalogMetadata: any): {
  label: string;
  value: string;
  isTextstore: boolean;
  direction: 'asc' | 'desc';
} {
  return (
    catalogMetadata?.propertyPages
      ?.flatMap((page) => page.properties)
      .filter((prop) => prop.sortActive)
      .map((prop) => ({
        label: prop.key,
        value: `properties.${prop.key}`,
        direction: prop.sortable,
        isTextstore: prop.controlType === 'translatable',
      }))
      .find(Boolean) || { label: 'id', value: 'id', direction: 'desc' }
  );
}

export function filterToString(
  filter: { value: string; direction: 'asc' | 'desc'; isTextstore: boolean },
  selectedLanguage: string,
): string {
  if (filter == null) return 'name.translations.' + selectedLanguage + '.text';
  if (!filter.isTextstore) return filter.value + ',' + filter.direction;
  return filter.value + '.translations.' + selectedLanguage + '.text,' + filter.direction;
}
