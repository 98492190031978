import { Action } from '@ngrx/store';
import { NotificationZD } from 'app/core/components/notification/notification.model';
import { ActionWithPayload, User } from 'app/shared';
import { BuildInfo } from 'app/shared/build-info/build-info.model';

export const INIT_USER_DATA = '[Side Panel On Init] Read current user from profile store and sync';
export const SYNC_CURRENT_USER_DATA = '[Side Panel Effects] Set current user.';
export const SYNC_EXTERNAL_CURRENT_USER_UPDATES = '[Current User State outside of Side Panel] Sync current user data.';

export const TRY_GET_INIT_NOTIFICATION_PAGE = '[Side Panel initialization] Try get initial notification page.';
export const TRY_GET_NOTIFICATION_PAGE = '[Side Panel Pagination update] Try get initial notification page.';
export const GET_INIT_NOTIFICATION_PAGE_SUCCESS = '[Side Panel Api response] Get initial notification page success.';
export const GET_INIT_NOTIFICATION_PAGE_ERROR = '[Side Panel Api response] Get initial notification page error.';

export const TRY_TOGGLE_READ_STATUS = '[Side Panel list item checkbox] Try toggle list item read status.';
export const TOGGLE_READ_STATUS_SUCCESS = '[Side Panel Api response] Toggle list item read status success.';
export const TOGGLE_READ_STATUS_ERROR = '[Side Panel Api response] Toggle list item read status error.';

export const START_PASSWORD_CHANGE = '[Side Panel change password button] Open change password form';
export const CANCEL_PASSWORD_CHANGE = '[Side Panel password form cancel button] Close change password form';
export const TRY_UPDATE_CURRENT_USER_PASSWORD = '[Side Panel password form] Try update current user password.';
export const UPDATE_CURRENT_USER_PASSWORD_SUCCESS = '[Side Panel Api response] Update current user password success.';
export const UPDATE_CURRENT_USER_PASSWORD_ERROR = '[Side Panel Api response] Update current user password error.';

export const TRY_UPDATE_CURRENT_USER_LANGUAGE = '[Side Panel language dropdown] Try update current user language.';
export const UPDATE_CURRENT_USER_LANGUAGE_SUCCESS = '[Side Panel Api response] Update current user language success.';
export const UPDATE_CURRENT_USER_LANGUAGE_ERROR = '[Side Panel Api response] Update current user language error.';

export const ACTIVATE_NOTIFICATION = '[Side Panel list item] Activate the notification action.';
export const ACTIVATE_NOTIFICATION_MISSING = '[Side Panel effect] Missing notification action type.';
export const ACTIVATE_TASK_NOTFICATION = '[Side Panel effect] Activate task manager notification.';

export const START_FILE_UPLOAD = '[Side Panel user image] Start the file upload process.';
export const START_FILE_UPLOAD_ERROR = '[Side Panel user image] Start the file upload process error.';

export const UPLOAD_USER_FILE = '[Side Panel user image] Upload the user file.';
export const UPLOAD_USER_FILE_SUCCESS = '[Side Panel user image] Upload the user file success.';
export const UPLOAD_USER_FILE_ERROR = '[Side Panel user image] Upload the user file error.';

export const LOG_OUT_CURRENT_USER = '[Side Panel footer] Log out the user.';
export const TRY_UPDATE_CURRENT_USER = '[Side Panel user form] Try update user';
export const UPDATE_CURRENT_USER_SUCCESS = '[Side Panel Api Response] Update user success';
export const UPDATE_CURRENT_USER_ERROR = '[Side Panel Api Response] Update user error';

export const TRY_UPDATE_NOTIFICATION_LIST = '[Side Panel notification list] Try update the notification list.';
export const UPDATE_NOTIFICATION_LIST_SUCCESS = '[Side Panel notification list] Update the notification list success.';
export const UPDATE_NOTIFICATION_LIST_ERROR = '[Side Panel notification list] Update the notification list error.';

export const TRY_GET_BUILD_INFO = '[Side Panel initialization] Try get build info.';
export const GET_BUILD_INFO_SUCCESS = '[Side Panel Api response] Get build info success.';
export const GET_BUILD_INFO_ERROR = '[Side Panel Api response] Get build info error.';

export class InitializeUserData implements Action {
  readonly type = INIT_USER_DATA;
}

export class TryGetBuildInfo implements Action {
  readonly type = TRY_GET_BUILD_INFO;
}

export class GetBuildInfoSuccess implements ActionWithPayload<BuildInfo> {
  readonly type = GET_BUILD_INFO_SUCCESS;
  constructor(public payload: BuildInfo) {}
}

export class GetBuildInfoError implements Action {
  readonly type = GET_BUILD_INFO_ERROR;
}

export class SyncExternalUserUpdates implements ActionWithPayload<User> {
  readonly type = SYNC_EXTERNAL_CURRENT_USER_UPDATES;
  constructor(public payload: User) {}
}

export class TryUpdateCurrentUser implements ActionWithPayload<User> {
  readonly type = TRY_UPDATE_CURRENT_USER;
  constructor(public payload: User) {}
}

export class UpdateCurrentUserSuccess implements ActionWithPayload<User> {
  readonly type = UPDATE_CURRENT_USER_SUCCESS;
  constructor(public payload: User) {}
}

export class UpdateCurrentUserError implements Action {
  readonly type = UPDATE_CURRENT_USER_ERROR;
}

export class TryUpdateCurrentUserLang implements ActionWithPayload<string> {
  readonly type = TRY_UPDATE_CURRENT_USER_LANGUAGE;
  constructor(public payload: string) {}
}

export class UpdateCurrentUserLangSuccess implements ActionWithPayload<string> {
  readonly type = UPDATE_CURRENT_USER_LANGUAGE_SUCCESS;
  constructor(public payload: string) {}
}

export class UpdateCurrentUserLangError implements ActionWithPayload<string> {
  readonly type = UPDATE_CURRENT_USER_LANGUAGE_ERROR;
  constructor(public payload: string) {}
}

export class StartPasswordChange implements Action {
  readonly type = START_PASSWORD_CHANGE;
}

export class CancelPasswordChange implements Action {
  readonly type = CANCEL_PASSWORD_CHANGE;
}

export class TryUpdateCurrentUserPassword implements ActionWithPayload<{ oldPassword: string; newPassword: string }> {
  readonly type = TRY_UPDATE_CURRENT_USER_PASSWORD;
  constructor(public payload: { oldPassword: string; newPassword: string }) {}
}

export class UpdateCurrentUserPasswordSuccess implements Action {
  readonly type = UPDATE_CURRENT_USER_PASSWORD_SUCCESS;
}

export class UpdateCurrentUserPasswordError implements Action {
  readonly type = UPDATE_CURRENT_USER_PASSWORD_ERROR;
}

export class InitSyncCurrentUser implements ActionWithPayload<User> {
  readonly type = SYNC_CURRENT_USER_DATA;
  constructor(public payload: User) {}
}

export class TryGetInitNotificationPage implements Action {
  readonly type = TRY_GET_INIT_NOTIFICATION_PAGE;
}

export class TryGetNotificationPage implements ActionWithPayload<{ page: number; size: number }> {
  readonly type = TRY_GET_NOTIFICATION_PAGE;
  constructor(public payload: { page: number; size: number }) {}
}

export class GetInitNotificationPageSuccess
  implements
    ActionWithPayload<{
      notificationList: NotificationZD[];
      notificationCount: number;
      unreadCount: number;
    }>
{
  readonly type = GET_INIT_NOTIFICATION_PAGE_SUCCESS;
  constructor(
    public payload: {
      notificationList: NotificationZD[];
      notificationCount: number;
      unreadCount: number;
    },
  ) {}
}

export class GetInitNotificationPageError implements Action {
  readonly type = GET_INIT_NOTIFICATION_PAGE_ERROR;
}

export class TryToggleReadStatus implements ActionWithPayload<string> {
  readonly type = TRY_TOGGLE_READ_STATUS;
  constructor(public payload: string) {}
}

export class ToggleReadStatusSuccess
  implements ActionWithPayload<{ notificationId: string; notificationStatus: boolean }>
{
  readonly type = TOGGLE_READ_STATUS_SUCCESS;
  constructor(public payload: { notificationId: string; notificationStatus: boolean }) {}
}

export class ToggleReadStatusError implements Action {
  readonly type = TOGGLE_READ_STATUS_ERROR;
}

export class ActivateNotification implements ActionWithPayload<string> {
  readonly type = ACTIVATE_NOTIFICATION;
  constructor(public payload: string) {}
}

export class ActivateNotificationMissing implements Action {
  readonly type = ACTIVATE_NOTIFICATION_MISSING;
}

export class ActivateTaskNotification implements ActionWithPayload<NotificationZD> {
  readonly type = ACTIVATE_TASK_NOTFICATION;
  constructor(public payload: NotificationZD) {}
}

export class StartFileUpload implements ActionWithPayload<any> {
  readonly type = START_FILE_UPLOAD;
  constructor(public payload: any) {}
}
export class StartFileUploadError implements Action {
  readonly type = START_FILE_UPLOAD_ERROR;
}

export class UploadUserFile implements ActionWithPayload<any> {
  readonly type = UPLOAD_USER_FILE;
  constructor(public payload: any) {}
}
export class UploadUserFileSuccess implements ActionWithPayload<any> {
  readonly type = UPLOAD_USER_FILE_SUCCESS;
  constructor(public payload: any) {}
}
export class UploadUserFileError implements Action {
  readonly type = UPLOAD_USER_FILE_ERROR;
}

export class LogOutCurrentUser implements Action {
  readonly type = LOG_OUT_CURRENT_USER;
}

export class TryUpdateNotificationList implements Action {
  readonly type = TRY_UPDATE_NOTIFICATION_LIST;
}

export class UpdateNotificationListSuccess
  implements
    ActionWithPayload<{
      notificationList: NotificationZD[];
      notificationCount: number;
      unreadCount: number;
    }>
{
  readonly type = UPDATE_NOTIFICATION_LIST_SUCCESS;
  constructor(
    public payload: {
      notificationList: NotificationZD[];
      notificationCount: number;
      unreadCount: number;
    },
  ) {}
}

export class UpdateNotificationListError implements Action {
  readonly type = UPDATE_NOTIFICATION_LIST_ERROR;
}

export type SidePanelActions =
  | InitializeUserData
  | TryGetInitNotificationPage
  | TryGetNotificationPage
  | GetInitNotificationPageSuccess
  | GetInitNotificationPageError
  | TryToggleReadStatus
  | ToggleReadStatusSuccess
  | ToggleReadStatusError
  | TryUpdateCurrentUserLang
  | UpdateCurrentUserLangSuccess
  | UpdateCurrentUserLangError
  | StartPasswordChange
  | CancelPasswordChange
  | TryUpdateCurrentUserPassword
  | UpdateCurrentUserPasswordSuccess
  | UpdateCurrentUserPasswordError
  | InitSyncCurrentUser
  | TryUpdateCurrentUser
  | UpdateCurrentUserSuccess
  | UpdateCurrentUserError
  | ActivateNotification
  | ActivateNotificationMissing
  | ActivateTaskNotification
  | StartFileUpload
  | StartFileUploadError
  | UploadUserFile
  | UploadUserFileSuccess
  | UploadUserFileError
  | LogOutCurrentUser
  | SyncExternalUserUpdates
  | TryUpdateNotificationList
  | UpdateNotificationListSuccess
  | UpdateNotificationListError
  | TryGetBuildInfo
  | GetBuildInfoSuccess
  | GetBuildInfoError;
