/* eslint-disable max-lines-per-function */
import {
  KPIActions,
  ADD_DIAGRAM_SUCCESS,
  ADD_DIAGRAM_ERROR,
  TRY_UPDATE_DIAGRAM,
  UPDATE_DIAGRAM_ERROR,
  REMOVE_DIAGRAM_ERROR,
  UPDATE_DIAGRAM_SUCCESS,
  REMOVE_DIAGRAM_SUCCESS,
  SET_SELECTED_DIAGRAM,
  TRY_FIND_DIAGRAM,
  TRY_GET_DIAGRAM_NEXT_PAGE,
  GET_DIAGRAM_NEXT_PAGE_SUCCESS,
  GET_DIAGRAM_NEXT_PAGE_ERROR,
  TRY_LOAD_FILTERED_DIAGRAM_LIST,
  ROUTE_UPDATE_DIAGRAM_DATA_SUCCESS,
  TRY_ROUTE_UPDATE_DIAGRAM_DATA,
  ROUTE_UPDATE_DIAGRAM_DATA_ERROR,
  LOAD_BREADCRUMB_SUCCESS,
  TRY_LOAD_BREADCRUMB,
  SLICE_BREADCRUMB,
  TRY_FIND_DIAGRAM_FROM_BREADCRUMB,
  CLEAR_DIAGRAM_SELECTION,
  RESET_STATE,
  TRY_LOAD_SORTED_DIAGRAM_LIST,
  TRY_LOAD_INITIAL_DIAGRAM_LIST,
  TRY_OPEN_PROCESS_DESIGNER,
  OPEN_PROCESS_DESIGNER_SUCCESS,
  OPEN_PROCESS_DESIGNER_ERROR,
  TRY_LOAD_CHILDREN_LIST,
  TRY_LOAD_SELECTED_DIAGRAM_LIST,
  LOAD_VIEW_ONLY_MODE,
  DESELECT_DIAGRAM,
  TRY_DRAG_DIAGRAM,
  DRAG_DIAGRAM_SUCCESS,
  DRAG_DIAGRAM_ERROR,
  TRY_CREATE_NEW_PROCESS_INSTANCE,
  CREATE_NEW_PROCESS_INSTANCE_SUCCESS,
  CREATE_NEW_PROCESS_INSTANCE_ERROR,
  OPEN_PROCESS_INSTANCE_ERROR,
  RESUME_PROCESS_INSTANCE_SUCCESS,
  RESUME_PROCESS_INSTANCE_ERROR,
} from './kpi-diagram.actions';

import { KPIProcessDiagram } from '../../../process/model/process-diagram.model';
import { ITEMS_PER_PAGE } from '../../../../../../../shared';
import { produce } from 'immer';
import { Textstore } from 'app/shared/lang-dialog/textstore.model';

export interface State {
  kpiDiagramList: KPIProcessDiagram[];
  selectedKPIDiagram: KPIProcessDiagram;
  rootKPIDiagramId: string;
  loading: boolean;
  sortOrder: 'asc' | 'desc';
  queryParams: { page: number; size: number; sort: string[] };
  breadcrumb: { id: string; parentId: string; name: Textstore }[];
  viewOnly: boolean;
}

const initialState: State = {
  kpiDiagramList: [],
  selectedKPIDiagram: null,
  rootKPIDiagramId: undefined,
  loading: false,
  sortOrder: 'asc',
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: ['is_parent,desc', 'id,desc'],
  },
  breadcrumb: [],
  viewOnly: true,
};

export const kpiDiagramReducer = produce((draft: State, action: KPIActions): void => {
  switch (action.type) {
    case RESET_STATE:
      Object.assign(draft, initialState);

      return;
    case DRAG_DIAGRAM_SUCCESS:
      draft.kpiDiagramList = draft.kpiDiagramList.filter((diagram) => diagram.id !== action.payload.diagramId);
      draft.loading = false;

      return;
    case LOAD_VIEW_ONLY_MODE:
      draft.viewOnly = action.payload;

      return;
    case ROUTE_UPDATE_DIAGRAM_DATA_SUCCESS:
      draft.loading = false;

      draft.selectedKPIDiagram = action.payload.diagram;
      const lastIndex = Math.max(draft.breadcrumb.length - 1, 0);
      const newBcItem = {
        id: action.payload?.diagram?.id,
        parentId: action.payload?.diagram?.parentId,
        name: action.payload?.diagram?.name,
      };
      if (action.payload.diagram == null) return;
      if (action.payload.diagram.parentId !== draft.breadcrumb[lastIndex]?.id) draft.breadcrumb[lastIndex] = newBcItem;
      else draft.breadcrumb.push(newBcItem);

      return;
    case SLICE_BREADCRUMB:
      const lastDiagramId = draft.selectedKPIDiagram?.id || draft.rootKPIDiagramId;
      const diagramBCIndex = draft.breadcrumb.findIndex((bcd) => bcd.id === lastDiagramId);

      draft.breadcrumb = draft.breadcrumb.slice(0, diagramBCIndex + 1);

      return;

    case TRY_LOAD_CHILDREN_LIST:
      draft.rootKPIDiagramId = action.payload;
      draft.loading = true;
      draft.kpiDiagramList = [];
      draft.queryParams.page = 0;
      draft.selectedKPIDiagram = null;

      const bcIndex = draft.breadcrumb.findIndex((bc) => bc.id === action.payload);
      draft.breadcrumb = draft.breadcrumb.slice(0, bcIndex != null ? bcIndex + 1 : 0);

      return;
    case DESELECT_DIAGRAM:
      draft.selectedKPIDiagram = null;
      draft.breadcrumb.pop();

      return;
    case TRY_LOAD_SELECTED_DIAGRAM_LIST:
      draft.rootKPIDiagramId = action.payload;
      draft.loading = true;
      draft.kpiDiagramList = [];
      draft.queryParams.page = 0;

      return;
    case TRY_LOAD_BREADCRUMB:
      draft.breadcrumb = [];

      return;
    case LOAD_BREADCRUMB_SUCCESS:
      // Without Required wrapper ts will complain because id is optional in process diagram
      draft.breadcrumb = action.payload as Required<KPIProcessDiagram>[];

      return;

    case TRY_LOAD_INITIAL_DIAGRAM_LIST:
      draft.loading = true;
      draft.kpiDiagramList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = initialState.sortOrder;

      return;
    case TRY_LOAD_SORTED_DIAGRAM_LIST:
      draft.loading = true;
      draft.kpiDiagramList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = draft.sortOrder === 'asc' ? 'desc' : 'asc';

      return;
    case TRY_LOAD_FILTERED_DIAGRAM_LIST:
      draft.selectedKPIDiagram = null;
      draft.kpiDiagramList = [];

      return;
    case CLEAR_DIAGRAM_SELECTION:
      draft.selectedKPIDiagram = null;

      return;
    case TRY_FIND_DIAGRAM:
      draft.selectedKPIDiagram = null;

      return;
    case TRY_FIND_DIAGRAM_FROM_BREADCRUMB:
      draft.selectedKPIDiagram = null;
      draft.breadcrumb = draft.breadcrumb.slice(0, draft.breadcrumb.map((bc) => bc.id).indexOf(action.payload) + 1);

      return;
    case TRY_GET_DIAGRAM_NEXT_PAGE:
    case TRY_UPDATE_DIAGRAM:
    case TRY_DRAG_DIAGRAM:
    case TRY_OPEN_PROCESS_DESIGNER:
    case TRY_CREATE_NEW_PROCESS_INSTANCE:
      draft.loading = true;

      return;
    case TRY_ROUTE_UPDATE_DIAGRAM_DATA:
      draft.loading = true;
      if (draft.kpiDiagramList.every((diagram) => diagram.id !== action.payload)) {
        draft.queryParams.page = 0;
      }

      return;
    case ROUTE_UPDATE_DIAGRAM_DATA_ERROR:
    case GET_DIAGRAM_NEXT_PAGE_ERROR:
    case ADD_DIAGRAM_ERROR:
    case UPDATE_DIAGRAM_ERROR:
    case REMOVE_DIAGRAM_ERROR:
    case DRAG_DIAGRAM_ERROR:
    case OPEN_PROCESS_DESIGNER_SUCCESS:
    case OPEN_PROCESS_DESIGNER_ERROR:
    case CREATE_NEW_PROCESS_INSTANCE_SUCCESS:
    case CREATE_NEW_PROCESS_INSTANCE_ERROR:
    case OPEN_PROCESS_INSTANCE_ERROR:
    case RESUME_PROCESS_INSTANCE_SUCCESS:
    case RESUME_PROCESS_INSTANCE_ERROR:
      draft.loading = false;

      return;
    case GET_DIAGRAM_NEXT_PAGE_SUCCESS:
      draft.loading = false;
      draft.kpiDiagramList.push(
        ...action.payload.data.filter((diagram) => !draft.kpiDiagramList.map((d) => d.id).includes(diagram.id)),
      );
      if (action.payload.data.length > 0) draft.queryParams.page += 1;

      return;
    case SET_SELECTED_DIAGRAM:
      draft.selectedKPIDiagram = action.payload;

      return;
    case ADD_DIAGRAM_SUCCESS:
      draft.kpiDiagramList.unshift(action.payload);
      draft.selectedKPIDiagram = action.payload;

      return;
    case UPDATE_DIAGRAM_SUCCESS:
      draft.loading = false;
      draft.kpiDiagramList =
        draft.selectedKPIDiagram?.deleted === action.payload?.deleted
          ? draft.kpiDiagramList.map((item) => (item.id === action.payload.id ? action.payload : item))
          : draft.kpiDiagramList.filter((item) => item.id !== action.payload.id);

      draft.selectedKPIDiagram =
        draft.selectedKPIDiagram?.id !== action.payload.id
          ? draft.selectedKPIDiagram
          : draft.selectedKPIDiagram?.deleted === action.payload?.deleted
            ? action.payload
            : null;

      // if edited diagram is in breadcrumb it will always be last element
      if (action.payload.id === draft.breadcrumb[draft.breadcrumb.length - 1]?.id) {
        draft.breadcrumb[draft.breadcrumb.length - 1] = {
          id: action.payload.id,
          parentId: action.payload.parentId,
          name: action.payload.name,
        };
      }

      return;
    case REMOVE_DIAGRAM_SUCCESS:
      draft.kpiDiagramList = draft.kpiDiagramList.filter((item) => draft.selectedKPIDiagram.id !== item.id);
      draft.selectedKPIDiagram = null;

      
  }
}, initialState);

export const getKPIDiagramList = (state: State) => state.kpiDiagramList;

export const getQueryParams = (state: State) => state.queryParams;

export const getSelectedKPIDiagram = (state: State) => state.selectedKPIDiagram;

export const getKPIDiagramLoading = (state: State) => state.loading;

export const getRootKPIDiagramId = (state: State) => state.rootKPIDiagramId;

export const getKPIDiagramCurrentSortOrder = (state: State) => state.sortOrder;

export const getKPIDiagramBreadcrumb = (state: State) => state.breadcrumb;

export const getViewMode = (state: State) => state.viewOnly;
