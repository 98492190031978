import { Textstore } from '../../../../../shared/lang-dialog/textstore.model';
import { BaseModel } from 'app/core/base.model';
export class Catalog extends BaseModel {
  constructor(
    public id?: string,
    public name?: Textstore,
    public textName?: string,
    public type?: string,
    public description?: Textstore,
    public children: Catalog[] = [],
    public parent?: Catalog,
    public hasChildren = false,
    public properties: { [key: string]: any } = {},
  ) {
    super();
  }
}
