import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';

import { User } from '..';
import { IRestResponse, RestResponse, IRestError } from 'app/core/models/rest.model';
import { RestUtils } from 'app/core/utils/rest.utils';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient) {}

  save(user: User): Observable<IRestResponse<User>> {
    return this.http.post(SERVER_API_URL + 'api/account', user).pipe(
      map((res) => {
        const result: any = res;
        const response: IRestResponse = new RestResponse();
        response.data = Object.assign(new User(), result);
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }
}
