import { User } from '../user/user.model';

export interface LicenseDetails {
   hasLicense?: boolean;
   hasViewOnlyLicense?: boolean;
   licenseState?: LicenseState;
   license?: License;
}

export interface License {
   id?: string;
   plantId?: any;
   tenantId?: string;
   tenantName?: string;
   features?: LicenseFeatures;
   digest?: string;
   createdBy?: string;
   lastModifiedBy?: string;
   productLine?: ProductLine;
   featurePackage?: string;
   licenseType?: LicenseType;
   licenseStrategy?: LicenseStrategy;
}

export enum ProductLine {
   CAQ360 = 'CAQ360',
   SQM360 = 'SQM360',
   MOBILE_INSPECTION = 'MOBILE_INSPECTION'
}

export enum LicenseState {
   VALID = 'VALID',
   INVALID = 'INVALID',
   EXPIRED = 'EXPIRED'
}

export interface LicenseFeatures {
   dashboard?: DashboardFeatures;
   tasks?: TasksFeatures;
   documents?: DocumentsFeatures;
   inspection?: InspectionFeatures;
   inspectionPlan?: InspectionPlanFeatures;
   masterData?: MasterDataFeatures;
   administration?: AdministrationFeatures;
   multilanguage?: MultilanguageFeatures;
   api: APIFeatures;
   prototype?: PrototypeFeatures;
   bridge?: Bridge;
   inspectionEquipment?: InspectionEquipmentFeatures;
}

export type LicenseStrategy = NamedLicenseStrategy | NamedViewOnlyLicenseStrategy | TrialLicenseStrategy;

export enum LicenseType {
   TRIAL = 'TRIAL',
   NAMED = 'NAMED',
   FLOATING = 'FLOATING',
   NAMED_VIEW_ONLY = 'NAMED_VIEW_ONLY'
}

export class NamedViewOnlyLicenseStrategy {
   _class?: string;
   numberOfStandardUsers?: number;
   numberOfViewOnlyUsers?: number;
   standardUserEmails?: string[];
   viewOnlyUserEmails?: string[];
}

export class NamedUser {
   id?: string;
   email?: string;
}

export class NamedLicenseStrategy {
   _class?: string;
   numberOfNamedUsers?: number;
   namedUsers?: User[];
}

export class TrialLicenseStrategy {
   _class?: string;
   endDate?: Date;
}

export interface DashboardFeatures {
   enabled: boolean;
}

export interface TasksFeatures {
   enabled: boolean;
}

export interface DocumentsFeatures {
   enabled: boolean;
}

export interface InspectionFeatures {
   enabled: boolean;
   inspectionType: InspectionType;
}

export interface InspectionType {
   incoming: boolean;
   outgoing: boolean;
   spc: boolean;
   production: boolean;
}

export interface InspectionPlanFeatures {
   enabled: boolean;
}

export interface InspectionEquipmentFeatures {
   enabled: boolean;
}

export interface APIFeatures {
   enabled: boolean;
}

export interface PrototypeFeatures {
   enabled: boolean;
}

export interface Bridge {
   enabled: boolean;
   boxType: BoxType;
}

export interface BoxType {
   steinwald: boolean;
   bobe: boolean;
   brecht: boolean;
   chatillon_distance: boolean;
   chatillon_load: boolean;
   com: boolean;
   elias: boolean;
   hahn: boolean;
   ibr: boolean;
   index_instuments: boolean;
   keyence: boolean;
   krahl: boolean;
   mettler_mtsics: boolean;
   mettler_refractometer: boolean;
   mettler_scale: boolean;
   mettler_titrator: boolean;
   mitutoyo_dmx1: boolean;
   mitutoyo_dmx8: boolean;
   mitutoyo_dmx16: boolean;
   mitutoyo_dmx50: boolean;
   nexygen: boolean;
   ogp: boolean;
   pharmatron: boolean;
   radwag: boolean;
   sartorius_offline: boolean;
   sartorius_online: boolean;
   sylvac_sc299: boolean;
   sylvac_vmux6: boolean;
   sylvac_vmux11: boolean;
   sylvac: boolean;
   trimos: boolean;
}

export interface MasterDataFeatures {
   enabled: boolean;
}

export interface AdministrationFeatures {
   enabled: boolean;
}

export interface MultilanguageFeatures {
   enabled: boolean;
}
