import { JmsWebSocketConnectionVendor } from 'app/core/services/socket/jms-socket-connection-vendor.service';
import { Catalog } from './catalog/catalog.model';
import { Injectable } from '@angular/core';
import { JmsSubscription } from 'app/core/services/socket/jms-socket-subscription';
import { BridgeMessage as BridgeMessage } from '../dashboard/board/models/message.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, finalize, first, Observable, tap, throwError, timeout } from 'rxjs';
import { BridgeMessageResult } from './bridge-result-message';
@Injectable({
  providedIn: 'root',
})
export class BridgeBoxConfigurationService {
  private socket: JmsSubscription;
  constructor(
    private connectionVendor: JmsWebSocketConnectionVendor,
    private matSnackBar: MatSnackBar,
  ) {}

  public sendBoxConfiguration(dataSourceCatalog: Catalog): Observable<BridgeMessage> {
    const dsCat = dataSourceCatalog;
    this.socket = new JmsSubscription(this.connectionVendor.getConnection(), '/topic/' + dsCat.properties['inputUrl']);

    this.connectionVendor.send('/topic/' + dsCat.properties['outputUrl'], {
      action: 'UPDATE_CONFIG',
      deviceId: dsCat.id,
      boxConfigurations: (dsCat.properties.boxConfigurations ?? []).map(({ name, desc, ...boxConfig }) => boxConfig),
    });

    return this.socket.startSub<BridgeMessage>().pipe(
      timeout(10000),
      first(),
      tap((message) => {
        if (message.messageType != BridgeMessageResult.UPDATE_SUCESS) return;

        if (message.status == BridgeMessageResult.SUCCESS) {
          this.matSnackBar.open('Success', 'OK', { duration: 3000 });
        } else if (message.status == BridgeMessageResult.BUSY) {
          this.matSnackBar.open('BRIDGE is busy', 'OK', { duration: 3000 });
        } else {
          this.matSnackBar.open('Box configuration update failed', 'OK', {
            duration: 3000,
          });
        }
      }),
      catchError((error) => {
        this.matSnackBar.open('Box configuration update failed', 'OK', {
          duration: 3000,
        });
        return throwError(() => error);
      }),
      finalize(() => {
        this.socket?.unsubscribe();
      }),
    );
  }
}
