export interface IRestResponse<T = any> {
  totalItems?: number;
  data?: T;
}

export interface IRestError {
  status?: number;
  statusText?: string;
  message?: string;
  translationPath?: string;
  url?: string;
}

export class RestResponse<T = any> implements IRestResponse {
  constructor(
    public data?: T,
    public totalItems?: number,
  ) {}
}

export class RestError implements IRestError {
  constructor(
    public status?: number,
    public statusText?: string,
    public message?: string,
    public translationPath?: string,
    public url?: string,
  ) {
    this.status = status ? status : null;
    this.statusText = statusText ? statusText : null;
    this.message = message ? message : null;
    this.translationPath = translationPath ? translationPath : null;
    this.url = url ? url : null;
  }
}
