import { ValidationErrors, AbstractControl } from '@angular/forms';
import { ValidatorNames } from './valdator-names.constant';
import { Observable, timer } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AdministrationUserManagerService } from 'app/main/content/apps/administration/user-manager';

@Injectable({
  providedIn: 'root',
})
export class UniqueUserEmailValidator {
  readonly time = 500;
  readonly errorObj = { [ValidatorNames.USER_EMAIL_NOT_UNIQUE]: true };

  constructor(private userService: AdministrationUserManagerService) {}

  validate(userId: string): (control: AbstractControl) => Observable<ValidationErrors | null> {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      timer(this.time).pipe(
        switchMap(() =>
          this.userService
            .checkIfEmailExists(userId, control.value)
            .pipe(map((res: any) => (res ? this.errorObj : null))),
        ),
      );
  }
}
