import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { AppComponent } from './app.component';
import { TranslationCacheLoader } from './core/services/translation-cache.loader';
import { GlobalOptionsService } from './main/content/apps/administration/global-options/global-options.service';
import { Observable } from 'rxjs';
import { AppDataService } from './main/content/apps/profile/app-data.service';
import { HttpClient } from '@angular/common/http';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app.config';

function appLoadFactory(config: AppDataService): () => Observable<unknown> {
  return () => config.getApplicationData();
}

// For unsafe websites disable media device info
// app won't load otherwise
if (location.protocol !== 'https:' && !window.origin.includes('localhost')) {
  console.error('Unsafe location MediaDeviceInfo is not available');
  window.MediaDeviceInfo = null;
}

function HttpLoaderFactory(http: HttpClient, globalOptionsService: GlobalOptionsService): TranslationCacheLoader {
  return new TranslationCacheLoader(http, globalOptionsService);
}

// disable debug data on prod profile to improve performance
if (environment.production) enableProdMode();

if (module['hot']) module['hot'].accept();

bootstrapApplication(AppComponent, appConfig)
  // eslint-disable-next-line no-console
  .then((success) => console.log('Application started'))
  .catch((err) => console.error(err));
