import { produce } from 'immer';
import { DocumentStore } from '../../document.model';
import { FMSDActions } from './file-manager-selection-dialog.actions';
import * as FSMD from './file-manager-selection-dialog.actions';

export interface State {
  fileList: DocumentStore[];
  loading: boolean;
}

const initialState: State = {
  fileList: [],
  loading: false,
};

export const fmsdReducer = produce((draft: State, action: FMSDActions) => {
  switch (action.type) {
    case FSMD.RESET_DIALOG_STATE:
      Object.assign(draft, initialState);

      return;
    case FSMD.SET_INITIAL_DOCUMENT_LIST_SUCCESS:
      draft.fileList = [...action.payload] || [];

      return;
    case FSMD.TRY_SET_INITIAL_DOCUMENT_LIST:
      draft.loading = true;

      return;
    case FSMD.SET_INITIAL_DOCUMENT_LIST_ERROR:
      draft.loading = false;

      return;
    case FSMD.ADD_DOCUMENTS:
      draft.loading = false;
      draft.fileList.push(...action.payload);

      return;
    case FSMD.REMOVE_FILE_FROM_LIST:
      draft.fileList = draft.fileList.filter((doc) => !(action.payload ?? []).includes(doc.id));
  }
}, initialState);

export const getFileList = (state: State) => state.fileList;

export const getLoading = (state: State) => state.loading;
