import { Component } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'accessdenied',
  templateUrl: './error-403.component.html',
  styleUrls: ['./error-403.component.scss'],
  standalone: true,
  imports: [RouterLink, TranslateModule],
})
export class Error403Component {
  constructor(private translate: TranslateService) {
    this.translate.get('error.http.403').subscribe(console.warn);
  }
}
