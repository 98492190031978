import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';
import { of as observableOf, of, pipe } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, concatMap, tap, takeUntil, debounce, debounceTime } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as FILE from './file-manager.actions';
import * as fromRoot from '../../../../../app.reducer';
import { FileManagerService } from '../file-manager.service';
import { ActionWithPayload } from '../../../../../shared';
import { DocumentStore, FileData } from '../document.model';
import { IRestResponse, IRestError } from 'app/core/models/rest.model';
import { FilterModuleName } from '../../filter/filter-modules-list';
import { FileHandlerService } from '../file-download.service';
import { FilePreviewService } from 'app/core/components/file-preview/file-preview-wrapper/file-preview-wrapper.service';
import { TranslateService } from '@ngx-translate/core';
import { AppUtils } from 'app/core/utils/app.utils';

@Injectable()
export class DocumentEffects {
   // Try get document list
   loadFiles$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_GET_FILE_LIST, FILE.TRY_LOAD_SORTED_FILE_LIST, FILE.NAVIGATE_TO_PATH),
         withLatestFrom(
            this.store$.select(fromRoot.getActiveFilter(FilterModuleName.FILE)),
            this.store$.select(fromRoot.getSearchText(FilterModuleName.FILE)),
            this.store$.select(fromRoot.getFileQueryParams),
            this.store$.select(fromRoot.getParentDocument),
            this.store$.select(fromRoot.getSelectedLanguage),
            this.store$.select(fromRoot.getFileListSortOrder),
         ),
         switchMap(([action, activeFilter, searchText, queryParams, parentDocument, selectedLanguage, sortOrder]) => {
            return this.documentService
               .query(
                  { ...queryParams, sort: ['is_folder,desc', 'custom_name.translations.' + selectedLanguage + '.text,' + sortOrder, 'id,desc'] },
                  activeFilter,
                  searchText,
                  parentDocument,
               )
               .pipe(
                  map((res: IRestResponse) => ({
                     type: FILE.GET_FILE_LIST_SUCCESS,
                     payload: res,
                  })),
                  catchError((error: IRestError) => observableOf({ type: FILE.GET_FILE_LIST_ERROR, payload: error })),
               );
         }),
      );
   });

   // Try get document next page
   loadFilesNextPage$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_GET_FILE_NEXT_PAGE),
         withLatestFrom(
            this.store$.select(pipe(fromRoot.getActiveFilter(FilterModuleName.FILE))),
            this.store$.select(pipe(fromRoot.getSearchText(FilterModuleName.FILE))),
            this.store$.select(fromRoot.getFileQueryParams),
            this.store$.select(fromRoot.getParentDocument),
            this.store$.select(fromRoot.getSelectedLanguage),
            this.store$.select(fromRoot.getFileListSortOrder),
         ),
         switchMap(([action, activeFilter, searchText, queryParams, parentDocument, selectedLanguage, sortOrder]) => {
            return this.documentService
               .query(
                  { ...queryParams, sort: ['is_folder,desc', 'custom_name.translations.' + selectedLanguage + '.text,' + sortOrder, 'id,desc'] },
                  activeFilter,
                  searchText,
                  parentDocument,
               )
               .pipe(
                  map((res: IRestResponse) => ({
                     type: FILE.GET_FILE_NEXT_PAGE_SUCCESS,
                     payload: res,
                  })),
                  catchError((error: IRestError) =>
                     observableOf({
                        type: FILE.GET_FILE_NEXT_PAGE_ERROR,
                        payload: error,
                     }),
                  ),
               );
         }),
      );
   });

   // Try update file
   updateFile$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_UPDATE_FILE),
         map((action: ActionWithPayload<DocumentStore>) => action.payload),
         switchMap(payload =>
            this.documentService.update(payload).pipe(
               map((res: IRestResponse) => ({
                  type: FILE.UPDATE_FILE_SUCCESS,
                  payload: res,
               })),
               catchError((error: IRestError) => observableOf({ type: FILE.UPDATE_FILE_ERROR, payload: error })),
            ),
         ),
      );
   });

   updateTextContentFile$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_UPDATE_TEXT_CONTENT_FILE),
         concatLatestFrom(() => this.store$.select(fromRoot.getSelectedFile)),
         map(([action, selectedDocument]: [ActionWithPayload<any>, DocumentStore]) => ({
            ...selectedDocument,
            customName: action.payload.customName,
            currentVersion: {
               ...selectedDocument.currentVersion,
               fileData: {
                  ...selectedDocument.currentVersion.fileData,
                  doc: AppUtils.toB64String(action.payload.content),
               },
            },
         })),
         switchMap(docStore =>
            this.documentService.update(docStore).pipe(
               map(res => ({
                  type: FILE.UPDATE_TEXT_CONTENT_FILE_SUCCESS,
                  payload: res.data,
               })),
               catchError(() => observableOf({ type: FILE.UPDATE_TEXT_CONTENT_FILE_ERROR })),
            ),
         ),
      );
   });

   // Try save document
   addFile$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_ADD_FILE),
         map((action: ActionWithPayload<DocumentStore[]>) => action.payload),
         concatMap(payload =>
            this.documentService.create(payload).pipe(
               map((res: IRestResponse) => ({
                  type: FILE.ADD_FILE_SUCCESS,
                  payload: res,
               })),
               catchError((error: IRestError) => observableOf({ type: FILE.ADD_FILE_ERROR, payload: error })),
            ),
         ),
      );
   });

   // Try delete document
   removeFile$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_REMOVE_FILE),
         map((action: ActionWithPayload<string>) => action.payload),
         switchMap(fileId =>
            this.documentService.delete(fileId).pipe(
               map(() => ({ type: FILE.REMOVE_FILE_SUCCESS })),
               catchError((error: IRestError) => observableOf({ type: FILE.REMOVE_FILE_ERROR, payload: error })),
            ),
         ),
      );
   });

   // Try find document preview
   previewFile$ = createEffect(
      () => {
         return this.actions$.pipe(
            ofType(FILE.PREVIEW_FILE),
            map((action: ActionWithPayload<{ id: string; type: string }>) => action.payload),
            tap(({ id, type }) => this.filePreviewService.previewFile(id, type)),
         );
      },
      { dispatch: false },
   );

   // Try find document
   findFile$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_FIND_FILE),
         map((action: ActionWithPayload<string>) => action.payload),
         switchMap(documentId =>
            this.documentService.find(documentId).pipe(
               map((res: IRestResponse) => ({
                  type: FILE.FIND_FILE_SUCCESS,
                  payload: res,
               })),
               catchError((error: IRestError) => observableOf({ type: FILE.FIND_FILE_ERROR, payload: error })),
            ),
         ),
      );
   });

   // Try to move a document to another folder
   tryDragDocument$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_DRAG_DOCUMENT),
         map(
            (
               action: ActionWithPayload<{
                  targetFolderId: string;
                  documentId: string;
               }>,
            ) => action.payload,
         ),
         switchMap(dragData =>
            this.documentService.changeFolder(dragData).pipe(
               map(() => ({ type: FILE.DRAG_DOCUMENT_SUCCESS, payload: dragData })),
               catchError(() => of({ type: FILE.DRAG_DOCUMENT_ERROR })),
            ),
         ),
      );
   });

   // Try download document
   downloadFile$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_DOWNLOAD_FILE),
         map((action: ActionWithPayload<string>) => action.payload),
         switchMap(documentId =>
            this.documentService.find(documentId).pipe(
               map((res: IRestResponse) => ({
                  type: FILE.DOWNLOAD_FILE_SUCCESS,
                  payload: res.data,
               })),
               catchError(() => observableOf({ type: FILE.DOWNLOAD_FILE_ERROR })),
            ),
         ),
      );
   });

   startFileEdit$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_START_EDIT_TEXT_FILE),
         concatLatestFrom(() => this.store$.select(fromRoot.getSelectedFile)),
         switchMap(([action, selectedFile]) =>
            this.documentService.find(selectedFile.id).pipe(
               map((res: IRestResponse) => ({
                  type: FILE.START_EDIT_TEXT_FILE_SUCCESS,
                  payload: res.data,
               })),
               catchError(() => observableOf({ type: FILE.START_EDIT_TEXT_FILE_ERROR })),
            ),
         ),
      );
   });

   trackLangChanges$ = createEffect(
      () => {
         return this.actions$.pipe(
            ofType(FILE.START_TRACKING_LANG_CHANGES),
            tap(() =>
               this.translate.onLangChange
                  .pipe(takeUntil(this.actions$.pipe(ofType(FILE.STOP_TRACKING_LANG_CHANGES))))
                  .subscribe(() => this.store$.dispatch(new FILE.TryGetDocumentList())),
            ),
         );
      },
      { dispatch: false },
   );

   downloadFileSuccess$ = createEffect(
      () => {
         return this.actions$.pipe(
            ofType(FILE.DOWNLOAD_FILE_SUCCESS),
            map((action: ActionWithPayload<DocumentStore>) => action.payload),
            tap(file => this.fileDownloadService.download(file)),
         );
      },
      { dispatch: false },
   );

   followLink$ = createEffect(
      () => {
         return this.actions$.pipe(
            ofType(FILE.FOLLOW_LINK),
            map((action: ActionWithPayload<DocumentStore>) => action.payload),
            tap(file => this.documentService.openLink(file)),
         );
      },
      { dispatch: false },
   );

   findDocumentHistory$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_FIND_DOCUMENT_HISTORY),
         map((action: ActionWithPayload<string>) => action.payload),
         concatLatestFrom(() => this.store$.select(fromRoot.getDocumentHistoryQueryParams)),
         switchMap(([documentId, queryParams]: [string, any]) =>
            this.documentService.findDocumentHistory(queryParams, documentId).pipe(
               map((res: IRestResponse) => ({
                  type: FILE.FIND_DOCUMENT_HISTORY_SUCCESS,
                  payload: res,
               })),
               catchError((error: IRestError) =>
                  observableOf({
                     type: FILE.FIND_DOCUMENT_HISTORY_ERROR,
                     payload: error,
                  }),
               ),
            ),
         ),
      );
   });

   undoDocumentChange$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_UNDO_DOCUMENT_CHANGE),
         map((action: ActionWithPayload<string>) => action.payload),
         switchMap(documentHistoryId =>
            this.documentService.undo(documentHistoryId).pipe(
               map((res: IRestResponse) => ({
                  type: FILE.UNDO_DOCUMENT_CHANGE_SUCCESS,
                  payload: res,
               })),
               catchError((error: IRestError) =>
                  observableOf({
                     type: FILE.UNDO_DOCUMENT_CHANGE_ERROR,
                     payload: error,
                  }),
               ),
            ),
         ),
      );
   });

   addNewDocumentVersion$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILE.TRY_ADD_NEW_DOCUMENT_VERSION),
         map((action: ActionWithPayload<FileData>) => action.payload),
         concatLatestFrom(() => this.store$.select(fromRoot.getSelectedFile)),
         switchMap(([fileData, document]) =>
            this.documentService.addNewDocumentVersion(document.id, fileData).pipe(
               map((res: IRestResponse) => ({
                  type: FILE.ADD_NEW_DOCUMENT_VERSION_SUCCESS,
                  payload: res,
               })),
               catchError((error: IRestError) =>
                  observableOf({
                     type: FILE.ADD_NEW_DOCUMENT_VERSION_ERROR,
                     payload: error,
                  }),
               ),
            ),
         ),
      );
   });

   constructor(
      private actions$: Actions,
      private documentService: FileManagerService,
      private fileDownloadService: FileHandlerService,
      private filePreviewService: FilePreviewService,
      private translate: TranslateService,
      private store$: Store,
   ) {}
}
