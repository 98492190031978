export class TenantRegistration {
  constructor(
    public id?: string,
    public name?: string,
    public tenantId?: string,
    public registrationDate?: Date,
    public expiryDate?: Date,
    public idpConfig?: any,
    public onPermise = false,
  ) {}
}
