import { Action } from '@ngrx/store';
import { ActionWithPayload, User } from 'app/shared';
import { LicenseDetails } from 'app/shared/license/licensedetails.model';
import { GlobalOptions } from '../../administration/global-options/global-options.model';
import { UserOptions } from '../../profile/user-options.model';
import { FileData } from '../../file-manager/document.model';

export const TRY_LOGIN_USER = '[Login page form] Try login user';
export const LOGIN_USER_SUCCESS = '[Login Api Response] Login user success';
export const LOGIN_USER_ERROR = '[Login Api Response] Login user error';

export const TRY_SEND_RESET_PASSWORD_EMAIL = '[Submit email page] Try send reset password email';
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = '[Submit email page Api response] Send reset password email success';
export const SEND_RESET_PASSWORD_EMAIL_ERROR = '[Submit email page Api response] Send reset password email error';

export const TRY_SET_FIRST_PASSWORD = '[Set initial password page] Try set initial password';
export const TRY_RESET_PASSWORD = '[Forgot Password reset button] Try reset password';
export const RESET_PASSWORD_SUCCESS = '[Reset Password Api response] Reset password success';
export const RESET_PASSWORD_ERROR = '[Reset Password Api response] Reset password error';

export const TRY_ACTIVATE_USER = '[Activate User page] Try activate user';
export const ACTIVATE_USER_SUCCESS = '[Activate User Api response] Activate user success';
export const ACTIVATE_USER_ERROR = '[Activate User Api response] Activate user error';

export const TRY_LOAD_TENANT_LOGO = '[Page Init] Try load logo if not loaded';
export const TENANT_LOGO_IS_ALREADY_LOADED = '[Authentication Effects] Logo is already loaded';
export const LOAD_TENANT_LOGO_SUCCESS = '[API Response] Load logo if not loaded success';
export const LOAD_TENANT_LOGO_ERROR = '[API Response] Load logo if not loaded error';

export class TryLoadTenantLogo implements Action {
  readonly type = TRY_LOAD_TENANT_LOGO;
}

export class TenantLogoIsAlreadyLoaded implements Action {
  readonly type = TENANT_LOGO_IS_ALREADY_LOADED;
}

export class LoadTenantLogoSuccess implements ActionWithPayload<FileData> {
  readonly type = LOAD_TENANT_LOGO_SUCCESS;
  constructor(public payload: FileData) {}
}

export class LoadTenantLogoError implements Action {
  readonly type = LOAD_TENANT_LOGO_ERROR;
}

export class TrySetFirstPassword implements ActionWithPayload<{ key: string; newPassword: string }> {
  readonly type = TRY_SET_FIRST_PASSWORD;
  constructor(public payload: { key: string; newPassword: string }) {}
}
export class TryResetPassword implements ActionWithPayload<{ key: string; newPassword: string }> {
  readonly type = TRY_RESET_PASSWORD;
  constructor(public payload: { key: string; newPassword: string }) {}
}

export class ResetPasswordSuccess implements Action {
  readonly type = RESET_PASSWORD_SUCCESS;
}

export class ResetPasswordError implements Action {
  readonly type = RESET_PASSWORD_ERROR;
}

export class TrySendResetPasswordEmail implements ActionWithPayload<string> {
  readonly type = TRY_SEND_RESET_PASSWORD_EMAIL;
  constructor(public payload: string) {}
}

export class SendResetPasswordEmailSuccess implements Action {
  readonly type = SEND_RESET_PASSWORD_EMAIL_SUCCESS;
}

export class SendResetPasswordEmailError implements ActionWithPayload<any> {
  readonly type = SEND_RESET_PASSWORD_EMAIL_ERROR;
  constructor(public payload: any) {}
}

export class TryLoginUser implements ActionWithPayload<{ userName: string; password: string }> {
  readonly type = TRY_LOGIN_USER;
  constructor(public payload: { userName: string; password: string }) {}
}

export class LoginUserSuccess
  implements
    ActionWithPayload<{
      user: User;
      license: LicenseDetails;
      userOptions: UserOptions;
      globalOptions: GlobalOptions;
    }>
{
  readonly type = LOGIN_USER_SUCCESS;
  constructor(
    public payload: {
      user: User;
      license: LicenseDetails;
      userOptions: UserOptions;
      globalOptions: GlobalOptions;
    },
  ) {}
}

export class LoginUserError implements ActionWithPayload<string> {
  readonly type = LOGIN_USER_ERROR;
  constructor(public payload: string) {}
}

export class TryActivateUser implements ActionWithPayload<string> {
  readonly type = TRY_ACTIVATE_USER;
  constructor(public payload: string) {}
}

export class ActivateUserSuccess implements Action {
  readonly type = ACTIVATE_USER_SUCCESS;
}

export class ActivateUserError implements ActionWithPayload<string> {
  readonly type = ACTIVATE_USER_ERROR;
  constructor(public payload: string) {}
}

export type AUTHActions =
  | TryLoginUser
  | LoginUserSuccess
  | LoginUserError
  | TrySetFirstPassword
  | TryResetPassword
  | ResetPasswordSuccess
  | ResetPasswordError
  | TrySendResetPasswordEmail
  | SendResetPasswordEmailSuccess
  | SendResetPasswordEmailError
  | TryActivateUser
  | ActivateUserSuccess
  | ActivateUserError
  | TryLoadTenantLogo
  | TenantLogoIsAlreadyLoaded
  | LoadTenantLogoSuccess
  | LoadTenantLogoError;
