import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from 'app/app.constants';
import { IRestError, IRestResponse, RestResponse } from 'app/core/models/rest.model';
import { RestUtils } from 'app/core/utils/rest.utils';
import { LicenseDetails } from 'app/shared/license/licensedetails.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Defines methods used by multiple module components as well as common data and utility
 */
@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  private resourceUrl = SERVER_API_URL + 'api/license';

  constructor(private http: HttpClient) {}

  // Get license details for current user
  getLicense(): Observable<IRestResponse<LicenseDetails>> {
    return this.http.get<LicenseDetails>(this.resourceUrl).pipe(
      map((res) => {
        const response = new RestResponse();
        response.totalItems = 1;
        response.data = res;
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }
}
