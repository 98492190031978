import { MatDateFormats } from '@angular/material/core';

export class AppGlobalConstants {
  public static readonly urlPattern = '(ftp|http|https|ws|wss|tcp):?:(\\/.)?([a-zA-Z0-9._:]+)';
  public static readonly wwwPattern = '(www|[a-zA-Z0-9]+)([.])([a-zA-Z0-9._\\-]+)([.])([a-zA-Z0-9.:;\\-\\/]+)';

  public static readonly RIGHT_MOUSE_BUTTON = 2; // A number assigned to the right mouse button in the MouseEvent event type

  public static readonly DATE_FORMATS: MatDateFormats = {
    parse: {
      dateInput: 'L',
    },
    display: {
      dateInput: 'L',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'L',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
}
