import { Action } from '@ngrx/store';
import { ActionWithPayload } from 'app/shared';
import { ScheduledJob } from '@zero/angular-ui/zero-scheduler';
import { Project } from '../model/project.model';

export const CLEAR_PROJECT_SELECTION = '[Project Detail Back Arrow] Deselect selected process PROJECT';

export const SELECT_PROJECT_AS_PARENT = '[Project list item folder double click] Select project as parent.';

export const TRY_GET_PROJECT_NEXT_PAGE = '[Project List Infinite Scroll] Try get project next page';
export const GET_PROJECT_NEXT_PAGE_SUCCESS = '[Project Api Response] Get project next page success';
export const GET_PROJECT_NEXT_PAGE_ERROR = '[Project Api Response] Get project next page error';
export const GET_PROJECT_NEXT_PAGE_PARENT_NOT_AVAILABLE_ERROR =
  '[Project Api Response] Get project next page because parent is not available error';

export const SET_SELECTED_PROJECT = '[Project Selection] Set selected Project';
export const TRY_ADD_PROJECT = '[Project Details Submit] Try Add project';
export const ADD_PROJECT_SUCCESS = '[Project Api Response] Add project success';
export const ADD_PRIVATE_PROJECT_FOR_ANOTHER_USER_SUCCESS =
  '[Project Api Response] Add private project for another user success';
export const ADD_PROJECT_ERROR = '[Project Api Response] Add project error';

export const TOGGLE_PROJECT_DELETED = '[Project Toggle Deleted Header Button] Updated deleted flag in selected project';
export const TRY_UPDATE_PROJECT = '[Project Form Update and Toggle Deleted] Try Update project';
export const UPDATE_PROJECT_SUCCESS = '[Project Api Response] Update project success';
export const UPDATE_PROJECT_ERROR = '[Project Api Response] Update project error';
export const UPDATE_PROJECT_TO_PRIVATE_FOR_ANOTHER_USER_SUCCESS =
  '[Project Api Response] Update project to private for another user success';

export const TRY_REMOVE_PROJECT = '[Project Details Remove Button] Try remove project';
export const REMOVE_PROJECT_SUCCESS = '[Project Api Response] Remove project success';
export const REMOVE_PROJECT_ERROR = '[Project Api Response] Remove project error';

export const START_NEW_PROJECT_CREATION = '[Project List Add button] Start new project creation.';

export const TRY_ROUTE_UPDATE_PROJECT_DATA = '[Project Route Change] Try load route project';
export const ROUTE_UPDATE_PROJECT_DATA_SUCCESS = '[Project Api Response] Load route project success';
export const ROUTE_UPDATE_PROJECT_DATA_ERROR = '[Project Api Response] Load route project error';

export const ROUTE_UPDATE_TO_NULL = '[Project Update Route to Null] Set data for root null';
export const ROUTE_UPDATE_TO_ROOT_FOLDER = '[Project Update Route is Root Route] Reroute to match root id';

export const TRY_LOAD_CHILDREN_LIST = '[Goal Root navigation triggered] Try load children projects';
export const TRY_LOAD_SELECTED_PROJECT_LIST = '[Goal Root navigation triggered] Try load selected project list';
export const TRY_LOAD_INITIAL_PROJECT_LIST = '[Project List Loading Parameters] Try load initial projects';
export const TRY_LOAD_SORTED_PROJECT_LIST = '[Project List Loading Parameters] Try load sorted projects';
export const TRY_LOAD_FILTERED_PROJECT_LIST = '[Project Filter/Search] Load filtered projects';

export const TRY_FIND_PROJECT = '[Project List Selection] Try find project';
export const TRY_FIND_PROJECT_FROM_BREADCRUMB = '[Project Breadcrumb] Try find project';

export const TRY_LOAD_BREADCRUMB = '[Project Routing] Try load breadcrumb';
export const LOAD_BREADCRUMB_SUCCESS = '[Project Api Response] Load breadcrumb success';
export const LOAD_BREADCRUMB_ERROR = '[Project Api Response] Load breadcrumb error';

export const SLICE_BREADCRUMB = '[Project Routing] Slice existing breadcrumb size after navigation';

export const TRY_OPEN_PROJECT_DATA = '[Project table double click] Try open goal designer.';

export const RESET_STATE = '[Project On Destroy] Restart state';

export const START_TRACKING_ROUTE_CHANGES = '[Project On Init] Start tracking route changes.';
export const STOP_TRACKING_ROUTE_CHANGES = '[Project On Destroy] Stop tracking route changes.';

export const START_TRACKING_LANG_CHANGES = '[Project On Init] Start tracking lang changes.';
export const STOP_TRACKING_LANG_CHANGES = '[Project On Destroy] Stop tracking lang changes.';

export const ATEMPTED_NAVIGATION_TO_SAME_PROJECT =
  '[Project Breadcrumb/Parent Navigation to the same Diagram] Clear selected diagram';

export const UPDATE_VIEW_ONLY_MODE = '[Project init] Update view only status';
export const LOAD_VIEW_ONLY_MODE = '[Project Effects] Set if view only mode';

export const DESELECT_PROJECT = '[Project Effects Route to same root] Set if view only mode';

export const TRY_DRAG_PROJECT = '[Project Table Drop diagram on parent] Try update diagram parent';
export const DRAG_PROJECT_SUCCESS = '[Project Api Response] Update diagram parent success';
export const DRAG_PROJECT_ERROR = '[Project Api Response] Update diagram parent error';
export const SKIP_PROJECT_DRAG = '[Project Effects] Skip dragging project.';

export const SCHEDULE_TASK_SUCCESS = '[Project Api Response] Schedule task success';
export const SCHEDULE_TASK_ERROR = '[Project Api Response] Schedule task error';

export class UpdateProjectPrivateForAnotherUser implements Action {
  readonly type = UPDATE_PROJECT_TO_PRIVATE_FOR_ANOTHER_USER_SUCCESS;
}

export class RouteUpdateToNull implements Action {
  readonly type = ROUTE_UPDATE_TO_NULL;
}

export class RouteUpdateToRootId implements ActionWithPayload<string> {
  readonly type = ROUTE_UPDATE_TO_ROOT_FOLDER;
  constructor(public payload: string) {}
}
export class ScheduleTaskSuccess implements ActionWithPayload<ScheduledJob> {
  readonly type = SCHEDULE_TASK_SUCCESS;
  constructor(public payload: ScheduledJob) {}
}
export class ScheduleTaskError implements Action {
  readonly type = SCHEDULE_TASK_ERROR;
}

export class TryDragProject implements ActionWithPayload<{ targetProjectId: string; sourceProjectId: string }> {
  readonly type = TRY_DRAG_PROJECT;
  constructor(public payload: { targetProjectId: string; sourceProjectId: string }) {}
}

export class DragProjectSuccess implements ActionWithPayload<{ targetProjectId: string; sourceProjectId: string }> {
  readonly type = DRAG_PROJECT_SUCCESS;
  constructor(public payload: { targetProjectId: string; sourceProjectId: string }) {}
}

export class SkipProjectDrag implements Action {
  readonly type = SKIP_PROJECT_DRAG;
}

export class DragProjectError implements Action {
  readonly type = DRAG_PROJECT_ERROR;
}

export class DeselectProject implements Action {
  readonly type = DESELECT_PROJECT;
}

export class UpdateViewOnlyMode implements Action {
  readonly type = UPDATE_VIEW_ONLY_MODE;
}

export class LoadViewOnlyMode implements ActionWithPayload<boolean> {
  readonly type = LOAD_VIEW_ONLY_MODE;
  constructor(public payload: boolean) {}
}

export class TryLoadChildrenList implements ActionWithPayload<string> {
  readonly type = TRY_LOAD_CHILDREN_LIST;
  constructor(public payload: string) {}
}

export class TryLoadSelectedProjectList implements ActionWithPayload<string> {
  readonly type = TRY_LOAD_SELECTED_PROJECT_LIST;
  constructor(public payload: string) {}
}

export class StartTrackingRouteChanges implements Action {
  readonly type = START_TRACKING_ROUTE_CHANGES;
}

export class StopTrackingRouteChanges implements Action {
  readonly type = STOP_TRACKING_ROUTE_CHANGES;
}

export class StartTrackingLangChanges implements Action {
  readonly type = START_TRACKING_LANG_CHANGES;
}

export class StopTrackingLangChanges implements Action {
  readonly type = STOP_TRACKING_LANG_CHANGES;
}

export class ResetState implements Action {
  readonly type = RESET_STATE;
}

export class TryToggleSelectedDiagramDeleted implements Action {
  readonly type = TOGGLE_PROJECT_DELETED;
}
export class TryOpenProjectData implements ActionWithPayload<Project> {
  readonly type = TRY_OPEN_PROJECT_DATA;
  constructor(public payload: Project) {}
}
export class TryLoadBreadcrumb implements Action {
  readonly type = TRY_LOAD_BREADCRUMB;
}

export class SelectDiagramAsParent implements ActionWithPayload<string> {
  readonly type = SELECT_PROJECT_AS_PARENT;
  constructor(public payload: string) {}
}

export class LoadBreadcrumbSuccess implements ActionWithPayload<Project[]> {
  readonly type = LOAD_BREADCRUMB_SUCCESS;
  constructor(public payload: Project[]) {}
}

export class LoadBreadcrumbError implements Action {
  readonly type = LOAD_BREADCRUMB_ERROR;
}

export class TryLoadInitialProjectList implements Action {
  readonly type = TRY_LOAD_INITIAL_PROJECT_LIST;
}

export class TryLoadSortedProjectList implements Action {
  readonly type = TRY_LOAD_SORTED_PROJECT_LIST;
}

export class TryLoadFilteredProjectList implements Action {
  readonly type = TRY_LOAD_FILTERED_PROJECT_LIST;
}

export class TryRouteUpdateProjectData implements ActionWithPayload<string> {
  readonly type = TRY_ROUTE_UPDATE_PROJECT_DATA;
  constructor(public payload: string) {}
}

export class RouteUpdateProjectDataSuccess implements ActionWithPayload<{ project: Project; rootProjectId: string }> {
  readonly type = ROUTE_UPDATE_PROJECT_DATA_SUCCESS;
  constructor(public payload: { project: Project; rootProjectId: string }) {}
}

export class RouteUpdateProjectDataError implements Action {
  readonly type = ROUTE_UPDATE_PROJECT_DATA_ERROR;
}

export class StartNewProjectCreation implements ActionWithPayload<'folder' | 'leaf'> {
  readonly type = START_NEW_PROJECT_CREATION;
  constructor(public payload: 'folder' | 'leaf') {}
}

export class ClearProjectSelection implements Action {
  readonly type = CLEAR_PROJECT_SELECTION;
}

export class TryGetProjectNextPage implements Action {
  readonly type = TRY_GET_PROJECT_NEXT_PAGE;
}
export class GetProjectNextPageSuccess implements ActionWithPayload<Project[]> {
  readonly type = GET_PROJECT_NEXT_PAGE_SUCCESS;
  constructor(public payload: Project[]) {}
}

export class GetProjectNextPageError implements Action {
  readonly type = GET_PROJECT_NEXT_PAGE_ERROR;
}

export class GetProjectNextPageParentNotAvailableError implements Action {
  readonly type = GET_PROJECT_NEXT_PAGE_PARENT_NOT_AVAILABLE_ERROR;
}

export class SetSelectedProject implements ActionWithPayload<Project> {
  readonly type = SET_SELECTED_PROJECT;
  constructor(public payload: Project) {}
}

export class TryAddProject implements ActionWithPayload<Project> {
  readonly type = TRY_ADD_PROJECT;
  constructor(public payload: Project) {}
}
export class AddProjectSuccess implements ActionWithPayload<Project> {
  readonly type = ADD_PROJECT_SUCCESS;
  constructor(public payload: Project) {}
}

export class AddPrivateProjectForAnotherUserSuccess implements Action {
  readonly type = ADD_PRIVATE_PROJECT_FOR_ANOTHER_USER_SUCCESS;
}

export class AddProjectError implements Action {
  readonly type = ADD_PROJECT_ERROR;
}

export class TryUpdateProject implements ActionWithPayload<Project> {
  readonly type = TRY_UPDATE_PROJECT;
  constructor(public payload: Project) {}
}
export class UpdateProjectSuccess implements ActionWithPayload<Project> {
  readonly type = UPDATE_PROJECT_SUCCESS;
  constructor(public payload: Project) {}
}
export class UpdateProjectError implements Action {
  readonly type = UPDATE_PROJECT_ERROR;
}

export class TryRemoveSelectedProject implements Action {
  readonly type = TRY_REMOVE_PROJECT;
}

export class RemoveProjectSuccess implements Action {
  readonly type = REMOVE_PROJECT_SUCCESS;
}
export class RemoveProjectError implements Action {
  readonly type = REMOVE_PROJECT_ERROR;
}

export class TryFindProject implements ActionWithPayload<string> {
  readonly type = TRY_FIND_PROJECT;
  constructor(public payload: string) {}
}

export class TryFindProjectBreadcrumb implements ActionWithPayload<string> {
  readonly type = TRY_FIND_PROJECT_FROM_BREADCRUMB;
  constructor(public payload: string) {}
}

export class SliceBreadcrumb implements Action {
  readonly type = SLICE_BREADCRUMB;
}

export type ProjectActions =
  | SliceBreadcrumb
  | TryGetProjectNextPage
  | GetProjectNextPageSuccess
  | GetProjectNextPageError
  | GetProjectNextPageParentNotAvailableError
  | SetSelectedProject
  | TryAddProject
  | AddProjectSuccess
  | AddPrivateProjectForAnotherUserSuccess
  | AddProjectError
  | TryUpdateProject
  | UpdateProjectSuccess
  | UpdateProjectError
  | TryRemoveSelectedProject
  | RemoveProjectSuccess
  | RemoveProjectError
  | TryLoadBreadcrumb
  | LoadBreadcrumbSuccess
  | LoadBreadcrumbError
  | TryFindProject
  | TryFindProjectBreadcrumb
  | TryRouteUpdateProjectData
  | RouteUpdateProjectDataSuccess
  | RouteUpdateProjectDataError
  | StartNewProjectCreation
  | TryLoadInitialProjectList
  | TryLoadSortedProjectList
  | TryLoadFilteredProjectList
  | ClearProjectSelection
  | TryOpenProjectData
  | SelectDiagramAsParent
  | TryToggleSelectedDiagramDeleted
  | StartTrackingRouteChanges
  | StopTrackingRouteChanges
  | StartTrackingLangChanges
  | StopTrackingLangChanges
  | TryLoadChildrenList
  | TryLoadSelectedProjectList
  | UpdateViewOnlyMode
  | LoadViewOnlyMode
  | DeselectProject
  | TryDragProject
  | DragProjectSuccess
  | DragProjectError
  | SkipProjectDrag
  | ScheduleTaskSuccess
  | ScheduleTaskError
  | RouteUpdateToRootId
  | UpdateProjectPrivateForAnotherUser
  | ResetState;
