import {
  PDActions,
  UPDATE_INSTANCE_ERROR,
  REMOVE_INSTANCE_ERROR,
  UPDATE_INSTANCE_SUCCESS,
  REMOVE_INSTANCE_SUCCESS,
  SET_SELECTED_INSTANCE,
  TRY_GET_INSTANCE_NEXT_PAGE,
  GET_INSTANCE_NEXT_PAGE_SUCCESS,
  GET_INSTANCE_NEXT_PAGE_ERROR,
  TRY_LOAD_FILTERED_INSTANCE_LIST,
  RESET_STATE,
  TRY_LOAD_SORTED_INSTANCE_LIST,
  ROUTE_UPDATE_INSTANCE_DATA_SUCCESS,
  TRY_LOAD_INITIAL_INSTANCE_LIST,
  TRY_START_PROCESS_INSTANCE,
  START_PROCESS_INSTANCE_SUCCESS,
  START_PROCESS_INSTANCE_ERROR,
  LOAD_VIEW_ONLY_MODE,
} from './process-result.actions';

import { ITEMS_PER_PAGE } from '../../../../../../shared';
import { produce } from 'immer';
import { ProcessInstance } from '../process-instance.model';
import { ProcessDiagram } from '../../../designers/process/model/process-diagram.model';

export interface State {
  processResultList: ProcessInstance[];
  rootProcessDiagram: ProcessDiagram;
  selectedProcessResult: ProcessInstance;
  rootProcessResutId: string;
  loading: boolean;
  sortOrder: 'asc' | 'desc';
  queryParams: { page: number; size: number; sort: string[] };
  viewOnly: boolean;
}

const initialState: State = {
  processResultList: [],
  rootProcessDiagram: null,
  selectedProcessResult: null,
  rootProcessResutId: undefined,
  loading: false,
  sortOrder: 'asc',
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: ['number, asc', 'id,desc'],
  },
  viewOnly: true,
};

export const processResultReducer = produce((draft: State, action: PDActions): void => {
  switch (action.type) {
    case RESET_STATE:
      Object.assign(draft, initialState);

      return;
    case LOAD_VIEW_ONLY_MODE:
      draft.viewOnly = action.payload;

      return;
    case TRY_LOAD_INITIAL_INSTANCE_LIST:
      draft.loading = true;
      draft.selectedProcessResult = null;
      draft.processResultList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = initialState.sortOrder;

      return;
    case TRY_LOAD_SORTED_INSTANCE_LIST:
      draft.loading = true;
      draft.selectedProcessResult = null;
      draft.processResultList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = draft.sortOrder === 'asc' ? 'desc' : 'asc';

      return;
    case TRY_LOAD_FILTERED_INSTANCE_LIST:
      draft.selectedProcessResult = null;
      draft.processResultList = [];

      return;
    case SET_SELECTED_INSTANCE:
      draft.selectedProcessResult = draft.processResultList.find(
        (processResult) => processResult.id === action.payload,
      );

      return;
    case TRY_START_PROCESS_INSTANCE:
      draft.loading = true;

      return;
    case START_PROCESS_INSTANCE_SUCCESS:
    case START_PROCESS_INSTANCE_ERROR:
      draft.loading = false;

      return;
    case TRY_GET_INSTANCE_NEXT_PAGE:
      draft.loading = true;

      return;
    case ROUTE_UPDATE_INSTANCE_DATA_SUCCESS:
      draft.selectedProcessResult =
        action.payload && draft.processResultList.find((processResult) => processResult.id === action.payload);

      return;
    case GET_INSTANCE_NEXT_PAGE_ERROR:
    case UPDATE_INSTANCE_ERROR:
    case REMOVE_INSTANCE_ERROR:
      draft.loading = false;

      return;
    case GET_INSTANCE_NEXT_PAGE_SUCCESS:
      draft.loading = false;
      draft.processResultList.push(...action.payload.data);
      if (action.payload.data.length > 0) draft.queryParams.page += 1;

      return;
    case UPDATE_INSTANCE_SUCCESS:
      draft.processResultList =
        draft.selectedProcessResult?.deleted === action.payload?.deleted
          ? draft.processResultList.map((item) => (item.id === action.payload.id ? action.payload : item))
          : draft.processResultList.filter((item) => item.id !== action.payload.id);

      draft.selectedProcessResult =
        draft.selectedProcessResult?.id !== action.payload.id
          ? draft.selectedProcessResult
          : draft.selectedProcessResult?.deleted === action.payload?.deleted
            ? action.payload
            : null;

      return;
    case REMOVE_INSTANCE_SUCCESS:
      draft.processResultList = draft.processResultList.filter((item) => draft.selectedProcessResult.id !== item.id);
      draft.selectedProcessResult = null;

      
  }
}, initialState);

export const getProcessResultList = (state: State) => state.processResultList;

export const getQueryParams = (state: State) => state.queryParams;

export const getSelectedProcessResult = (state: State) => state.selectedProcessResult;

export const getProcessResultLoading = (state: State) => state.loading;

export const getRootProcessResultId = (state: State) => state.rootProcessResutId;

export const getProcessResultSortOrder = (state: State) => state.sortOrder;

export const getRootProcessResultDiagram = (state: State) => state.rootProcessDiagram;

export const getViewOnlyMode = (state: State) => state.viewOnly;
