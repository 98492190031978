import { FileData } from '../../file-manager/document.model';
import {
  ACTIVATE_USER_ERROR,
  ACTIVATE_USER_SUCCESS,
  AUTHActions,
  LOAD_TENANT_LOGO_SUCCESS,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  SEND_RESET_PASSWORD_EMAIL_ERROR,
  SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  TRY_LOGIN_USER,
} from './authentication-store.actions';

import { produce } from 'immer';

export enum AuthentificationRequestsStatus {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR',
  PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS',
  PASSWORD_RESET_EMAIL_NOT_REGISTERED = 'PASSWORD_RESET_EMAIL_NOT_REGISTERED',
  PASSWORD_RESET_EMAIL_ERROR = 'PASSWORD_RESET_EMAIL_ERROR',
  USER_NOT_REGISTERED = 'USER_NOT_REGISTERED',
  REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS',
  REGISTER_USER_ERROR = 'REGISTER_USER_ERROR',
  ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS',
  ACTIVATE_USER_ERROR = 'ACTIVATE_USER_ERROR',
  USERNAME_NOTUNIQUE = 'USERNAME_NOTUNIQUE',
  EMAIL_NOTUNIQUE = 'EMAIL_NOTUNIQUE',
  ERROR = 'ERROR',
  INVALID = 'INVALID',
  LICENSE_EXPIRED = 'LICENSE_EXPIRED',
}
export interface State {
  loginPageLoading: boolean;
  requestStatus: AuthentificationRequestsStatus;
  tenantLogo: FileData;
}

const initialState: State = {
  loginPageLoading: false,
  requestStatus: null,
  tenantLogo: null,
};

export const authenticationPageReducer = produce((draft: State, action: AUTHActions): void => {
  switch (action.type) {
    case LOAD_TENANT_LOGO_SUCCESS:
      draft.tenantLogo = action.payload;

      return;
    case TRY_LOGIN_USER:
      draft.loginPageLoading = true;

      return;
    case LOGIN_USER_SUCCESS:
      draft.loginPageLoading = false;
      draft.requestStatus = AuthentificationRequestsStatus.LOGIN_SUCCESS;
      return;
    case LOGIN_USER_ERROR:
      draft.loginPageLoading = false;
      draft.requestStatus = action.payload === 'INVALID'
        ? AuthentificationRequestsStatus.INVALID
        : (action.payload === 'EXPIRED'
          ? AuthentificationRequestsStatus.LICENSE_EXPIRED
          : AuthentificationRequestsStatus.LOGIN_ERROR);      
      return;
    case RESET_PASSWORD_SUCCESS:
      draft.requestStatus = AuthentificationRequestsStatus.PASSWORD_RESET_SUCCESS;

      return;
    case RESET_PASSWORD_ERROR:
      draft.requestStatus = AuthentificationRequestsStatus.PASSWORD_RESET_ERROR;

      return;
    case SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      draft.requestStatus = AuthentificationRequestsStatus.PASSWORD_RESET_EMAIL_SUCCESS;

      return;
    case SEND_RESET_PASSWORD_EMAIL_ERROR:
      draft.requestStatus =
        action.payload.status === 400
          ? AuthentificationRequestsStatus.PASSWORD_RESET_EMAIL_NOT_REGISTERED
          : AuthentificationRequestsStatus.PASSWORD_RESET_EMAIL_ERROR;

      return;
    case ACTIVATE_USER_SUCCESS:
      draft.requestStatus = AuthentificationRequestsStatus.ACTIVATE_USER_SUCCESS;

      return;
    case ACTIVATE_USER_ERROR:
      draft.requestStatus = AuthentificationRequestsStatus.ACTIVATE_USER_ERROR;
  }
}, initialState);

export const getLoginPageLoading = (state: State) => state.loginPageLoading;

export const getRequestStatus = (state: State) => state.requestStatus;

export const getTenantLogo = (state: State) => state.tenantLogo;
