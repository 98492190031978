import {
  KPIRActions,
  UPDATE_INSTANCE_ERROR,
  REMOVE_INSTANCE_ERROR,
  UPDATE_INSTANCE_SUCCESS,
  REMOVE_INSTANCE_SUCCESS,
  SET_SELECTED_INSTANCE,
  TRY_GET_INSTANCE_NEXT_PAGE,
  GET_INSTANCE_NEXT_PAGE_SUCCESS,
  GET_INSTANCE_NEXT_PAGE_ERROR,
  TRY_LOAD_FILTERED_INSTANCE_LIST,
  RESET_STATE,
  TRY_LOAD_SORTED_INSTANCE_LIST,
  ROUTE_UPDATE_INSTANCE_DATA_SUCCESS,
  TRY_LOAD_INITIAL_INSTANCE_LIST,
  TRY_START_KPI_INSTANCE,
  START_KPI_INSTANCE_SUCCESS,
  START_KPI_INSTANCE_ERROR,
} from './kpi-result.actions';

import { ITEMS_PER_PAGE } from '../../../../../../shared';
import { produce } from 'immer';
import { ProcessInstance } from '../../process-result/process-instance.model';
import { ProcessDiagram } from '../../../designers/process/model/process-diagram.model';

export interface State {
  kpiResultList: ProcessInstance[];
  rootKPIDiagram: ProcessDiagram;
  selectedKPIResult: ProcessInstance;
  rootKPIResutId: string;
  loading: boolean;
  sortOrder: 'asc' | 'desc';
  queryParams: { page: number; size: number; sort: string[] };
}

const initialState: State = {
  kpiResultList: [],
  rootKPIDiagram: null,
  selectedKPIResult: null,
  rootKPIResutId: undefined,
  loading: false,
  sortOrder: 'asc',
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: ['number, asc', 'id,desc'],
  },
};

export const kpiResultReducer = produce((draft: State, action: KPIRActions): void => {
  switch (action.type) {
    case RESET_STATE:
      Object.assign(draft, initialState);

      return;
    case TRY_LOAD_INITIAL_INSTANCE_LIST:
      draft.loading = true;
      draft.selectedKPIResult = null;
      draft.kpiResultList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = initialState.sortOrder;

      return;
    case TRY_LOAD_SORTED_INSTANCE_LIST:
      draft.loading = true;
      draft.selectedKPIResult = null;
      draft.kpiResultList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = draft.sortOrder === 'asc' ? 'desc' : 'asc';

      return;
    case TRY_LOAD_FILTERED_INSTANCE_LIST:
      draft.selectedKPIResult = null;
      draft.kpiResultList = [];

      return;
    case SET_SELECTED_INSTANCE:
      draft.selectedKPIResult = draft.kpiResultList.find((processResult) => processResult.id === action.payload);

      return;
    case TRY_START_KPI_INSTANCE:
      draft.loading = true;

      return;
    case START_KPI_INSTANCE_SUCCESS:
    case START_KPI_INSTANCE_ERROR:
      draft.loading = false;

      return;
    case TRY_GET_INSTANCE_NEXT_PAGE:
      draft.loading = true;

      return;
    case ROUTE_UPDATE_INSTANCE_DATA_SUCCESS:
      draft.selectedKPIResult =
        action.payload && draft.kpiResultList.find((processResult) => processResult.id === action.payload);

      return;
    case GET_INSTANCE_NEXT_PAGE_ERROR:
    case UPDATE_INSTANCE_ERROR:
    case REMOVE_INSTANCE_ERROR:
      draft.loading = false;

      return;
    case GET_INSTANCE_NEXT_PAGE_SUCCESS:
      draft.loading = false;
      draft.kpiResultList.push(...action.payload.data);
      if (action.payload.data.length > 0) draft.queryParams.page += 1;

      return;
    case UPDATE_INSTANCE_SUCCESS:
      draft.kpiResultList = draft.kpiResultList.map((item) => (item.id === action.payload.id ? action.payload : item));

      draft.selectedKPIResult =
        draft.selectedKPIResult?.id === action.payload.id ? action.payload : draft.selectedKPIResult;

      return;
    case REMOVE_INSTANCE_SUCCESS:
      draft.kpiResultList = draft.kpiResultList.filter((item) => draft.selectedKPIResult.id !== item.id);
      draft.selectedKPIResult = null;
  }
}, initialState);

export const getKPIResultList = (state: State) => state.kpiResultList;

export const getQueryParams = (state: State) => state.queryParams;

export const getSelectedKPIResult = (state: State) => state.selectedKPIResult;

export const getKPIResultLoading = (state: State) => state.loading;

export const getRootKPIResultId = (state: State) => state.rootKPIResutId;

export const getKPIResultSortOrder = (state: State) => state.sortOrder;

export const getRootKPIResultDiagram = (state: State) => state.rootKPIDiagram;
