import { Injectable } from '@angular/core';

import { AuthServerProvider } from '../auth/auth-jwt.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class LoginService {
  constructor(
    private authServerProvider: AuthServerProvider,
    private router: Router,
  ) {}

  login(credentials): Observable<{ idToken: string }> {
    return this.authServerProvider.login(credentials);
  }

  loginWithToken(jwt, rememberMe) {
    return this.authServerProvider.loginWithToken(jwt, rememberMe);
  }

  logout(): void {
    this.authServerProvider.logout();
    this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
  }

  logoutAndGoToLoginPage(redirectUrl?: string): void {
    this.authServerProvider.logout();
    if (!redirectUrl)
      { this.router.navigate(['/login'], {
        queryParamsHandling: 'merge',
        queryParams: { 'redirect-url': undefined },
      }); }
    else if (!redirectUrl.startsWith('/login'))
      { this.router.navigate(['/login'], {
        queryParamsHandling: 'merge',
        queryParams: { 'redirect-url': redirectUrl },
      }); }
  }
}
