import { Action } from '@ngrx/store';
import { DocumentStore } from '../../document.model';
import { ActionWithPayload } from 'app/shared';

export const RESET_DIALOG_STATE = '[FM Selection Dialog On Destroy] Reset dialog state.';

export const TRY_SET_INITIAL_DOCUMENT_LIST = '[FM Selection Dialog On Init] Try set initial document list.';
export const SET_INITIAL_DOCUMENT_LIST_SUCCESS =
  '[FM Selection Dialog Api Response] Set initial document list success.';
export const SET_INITIAL_DOCUMENT_LIST_ERROR = '[FM Selection Dialog Api Response] Set initial document list error.';

export const ADD_DOCUMENTS = '[FM Selection Dialog Add Button] Add selected document.';

export const REMOVE_FILE_FROM_LIST = '[FM Selection Dialog Remove document] Remove document from list.';

export class ResetDialogState implements Action {
  readonly type = RESET_DIALOG_STATE;
}

export class TrySetInitalDocumentList implements ActionWithPayload<string[]> {
  readonly type = TRY_SET_INITIAL_DOCUMENT_LIST;
  constructor(public payload: string[]) {}
}

export class SetInitalDocumentListSuccess implements ActionWithPayload<DocumentStore[]> {
  readonly type = SET_INITIAL_DOCUMENT_LIST_SUCCESS;
  constructor(public payload: DocumentStore[]) {}
}

export class InitalDocumentListError implements Action {
  readonly type = SET_INITIAL_DOCUMENT_LIST_ERROR;
}

export class AddDocuments implements Action {
  readonly type = ADD_DOCUMENTS;
  constructor(public payload: DocumentStore[]) {}
}

export class RemoveDocumentFromList implements ActionWithPayload<string[]> {
  readonly type = REMOVE_FILE_FROM_LIST;
  constructor(public payload: string[]) {}
}

export type FMSDActions =
  | ResetDialogState
  | TrySetInitalDocumentList
  | SetInitalDocumentListSuccess
  | InitalDocumentListError
  | AddDocuments
  | RemoveDocumentFromList;
