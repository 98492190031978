import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { LoginService } from '../../shared/auth/login.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: HttpResponseBase) => {
          if (!(err instanceof HttpErrorResponse) || err.status !== 401) return;
          const loginService: LoginService = this.injector.get(LoginService);
          loginService.logoutAndGoToLoginPage(this.router.routerState.snapshot.url);
        },
      ),
    );
  }
}
