import { Action } from '@ngrx/store';
import { ActionWithPayload } from 'app/shared';
import { Textstore } from 'app/shared/lang-dialog/textstore.model';
import { DiagramStep, GoalProcessDiagram } from '../../../process/model/process-diagram.model';
import { ProcessInstance } from 'app/main/content/apps/result/process-result/process-instance.model';
import { Characteristic } from 'app/core/components/characteristic/characteristic.model';
import { GoalStepHeaderData } from '../../../shared/step-requirements/model/step-requirement-data.model';

export const TRY_FIND_DIAGRAM = '[Goal Designer ] Try find diagram';
export const FIND_DIAGRAM_SUCCESS = '[Goal Designer Api Response] Find diagram success';
export const FIND_DIAGRAM_ERROR = '[Goal Designer Api Response] Find diagram error';

export const TRY_ADD_GOAL_STEP = '[Goal Designer Add Step] Try add a process step';
export const ADD_GOAL_STEP_SUCCESS = '[Goal Designer Api Response] Add a process step success';
export const ADD_GOAL_STEP_ERROR = '[Goal Designer Api Response] Add a process step error';

export const TRY_SET_SELECTED_STEP = '[Goal Designer Effects] Set selected step';
export const TRY_LOAD_MISSING_STEP = '[Goal Designer Effects] Try load step';
export const SET_LOADED_STEP_SELECTED = '[Goal Designer Effects] Step is already loaded';
export const LOAD_STEP_SUCCESS = '[Goal Designer Api Response] Load step success';
export const LOAD_STEP_ERROR = '[Goal Designer Api Response] Load step error';

export const TRY_RENAME_SELECTED_STEP = '[Goal Designer Step Update] Try rename step';
export const RENAME_SELECTED_STEP_SUCCESS = '[Goal Designer Api Response] Rename step success';
export const RENAME_SELECTED_STEP_ERROR = '[Goal Designer Api Response] Rename step error';

export const TRY_DELETE_SELECTED_STEP = '[Goal Designer Delete Dialog] Try delete selected step';
export const DELETE_SELECTED_STEP_SUCCESS = '[Goal Designer Api Response] Delete selected step success';
export const DELETE_SELECTED_STEP_ERROR = '[Goal Designer Api Response] Delete selected step error';

export const TRY_UPDATE_STEP_ORDER = '[Goal Designer Step Drag] Try update model';
export const UPDATE_STEP_ORDER_SUCCESS = '[Goal Designer Api Response] Update model success';
export const UPDATE_STEP_ORDER_ERROR = '[Goal Designer Api Response] Update model error';

export const RESET_DESIGNER = '[Goal Designer On Destroy] Reset designer.';

export const TRY_OPEN_PROCESS_INSTANCE = '[Goal Designer Play button] Try open process instance.';
export const OPEN_PROCESS_INSTANCE_SUCCESS = '[Goal Designer Api response] Open process instance success.';
export const OPEN_PROCESS_INSTANCE_ERROR = '[Goal Designer Api response] Open process instance error.';

export const TRY_CREATE_NEW_PROCESS_INSTANCE = '[Goal Designer Play button] Try create new process instance.';
export const CREATE_NEW_PROCESS_INSTANCE_SUCCESS = '[Goal Designer Api response] Create process instance success.';
export const CREATE_NEW_PROCESS_INSTANCE_ERROR = '[Goal Designer Api Response] Create process instance error.';

export const TRY_RESUME_PROCESS_INSTANCE = '[Goal Designer Play button] Try resume new process instance.';
export const RESUME_PROCESS_INSTANCE_SUCCESS = '[Goal Designer Api response] resume process instance success.';
export const RESUME_PROCESS_INSTANCE_ERROR = '[Goal Designer Api Response] resume process instance error.';

export const ROOT_TO_PROCESS_EXECUTIONER = '[Goal Designer] Root to process executioner.';

export const TRY_UPDATE_DIAGRAM_SELECTION = '[Goal Designer Effects] Goal designer update selection.';
export const UPDATE_DIAGRAM_SELECTION_SUCCESS = '[Goal Designer Api Response] Goal designer success.';
export const UPDATE_DIAGRAM_SELECTION_ERROR = '[Goal Designer Api Response] Goal designer error.';

export const START_TRACKING_ROUTE_CHANGES = '[Goal Designer On Init] Start tracking route changes.';
export const STOP_TRACKING_ROUTE_CHANGES = '[Goal Designer On Destroy] Stop tracking route changes.';

export const NAVIGATE_TO_STEP = '[Goal Designer Step click] Change step selection.';
export const DESELECT_STEP = '[Goal Designer Diagram blank click] Deselect step.';

export const UPDATE_VIEW_ONLY_MODE = '[Goal Designer init] Update view only status';
export const LOAD_VIEW_ONLY_MODE = '[Goal Designer Effects] Set if view only mode';

export const START_ADDING_STEP = '[Goal Designer Add Step Button Click] Start adding step.';
export const CANCEL_ADDING_STEP = '[Goal Designer Add Step Button Click] Cancel adding step.';

export const TRY_UPDATE_DIAGRAM = '[Goal Designer Details Form Update] Try Update diagram';
export const UPDATE_DIAGRAM_SUCCESS = '[Goal Designer Api Response] Update diagram success';
export const UPDATE_DIAGRAM_ERROR = '[Goal Designer Api Response] Update diagram error';

export const TRY_ADD_CHARACTERISTIC = '[Goal Designer Characteristic Add Button] Try add characteristic';
export const ADD_CHARACTERISTIC_SUCCESS = '[Goal Designer API Response] Add characteristic success';
export const ADD_CHARACTERISTIC_ERROR = '[Goal Designer API Response] Add characteristic error';

export const TRY_UPDATE_CHARACTERISTIC = '[Goal Designer Characteristic Update Form] Try update characteristic';
export const UPDATE_CHARACTERISTIC_SUCCESS = '[Goal Designer API Response] Update characteristic success';
export const UPDATE_CHARACTERISTIC_ERROR = '[Goal Designer API Response] Update characteristic error';

export const TRY_REMOVE_CHARACTERISTIC = '[Goal Designer Characteristic Remove] Try remove characteristic';
export const REMOVE_CHARACTERISTIC_SUCCESS = '[Goal Designer API Response] Remove characteristic success';
export const REMOVE_CHARACTERISTIC_ERROR = '[Goal Designer API Response] Remove characteristic error';

export const TRY_UPDATE_STEP_HEADER_DATA = '[Goal Designer Header form update] Try update step header data.';
export const UPDATE_STEP_HEADER_DATA_SUCCESS = '[Goal Designer Header form update] Update step header data success.';
export const UPDATE_STEP_HEADER_DATA_ERROR = '[Goal Designer Header form update] Update step header data error.';

export class TryUpdateStepHeaderData implements ActionWithPayload<GoalStepHeaderData> {
  readonly type = TRY_UPDATE_STEP_HEADER_DATA;
  constructor(public payload: GoalStepHeaderData) {}
}

export class UpdateStepHeaderDataSuccess
  implements ActionWithPayload<{ stepId: string; headerData: GoalStepHeaderData }>
{
  readonly type = UPDATE_STEP_HEADER_DATA_SUCCESS;
  constructor(public payload: { stepId: string; headerData: GoalStepHeaderData }) {}
}

export class UpdateStepHeaderDataError implements Action {
  readonly type = UPDATE_STEP_HEADER_DATA_ERROR;
}

export class TryAddCharacteristic implements Action {
  readonly type = TRY_ADD_CHARACTERISTIC;
}

export class AddCharacteristicSuccess implements ActionWithPayload<{ stepId: string; char: Characteristic }> {
  readonly type = ADD_CHARACTERISTIC_SUCCESS;
  constructor(public payload: { stepId: string; char: Characteristic }) {}
}

export class AddCharacteristicError implements Action {
  readonly type = ADD_CHARACTERISTIC_ERROR;
}

export class TryUpdateCharacteristic implements ActionWithPayload<Characteristic> {
  readonly type = TRY_UPDATE_CHARACTERISTIC;
  constructor(public payload: Characteristic) {}
}

export class UpdateCharacteristicSuccess implements ActionWithPayload<{ stepId: string; char: Characteristic }> {
  readonly type = UPDATE_CHARACTERISTIC_SUCCESS;
  constructor(public payload: { stepId: string; char: Characteristic }) {}
}

export class UpdateCharacteristicError implements Action {
  readonly type = UPDATE_CHARACTERISTIC_ERROR;
}

export class TryRemoveCharacteristic implements ActionWithPayload<string> {
  readonly type = TRY_REMOVE_CHARACTERISTIC;
  constructor(public payload: string) {}
}

export class RemoveCharacteristicSuccess implements ActionWithPayload<{ stepId: string; charId: string }> {
  readonly type = REMOVE_CHARACTERISTIC_SUCCESS;
  constructor(public payload: { stepId: string; charId: string }) {}
}

export class RemoveCharacteristicError implements Action {
  readonly type = REMOVE_CHARACTERISTIC_ERROR;
}

export class StartAddingStep implements Action {
  readonly type = START_ADDING_STEP;
}

export class CancelAddingStep implements Action {
  readonly type = CANCEL_ADDING_STEP;
}

export class UpdateViewOnlyMode implements Action {
  readonly type = UPDATE_VIEW_ONLY_MODE;
}

export class LoadViewOnlyMode implements ActionWithPayload<boolean> {
  readonly type = LOAD_VIEW_ONLY_MODE;
  constructor(public payload: boolean) {}
}

export class NavigateToStep implements ActionWithPayload<string> {
  readonly type = NAVIGATE_TO_STEP;
  constructor(public payload: string) {}
}

export class DeselectStep implements Action {
  readonly type = DESELECT_STEP;
}

export class StartTrackingRouteChanges implements Action {
  readonly type = START_TRACKING_ROUTE_CHANGES;
}

export class StopTrackingRouteChanges implements Action {
  readonly type = STOP_TRACKING_ROUTE_CHANGES;
}

export class TryUpdateDiagramSelection implements ActionWithPayload<{ diagramId: string; stepId: string }> {
  readonly type = TRY_UPDATE_DIAGRAM_SELECTION;
  constructor(public payload: { diagramId: string; stepId: string }) {}
}
export class UpdateDiagramSelectionSuccess
  implements
    ActionWithPayload<{
      diagram: GoalProcessDiagram;
      stepId: string;
      isNavigatedToStep: boolean;
    }>
{
  readonly type = UPDATE_DIAGRAM_SELECTION_SUCCESS;
  constructor(
    public payload: {
      diagram: GoalProcessDiagram;
      stepId: string;
      isNavigatedToStep: boolean;
    },
  ) {}
}
export class UpdateDiagramSelectionError implements Action {
  readonly type = UPDATE_DIAGRAM_SELECTION_ERROR;
}

export class OpenProcessInstanceError implements Action {
  readonly type = OPEN_PROCESS_INSTANCE_ERROR;
}

export class TryCreateNewProcessInstnace implements Action {
  readonly type = TRY_CREATE_NEW_PROCESS_INSTANCE;
}

export class CreateNewProcessInstnaceSuccess implements Action {
  readonly type = CREATE_NEW_PROCESS_INSTANCE_SUCCESS;
}
export class CreateNewProcessInstnaceError implements Action {
  readonly type = CREATE_NEW_PROCESS_INSTANCE_ERROR;
}

export class TryResumeProcessInstnace implements ActionWithPayload<ProcessInstance> {
  readonly type = TRY_RESUME_PROCESS_INSTANCE;
  constructor(public payload: ProcessInstance) {}
}

export class ResumeProcessInstnaceSuccess implements Action {
  readonly type = RESUME_PROCESS_INSTANCE_SUCCESS;
}
export class ResumeProcessInstnaceError implements Action {
  readonly type = RESUME_PROCESS_INSTANCE_ERROR;
}

export class ResetDesigner implements Action {
  readonly type = RESET_DESIGNER;
}

export class TryFindDiagram implements ActionWithPayload<string> {
  readonly type = TRY_FIND_DIAGRAM;
  constructor(public payload: string) {}
}
export class FindDiagramSuccess implements ActionWithPayload<GoalProcessDiagram> {
  readonly type = FIND_DIAGRAM_SUCCESS;
  constructor(public payload: GoalProcessDiagram) {}
}
export class FindDiagramError implements Action {
  readonly type = FIND_DIAGRAM_ERROR;
}

export class TryAddStep implements ActionWithPayload<Textstore> {
  readonly type = TRY_ADD_GOAL_STEP;
  constructor(public payload: Textstore) {}
}
export class TryAddStepSuccess implements ActionWithPayload<DiagramStep> {
  readonly type = ADD_GOAL_STEP_SUCCESS;
  constructor(public payload: DiagramStep) {}
}
export class TryAddStepError implements Action {
  readonly type = ADD_GOAL_STEP_ERROR;
}

export class TryRenameSelectedStep implements ActionWithPayload<Textstore> {
  readonly type = TRY_RENAME_SELECTED_STEP;
  constructor(public payload: Textstore) {}
}
export class RenameSelectedStepSuccess implements ActionWithPayload<DiagramStep> {
  readonly type = RENAME_SELECTED_STEP_SUCCESS;
  constructor(public payload: DiagramStep) {}
}

export class RenameSelectedStepError implements Action {
  readonly type = RENAME_SELECTED_STEP_ERROR;
}

export class TrySetSelectedStep implements ActionWithPayload<string> {
  readonly type = TRY_SET_SELECTED_STEP;
  constructor(public payload: string) {}
}

export class TryLoadStep implements ActionWithPayload<string> {
  readonly type = TRY_LOAD_MISSING_STEP;
  constructor(public payload: string) {}
}
export class LoadStepSuccess implements ActionWithPayload<DiagramStep> {
  readonly type = LOAD_STEP_SUCCESS;
  constructor(public payload: DiagramStep) {}
}

export class LoadStepError implements Action {
  readonly type = LOAD_STEP_ERROR;
}

export class SetLoadedStepSelected implements ActionWithPayload<string> {
  readonly type = SET_LOADED_STEP_SELECTED;
  constructor(public payload: string) {}
}

export class TryDeleteSelectedStep implements Action {
  readonly type = TRY_DELETE_SELECTED_STEP;
}

export class DeleteSelectedStepSuccess implements ActionWithPayload<string> {
  readonly type = DELETE_SELECTED_STEP_SUCCESS;
  constructor(public payload: string) {}
}

export class DeleteSelectedStepError implements Action {
  readonly type = DELETE_SELECTED_STEP_ERROR;
}

export class TryUpdateStepOrder implements ActionWithPayload<string[]> {
  readonly type = TRY_UPDATE_STEP_ORDER;
  constructor(public payload: string[]) {}
}
export class UpdateStepOrderSuccess implements ActionWithPayload<string[]> {
  readonly type = UPDATE_STEP_ORDER_SUCCESS;
  constructor(public payload: string[]) {}
}
export class UpdateStepOrderError implements Action {
  readonly type = UPDATE_STEP_ORDER_ERROR;
}

export class TryOpenProcessInstance implements Action {
  readonly type = TRY_OPEN_PROCESS_INSTANCE;
}

export class OpenProcessInstanceSuccess implements ActionWithPayload<ProcessInstance> {
  readonly type = OPEN_PROCESS_INSTANCE_SUCCESS;
  constructor(public payload: ProcessInstance) {}
}

export class RootToProcessExecutioner implements Action {
  readonly type = ROOT_TO_PROCESS_EXECUTIONER;
}

export class TryUpdateDiagram implements ActionWithPayload<GoalProcessDiagram> {
  readonly type = TRY_UPDATE_DIAGRAM;
  constructor(public payload: GoalProcessDiagram) {}
}
export class UpdateDiagramSuccess implements ActionWithPayload<GoalProcessDiagram> {
  readonly type = UPDATE_DIAGRAM_SUCCESS;
  constructor(public payload: GoalProcessDiagram) {}
}
export class UpdateDiagramError implements Action {
  readonly type = UPDATE_DIAGRAM_ERROR;
}

export type GoalDesignerActions =
  | TryAddStep
  | TryAddStepSuccess
  | TryAddStepError
  | TryUpdateStepOrder
  | UpdateStepOrderSuccess
  | UpdateStepOrderError
  | TrySetSelectedStep
  | TryDeleteSelectedStep
  | DeleteSelectedStepSuccess
  | DeleteSelectedStepError
  | TryRenameSelectedStep
  | RenameSelectedStepSuccess
  | RenameSelectedStepError
  | TryLoadStep
  | LoadStepSuccess
  | LoadStepError
  | TryFindDiagram
  | FindDiagramSuccess
  | FindDiagramError
  | ResetDesigner
  | OpenProcessInstanceSuccess
  | OpenProcessInstanceError
  | TryCreateNewProcessInstnace
  | CreateNewProcessInstnaceSuccess
  | CreateNewProcessInstnaceSuccess
  | TryResumeProcessInstnace
  | ResumeProcessInstnaceSuccess
  | ResumeProcessInstnaceError
  | RootToProcessExecutioner
  | SetLoadedStepSelected
  | TryUpdateDiagramSelection
  | UpdateDiagramSelectionSuccess
  | StartTrackingRouteChanges
  | StopTrackingRouteChanges
  | UpdateDiagramSelectionError
  | NavigateToStep
  | DeselectStep
  | StartAddingStep
  | CancelAddingStep
  | UpdateViewOnlyMode
  | LoadViewOnlyMode
  | TryUpdateDiagram
  | UpdateDiagramSuccess
  | UpdateDiagramError
  | TryUpdateStepHeaderData
  | UpdateStepHeaderDataSuccess
  | UpdateStepHeaderDataError
  | TryAddCharacteristic
  | AddCharacteristicSuccess
  | AddCharacteristicError
  | TryUpdateCharacteristic
  | UpdateCharacteristicSuccess
  | UpdateCharacteristicError
  | TryRemoveCharacteristic
  | RemoveCharacteristicSuccess
  | RemoveCharacteristicError;
