import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewIFrameComponent } from './view-iframe.component';

@Component({
  selector: 'view-audio-dialog',
  template: ' <view-iframe [src]="data.src"></view-iframe> ',
  styles: [''],
  standalone: true,
  imports: [ViewIFrameComponent],
})
export class ViewIFrameDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { src: string }) {}
}
