import { Action } from '@ngrx/store';
import { ActionWithPayload } from '../../../../../../shared';
import { IRestResponse, IRestError } from 'app/core/models/rest.model';
import { ProcessInstance } from '../../process-result/process-instance.model';

export const TRY_LOAD_INITIAL_INSTANCE_LIST = '[KPI Result List Loading Parameters] Try load initial diagrams';
export const TRY_LOAD_FILTERED_INSTANCE_LIST = '[KPI Result Filter/Search] Load filtered instance list';
export const TRY_LOAD_SORTED_INSTANCE_LIST = '[KPI Result Filter/Search] Load sorted instance list';

export const TRY_GET_INSTANCE_NEXT_PAGE = '[KPI Result List Infinite Scroll] Try get instance next page';
export const GET_INSTANCE_NEXT_PAGE_SUCCESS = '[KPI Result Api Response] Get instnace next page success';
export const GET_INSTANCE_NEXT_PAGE_ERROR = '[KPI Result Api Response] Get instance next page error';

export const SET_SELECTED_INSTANCE = '[KPI Result List Selection] Set selected instance';

export const TRY_UPDATE_INSTANCE = '[KPI Result Form Update and Toggle Deleted] Try Update instance';
export const UPDATE_INSTANCE_SUCCESS = '[KPI Result Api Response] Update instance success';
export const UPDATE_INSTANCE_ERROR = '[KPI Result Api Response] Update instance error';

export const TOGGLE_DIAGRAM_DELETED =
  '[KPI Result Toggle Deleted Header Button] Updated deleted flag in selected instance';
export const TRY_REMOVE_INSTANCE = '[KPI Result Details Remove Button] Try Remove instance';
export const REMOVE_INSTANCE_SUCCESS = '[KPI Result Api Response] Remove instance success';
export const REMOVE_INSTANCE_ERROR = '[KPI Result Api Response] Remove instance error';

export const TRY_ROUTE_UPDATE_INSTANCE_DATA = '[KPI Result Route Change] Try load route instance';
export const ROUTE_UPDATE_INSTANCE_DATA_SUCCESS = '[KPI Result Route Api Response] route instance success';
export const ROUTE_UPDATE_INSTANCE_DATA_ERROR = '[KPI Result Route Api Response] route instance error';

export const TRY_START_KPI_INSTANCE = '[KPI Result Play button] Try start KPI instance.';
export const START_KPI_INSTANCE_SUCCESS = '[KPI Result Api response] Start KPI instance success.';
export const START_KPI_INSTANCE_ERROR = '[KPI Result Api Response] Start KPI instance error.';

export const START_TRACKING_ROUTE_CHANGES = '[KPI Result On Init] Start tracking route changes.';
export const STOP_TRACKING_ROUTE_CHANGES = '[KPI Result On Destroy] Stop tracking route changes.';

export const START_TRACKING_LANG_CHANGES = '[KPI Result On Init] Start tracking lang changes.';
export const STOP_TRACKING_LANG_CHANGES = '[KPI Result On Destroy] Stop tracking lang changes.';

export const RESET_STATE = '[KPI Result On Destroy] Restart state';
export class ResetState implements Action {
  readonly type = RESET_STATE;
}

export class StartTrackingRouteChanges implements Action {
  readonly type = START_TRACKING_ROUTE_CHANGES;
}

export class StopTrackingRouteChanges implements Action {
  readonly type = STOP_TRACKING_ROUTE_CHANGES;
}

export class StartTrackingLangChanges implements Action {
  readonly type = START_TRACKING_LANG_CHANGES;
}

export class StopTrackingLangChanges implements Action {
  readonly type = STOP_TRACKING_LANG_CHANGES;
}

export class StartKPIInstanceSuccess implements ActionWithPayload<ProcessInstance> {
  readonly type = START_KPI_INSTANCE_SUCCESS;
  constructor(public payload: ProcessInstance) {}
}

export class TryToggleSelectedInstanceDeleted implements Action {
  readonly type = TOGGLE_DIAGRAM_DELETED;
}

export class TryStartKPIInstance implements Action {
  readonly type = TRY_START_KPI_INSTANCE;
}
export class StartKPIInstanceError implements Action {
  readonly type = START_KPI_INSTANCE_ERROR;
}

export class TryLoadInitialInstanceList implements Action {
  readonly type = TRY_LOAD_INITIAL_INSTANCE_LIST;
}
export class TryLoadFilteredInstanceList implements Action {
  readonly type = TRY_LOAD_FILTERED_INSTANCE_LIST;
}

export class TryLoadSortedInstanceList implements Action {
  readonly type = TRY_LOAD_SORTED_INSTANCE_LIST;
}

export class TryRouteUpdateInstanceData implements Action {
  readonly type = TRY_ROUTE_UPDATE_INSTANCE_DATA;
}
export class RouteUpdateInstanceDataSucces implements ActionWithPayload<string> {
  readonly type = ROUTE_UPDATE_INSTANCE_DATA_SUCCESS;
  constructor(public payload: string) {}
}

export class TryGetInstanceNextPage implements Action {
  readonly type = TRY_GET_INSTANCE_NEXT_PAGE;
}
export class GetInstanceNextPageSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_INSTANCE_NEXT_PAGE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class GetInstanceNextPageError implements ActionWithPayload<IRestError> {
  readonly type = GET_INSTANCE_NEXT_PAGE_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryUpdateInstance implements ActionWithPayload<ProcessInstance> {
  readonly type = TRY_UPDATE_INSTANCE;
  constructor(public payload: ProcessInstance) {}
}
export class UpdateInstanceSuccess implements ActionWithPayload<ProcessInstance> {
  readonly type = UPDATE_INSTANCE_SUCCESS;
  constructor(public payload: ProcessInstance) {}
}
export class UpdateInstanceError implements ActionWithPayload<IRestError> {
  readonly type = UPDATE_INSTANCE_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryRemoveSelectedInstance implements Action {
  readonly type = TRY_REMOVE_INSTANCE;
}

export class RemoveInstanceSuccess implements Action {
  readonly type = REMOVE_INSTANCE_SUCCESS;
}
export class RemoveInstanceError implements ActionWithPayload<IRestError> {
  readonly type = REMOVE_INSTANCE_ERROR;
  constructor(public payload: IRestError) {}
}

export class SetSelectedInstance implements ActionWithPayload<string> {
  readonly type = SET_SELECTED_INSTANCE;
  constructor(public payload: string) {}
}

export type KPIRActions =
  | TryGetInstanceNextPage
  | GetInstanceNextPageSuccess
  | StartTrackingRouteChanges
  | StopTrackingRouteChanges
  | StartTrackingLangChanges
  | StopTrackingLangChanges
  | GetInstanceNextPageError
  | TryUpdateInstance
  | UpdateInstanceSuccess
  | UpdateInstanceError
  | TryRemoveSelectedInstance
  | RemoveInstanceSuccess
  | RemoveInstanceError
  | SetSelectedInstance
  | TryLoadInitialInstanceList
  | TryLoadFilteredInstanceList
  | TryLoadSortedInstanceList
  | TryRouteUpdateInstanceData
  | RouteUpdateInstanceDataSucces
  | TryStartKPIInstance
  | StartKPIInstanceSuccess
  | StartKPIInstanceError
  | TryToggleSelectedInstanceDeleted
  | ResetState;
