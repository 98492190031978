import { Component, Input } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { MatIcon } from '@angular/material/icon';
import { MatDialogClose } from '@angular/material/dialog';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'view-iframe',
  templateUrl: './view-iframe.component.html',
  styleUrls: ['./view-iframe.component.scss'],
  standalone: true,
  imports: [MatIconButton, MatDialogClose, MatIcon],
})
export class ViewIFrameComponent {
  @Input() hideClose = false;

  thumbnailUrl: SafeResourceUrl;
  url: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  @Input() set src(src: string) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(src);
  }
}
