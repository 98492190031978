import { Action } from '@ngrx/store';
import { CommentData } from 'app/core/components/comments/comment.model';
import { OrderBy } from 'app/core/components/header-bar/header-buttons/order-by-list-button/order-by.model';
import { IRestError } from 'app/core/models/rest.model';
import { ActionWithPayload } from 'app/shared';
import { ScheduledJob } from '@zero/angular-ui/zero-scheduler';
import { BoardSearchCriteria } from '../../../dashboard/board/models/board-search-criteria.model';
import { Project } from '../../../project-manager/model/project.model';
import { Task, TaskDragDTO, TaskStatus } from '../../task.model';

export const TRY_ADD_SCRUMBOARD_TASK = '[SB Task Dialog] Try add task';
export const ADD_SCRUMBOARD_TASK_SUCCESS = '[SB Api Response] Add task success';
export const ADD_SCRUMBOARD_TASK_ERROR = '[SB Api Response] Add task error';

export const TRY_GET_INITIAL_SCRUMBOARD_TASKS = '[SB Component Init] Try get scrumboard tasks';
export const TRY_GET_FILTERED_SCRUMBOARD_TASKS = '[SB Component Filter] Try get filtered scrumboard tasks';
export const TRY_GET_SEARCHED_SCRUMBOARD_TASKS = '[SB Component Text Search] Try get searched scrumboard tasks';
export const TRY_GET_ORDERED_SCRUMBOARD_TASKS = '[SB Component Order By] Try get ordered scrumboard tasks';

export const TRY_GET_SCRUMBOARD_TASKS = '[SB Component] Try get scrumboard tasks';
export const GET_SCRUMBOARD_TASKS_SUCCESS = '[SB Api Response] Get scrumboard tasks success';
export const GET_SCRUMBOARD_TASKS_ERROR = '[SB Api Response] Get scrumboard tasks error';

export const GET_SCRUMBOARD_TASK_COUNT = '[SB Api Response] Get scrumboard task count success';

export const TRY_DRAG_TASK = '[SB Task Column] Try drag task';
export const DRAG_TASK_SUCCESS = '[SB Api Response] Drag task success';
export const APPLY_DRAG_UPDATE = '[SB Socket Response] Drag task from socket drag data';

export const DRAG_TASK_ERROR = '[SB Api Response] Drag task error';

export const TRY_ARCHIVE_DONE_TASKS = '[SB Done Task Column] Try archive done tasks.';
export const ARCHIVE_DONE_TASKS_SUCCESS = '[SB Api Response] Archive done tasks success.';
export const ARCHIVE_DONE_TASKS_ERROR = '[SB Api Response] Archive done tasks error.';

export const TRY_ARCHIVE_RELEASED_TASKS = '[SB Released Task Column] Try archive released tasks.';
export const ARCHIVE_RELEASED_TASKS_SUCCESS = '[SB Api Response] Archive released tasks success.';
export const ARCHIVE_RELEASED_TASKS_ERROR = '[SB Api Response] Archive released tasks error.';

export const RESET_SCRUMBOARD_TASKS = '[SB Component] Reset scrumboard tasks';

export const START_LISTEN_TO_DRAG_CHANGES = '[SB Socket] Listen to drag changes.';
export const STOP_LISTENING_TO_DRAG_CHANGES = '[SB Socket] Stop listening to drag changes.';

export const START_LISTEN_TO_SCRUMBOARD_TASK_ADDED_CHANGES = '[SB Socket] Listen to scrumboard task added changes.';
export const STOP_LISTENING_TO_SCRUMBOARD_TASK_ADDED_CHANGES =
  '[SB Socket] Stop listening to scrumboard task added changes.';

export const START_LISTEN_TO_SCRUMBOARD_TASK_DELETED_CHANGES = '[SB Socket] Listen to scrumboard task deleted changes.';
export const STOP_LISTENING_TO_SCRUMBOARD_TASK_DELETED_CHANGES =
  '[SB Socket] Stop listening to scrumboard task deleted changes.';

export const TRY_FIND_MISSING_DRAGGED_TASK = '[SB Socket] Try load missing dragged task';
export const FIND_MISSING_DRAGGED_TASK_SUCCESS = '[SB Socket] Load missing dragged task success';
export const FIND_MISSING_DRAGGED_TASK_ERROR = '[SB Socket] Load missing dragged task error';

export const ADD_RECEIVED_SCRUMBOARD_TASK = '[SB Socket] Add new task';
export const REMOVE_RECEIVED_SCRUMBOARD_TASKS = '[SB Socket] Remove task list from socket res';

export const TRY_UPDATE_TASK_FROM_SCRUMBOARD = '[SB Detail View] Try Update task';
export const UPDATE_TASK_SUCCESS_FROM_SCRUMBOARD = '[SB Api Response] Update task success';
export const UPDATE_TASK_ERROR_FROM_SCRUMBOARD = '[SB Api Response] Update task error';
export const SKIP_TASK_UPDATE_FROM_SCRUMBOARD = '[SB Task Effects] Skip task update';

export const UPDATE_TASK_FIELDS_FROM_SCRUMBOARD = '[SB Socket Response] Update task fields from scrumboard';

export const TRY_UPDATE_COMMENTS_FROM_SCRUMBOARD = '[SB Task Comments] Try update comments';
export const UPDATE_COMMENTS_FROM_SCRUMBOARD_SUCCESS = '[SB Task Comments] Update comments success';
export const UPDATE_COMMENTS_FROM_SCRUMBOARD_ERROR = '[SB Task Comments] Update comments error';

export const TRY_REMOVE_TASK = '[SB Detail View] TRY Remove task';
export const REMOVE_TASK_SUCCESS = '[SB Api Response] Remove task success';
export const REMOVE_TASK_ERROR = '[SB Api Response] Remove task error';

export const FIND_TASK_SUCCESS = '[SB] Find task success';
export const TRY_FIND_TASK = '[SB] Try find task';
export const FIND_TASK_ERROR = '[SB] Find task error';

export const START_TASK_CREATION = '[SB Add Button] Set selected task new';

export const SET_ORDER_BY = '[SB Sort Dropdown] Set sorting parameters';

export const DESELECT_TASK = '[SB] Deselect current task';

export const TRY_UPDATE_EFFECTIVENESS_COMMENTS = '[SB Task Effectiveness Comments] Try update effectivness comments';
export const UPDATE_EFFECTIVENESS_COMMENTS_SUCCESS =
  '[SB Task Effectiveness Comments] Update effectivness comments success';
export const UPDATE_EFFECTIVENESS_COMMENTS_ERROR =
  '[SB Task Effectiveness Comments] Update effectivness comments error';

export const TRY_NAVIGATE_TO_TASK_SOURCE = '[SB Details Source Link Button] Try navigate to the task source.';
export const TRY_NAVIGATE_TO_DASHBOARD_FROM_TASK_SOURCE = '[SB Effects] Navigate to a dashboard from a task source.';
export const NAVIGATE_TO_PROJECT_FROM_TASK_SOURCE = '[SB Effects] Navigate to a project from a task source.';
export const NAVIGATE_TO_DASHBOARD_FROM_TASK_SOURCE_SUCCESS =
  '[SB Api Response] Navigate to a dashboard from a task source success.';
export const NAVIGATE_TO_DASHBOARD_FROM_TASK_SOURCE_ERROR =
  '[SB Api Response] ] Navigate to a dashboard from a task source error.';

export const TRY_TOGGLE_TASK_DELETED = '[SB Move To Deleted Button] Try toggle task deleted.';
export const TOGGLE_TASK_DELETED_SUCCESS = '[SB Api Response] Toggle task deleted success.';
export const TOGGLE_TASK_DELETED_ERROR = '[SB Api Response]  Toggle task deleted error.';

export const TRY_LOAD_SCHEDULED_JOB = '[SB Effects] Try load scheduled job for the selected task';
export const LOAD_SCHEDULED_JOB_SUCCESS = '[SB Api Response] Load scheduled job for the selected task success';
export const LOAD_SCHEDULED_JOB_ERROR = '[SB Api Response] Load scheduled job for the selected task error';

export const TRY_SCHEDULE_TASK = '[SB Scheduled Checkbox True] Try schedule task';
export const SCHEDULE_TASK_SUCCESS = '[SB Api Response] Schedule task success';
export const SCHEDULE_TASK_ERROR = '[SB Api Response] Schedule task error';

export const TRY_UPDATE_SCHEDULE_JOB = '[SB Scheduled Form Update] Try update scheduled job';
export const UPDATE_SCHEDULE_JOB_SUCCESS = '[SB Api Response] Update scheduled job success';
export const UPDATE_SCHEDULE_JOB_ERROR = '[SB Api Response] Update scheduled job error';

export const TRY_PAUSE_JOB = '[SB Scheduled Checkbox false] Try pause scheduled job';
export const PAUSE_JOB_SUCCESS = '[SB Api Response] Pause scheduled job success';
export const PAUSE_JOB_ERROR = '[SB Api Response] Pause scheduled job error';

export const START_SCRUMBOARD_WITH_PROJECT = '[SB Init with Project] Set base project';
export const CLEAR_SCRUMBOARD_PROJECT = '[SB Breadcrumb header close button click] Clear project';

export class TryGetFilteredScrumboardTasks implements Action {
  readonly type = TRY_GET_FILTERED_SCRUMBOARD_TASKS;
}

export class TryGetInitialScrumboardTasks implements Action {
  readonly type = TRY_GET_INITIAL_SCRUMBOARD_TASKS;
}
export class TryGetSearchedScrumboardTasks implements Action {
  readonly type = TRY_GET_SEARCHED_SCRUMBOARD_TASKS;
}
export class TryGetOrderedScrumboardTasks implements ActionWithPayload<OrderBy> {
  readonly type = TRY_GET_ORDERED_SCRUMBOARD_TASKS;
  constructor(public payload: OrderBy) {}
}

export class ClearScrumboardProject implements Action {
  readonly type = CLEAR_SCRUMBOARD_PROJECT;
}

export class StartScrumboardWithProject implements ActionWithPayload<Project> {
  readonly type = START_SCRUMBOARD_WITH_PROJECT;
  constructor(public payload: Project) {}
}

export class TryPauseScheduleTask implements Action {
  readonly type = TRY_PAUSE_JOB;
}

export class PauseScheduleTaskSuccess implements ActionWithPayload<ScheduledJob> {
  readonly type = PAUSE_JOB_SUCCESS;
  constructor(public payload: ScheduledJob) {}
}
export class PauseScheduleTaskError implements Action {
  readonly type = PAUSE_JOB_ERROR;
}

export class TryUpdateScheduledJob implements ActionWithPayload<ScheduledJob> {
  readonly type = TRY_UPDATE_SCHEDULE_JOB;
  constructor(public payload: ScheduledJob) {}
}

export class UpdateScheduledJobSuccess implements ActionWithPayload<ScheduledJob> {
  readonly type = UPDATE_SCHEDULE_JOB_SUCCESS;
  constructor(public payload: ScheduledJob) {}
}
export class UpdateScheduledJobError implements Action {
  readonly type = UPDATE_SCHEDULE_JOB_ERROR;
}

export class TryScheduleTask implements Action {
  readonly type = TRY_SCHEDULE_TASK;
}

export class ScheduleTaskSuccess implements ActionWithPayload<ScheduledJob> {
  readonly type = SCHEDULE_TASK_SUCCESS;
  constructor(public payload: ScheduledJob) {}
}
export class ScheduleTaskError implements Action {
  readonly type = SCHEDULE_TASK_ERROR;
}

export class TryLoadScheduledJob implements Action {
  readonly type = TRY_LOAD_SCHEDULED_JOB;
}

export class LoadScheduledJobSuccess implements ActionWithPayload<ScheduledJob> {
  readonly type = LOAD_SCHEDULED_JOB_SUCCESS;
  constructor(public payload: ScheduledJob) {}
}
export class LoadScheduledJobError implements Action {
  readonly type = LOAD_SCHEDULED_JOB_ERROR;
}

export class TryToggleTaskDeleted implements ActionWithPayload<Task> {
  readonly type = TRY_TOGGLE_TASK_DELETED;
  constructor(public payload: Task) {}
}

export class ToggleTaskDeletedSuccess implements ActionWithPayload<Task> {
  readonly type = TOGGLE_TASK_DELETED_SUCCESS;
  constructor(public payload: Task) {}
}

export class ToggleTaskDeletedError implements ActionWithPayload<Task> {
  readonly type = TOGGLE_TASK_DELETED_ERROR;
  constructor(public payload: Task) {}
}
export class TryNavigateToTaskSource implements Action {
  readonly type = TRY_NAVIGATE_TO_TASK_SOURCE;
}

export class TryNavigateToDashboardFromTaskSource implements ActionWithPayload<{ criteria: BoardSearchCriteria }> {
  readonly type = TRY_NAVIGATE_TO_DASHBOARD_FROM_TASK_SOURCE;
  constructor(public payload: { criteria: BoardSearchCriteria }) {}
}

export class TryNavigateToProjectFromTaskSource implements ActionWithPayload<{ projectId: string }> {
  readonly type = NAVIGATE_TO_PROJECT_FROM_TASK_SOURCE;
  constructor(public payload: { projectId: string }) {}
}
export class NavigateToTaskSourceSuccess implements Action {
  readonly type = NAVIGATE_TO_DASHBOARD_FROM_TASK_SOURCE_SUCCESS;
}
export class NavigateToTaskSourceError implements Action {
  readonly type = NAVIGATE_TO_DASHBOARD_FROM_TASK_SOURCE_ERROR;
}

export class TryUpdateEffectivenessCommentsFromScrumboard implements ActionWithPayload<CommentData> {
  readonly type = TRY_UPDATE_EFFECTIVENESS_COMMENTS;
  constructor(public payload: CommentData) {}
}

export class UpdateEffectivenessCommentsFromScrumboardSuccess
  implements
    ActionWithPayload<{
      id: string;
      status: TaskStatus;
      comments: CommentData[];
    }>
{
  readonly type = UPDATE_EFFECTIVENESS_COMMENTS_SUCCESS;
  constructor(public payload: { id: string; status: TaskStatus; comments: CommentData[] }) {}
}

export class UpdateEffectivenessCommentsFromScrumboardError implements Action {
  readonly type = UPDATE_EFFECTIVENESS_COMMENTS_ERROR;
}

export class DeselectTask implements Action {
  readonly type = DESELECT_TASK;
}

export class SetOrderBy implements ActionWithPayload<OrderBy> {
  readonly type = SET_ORDER_BY;
  constructor(public payload: OrderBy) {}
}

export class StartTaskCreation implements Action {
  readonly type = START_TASK_CREATION;
}

export class TryFindTask implements ActionWithPayload<string> {
  readonly type = TRY_FIND_TASK;
  constructor(public payload: string) {}
}
export class FindTaskSuccess implements ActionWithPayload<Task> {
  readonly type = FIND_TASK_SUCCESS;
  constructor(public payload: Task) {}
}
export class FindTaskError implements Action {
  readonly type = FIND_TASK_ERROR;
}

export class TryRemoveTask implements ActionWithPayload<Task> {
  readonly type = TRY_REMOVE_TASK;
  constructor(public payload: Task) {}
}
export class RemoveTaskSuccess implements ActionWithPayload<string> {
  readonly type = REMOVE_TASK_SUCCESS;
  constructor(public payload: string) {}
}

export class RemoveTaskError implements ActionWithPayload<IRestError> {
  readonly type = REMOVE_TASK_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryUpdateCommentsFromScrumboard implements ActionWithPayload<CommentData> {
  readonly type = TRY_UPDATE_COMMENTS_FROM_SCRUMBOARD;
  constructor(public payload: CommentData) {}
}

export class UpdateCommentsFromScrumboardSuccess
  implements
    ActionWithPayload<{
      id: string;
      status: TaskStatus;
      comments: CommentData[];
    }>
{
  readonly type = UPDATE_COMMENTS_FROM_SCRUMBOARD_SUCCESS;
  constructor(public payload: { id: string; status: TaskStatus; comments: CommentData[] }) {}
}

export class UpdateCommentsFromScrumboardError implements Action {
  readonly type = UPDATE_COMMENTS_FROM_SCRUMBOARD_ERROR;
}

export class TryUpdateTaskScrumboard implements ActionWithPayload<Task> {
  readonly type = TRY_UPDATE_TASK_FROM_SCRUMBOARD;
  constructor(public payload: Task) {}
}
export class UpdateTaskScrumboardSuccess implements ActionWithPayload<{ [field: string]: any }> {
  readonly type = UPDATE_TASK_SUCCESS_FROM_SCRUMBOARD;
  constructor(public payload: { [field: string]: any }) {}
}
export class UpdateTaskScrumboardError implements ActionWithPayload<IRestError> {
  readonly type = UPDATE_TASK_ERROR_FROM_SCRUMBOARD;
  constructor(public payload: IRestError) {}
}

export class SkipScrumboardTaskUpdate implements Action {
  readonly type = SKIP_TASK_UPDATE_FROM_SCRUMBOARD;
}

export class StartListeningToAddedScrumboardTasks implements Action {
  readonly type = START_LISTEN_TO_SCRUMBOARD_TASK_ADDED_CHANGES;
}
export class StopListeningToAddedScrumboardTasks implements Action {
  readonly type = STOP_LISTENING_TO_SCRUMBOARD_TASK_ADDED_CHANGES;
}

export class StartListeningToDeletedScrumboardTasks implements Action {
  readonly type = START_LISTEN_TO_SCRUMBOARD_TASK_DELETED_CHANGES;
}
export class StopListeningToDeletedScrumboardTasks implements Action {
  readonly type = STOP_LISTENING_TO_SCRUMBOARD_TASK_DELETED_CHANGES;
}

export class RemoveRecievedScrumboardTask implements ActionWithPayload<string[]> {
  readonly type = REMOVE_RECEIVED_SCRUMBOARD_TASKS;
  constructor(public payload: string[]) {}
}
export class AddRecievedScrumboardTask implements ActionWithPayload<Task> {
  readonly type = ADD_RECEIVED_SCRUMBOARD_TASK;
  constructor(public payload: Task) {}
}
export class TryAddScrumboardTask implements ActionWithPayload<Task> {
  readonly type = TRY_ADD_SCRUMBOARD_TASK;
  constructor(public payload: Task) {}
}

export class GetScrumBoardTaskCount implements ActionWithPayload<any> {
  readonly type = GET_SCRUMBOARD_TASK_COUNT;
  constructor(public payload: any) {}
}
export class AddScrumboardTaskSuccess implements ActionWithPayload<Task> {
  readonly type = ADD_SCRUMBOARD_TASK_SUCCESS;
  constructor(public payload: Task) {}
}
export class AddScrumboardTaskError implements Action {
  readonly type = ADD_SCRUMBOARD_TASK_ERROR;
}

export class StartListeningToDragChanges implements Action {
  readonly type = START_LISTEN_TO_DRAG_CHANGES;
}

export class StopListeningToDragChanges implements Action {
  readonly type = STOP_LISTENING_TO_DRAG_CHANGES;
}

export class TryFindMissingDraggedTask implements ActionWithPayload<TaskDragDTO> {
  readonly type = TRY_FIND_MISSING_DRAGGED_TASK;
  constructor(public payload: TaskDragDTO) {}
}
export class FindMissingDraggedTaskSuccess implements ActionWithPayload<{ dragData: TaskDragDTO; task: Task }> {
  readonly type = FIND_MISSING_DRAGGED_TASK_SUCCESS;
  constructor(public payload: { dragData: TaskDragDTO; task: Task }) {}
}
export class FindMissingDraggedTaskError implements Action {
  readonly type = FIND_MISSING_DRAGGED_TASK_ERROR;
}

export class ResetScrumboard implements Action {
  readonly type = RESET_SCRUMBOARD_TASKS;
}

export class TryDragTask implements ActionWithPayload<TaskDragDTO> {
  readonly type = TRY_DRAG_TASK;
  constructor(public payload: TaskDragDTO) {}
}

export class DragTaskSuccess implements ActionWithPayload<TaskDragDTO> {
  readonly type = DRAG_TASK_SUCCESS;
  constructor(public payload: TaskDragDTO) {}
}

export class ApplyDragUpdate implements ActionWithPayload<TaskDragDTO> {
  readonly type = APPLY_DRAG_UPDATE;
  constructor(public payload: TaskDragDTO) {}
}

export class DragTaskError implements ActionWithPayload<IRestError> {
  readonly type = DRAG_TASK_ERROR;
  constructor(public payload: IRestError) {}
}

export class UpdateTaskFieldsFromScrumboard implements ActionWithPayload<Partial<Task>> {
  readonly type = UPDATE_TASK_FIELDS_FROM_SCRUMBOARD;
  constructor(public payload: Partial<Task>) {}
}

export class TryArchiveDoneTasks implements ActionWithPayload<string[]> {
  readonly type = TRY_ARCHIVE_DONE_TASKS;
  constructor(public payload: string[]) {}
}

export class ArchiveDoneTasksSuccess implements ActionWithPayload<string[]> {
  readonly type = ARCHIVE_DONE_TASKS_SUCCESS;
  constructor(public payload: string[]) {}
}

export class ArchiveDoneTasksError implements Action {
  readonly type = ARCHIVE_DONE_TASKS_ERROR;
}

export class TryArchiveReleasedTasks implements ActionWithPayload<string[]> {
  readonly type = TRY_ARCHIVE_RELEASED_TASKS;
  constructor(public payload: string[]) {}
}

export class ArchiveReleasedTasksSuccess implements ActionWithPayload<string[]> {
  readonly type = ARCHIVE_RELEASED_TASKS_SUCCESS;
  constructor(public payload: string[]) {}
}

export class ArchiveReleasedTasksError implements Action {
  readonly type = ARCHIVE_RELEASED_TASKS_ERROR;
}

export class TryGetScrumboardTasks implements ActionWithPayload<{ status: TaskStatus }> {
  readonly type = TRY_GET_SCRUMBOARD_TASKS;
  constructor(public payload: { status: TaskStatus }) {}
}

export class GetScrumboardTasksSuccess
  implements ActionWithPayload<{ status: TaskStatus; page: number; tasks: Task[] }>
{
  readonly type = GET_SCRUMBOARD_TASKS_SUCCESS;
  constructor(public payload: { status: TaskStatus; page: number; tasks: Task[] }) {}
}

export class GetScrumboardTasksError implements ActionWithPayload<string> {
  readonly type = GET_SCRUMBOARD_TASKS_ERROR;
  constructor(public payload: string) {}
}

export type SBActions =
  | GetScrumboardTasksSuccess
  | GetScrumboardTasksError
  | GetScrumBoardTaskCount
  | TryArchiveDoneTasks
  | ArchiveDoneTasksSuccess
  | ArchiveDoneTasksError
  | TryDragTask
  | DragTaskSuccess
  | DragTaskError
  | ResetScrumboard
  | TryNavigateToTaskSource
  | TryNavigateToProjectFromTaskSource
  | TryNavigateToDashboardFromTaskSource
  | NavigateToTaskSourceSuccess
  | NavigateToTaskSourceError
  | StartListeningToDragChanges
  | StopListeningToDragChanges
  | StartListeningToDragChanges
  | StopListeningToDragChanges
  | TryFindMissingDraggedTask
  | FindMissingDraggedTaskSuccess
  | FindMissingDraggedTaskError
  | TryAddScrumboardTask
  | AddScrumboardTaskSuccess
  | AddScrumboardTaskError
  | AddRecievedScrumboardTask
  | StartListeningToAddedScrumboardTasks
  | StopListeningToAddedScrumboardTasks
  | StartListeningToDeletedScrumboardTasks
  | StopListeningToDeletedScrumboardTasks
  | RemoveRecievedScrumboardTask
  | TryUpdateTaskScrumboard
  | UpdateTaskScrumboardSuccess
  | UpdateTaskScrumboardError
  | SkipScrumboardTaskUpdate
  | UpdateTaskFieldsFromScrumboard
  | TryUpdateCommentsFromScrumboard
  | UpdateCommentsFromScrumboardSuccess
  | UpdateCommentsFromScrumboardError
  | TryFindTask
  | FindTaskSuccess
  | FindTaskError
  | TryRemoveTask
  | RemoveTaskError
  | RemoveTaskSuccess
  | StartTaskCreation
  | SetOrderBy
  | DeselectTask
  | TryGetScrumboardTasks
  | ApplyDragUpdate
  | TryArchiveReleasedTasks
  | ArchiveReleasedTasksSuccess
  | ArchiveReleasedTasksError
  | TryUpdateEffectivenessCommentsFromScrumboard
  | UpdateEffectivenessCommentsFromScrumboardSuccess
  | UpdateEffectivenessCommentsFromScrumboardError
  | TryToggleTaskDeleted
  | ToggleTaskDeletedSuccess
  | ToggleTaskDeletedError
  | TryLoadScheduledJob
  | LoadScheduledJobSuccess
  | LoadScheduledJobError
  | TryScheduleTask
  | ScheduleTaskSuccess
  | ScheduleTaskError
  | TryUpdateScheduledJob
  | UpdateScheduledJobSuccess
  | UpdateScheduledJobError
  | TryPauseScheduleTask
  | PauseScheduleTaskSuccess
  | PauseScheduleTaskError
  | StartScrumboardWithProject
  | ClearScrumboardProject
  | TryGetInitialScrumboardTasks
  | TryGetFilteredScrumboardTasks
  | TryGetSearchedScrumboardTasks
  | TryGetOrderedScrumboardTasks;
