import { Action } from '@ngrx/store';
import { ActionWithPayload } from 'app/shared';
import { Part } from '../model/part.model';

export const TRY_GET_PART_LIST = '[Part on init] Try get part list';
export const TRY_GET_FILTERED_PART_LIST = '[Part filter change] Try get part list';
export const TRY_GET_NEXT_PART_PAGE = '[Part list on scroll] Try get next part page';
export const GET_PARTS_SUCCESS = '[API Response] Get parts success';
export const GET_PARTS_ERROR = '[API Response Error] Get parts error';

export const TRY_FIND_PART = '[Part list item click] Try get part';
export const FIND_PART_SUCCESS = '[API Response] Get part success';
export const FIND_PART_ERROR = '[API Response Error] Get part error';

export const TRY_UPDATE_PART = '[Part details form update] Try update part';
export const UPDATE_PART_SUCCESS = '[API Response] Update part success';
export const UPDATE_PART_ERROR = '[API Response Error] Update part error';

export const START_PART_CREATION = '[Add button click] Start user creation';
export const TRY_CREATE_PART = '[Part details form submit] Try create part';
export const CREATE_PART_SUCCESS = '[API Response] Create part success';
export const CREATE_PART_ERROR = '[API Response Error] Create part error';

export const TRY_REMOVE_SELECTED_PART = '[Remove part button] Try remove part';
export const REMOVE_SELECTED_PART_SUCCESS = '[API Response] Remove part success';
export const REMOVE_SELECTED_PART_ERROR = '[API Response Error] Remove part error';

export const DESELECT_PART = '[Part Details Back Arrow] Clear part selection';

export const RESET_STATE = '[Part on destroy] Restart state';

export class DeselectPart implements Action {
  readonly type = DESELECT_PART;
}

export class TryGetPartList implements Action {
  readonly type = TRY_GET_PART_LIST;
}

export class TryGetFilteredPartList implements Action {
  readonly type = TRY_GET_FILTERED_PART_LIST;
}

export class TryGetNextPartPage implements Action {
  readonly type = TRY_GET_NEXT_PART_PAGE;
}

export class GetPartsSuccess implements ActionWithPayload<Part[]> {
  readonly type = GET_PARTS_SUCCESS;
  constructor(public payload: Part[]) {}
}

export class GetPartsError implements Action {
  readonly type = GET_PARTS_ERROR;
}

export class TryFindPart implements ActionWithPayload<string> {
  readonly type = TRY_FIND_PART;
  constructor(public payload: string) {}
}

export class FindPartSuccess implements ActionWithPayload<Part> {
  readonly type = FIND_PART_SUCCESS;
  constructor(public payload: Part) {}
}

export class FindPartError implements Action {
  readonly type = FIND_PART_ERROR;
}

export class TryUpdatePart implements ActionWithPayload<Part> {
  readonly type = TRY_UPDATE_PART;
  constructor(public payload: Part) {}
}

export class UpdatePartSuccess implements ActionWithPayload<Part> {
  readonly type = UPDATE_PART_SUCCESS;
  constructor(public payload: Part) {}
}

export class UpdatePartError implements Action {
  readonly type = UPDATE_PART_ERROR;
}

export class StartPartCreation implements Action {
  readonly type = START_PART_CREATION;
}

export class TryCreatePart implements ActionWithPayload<Part> {
  readonly type = TRY_CREATE_PART;
  constructor(public payload: Part) {}
}

export class CreatePartSuccess implements ActionWithPayload<Part> {
  readonly type = CREATE_PART_SUCCESS;
  constructor(public payload: Part) {}
}

export class CreatePartError implements Action {
  readonly type = CREATE_PART_ERROR;
}

export class TryRemoveSelectedPart implements Action {
  readonly type = TRY_REMOVE_SELECTED_PART;
}

export class RemoveSelectedPartSuccess implements ActionWithPayload<string> {
  readonly type = REMOVE_SELECTED_PART_SUCCESS;
  constructor(public payload: string) {}
}

export class RemoveSelectedPartError implements Action {
  readonly type = REMOVE_SELECTED_PART_ERROR;
}

export class ResetState implements Action {
  readonly type = RESET_STATE;
}

export type PartActions =
  | TryGetPartList
  | TryGetFilteredPartList
  | TryGetNextPartPage
  | GetPartsSuccess
  | GetPartsError
  | TryFindPart
  | FindPartSuccess
  | FindPartError
  | TryUpdatePart
  | UpdatePartSuccess
  | UpdatePartError
  | StartPartCreation
  | TryCreatePart
  | CreatePartSuccess
  | CreatePartError
  | TryRemoveSelectedPart
  | RemoveSelectedPartSuccess
  | RemoveSelectedPartError
  | DeselectPart
  | ResetState;
