import { Injectable } from '@angular/core';

import { FileManagerUtil } from './file-manager.util';
import { AppUtils } from 'app/core/utils/app.utils';

import { DocumentStore, DocumentVersion, FileData } from './document.model';
import { fixRotation } from 'fix-image-rotation';

import * as cloneDeep from 'lodash.clonedeep';

const MAX_FILE_SIZE = 100 * 1024 * 1024;
@Injectable({ providedIn: 'root' })
export class FileHandlerService {
  validateUploadFiles(uploadedData) {
    let files = [],
      hasErrors = false;

    Array.from(uploadedData).forEach((file: any) => {
      if (file.size < MAX_FILE_SIZE) files.push(file);
      else hasErrors = true;
    });

    return { files, hasErrors };
  }

  prepareFileData(file): Promise<FileData> {
    return new Promise<FileData>((resolve) => {
      const fileData: FileData = new FileData();
      fileData.name = file.name;
      fileData.size = file.size;
      fileData.docContentType = file.type;
      fileData.type = this.setType(file.type);
      fileData.extension = file.type !== 'link' ? file.name.substr(file.name.lastIndexOf('.') + 1) : '';
      fileData.thumbnail = '';

      if (this.shouldSkipRotationFix(fileData.docContentType)) {
        this.toBase64(file, (base64Data) => {
          fileData.doc = base64Data;
          resolve(fileData);
        });
        return;
      }

      fixRotation([file]).then((correctedFiles) => {
        this.toBase64(correctedFiles[0], (base64Data) => {
          fileData.doc = base64Data;
          resolve(fileData);
        });
      });
    });
  }

  prepareUploadFiles(files, parentDocument?: DocumentStore): Promise<DocumentStore[]> {
    return new Promise<DocumentStore[]>((resolve) => {
      const sendItems = [];

      files.forEach((file) => {
        const docStore = {
          ...new DocumentStore(),
          currentVersion: {
            ...new DocumentVersion(),
            fileData: {
              ...new FileData(),
              name: file.name,
              size: file.size,
              docContentType: file.type,
              type: this.setType(file.type),
              extension: file.name.substr(file.name.lastIndexOf('.') + 1),
              thumbnail: '',
            },
          },
          parent: parentDocument ?? null,
        };

        if (this.shouldSkipRotationFix(docStore?.currentVersion?.fileData?.docContentType)) {
          this.toBase64(file, (base64Data) => {
            const newDocStore = cloneDeep(docStore);
            newDocStore.currentVersion.fileData.doc = base64Data;
            sendItems.push(newDocStore);
            if (sendItems.length === files.length) resolve(sendItems);
          });
          return;
        }

        fixRotation([file]).then((correctedFiles) => {
          this.toBase64(correctedFiles[0], (base64Data) => {
            const newDocStore = cloneDeep(docStore);
            newDocStore.currentVersion.fileData.doc = base64Data;
            sendItems.push(newDocStore);
            if (sendItems.length === files.length) resolve(sendItems);
          });
        });
      });
    });
  }

  download(file) {
    if (!file) return;
    const fileName = this.getDownloadFileName(file);
    const docFile: DocumentStore = FileManagerUtil.convertObjectToDocument(file);
    const index = docFile?.documentVersions?.findIndex((version) => version.status === 'RELEASED');
    const doc =
      index && index === -1 ? docFile?.currentVersion?.fileData?.doc : docFile.documentVersions[index].fileData.doc;
    this.downloadFile(fileName, doc);
  }

  downloadDocumentVersion(docVersion: DocumentVersion) {
    if (!docVersion) return;
    const fileName = this.getDownloadFileName(docVersion?.fileData);
    this.downloadFile(fileName, docVersion?.fileData?.doc);
  }

  downloadFile(fileName: string, b64data: any) {
    const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    const blob = AppUtils.b64toBlob(b64data, { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const windowNavigator = window.navigator as any;
    if (windowNavigator.msSaveOrOpenBlob) {
      windowNavigator.msSaveBlob(blob, fileName);
      return;
    }

    if (isIOS) {
      window.open(url, '_blank');
      return;
    }

    const a = document.createElement('a');
    document.body.appendChild(a);

    a.href = url;
    a.download = fileName;
    a.target = '_self';
    a.click();
  }

  private setType(docContentType: string) {
    if (!docContentType) return 'unknown';

    if (docContentType.includes('mp4')) return 'video';
    if (docContentType.includes('ogg')) return 'video';
    if (docContentType.includes('video/quicktime')) return 'video';
    if (docContentType.includes('webm')) return 'video';

    if (docContentType.includes('apng')) return 'image';
    if (docContentType.includes('bmp')) return 'image';
    if (docContentType.includes('gif')) return 'image';
    if (docContentType.includes('x-icon')) return 'image';
    if (docContentType.includes('png')) return 'image';
    if (docContentType.includes('jpeg')) return 'image';
    if (docContentType.includes('svg')) return 'image';
    if (docContentType.includes('webp')) return 'image';

    if (docContentType.includes('mpeg')) return 'audio';
    if (docContentType.includes('wav')) return 'audio';
    if (docContentType.includes('ogg')) return 'audio';

    if (docContentType.includes('text')) return 'file';
    if (docContentType.includes('officedocument')) return 'file';
    if (docContentType.includes('msword')) return 'file';
    if (docContentType.includes('ms-excel')) return 'file';
    if (docContentType.includes('ms-powerpoint')) return 'file';
    if (docContentType.includes('csv')) return 'file';
    if (docContentType.includes('pdf')) return 'file';

    return 'unknown';
  }

  private getDownloadFileName(file): string {
    const fileName: string = file.name || file?.currentVersion?.fileData?.name;
    const fileExtension: string = file.extension || file?.currentVersion?.fileData?.extension;
    if (fileName.split('.').length > 1) return fileName;
    return fileName + '.' + fileExtension;
  }

  private shouldSkipRotationFix(docContentType: string): boolean {
    return (
      !docContentType.includes('image') ||
      docContentType.includes('x-wmf') ||
      docContentType.includes('jpeg') ||
      docContentType.includes('jpg') ||
      docContentType.includes('svg') ||
      docContentType.includes('bmp') ||
      docContentType.includes('gif') ||
      docContentType.includes('png') ||
      docContentType.includes('tiff')
    );
  }

  private toBase64(file, cb) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e: any) => {
      const base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
      cb(base64Data);
    };
  }
}
