/* eslint-disable max-lines-per-function */
import {
  RoleActions,
  SET_SELECTED_ROLE_EDIT,
  SET_SELECTED_ROLE_NEW,
  SET_SELECTED_RIGHTS,
  SET_SELECTED_RIGHTS_PARENT_KEY,
  ADD_KEY_TO_BREADCRUMB,
  REMOVE_KEY_FROM_BREADCRUMB,
  ENABLE_EDIT_MODE,
  DISABLE_EDIT_MODE,
  TRY_GET_ALL_ROLES,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_ERROR,
  TRY_GET_ROLE_LIST,
  GET_ROLE_LIST_SUCCESS,
  GET_ROLE_LIST_ERROR,
  TRY_ADD_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_ERROR,
  TRY_UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  ROLE_UPDATED,
  TRY_REMOVE_ROLE,
  REMOVE_ROLE_SUCCESS,
  REMOVE_ROLE_ERROR,
  SET_QUERY_PARAMS,
  TRY_GET_ROLE_NEXT_PAGE,
  GET_ROLE_NEXT_PAGE_ERROR,
  GET_ROLE_NEXT_PAGE_SUCCESS,
  GET_ALL_PERMISSIONS_SUCCESS,
} from './role.actions';

import { ITEMS_PER_PAGE } from '../../../../../../shared';
import { Authority, Role } from '../role.model';

export interface State {
  allRoles: Role[];
  selectedRole: Role;
  selectedRights: any;
  selectedRightsParentKey: string;
  breadcrumb: string[];
  editMode: boolean;
  totalItemsCount: any;
  queryParams: any;
  loading: boolean;
  allPermissions: Authority[];
}

const initialState: State = {
  allRoles: [],
  selectedRole: null,
  selectedRights: null,
  selectedRightsParentKey: null,
  breadcrumb: [],
  editMode: false,
  totalItemsCount: null,
  loading: false,
  allPermissions: null,
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: 'id,desc',
  },
};

export function RoleReducer(state = initialState, action: RoleActions) {
  switch (action.type) {
    case SET_SELECTED_ROLE_EDIT:
      return {
        ...state,
        selectedRole: action.payload,
        editMode: true,
      };
    case SET_SELECTED_ROLE_NEW:
      return {
        ...state,
        selectedRole: action.payload,
        editMode: false,
      };
    case SET_SELECTED_RIGHTS:
      return {
        ...state,
        selectedRights: action.payload,
      };
    case SET_SELECTED_RIGHTS_PARENT_KEY:
      return {
        ...state,
        selectedRightsParentKey: action.payload,
      };
    case ADD_KEY_TO_BREADCRUMB:
      return {
        ...state,
        breadcrumb: [...state.breadcrumb, action.payload],
      };
    case REMOVE_KEY_FROM_BREADCRUMB: {
      if (!action.payload || state.breadcrumb.indexOf(action.payload) < 0) {
        if (action.payload === null) {
          return {
            ...state,
            breadcrumb: [],
          };
        }
        return { ...state };
      }
      const index = state.breadcrumb.indexOf(action.payload);
      return {
        ...state,
        breadcrumb: [...state.breadcrumb.slice(0, index)],
      };
    }
    case ENABLE_EDIT_MODE:
      return {
        ...state,
        editMode: true,
      };
    case DISABLE_EDIT_MODE:
      return {
        ...state,
        editMode: false,
      };
    case TRY_GET_ALL_ROLES:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        allRoles: action.payload.data,
        selectedRole: null,
        editMode: true,
        loading: false,
      };
    case GET_ALL_ROLES_ERROR:
      return {
        ...state,
        restError: action.payload,
        loading: false,
      };
    case TRY_GET_ROLE_LIST:
      return {
        ...state,
        allRoles: [],
        selectedRole: null,
        loading: true,
      };
    case GET_ROLE_LIST_SUCCESS:
      return {
        ...state,
        allRoles: action.payload.data,
        totalItemsCount: action.payload.totalItems,
        selectedRole: null,
        editMode: false,
        loading: false,
      };
    case GET_ROLE_LIST_ERROR:
      return {
        ...state,
        restError: action.payload,
        loading: false,
      };
    case TRY_GET_ROLE_NEXT_PAGE:
      return {
        ...state,
        loading: true,
      };
    case GET_ROLE_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        allRoles: [...state.allRoles, ...action.payload.data],
        totalItemsCount: action.payload.totalItems,
        loading: false,
      };
    case GET_ROLE_NEXT_PAGE_ERROR:
      return {
        ...state,
        restError: action.payload,
        loading: false,
      };
    case TRY_ADD_ROLE:
      return {
        ...state,
        loading: true,
      };
    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        allRoles: [action.payload.data, ...state.allRoles],
        selectedRole: action.payload.data,
        editMode: true,
        loading: false,
      };
    case ADD_ROLE_ERROR:
      return {
        ...state,
        restError: action.payload,
        loading: false,
      };
    case TRY_UPDATE_ROLE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        allRoles: state.allRoles.map((item) => {
          return item.id === action.payload.data.id ? action.payload.data : item;
        }),
        selectedRole:
          state.selectedRole && state.selectedRole.id === action.payload.data.id
            ? action.payload.data
            : state.selectedRole,
        loading: false,
      };
    case UPDATE_ROLE_ERROR:
      return {
        ...state,
        restError: action.payload,
        loading: false,
      };
    case ROLE_UPDATED:
      return {
        ...state,
      };
    case TRY_REMOVE_ROLE:
      return {
        ...state,
      };
    case REMOVE_ROLE_SUCCESS:
      return {
        ...state,
        allRoles: state.allRoles.filter((item) => state.selectedRole.id !== item.id),
        selectedRole: null,
      };
    case REMOVE_ROLE_ERROR:
      return {
        ...state,
        restError: action.payload,
      };
    case SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: action.payload,
      };
    case GET_ALL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        allPermissions: action.payload,
      };
    default:
      return state;
  }
}

export const getAllRoles = (state: State) => state.allRoles;

export const getSelectedRole = (state: State) => state.selectedRole;

export const getSelectedRights = (state: State) => state.selectedRights;

export const getSelectedRightsParentKey = (state: State) => state.selectedRightsParentKey;

export const getBreadcrumb = (state: State) => state.breadcrumb;

export const getEditMode = (state: State) => state.editMode;

export const getTotalItemsCount = (state: State) => state.totalItemsCount;

export const getQueryParams = (state: State) => state.queryParams;

export const getLoading = (state: State) => state.loading;

export const getAllPermissions = (state: State) => state.allPermissions;
