import { LicenseDetails } from 'app/shared/license/licensedetails.model';

import {
  UserActions,
  SET_ACTIVE_USER_LICENSE_DETAILS,
  SET_ACTIVE_USER_LICENSE_DETAILS_SUCCESS,
  SET_ACTIVE_USER_LICENSE_DETAILS_ERROR,
  UPDATE_ACTIVE_USER_LICENSE_DETAILS,
  UPDATE_ACTIVE_USER_LICENSE_DETAILS_SUCCESS,
  UPDATE_ACTIVE_USER_LICENSE_DETAILS_ERROR,
  TRY_GET_ACTIVE_USER_LICENSE_DETAILS,
  GET_ACTIVE_USER_LICENSE_DETAILS_SUCCESS,
  GET_ACTIVE_USER_LICENSE_DETAILS_ERROR,
} from './license-manager.actions';

export interface State {
  activeUserLicenseDetails: LicenseDetails;
  loading: boolean;
}

const initialState: State = {
  activeUserLicenseDetails: null,
  loading: false,
};

export function licenseManagerReducer(state = initialState, action: UserActions): State {
  switch (action.type) {
    case SET_ACTIVE_USER_LICENSE_DETAILS:
      return {
        ...state,
        activeUserLicenseDetails: action.payload,
        loading: true,
      };
    case UPDATE_ACTIVE_USER_LICENSE_DETAILS:
    case TRY_GET_ACTIVE_USER_LICENSE_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case SET_ACTIVE_USER_LICENSE_DETAILS_SUCCESS:
    case UPDATE_ACTIVE_USER_LICENSE_DETAILS_SUCCESS:
    case GET_ACTIVE_USER_LICENSE_DETAILS_SUCCESS:
      return {
        ...state,
        activeUserLicenseDetails: action.payload.data,
        loading: false,
      };
    case SET_ACTIVE_USER_LICENSE_DETAILS_ERROR:
    case UPDATE_ACTIVE_USER_LICENSE_DETAILS_ERROR:
    case GET_ACTIVE_USER_LICENSE_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export const getActiveUserLicenseDetails = (state: State) => state.activeUserLicenseDetails;
export const getActiveUserLicenseLoading = (state: State) => state.loading;
