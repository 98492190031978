import { BaseModel } from 'app/core/base.model';
import { Filter } from '../filter/filter.model';

export class UserOptions extends BaseModel {
  constructor(
    public id?: string,
    public userId?: string,
    public options?: any,
    public activeFilters?: Filter[],
    public navigationOptions?: Record<string, boolean>,
  ) {
    super();
  }
}

export const globalObject = 'globalObject';
export const globalObjectIncoming = 'INCOMING_INSPECTION';
export const globalObjectSPC = 'SPC';

export const GlobalObjectField = {
  lastSelectedDiagram: 'lastSelectedDiagram',
  lastSelectedInstance: 'lastSelectedInstance',
  lastSelectedStep: 'lastSelectedStep',
  lastSelectedCharacteristic: 'lastSelectedCharacteristic',
  lastSelectedIndexInSample: 'lastSelectedIndexInSample',
};
