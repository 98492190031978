import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EmbeddedTrackerService {
  readonly isEmbedded$ = this.route.queryParams.pipe(map((queryParams) => queryParams?.embedded === 'true'));

  constructor(private route: ActivatedRoute) {}
}
