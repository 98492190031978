import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewAudioComponent } from './view-audio.component';

@Component({
  selector: 'view-audio-dialog',
  template: ' <view-audio [thumbnailBlobUrl]="data.thumbnailBlobUrl" [blobUrl]="data.blobUrl"></view-audio> ',
  styles: [''],
  standalone: true,
  imports: [ViewAudioComponent],
})
export class ViewAudioDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { blobUrl: string; thumbnailBlobUrl: string },
  ) {}
}
