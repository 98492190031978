<div id="view-content-wrapper">
  <div #cont id="content-container">
    <iframe [src]="url"></iframe>

    @if (!hideClose) {
      <button mat-icon-button class="mat-icon-button" mat-dialog-close autoplay>
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>
</div>
