import { Action } from '@ngrx/store';
import { ActionWithPayload } from 'app/shared';
import { Dashboard } from '../../board/models/board.model';

export const CLEAR_SELECTED_DASHBOARD = '[DASH_SETTINGS] Clear selected dashboard value';

export const TRY_LOAD_FILTERED_DASHBOARDS_FROM_ROUTE = '[Dashboard Settings filter route change] Update filter';
export const TRY_GET_NEXT_DASHBOARD_PAGE = '[Dashboard Settings On List Scroll] Try get next dashboard page';
export const TRY_GET_SEARCH_TEXT_DASHBOARD_LIST = '[Dashboard Settings Search Bar] Try get searched dashboards';
export const GET_DASHBOARD_LIST_SUCCESS = '[Dashboard Settings API Response] Get dashboard list success';
export const GET_DASHBOARD_LIST_ERROR = '[Dashboard Settings API Response] Get dashboard list error';

export const SET_SELECTED_DASHBOARD_NEW = '[Dashboard Settings Add Button] Set selected dashboard new';
export const SET_SELECTED_DASHBOARD_NEW_FROM_ROUTE =
  '[Dashboard Settings new route change] Change selected dashboard to new';

export const OPEN_FOLDER = '[Dashboard Settings Double click folder] Set folder parent';
export const SELECT_DASHBOARD = '[Dashboard Settings Selected list item] Change selected dashboard id route';
export const SET_SELECTED_DASHBOARD_FROM_ROUTE = '[Dashboard Settings Id route change] Change selected dashboard';
export const SELECT_BREADCRUMB_ITEM = '[Dashboard Settings Select Breadcrumb Item] Change selected dashboard';

export const SELECT_PARENT_DASHBOARD =
  '[Dashboard Settings Reroute to a different root dashboard] Get child dashboard list';

export const ROUTED_TO_ROOT_USER_DASHBOARDS =
  '[Dashboard Settings Rerouted to root null] Get dashboard list and deselect.';
export const SELECTED_ROUTED_LIST_DASHBOARD =
  '[Dashboard Settings Rerouted to a dashboard on the list] Change dashboard id selection';
export const SELECTED_ROUTED_SAME_PARENT_DASHBOARD =
  '[Dashboard Settings Rerouted to a dashboard not on the list with shared parent] Change dashboard id selection';
export const SELECTED_ROUTED_DIFFERENT_PARENT_DASHBOARD =
  '[Dashboard Settings Rerouted to a dashboard not on the list without shared parent] Change dashboard id selection';
export const SELECTED_ROUTED_DASHBOARD_NOT_FOUND =
  '[Dashboard Settings Rerouted to nonexisting dasbhoard] Rerouted dashboard cannot be found.';

export const TRY_ADD_DASHBOARD = '[Dashboard Settings Submit form] Try Add dashboard';
export const ADD_DASHBOARD_SUCCESS = '[Dashboard Settings API Response] Add dashboard success';
export const ADD_DASHBOARD_ERROR = '[Dashboard Settings API Response] Add dashboard error';

export const TRY_UPDATE_DASHBOARD = '[DASH_SETTINGS] Try Update dashboard';
export const UPDATE_DASHBOARD_SUCCESS = '[DASH_SETTINGS] Update dashboard success';
export const UPDATE_DASHBOARD_ERROR = '[DASH_SETTINGS] Update dashboard error';

export const TRY_REMOVE_SELECTED_DASHBOARD = '[Dashboard Settings Header Remove Button] Try Remove dashboard';
export const REMOVE_SELECTED_DASHBOARD_SUCCESS = '[Dashboard Settings API Response] Remove dashboard success';
export const REMOVE_SELECTED_DASHBOARD_ERROR = '[Dashboard Settings API Response] Remove dashboard error';

export const TRY_EXPORT_DASHBOARD = '[Dashboard Settings Header Export Button] Try export dashboard by Id';
export const EXPORT_DASHBOARDS_SUCCESS = '[Dashboard Settings API Response] Export dashboards success';
export const EXPORT_DASHBOARDS_ERROR = '[Dashboard Settings API Response] Export dashboards error';

export const TRY_IMPORT_DASHBOARDS = '[Dashboard Settings Header Import Button] Try import dashboards';
export const IMPORT_DASHBOARDS_SUCCESS = '[Dashboard Settings API Response] Import dashboards success';
export const IMPORT_DASHBOARDS_ERROR = '[Dashboard Settings API Response] Import dashboards error';

export const SET_ACTIVE_FILTER = '[Dashboard Settings Header Filter] Set active filter';

export const EMPTY_BREADCRUMB = '[Dashboard Settings Effects] Empty breadcrumb on parent id change to null';
export const BREADCRUMB_ALREADY_UPDATED =
  '[Dashboard Settings Effects] Skip breadcrumb on parent id since it was already updated';

export const GET_BREADCRUMB_SUCCESS = '[Dashboard Settings API Response] Get breadrumb success';
export const GET_BREADCRUMB_ERROR = '[Dashboard Settings API Response] Get breadrumb error';

export const TRY_DRAG_SORT_DASHBOARD = '[Dashboard Settings Drag and Drop] Try update list position';
export const DRAG_SORT_DASHBOARD_SUCCESS = '[Dashboard Settings API Response] Update list position success';
export const DRAG_SORT_DASHBOARD_ERROR = '[Dashboard Settings API Response] Update list position error';

export const TRY_GET_ROOT_DASHBOARD = '[Dashboard Init] Try get root dashboard';
export const GET_ROOT_DASHBOARD_SUCCESS = '[Dashboard API Response] Get root dashboard success';
export const GET_ROOT_DASHBOARD_ERROR = '[Dashboard API Response] Get root dashboard error';

export const OPEN_DASHBOARD_FOLDER = '[Dashboard Settings Header Button click] Open dashboard folder';

export const TRY_COPY_SELECTED_DASHBOARD = '[Dashboard Settings Header Copy Button] Try copy dashboard';
export const COPY_SELECTED_DASHBOARD_SUCCESS = '[Dashboard Settings API Response] Copy dashboard success';
export const COPY_SELECTED_DASHBOARD_ERROR = '[Dashboard Settings API Response] Copy dashboard error';

export class OpenDashboardFolder implements Action {
  readonly type = OPEN_DASHBOARD_FOLDER;
}

export class TryGetRootDashboard implements Action {
  readonly type = TRY_GET_ROOT_DASHBOARD;
}

export class GetRootDashboardSuccess implements Action {
  readonly type = GET_ROOT_DASHBOARD_SUCCESS;
  constructor(public payload: Dashboard) {}
}

export class GetRootDashboardError implements Action {
  readonly type = GET_ROOT_DASHBOARD_ERROR;
}

export class TryDragSortDashboard implements ActionWithPayload<{ index: number; dashboardId: string }> {
  readonly type = TRY_DRAG_SORT_DASHBOARD;
  constructor(public payload: { index: number; dashboardId: string }) {}
}

export class DragSortDashboardSuccess implements ActionWithPayload<{ index: number; dashboardId: string }> {
  readonly type = DRAG_SORT_DASHBOARD_SUCCESS;
  constructor(public payload: { index: number; dashboardId: string }) {}
}

export class DragSortDashboardError implements Action {
  readonly type = DRAG_SORT_DASHBOARD_ERROR;
}

export class SelectBreadcrumbItem implements ActionWithPayload<string | null> {
  readonly type = SELECT_BREADCRUMB_ITEM;
  constructor(public payload: string | null) {}
}

export class EmptyBreadcrumb implements Action {
  readonly type = EMPTY_BREADCRUMB;
}

export class BreadcrumbAlreadyUpdated implements Action {
  readonly type = BREADCRUMB_ALREADY_UPDATED;
}

export class GetBreadcrumbSuccess implements ActionWithPayload<Dashboard[]> {
  readonly type = GET_BREADCRUMB_SUCCESS;
  constructor(public payload: Dashboard[]) {}
}

export class GetBreadcrumbError implements Action {
  readonly type = GET_BREADCRUMB_ERROR;
}

export class SelectParentDashboard implements ActionWithPayload<string> {
  readonly type = SELECT_PARENT_DASHBOARD;
  constructor(public payload: string) {}
}

export class OpenFolder implements ActionWithPayload<string> {
  readonly type = OPEN_FOLDER;
  constructor(public payload: string) {}
}

export class RoutedToRootUserDashboards implements Action {
  readonly type = ROUTED_TO_ROOT_USER_DASHBOARDS;
}

export class SelectedRoutedListDashboard implements ActionWithPayload<string> {
  readonly type = SELECTED_ROUTED_LIST_DASHBOARD;
  constructor(public payload: string) {}
}

export class SelectedRoutedSameParentDashboard implements ActionWithPayload<Dashboard> {
  readonly type = SELECTED_ROUTED_SAME_PARENT_DASHBOARD;
  constructor(public payload: Dashboard) {}
}

export class SelectedRoutedDifferentParentDashboard implements ActionWithPayload<Dashboard> {
  readonly type = SELECTED_ROUTED_DIFFERENT_PARENT_DASHBOARD;
  constructor(public payload: Dashboard) {}
}

export class SelectedRoutedDashboardNotFound implements Action {
  readonly type = SELECTED_ROUTED_DASHBOARD_NOT_FOUND;
}

export class SetSelectedDashboardNewFromRoute implements ActionWithPayload<{ isParent: boolean }> {
  readonly type = SET_SELECTED_DASHBOARD_NEW_FROM_ROUTE;
  constructor(public payload: { isParent: boolean }) {}
}

export class SelectDashboard implements ActionWithPayload<string> {
  readonly type = SELECT_DASHBOARD;
  constructor(public payload: string) {}
}

export class SetSelectedDashboardFromRoute implements ActionWithPayload<string> {
  readonly type = SET_SELECTED_DASHBOARD_FROM_ROUTE;
  constructor(public payload: string) {}
}

/* Selected dashboard */
export class ClearSelectedDashboard implements Action {
  readonly type = CLEAR_SELECTED_DASHBOARD;
}

export class TryLoadFilteredDashboardsFromRoute implements ActionWithPayload<string> {
  readonly type = TRY_LOAD_FILTERED_DASHBOARDS_FROM_ROUTE;
  constructor(public payload: 'shared' | 'user' | 'strategy-canvas') {}
}

export class SetSelectedDashboardNew implements ActionWithPayload<{ isParent: boolean }> {
  readonly type = SET_SELECTED_DASHBOARD_NEW;
  constructor(public payload: { isParent: boolean }) {}
}

export class TryGetDashboardNextPage implements Action {
  readonly type = TRY_GET_NEXT_DASHBOARD_PAGE;
}

export class TryGetSearchedDashboardList implements Action {
  readonly type = TRY_GET_SEARCH_TEXT_DASHBOARD_LIST;
  constructor(public payload: string) {}
}

export class GetDashboardListSuccess implements ActionWithPayload<Dashboard[]> {
  readonly type = GET_DASHBOARD_LIST_SUCCESS;
  constructor(public payload: Dashboard[]) {}
}
export class GetDashboardListError implements Action {
  readonly type = GET_DASHBOARD_LIST_ERROR;
}

/* Add dashboard  */
export class TryAddDashboard implements Action {
  readonly type = TRY_ADD_DASHBOARD;
  constructor(public payload: Dashboard) {}
}
export class AddDashboardSuccess implements ActionWithPayload<Dashboard> {
  readonly type = ADD_DASHBOARD_SUCCESS;
  constructor(public payload: Dashboard) {}
}
export class AddDashboardError implements Action {
  readonly type = ADD_DASHBOARD_ERROR;
}

/* Update dashboard  */
export class TryUpdateDashboard implements ActionWithPayload<Dashboard> {
  readonly type = TRY_UPDATE_DASHBOARD;
  constructor(public payload: Dashboard) {}
}
export class UpdateDashboardSuccess implements ActionWithPayload<Dashboard> {
  readonly type = UPDATE_DASHBOARD_SUCCESS;
  constructor(public payload: Dashboard) {}
}
export class UpdateDashboardError implements Action {
  readonly type = UPDATE_DASHBOARD_ERROR;
}

/* Delete dashboard  */
export class TryRemoveSelectedDashboard implements Action {
  readonly type = TRY_REMOVE_SELECTED_DASHBOARD;
}

export class RemoveSelectedDashboardSuccess implements ActionWithPayload<string> {
  readonly type = REMOVE_SELECTED_DASHBOARD_SUCCESS;
  constructor(public payload: string) {}
}
export class RemoveSelectedDashboardError implements Action {
  readonly type = REMOVE_SELECTED_DASHBOARD_ERROR;
}

export class TryExportDashboards implements Action {
  readonly type = TRY_EXPORT_DASHBOARD;
}

export class ExportDashboardsSuccess implements ActionWithPayload<Dashboard[]> {
  readonly type = EXPORT_DASHBOARDS_SUCCESS;
  constructor(public payload: Dashboard[]) {}
}

export class ExportDashboardsError implements Action {
  readonly type = EXPORT_DASHBOARDS_ERROR;
}

export class TryImportDashboards implements ActionWithPayload<Dashboard[]> {
  readonly type = TRY_IMPORT_DASHBOARDS;
  constructor(public payload: Dashboard[]) {}
}

export class ImportDashboardsSuccess implements ActionWithPayload<Dashboard[]> {
  readonly type = IMPORT_DASHBOARDS_SUCCESS;
  constructor(public payload: Dashboard[]) {}
}

export class ImportDashboardsError implements Action {
  readonly type = IMPORT_DASHBOARDS_ERROR;
}

export class SetActiveFilter implements ActionWithPayload<'shared' | 'user' | 'strategy-canvas'> {
  readonly type = SET_ACTIVE_FILTER;
  constructor(public payload: 'shared' | 'user' | 'strategy-canvas') {}
}

export class TryCopySelectedDashboard implements Action {
  readonly type = TRY_COPY_SELECTED_DASHBOARD;
}

export class CopySelectedDashboardSuccess implements ActionWithPayload<Dashboard> {
  readonly type = COPY_SELECTED_DASHBOARD_SUCCESS;
  constructor(public payload: Dashboard) {}
}

export class CopySelectedDashboardError implements Action {
  readonly type = COPY_SELECTED_DASHBOARD_ERROR;
}

export type DashSettingsActions =
  | OpenDashboardFolder
  | BreadcrumbAlreadyUpdated
  | EmptyBreadcrumb
  | SelectBreadcrumbItem
  | SelectParentDashboard
  | OpenFolder
  | BreadcrumbAlreadyUpdated
  | SetSelectedDashboardNew
  | SetSelectedDashboardNewFromRoute
  | RoutedToRootUserDashboards
  | SelectedRoutedListDashboard
  | SelectedRoutedSameParentDashboard
  | SelectedRoutedDifferentParentDashboard
  | SelectedRoutedDashboardNotFound
  | SelectDashboard
  | SetSelectedDashboardFromRoute
  | TryLoadFilteredDashboardsFromRoute
  | ClearSelectedDashboard
  | SetSelectedDashboardNew
  | TryGetDashboardNextPage
  | TryGetSearchedDashboardList
  | GetDashboardListSuccess
  | GetDashboardListError
  | TryAddDashboard
  | AddDashboardSuccess
  | AddDashboardError
  | TryUpdateDashboard
  | UpdateDashboardSuccess
  | UpdateDashboardError
  | TryRemoveSelectedDashboard
  | RemoveSelectedDashboardSuccess
  | RemoveSelectedDashboardError
  | SetActiveFilter
  | TryDragSortDashboard
  | DragSortDashboardSuccess
  | DragSortDashboardError
  | GetBreadcrumbSuccess
  | GetBreadcrumbError
  | TryGetRootDashboard
  | GetRootDashboardSuccess
  | GetRootDashboardError
  | TryCopySelectedDashboard
  | CopySelectedDashboardSuccess
  | CopySelectedDashboardError
  | TryExportDashboards
  | ExportDashboardsSuccess
  | ExportDashboardsError
  | TryImportDashboards
  | ImportDashboardsSuccess
  | ImportDashboardsError;
