import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'custom-error-handler-snack-bar',
  templateUrl: 'custom-error-handler-snack-bar.component.html',
  styleUrls: ['./custom-error-handler-snack-bar.component.scss'],
  standalone: true,
})
export class CustomErrorHandlerSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { errorMessage: string }) {}
}
