import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionWithPayload } from 'app/shared';
import { switchMap, catchError, of, filter, tap, map, withLatestFrom } from 'rxjs';
import { GlobalOptions } from '../global-options.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromRoot from '../../../../../../app.reducer';
import * as TO from './global-options.actions';
import * as PROFILE from '../../../profile/store/profile.actions';
import { GlobalOptionsService } from '../global-options.service';

@Injectable()
export class GlobalOptionsEffects {
  loadGlobalOptions$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(TO.TRY_LOAD_GLOBAL_OPTIONS),
      switchMap(() =>
        this.globalOptionsService.find().pipe(
          map((options) => ({
            type: TO.LOAD_GLOBAL_OPTIONS_SUCCESS,
            payload: options,
          })),
          catchError(() => of({ type: TO.LOAD_GLOBAL_OPTIONS_ERROR })),
        ),
      ),
    ); },
  );

  updateGlobalOptions$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(TO.TRY_UPDATE_GLOBAL_OPTIONS),
      map((action: ActionWithPayload<GlobalOptions>) => action.payload),
      withLatestFrom(this.store$.select(fromRoot.getGlobalOptionsfromProfile)),
      switchMap(([updateOptions, currentOptions]) =>
        this.globalOptionsService.save({
          navigationOptions: {
            ...currentOptions.navigationOptions,
            ...updateOptions.navigationOptions,
          },
          translationsOptions: updateOptions.translationsOptions,
          inspectionOptions: updateOptions.inspectionOptions}).pipe(
          map((options) => ({
            type: TO.UPDATE_GLOBAL_OPTIONS_SUCCESS,
            payload: {
              options: {
                navigationOptions: {
                  ...currentOptions.navigationOptions,
                  ...updateOptions.navigationOptions,
                },
                translationsOptions: options.translationsOptions,
                inspectionOptions: options.inspectionOptions,
              },
            },
          })),
          catchError(() => of({ type: TO.UPDATE_GLOBAL_OPTIONS_ERROR })),
        ),
      ),
    ); },
  );

  reloadLanguagesOnGlobalOptionChanges$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(TO.UPDATE_GLOBAL_OPTIONS_SUCCESS),
        map(
          (action: ActionWithPayload<{ translationsStyleChanged: boolean }>) => action.payload.translationsStyleChanged,
        ),
        filter(Boolean),
        tap(() => {
          this.translate.getLangs().map((lang) => {
            if (lang === this.translate.currentLang) {
              this.translate.reloadLang(lang).subscribe((translations) => {
                this.translate.onTranslationChange.next({ lang, translations });
              });
            } else this.translate.resetLang(lang);
          });
        }),
      ); },
    { dispatch: false },
  );

  updateProfileGlobalOptions$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(TO.UPDATE_GLOBAL_OPTIONS_SUCCESS),
      map((action: ActionWithPayload<{ options: GlobalOptions }>) => action.payload.options),
      map((options) => ({
        type: PROFILE.UPDATE_GLOBAL_OPTIONS,
        payload: options,
      })),
    ); },
  );

  constructor(
    private actions$: Actions,
    private globalOptionsService: GlobalOptionsService,
    private translate: TranslateService,
    private store$: Store<fromRoot.State>,
  ) {}
}
