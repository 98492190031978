import { Action } from '@ngrx/store';
import { ActionWithPayload } from '../../../../../../shared';
import { Authority, Role } from '../role.model';
import { IRestResponse, IRestError } from 'app/core/models/rest.model';

export const SET_SELECTED_ROLE_EDIT = '[ROLE] Set selected role edit';

export const SET_SELECTED_ROLE_NEW = '[ROLE] Set selected role new';

export const SET_SELECTED_RIGHTS = '[ROLE] Set selected rights';
export const SET_SELECTED_RIGHTS_PARENT_KEY = '[ROLE] Set selected rights parent key';

export const ADD_KEY_TO_BREADCRUMB = '[ROLE] Add the key to the breadcrumb';
export const REMOVE_KEY_FROM_BREADCRUMB = '[ROLE] Remove given kay and all other keys to the end of the breadcrumb';

export const ENABLE_EDIT_MODE = '[ROLE] Enable edit mode for detail view';
export const DISABLE_EDIT_MODE = '[ROLE] Disable edit mode for detail view';

export const TRY_GET_ALL_ROLES = '[ROLE] Try get all roles list';
export const GET_ALL_ROLES_SUCCESS = '[ROLE] Get all roles success';
export const GET_ALL_ROLES_ERROR = '[ROLE] Get all roles error';

export const TRY_GET_ROLE_LIST = '[ROLE] Try get role list';
export const GET_ROLE_LIST_SUCCESS = '[ROLE] Get role list success';
export const GET_ROLE_LIST_ERROR = '[ROLE] Get role list error';

export const TRY_GET_ROLE_NEXT_PAGE = '[ROLE] Try get role next page';
export const GET_ROLE_NEXT_PAGE_SUCCESS = '[ROLE] Get role next page success';
export const GET_ROLE_NEXT_PAGE_ERROR = '[ROLE] Get role next page error';

export const TRY_ADD_ROLE = '[ROLE] Try Add role';
export const ADD_ROLE_SUCCESS = '[ROLE] Add role success';
export const ADD_ROLE_ERROR = '[ROLE] Add role error';

export const TRY_UPDATE_ROLE = '[ROLE] Try Update role';
export const UPDATE_ROLE_SUCCESS = '[ROLE] Update role success';
export const UPDATE_ROLE_ERROR = '[ROLE] Update role error';
export const ROLE_UPDATED = '[ROLE] Role is updated';

export const TRY_REMOVE_ROLE = '[ROLE] TRY Remove role';
export const REMOVE_ROLE_SUCCESS = '[ROLE] Remove role success';
export const REMOVE_ROLE_ERROR = '[ROLE] Remove role error';

export const SET_QUERY_PARAMS = '[ROLE] Set query params';

export const TRY_GET_ALL_PERMISSIONS = '[ROLE] Try get all permissions';
export const GET_ALL_PERMISSIONS_SUCCESS = '[ROLE] Get all permissions success';
export const GET_ALL_PERMISSIONS_ERROR = '[ROLE] Get all permissions error';

export class TryGetAllPermissions implements Action {
  readonly type = TRY_GET_ALL_PERMISSIONS;
  constructor() {}
}

export class GetAllPermissionsSuccess implements ActionWithPayload<Authority[]> {
  readonly type = GET_ALL_PERMISSIONS_SUCCESS;
  constructor(public payload: Authority[]) {}
}

export class GetAllPermissionsError implements ActionWithPayload<IRestError> {
  readonly type = GET_ALL_PERMISSIONS_ERROR;
  constructor(public payload: IRestError) {}
}

/* set selected role */
export class SetSelectedRoleEdit implements ActionWithPayload<Role> {
  readonly type = SET_SELECTED_ROLE_EDIT;
  constructor(public payload: Role) {}
}

export class SetSelectedRoleNew implements ActionWithPayload<Role> {
  readonly type = SET_SELECTED_ROLE_NEW;
  constructor(public payload: Role) {}
}

/* rights */
export class SetSelectedRights implements ActionWithPayload<any> {
  readonly type = SET_SELECTED_RIGHTS;
  constructor(public payload: any) {}
}

export class SetSelectedRightsParentKey implements ActionWithPayload<string> {
  readonly type = SET_SELECTED_RIGHTS_PARENT_KEY;
  constructor(public payload: string) {}
}

/* Breadcrumb */

export class AddKeyToBreadcrumb implements ActionWithPayload<string> {
  readonly type = ADD_KEY_TO_BREADCRUMB;
  constructor(public payload: string) {}
}

export class RemoveKeyFromBreadcrumb implements ActionWithPayload<string> {
  readonly type = REMOVE_KEY_FROM_BREADCRUMB;
  constructor(public payload: string) {}
}

/* role edti mode */
export class EnableEditMode implements Action {
  readonly type = ENABLE_EDIT_MODE;
}
export class DisableEditMode implements Action {
  readonly type = DISABLE_EDIT_MODE;
}
/* get all roles */
export class TryGetAllRoles implements Action {
  readonly type = TRY_GET_ALL_ROLES;
}
export class GetAllRolesSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_ALL_ROLES_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class GetAllRolesError implements ActionWithPayload<IRestError> {
  readonly type = GET_ALL_ROLES_ERROR;
  constructor(public payload: IRestError) {}
}

/* get role list */
export class TryGetRoleList implements Action {
  readonly type = TRY_GET_ROLE_LIST;
}
export class GetRoleListSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_ROLE_LIST_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class GetRoleListError implements ActionWithPayload<IRestError> {
  readonly type = GET_ROLE_LIST_ERROR;
  constructor(public payload: IRestError) {}
}

/* get role next page */
export class TryGetRoleNextPage implements Action {
  readonly type = TRY_GET_ROLE_NEXT_PAGE;
}
export class GetRoleNextPageSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_ROLE_NEXT_PAGE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class GetRoleNextPageError implements ActionWithPayload<IRestError> {
  readonly type = GET_ROLE_NEXT_PAGE_ERROR;
  constructor(public payload: IRestError) {}
}

/* add role  */
export class TryAddRole implements ActionWithPayload<Role> {
  readonly type = TRY_ADD_ROLE;
  constructor(public payload: Role) {}
}
export class AddRoleSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = ADD_ROLE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class AddRoleError implements ActionWithPayload<IRestError> {
  readonly type = ADD_ROLE_ERROR;
  constructor(public payload: IRestError) {}
}

/* update role  */
export class TryUpdateRole implements ActionWithPayload<Role> {
  readonly type = TRY_UPDATE_ROLE;
  constructor(public payload: Role) {}
}
export class UpdateRoleSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = UPDATE_ROLE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class UpdateRoleError implements ActionWithPayload<IRestError> {
  readonly type = UPDATE_ROLE_ERROR;
  constructor(public payload: IRestError) {}
}
export class RoleUpdated implements Action {
  readonly type = ROLE_UPDATED;
}

/* delete role  */
export class TryRemoveRole implements Action {
  readonly type = TRY_REMOVE_ROLE;
}

export class RemoveRoleSuccess implements Action {
  readonly type = REMOVE_ROLE_SUCCESS;
}
export class RemoveRoleError implements ActionWithPayload<IRestError> {
  readonly type = REMOVE_ROLE_ERROR;
  constructor(public payload: IRestError) {}
}

/* Query params */
export class SetQueryParams implements ActionWithPayload<any> {
  readonly type = SET_QUERY_PARAMS;
  constructor(public payload: any) {}
}

export type RoleActions =
  | SetSelectedRoleEdit
  | SetSelectedRoleNew
  | SetSelectedRights
  | SetSelectedRightsParentKey
  | AddKeyToBreadcrumb
  | RemoveKeyFromBreadcrumb
  | EnableEditMode
  | DisableEditMode
  | TryGetAllRoles
  | GetAllRolesSuccess
  | GetAllRolesError
  | TryGetRoleList
  | GetRoleListSuccess
  | GetRoleListError
  | TryGetRoleNextPage
  | GetRoleNextPageSuccess
  | GetRoleNextPageError
  | TryAddRole
  | AddRoleSuccess
  | AddRoleError
  | TryUpdateRole
  | UpdateRoleSuccess
  | UpdateRoleError
  | RoleUpdated
  | TryRemoveRole
  | RemoveRoleSuccess
  | RemoveRoleError
  | SetQueryParams
  | TryGetAllPermissions
  | GetAllPermissionsSuccess
  | GetAllPermissionsError;
