import { Action } from '@ngrx/store';
import { ActionWithPayload } from '../../../../../../shared/action.payload';
import { Catalog } from '../catalog.model';
import { IRestResponse } from 'app/core/models/rest.model';

export const TRY_FIND_CATALOG_LIBRARY_ITEM = '[Catalog dialog Api Route change] Try find catalog library item';
export const FIND_CATALOG_LIBRARY_ITEM_SUCCESS = '[Catalog dialog Api response] Find catalog library item success';
export const FIND_CATALOG_LIBRARY_ITEM_ERROR = '[Catalog dialog Api response] Find catalog library item error';

export const GET_CATALOG_LIST_SUCCESS = '[Catalog dialog] Get catalog list success';
export const GET_CATALOG_LIST_ERROR = '[Catalog dialog] Get catalog list error';

export const SET_ROOT_CATALOG = '[Catalog dialog] Set root catalog r';

export const TRY_FIND_CATALOG_NEXT_PAGE = '[Catalog dialog] Try get catalog next page';

export const TRY_LOAD_CATALOG_CHILDREN = '[Catalog dialog] Try load catalog children';
export const LOAD_CATALOG_CHILDREN = '[Catalog dialog] Load catalog children';
export const LOAD_CATALOG_CHILDREN_BREAK = '[Catalog dialog] Load catalog children break';

export const TRY_GET_CATALOG_CHILDREN_NEXT_PAGE = '[Catalog dialog ] Try get cat children next page';
export const GET_CATALOG_CHILDREN_NEXT_PAGE_SUCCESS = '[Catalog dialog ] Get cat children next page success';
export const GET_CATALOG_CHILDREN_NEXT_PAGE_ERROR = '[Catalog dialog ] Get cat children next page error';

export const TRY_NAVIGATE_TO_BREADCRUMB_CATALOG = '[Catalog dialog breadcrumb item] Navigate to breadcrumb catalog';
export const TRY_NAVIGATE_TO_BREADCRUMB_BASE = '[Catalog dialog Home Button Click ] Navigate to breadcrumb base';

export const TRY_GET_SEARCH_TEXT_FILTERED_CATALOGS =
  '[Catalog dialog Search Text Area] Try get search text filtered catalogs';

export const TRY_GET_SORTED_CATALOG_LIST = '[Catalog dialog Sort list] Try get filtered catalog list';

export const RESET_CATALOG_DIALOG_STATE = '[Catalog dialog] Reset the catalog state';

/* set catalog library item - specific catalog e.g. process cat, location cat...*/
export class TryFindCatalogLibraryItem implements Action {
  readonly type = TRY_FIND_CATALOG_LIBRARY_ITEM;
  constructor(public payload: any) {}
}

/* get catalog library item - specific catalog e.g. process cat, location cat...*/
export class FindCatalogLibraryItemSuccess implements ActionWithPayload<any> {
  readonly type = FIND_CATALOG_LIBRARY_ITEM_SUCCESS;
  constructor(public payload: any) {}
}

export class FindCatalogLibraryItemError implements Action {
  readonly type = FIND_CATALOG_LIBRARY_ITEM_ERROR;
}

export class GetCatalogListSuccess implements ActionWithPayload<any> {
  readonly type = GET_CATALOG_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCatalogListError implements Action {
  readonly type = GET_CATALOG_LIST_ERROR;
}
export class SetRootCatalog implements ActionWithPayload<Catalog> {
  readonly type = SET_ROOT_CATALOG;
  constructor(public payload: Catalog) {}
}

export class TryLoadCatalogChildren implements ActionWithPayload<Catalog> {
  readonly type = TRY_LOAD_CATALOG_CHILDREN;
  constructor(public payload: Catalog) {}
}

export class LoadCatalogChildren implements ActionWithPayload<Catalog> {
  readonly type = LOAD_CATALOG_CHILDREN;
  constructor(public payload: Catalog) {}
}

export class LoadCatalogChildrenBreak implements Action {
  readonly type = LOAD_CATALOG_CHILDREN_BREAK;
}
export class TryGetSearchTextFilterCatalogs implements ActionWithPayload<string> {
  readonly type = TRY_GET_SEARCH_TEXT_FILTERED_CATALOGS;
  constructor(public payload: string) {}
}

export class TryFindCatalogNextPage implements Action {
  readonly type = TRY_FIND_CATALOG_NEXT_PAGE;
}

export class ResetCatalogDialogState implements Action {
  readonly type = RESET_CATALOG_DIALOG_STATE;
}
export class TryGetSortedCatalogList
  implements
    ActionWithPayload<{
      value: string;
      label: string;
      direction: 'asc' | 'desc';
      isTextstore: boolean;
    }>
{
  readonly type = TRY_GET_SORTED_CATALOG_LIST;
  constructor(
    public payload: {
      value: string;
      label: string;
      direction: 'asc' | 'desc';
      isTextstore: boolean;
    },
  ) {}
}
export class TryNavigateToBreadcrumbCatalog implements ActionWithPayload<Catalog> {
  readonly type = TRY_NAVIGATE_TO_BREADCRUMB_CATALOG;
  constructor(public payload: Catalog) {}
}

export class TryNavigateToBreadcrumbBase implements Action {
  readonly type = TRY_NAVIGATE_TO_BREADCRUMB_BASE;
}

export class TryGetCatalogChildrenNextPage implements ActionWithPayload<Catalog> {
  readonly type = TRY_GET_CATALOG_CHILDREN_NEXT_PAGE;
  constructor(public payload: Catalog) {}
}

export class GetCatalogChildrenNextPageSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_CATALOG_CHILDREN_NEXT_PAGE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}

export class GetCatalogChildrenNextPageError implements Action {
  readonly type = GET_CATALOG_CHILDREN_NEXT_PAGE_ERROR;
}

export type CatalogDialogActions =
  | TryFindCatalogLibraryItem
  | FindCatalogLibraryItemError
  | FindCatalogLibraryItemSuccess
  | SetRootCatalog
  | TryLoadCatalogChildren
  | LoadCatalogChildren
  | LoadCatalogChildrenBreak
  | GetCatalogListSuccess
  | GetCatalogListError
  | TryFindCatalogNextPage
  | TryGetSearchTextFilterCatalogs
  | ResetCatalogDialogState
  | TryNavigateToBreadcrumbCatalog
  | TryGetSortedCatalogList
  | TryNavigateToBreadcrumbBase
  | TryGetCatalogChildrenNextPage
  | GetCatalogChildrenNextPageSuccess
  | GetCatalogChildrenNextPageError;
