import { BaseModel } from '../../core/base.model';
import { FileData } from 'app/main/content/apps/file-manager/document.model';
import { Authority, Role } from 'app/main/content/apps/administration/roles/role.model';

export class User extends BaseModel {
  constructor(
    public id?: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public activated?: boolean,
    public langKey?: string,
    public authorities: Authority[] = [],
    public password?: string,
    public status = 'NEW',
    public imageFileData?: FileData,
    public location?: string,
    public organisation?: string,
    public contactData?: UserContactData,
    public roles?: Role[],
    plantId?: string,
  ) {
    super(plantId);
  }
}

export class UserInfo {
  constructor(
    public id?: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public tenantId?: string,
    public tenantName?: string,
    public imageFileData?: FileData,
  ) {}
}

export interface UserContactData {
  emailData: EmailData[];
  phoneData: PhoneData[];
  addressData: AddressData[];
  socialMediaData: SocialMediaData[];
}

export interface EmailData {
  id: string;
  email: string;
  isActive: boolean;
}

export interface PhoneData {
  id: string;
  number: string;
  type: string;
}

export interface AddressData {
  id: string;
  address: string;
}

export interface SocialMediaData {
  id: string;
  url: string;
}
