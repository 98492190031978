import { Action } from '@ngrx/store';

import { ActionWithPayload } from '../../../../../../shared';
import { IRestResponse, IRestError } from 'app/core/models/rest.model';
import { LicenseDetails } from 'app/shared/license/licensedetails.model';

export const SET_ACTIVE_USER_LICENSE_DETAILS = '[LICENSE] Set active user license details';
export const SET_ACTIVE_USER_LICENSE_DETAILS_SUCCESS = '[LICENSE] Set active user license details success';
export const SET_ACTIVE_USER_LICENSE_DETAILS_ERROR = '[LICENSE] Set active user license details error';

export const UPDATE_ACTIVE_USER_LICENSE_DETAILS = '[LICENSE] Update active user license details';
export const UPDATE_ACTIVE_USER_LICENSE_DETAILS_SUCCESS = '[LICENSE] Update active user license details success';
export const UPDATE_ACTIVE_USER_LICENSE_DETAILS_ERROR = '[LICENSE] Update active user license details error';

export const TRY_GET_ACTIVE_USER_LICENSE_DETAILS = '[LICENSE] Try get active user license details';
export const GET_ACTIVE_USER_LICENSE_DETAILS_SUCCESS = '[LICENSE] Get active user license details success';
export const GET_ACTIVE_USER_LICENSE_DETAILS_ERROR = '[LICENSE] Get active user license details error';

export class SetActiveUserLicense implements ActionWithPayload<LicenseDetails> {
  readonly type = SET_ACTIVE_USER_LICENSE_DETAILS;
  constructor(public payload: LicenseDetails) {}
}
export class SetActiveUserLicenseDetailsSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = SET_ACTIVE_USER_LICENSE_DETAILS_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class SetActiveUserLicenseDetailsError implements ActionWithPayload<IRestError> {
  readonly type = SET_ACTIVE_USER_LICENSE_DETAILS_ERROR;
  constructor(public payload: IRestError) {}
}

export class UpdateActiveUserLicense implements ActionWithPayload<LicenseDetails> {
  readonly type = UPDATE_ACTIVE_USER_LICENSE_DETAILS;
  constructor(public payload: LicenseDetails) {}
}
export class UpdateActiveUserLicenseDetailsSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = UPDATE_ACTIVE_USER_LICENSE_DETAILS_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class UpdateActiveUserLicenseDetailsError implements ActionWithPayload<IRestError> {
  readonly type = UPDATE_ACTIVE_USER_LICENSE_DETAILS_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryGetActiveUserLicenseDetails implements Action {
  readonly type = TRY_GET_ACTIVE_USER_LICENSE_DETAILS;
}
export class GetActiveUserLicenseDetailsSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_ACTIVE_USER_LICENSE_DETAILS_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class GetActiveUserLicenseDetailsError implements ActionWithPayload<IRestError> {
  readonly type = GET_ACTIVE_USER_LICENSE_DETAILS_ERROR;
  constructor(public payload: IRestError) {}
}

export type UserActions =
  | SetActiveUserLicense
  | SetActiveUserLicenseDetailsSuccess
  | SetActiveUserLicenseDetailsError
  | UpdateActiveUserLicense
  | UpdateActiveUserLicenseDetailsSuccess
  | UpdateActiveUserLicenseDetailsError
  | TryGetActiveUserLicenseDetails
  | GetActiveUserLicenseDetailsSuccess
  | GetActiveUserLicenseDetailsError;
