import { Component, Input, OnDestroy } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { MatIcon } from '@angular/material/icon';
import { MatDialogClose } from '@angular/material/dialog';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'view-audio',
  templateUrl: './view-audio.component.html',
  styleUrls: ['./view-audio.component.scss'],
  standalone: true,
  imports: [MatIconButton, MatDialogClose, MatIcon],
})
export class ViewAudioComponent implements OnDestroy {
  @Input() hideClose = false;

  thumbnailUrl: SafeResourceUrl;
  url: SafeResourceUrl;

  private _audioBlobUrl: string;
  private _thumbnailBlobUrl: string;

  constructor(private sanitizer: DomSanitizer) {}

  get thumbnailBlobUrl() {
    return this._thumbnailBlobUrl;
  }

  get audioBlobUrl() {
    return this._audioBlobUrl;
  }

  @Input() set thumbnailBlobUrl(thumbnailBlobUrl: string) {
    this._thumbnailBlobUrl = thumbnailBlobUrl;
    if (!thumbnailBlobUrl) {
      this.thumbnailUrl = null;
      return;
    }
    this.thumbnailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(thumbnailBlobUrl);
  }
  @Input() set blobUrl(blobUrl: string) {
    this._audioBlobUrl = blobUrl;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
  }

  revokeThumbnail() {
    if (this.thumbnailBlobUrl) URL.revokeObjectURL(this.thumbnailBlobUrl);
  }

  ngOnDestroy(): void {
    URL.revokeObjectURL(this.audioBlobUrl);
  }
}
