import { Action } from '@ngrx/store';
import { ActionWithPayload } from 'app/shared';
import { InspectionEquipment } from '../model/inspection-equipment.model';
import { InspectionEquipmentView } from '../inspection-view';
import { Catalog } from '../../catalog/catalog/catalog.model';

export const TRY_GET_INSPECTION_EQUIPMENT_LIST = '[InspectionEquipment on init] Try get inspection equipment list';
export const TRY_GET_FILTERED_INSPECTION_EQUIPMENT_LIST = '[InspectionEquipment filter change] Try get inspection equipment list';
export const TRY_GET_NEXT_INSPECTION_EQUIPMENT_PAGE = '[InspectionEquipment list on scroll] Try get next inspection equipment page';
export const GET_INSPECTION_EQUIPMENTS_SUCCESS = '[API Response] Get inspection equipments success';
export const GET_INSPECTION_EQUIPMENTS_ERROR = '[API Response Error] Get inspection equipments error';

export const SELECT_INSPECTION_EQUIPMENT = '[InspectionEquipment list item click] Select inspection equipment';
export const FIND_INSPECTION_EQUIPMENT_SUCCESS = '[API Response] Get inspection equipment success';
export const FIND_INSPECTION_EQUIPMENT_ERROR = '[API Response Error] Get inspection equipment error';

export const TRY_UPDATE_INSPECTION_EQUIPMENT = '[InspectionEquipment details form update] Try update inspection equipment';
export const UPDATE_INSPECTION_EQUIPMENT_SUCCESS = '[API Response] Update inspection equipment success';
export const UPDATE_INSPECTION_EQUIPMENT_ERROR = '[API Response Error] Update inspection equipment error';

export const TRY_RELEASED_INSPECTION_EQUIPMENT = '[InspectionEquipment details form update] Try released inspection equipment';
export const RELEASED_INSPECTION_EQUIPMENT_SUCCESS = '[API Response] Released inspection equipment success';
export const RELEASED_INSPECTION_EQUIPMENT_ERROR = '[API Response Error] Released inspection equipment error';

export const TRY_LOCK_INSPECTION_EQUIPMENT = '[InspectionEquipment details form update] Try lock inspection equipment';
export const LOCK_INSPECTION_EQUIPMENT_SUCCESS = '[API Response] Lock inspection equipment success';
export const LOCK_INSPECTION_EQUIPMENT_ERROR = '[API Response Error] Lock inspection equipment error';

export const START_INSPECTION_EQUIPMENT_CREATION = '[Add button click] Start inspection equipment creation';
export const TRY_CREATE_INSPECTION_EQUIPMENT = '[InspectionEquipment details form submit] Try create inspection equipment';
export const CREATE_INSPECTION_EQUIPMENT_SUCCESS = '[API Response] Create inspection equipment success';
export const CREATE_INSPECTION_EQUIPMENT_ERROR = '[API Response Error] Create inspection equipment error';

export const TRY_REMOVE_SELECTED_INSPECTION_EQUIPMENT = '[Remove inspection equipment button] Try remove inspection equipment';
export const REMOVE_SELECTED_INSPECTION_EQUIPMENT_SUCCESS = '[API Response] Remove inspection equipment success';
export const REMOVE_SELECTED_INSPECTION_EQUIPMENT_ERROR = '[API Response Error] Remove inspection equipment error';

export const DESELECT_INSPECTION_EQUIPMENT = '[InspectionEquipment Details Back Arrow] Clear inspection equipment selection';

export const SET_DISPLAY_MODE = '[InspectionEquipment] Set display mode';

export const RESET_STATE = '[InspectionEquipment on destroy] Restart state';

export const INSPECTION_EQUIPMENT_ROUTE_CHANGE = '[Router] Inspection equipment route change';

export const INSPECTION_EQUIPMENT_EMPTY_ROUTE_CHANGE = '[Router] Inspection equipment empty route change';

export const FIND_MISSING_INSPECTION_EQUIPMENT = '[Router] Find missing inspection equipment';
export const FIND_MISSING_INSPECTION_EQUIPMENT_SUCCESS = '[Router] Find missing inspection equipment success';
export const FIND_MISSING_INSPECTION_EQUIPMENT_ERROR = '[Router] Find missing inspection equipment error';

export class FindMissingInspectionEquipment implements Action {
   readonly type = FIND_MISSING_INSPECTION_EQUIPMENT;
}

export class FindMissingInspectionEquipmentSuccess implements ActionWithPayload<InspectionEquipment> {
   readonly type = FIND_MISSING_INSPECTION_EQUIPMENT_SUCCESS;
   constructor(public payload: InspectionEquipment) {}
}

export class FindMissingInspectionEquipmentError implements Action {
   readonly type = FIND_MISSING_INSPECTION_EQUIPMENT_ERROR;
}

export class InspectionEquipmentEmptyRouteChange implements Action {
   readonly type = INSPECTION_EQUIPMENT_EMPTY_ROUTE_CHANGE;
}

export class InspectionEquipmentRouteChange implements ActionWithPayload<string> {
   readonly type = INSPECTION_EQUIPMENT_ROUTE_CHANGE;
   constructor(public payload: string) {}
}

export class DeselectInspectionEquipment implements Action {
   readonly type = DESELECT_INSPECTION_EQUIPMENT;
}

export class TryGetInspectionEquipmentList implements Action {
   readonly type = TRY_GET_INSPECTION_EQUIPMENT_LIST;
}

export class TryGetFilteredInspectionEquipmentList implements Action {
   readonly type = TRY_GET_FILTERED_INSPECTION_EQUIPMENT_LIST;
}

export class TryGetNextInspectionEquipmentPage implements Action {
   readonly type = TRY_GET_NEXT_INSPECTION_EQUIPMENT_PAGE;
}

export class GetInspectionEquipmentsSuccess implements ActionWithPayload<InspectionEquipment[]> {
   readonly type = GET_INSPECTION_EQUIPMENTS_SUCCESS;
   constructor(public payload: InspectionEquipment[]) {}
}

export class GetInspectionEquipmentsError implements Action {
   readonly type = GET_INSPECTION_EQUIPMENTS_ERROR;
}

export class SelectInspectionEquipment implements ActionWithPayload<string> {
   readonly type = SELECT_INSPECTION_EQUIPMENT;
   constructor(public payload: string) {}
}

export class FindInspectionEquipmentSuccess implements ActionWithPayload<InspectionEquipment> {
   readonly type = FIND_INSPECTION_EQUIPMENT_SUCCESS;
   constructor(public payload: InspectionEquipment) {}
}

export class FindInspectionEquipmentError implements Action {
   readonly type = FIND_INSPECTION_EQUIPMENT_ERROR;
}

export class TryUpdateInspectionEquipment implements ActionWithPayload<InspectionEquipment> {
   readonly type = TRY_UPDATE_INSPECTION_EQUIPMENT;
   constructor(public payload: InspectionEquipment) {}
}

export class UpdateInspectionEquipmentSuccess implements ActionWithPayload<InspectionEquipment> {
   readonly type = UPDATE_INSPECTION_EQUIPMENT_SUCCESS;
   constructor(public payload: InspectionEquipment) {}
}

export class UpdateInspectionEquipmentError implements Action {
   readonly type = UPDATE_INSPECTION_EQUIPMENT_ERROR;
}

export class StartInspectionEquipmentCreation implements Action {
   readonly type = START_INSPECTION_EQUIPMENT_CREATION;
}

export class TryCreateInspectionEquipment implements ActionWithPayload<InspectionEquipment> {
   readonly type = TRY_CREATE_INSPECTION_EQUIPMENT;
   constructor(public payload: InspectionEquipment) {}
}

export class CreateInspectionEquipmentSuccess implements ActionWithPayload<InspectionEquipment> {
   readonly type = CREATE_INSPECTION_EQUIPMENT_SUCCESS;
   constructor(public payload: InspectionEquipment) {}
}

export class CreateInspectionEquipmentError implements Action {
   readonly type = CREATE_INSPECTION_EQUIPMENT_ERROR;
}

export class TryRemoveSelectedInspectionEquipment implements Action {
   readonly type = TRY_REMOVE_SELECTED_INSPECTION_EQUIPMENT;
}

export class RemoveSelectedInspectionEquipmentSuccess implements ActionWithPayload<string> {
   readonly type = REMOVE_SELECTED_INSPECTION_EQUIPMENT_SUCCESS;
   constructor(public payload: string) {}
}

export class RemoveSelectedInspectionEquipmentError implements Action {
   readonly type = REMOVE_SELECTED_INSPECTION_EQUIPMENT_ERROR;
}

export class SetDisplayMode implements ActionWithPayload<InspectionEquipmentView> {
   readonly type = SET_DISPLAY_MODE;
   constructor(public payload: InspectionEquipmentView) {}
}

export class TryReleaseInspectionEquipment implements Action {
   readonly type = TRY_RELEASED_INSPECTION_EQUIPMENT;
}

export class ReleasedInspectionEquipmentSuccess implements ActionWithPayload<InspectionEquipment> {
   readonly type = RELEASED_INSPECTION_EQUIPMENT_SUCCESS;
   constructor(public payload: InspectionEquipment) {}
}

export class ReleasedInspectionEquipmentError implements Action {
   readonly type = RELEASED_INSPECTION_EQUIPMENT_ERROR;
}

export class TryLockInspectionEquipment implements ActionWithPayload<Catalog> {
   readonly type = TRY_LOCK_INSPECTION_EQUIPMENT;
   constructor(public payload: Catalog) {}
}

export class LockInspectionEquipmentSuccess implements ActionWithPayload<InspectionEquipment> {
   readonly type = LOCK_INSPECTION_EQUIPMENT_SUCCESS;
   constructor(public payload: InspectionEquipment) {}
}

export class LockInspectionEquipmentError implements Action {
   readonly type = LOCK_INSPECTION_EQUIPMENT_ERROR;
}

export class ResetState implements Action {
   readonly type = RESET_STATE;
}

export type InspectionEquipmentActions =
   | TryGetInspectionEquipmentList
   | TryGetFilteredInspectionEquipmentList
   | TryGetNextInspectionEquipmentPage
   | GetInspectionEquipmentsSuccess
   | GetInspectionEquipmentsError
   | SelectInspectionEquipment
   | FindInspectionEquipmentSuccess
   | FindInspectionEquipmentError
   | TryUpdateInspectionEquipment
   | UpdateInspectionEquipmentSuccess
   | UpdateInspectionEquipmentError
   | StartInspectionEquipmentCreation
   | TryCreateInspectionEquipment
   | CreateInspectionEquipmentSuccess
   | CreateInspectionEquipmentError
   | TryRemoveSelectedInspectionEquipment
   | RemoveSelectedInspectionEquipmentSuccess
   | RemoveSelectedInspectionEquipmentError
   | DeselectInspectionEquipment
   | SetDisplayMode
   | TryReleaseInspectionEquipment
   | ReleasedInspectionEquipmentSuccess
   | ReleasedInspectionEquipmentError
   | TryLockInspectionEquipment
   | LockInspectionEquipmentSuccess
   | LockInspectionEquipmentError
   | InspectionEquipmentRouteChange
   | InspectionEquipmentEmptyRouteChange
   | FindMissingInspectionEquipment
   | FindMissingInspectionEquipmentSuccess
   | FindMissingInspectionEquipmentError
   | ResetState;
