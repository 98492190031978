import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, first, map } from 'rxjs';
import * as fromRoot from 'app/app.reducer';

@Injectable({ providedIn: 'root' })
export class IsUnauthenticatedAccessService {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.store.select(fromRoot.getActiveUser).pipe(
      first(),
      map((user) => !user),
    );
  }

  canLoad(): Observable<boolean> {
    return this.store.select(fromRoot.getActiveUser).pipe(
      first(),
      map((user) => !user),
    );
  }
}
