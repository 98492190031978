import { SET_ACTIVE_FILTER } from './../../filter/store/filter.actions';
import { UserWidgetService } from './../user-widget.service';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, filter, tap, first, withLatestFrom } from 'rxjs/operators';

import * as fromRoot from '../../../../../app.reducer';
import * as PROFILE from './profile.actions';
import * as USER from '../../administration/user-manager/store/user-manager.actions';
import * as SP from 'app/main/side-panel/store/side-panel.actions';
import * as AUTH from 'app/main/content/apps/authentication/authentication-store/authentication-store.actions';
import * as FILTER from 'app/main/content/apps/filter/store/filter.actions';

import { User, ActionWithPayload, LoginService } from 'app/shared';
import { IRestResponse, IRestError } from 'app/core/models/rest.model';

import { Store } from '@ngrx/store';
import { globalObject, GlobalObjectField, UserOptions } from '../user-options.model';
import { UserOptionsService } from '../user-options.service';
import { BoardSearchCriteria } from '../../dashboard/board/models/board-search-criteria.model';
import { JmsWebSocketConnectionVendor } from 'app/core/services/socket/jms-socket-connection-vendor.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { DashboardFlowType } from '../../dashboard/board/models/board.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/core/components/confirm-dialog/confirm-dialog.component';
import { Filter } from '../../filter/filter.model';
import {TRY_UPDATE_ACTIVE_USER_FILTERS} from './profile.actions';
import { FilterNameList } from '../../filter/filter-modules-list';

@Injectable()
export class ProfileEffects {
   onLogin$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(AUTH.LOGIN_USER_SUCCESS),
         map(({ payload }) => ({ type: PROFILE.SET_DATA_ON_LOGIN, payload })),
      );
   });

   updateActiveUserOnLangChange$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(SP.UPDATE_CURRENT_USER_LANGUAGE_SUCCESS),
         map((action: ActionWithPayload<string>) => action.payload),
         concatLatestFrom(() => this.store$.select(fromRoot.getActiveUser)),
         map(([langKey, user]) => ({
            type: PROFILE.SET_ACTIVE_USER,
            payload: { ...user, langKey },
         })),
      );
   });

   updateDashboardSearchCriteria$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(PROFILE.TRY_UPDATE_DASHBOARD_CRITERIA),
         map(
            (
               action: ActionWithPayload<{
                  flow: DashboardFlowType;
                  criteria: BoardSearchCriteria;
               }>,
            ) => action.payload,
         ),
         switchMap(({ criteria, flow }) =>
            this.store$.select(fromRoot.getActiveUserOptions).pipe(
               first(),
               map(({ options }) => ({ options, criteria, flow })),
            ),
         ),
         map(({ options, criteria, flow }) => ({
            type: PROFILE.UPDATE_ACTIVE_USER_OPTIONS,
            payload: {
               [globalObject]: {
                  ...(options[globalObject] ?? {}),
                  [flow]: {
                     [GlobalObjectField.lastSelectedDiagram]: criteria.diagramId || null,
                     [GlobalObjectField.lastSelectedInstance]: criteria.instanceId || null,
                     [GlobalObjectField.lastSelectedStep]: criteria.stepId || null,
                     [GlobalObjectField.lastSelectedCharacteristic]: criteria.characteristicId || null,
                     [GlobalObjectField.lastSelectedIndexInSample]: criteria.indexInSample || null,
                  },
               },
            },
         })),
      );
   });

   updateUserSuccess$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(USER.UPDATE_USER_SUCCESS, SP.UPDATE_CURRENT_USER_SUCCESS),
         map((action: ActionWithPayload<User>) => action.payload),
         concatLatestFrom(() => this.store$.select(fromRoot.getActiveUser)),
         filter(([updatedUser, activeUser]) => updatedUser.id === activeUser?.id),
         map(([updatedUser]) => ({
            type: PROFILE.SET_ACTIVE_USER,
            payload: updatedUser,
         })),
      );
   });

   updateNavigationOptionForCurrentUser$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(PROFILE.TRY_UPDATE_NAVIGATION_OPTIONS),
         map((action: ActionWithPayload<Record<string, boolean>>) => action.payload),
         withLatestFrom(this.store$.select(fromRoot.getActiveUserOptions)),
         switchMap(([updatedNav, currentNav]) =>
            this.userOptionsService.updateNavigationOptionsForCurrentUser({
               ...currentNav.navigationOptions,
               ...updatedNav,
            }).pipe(
               map((res: Record<string, boolean>) => ({
                  type: PROFILE.UPDATE_NAVIGATION_OPTIONS_SUCCESS,
                  payload: res,
               })),
               catchError((error: IRestError) =>
                  of({
                     type: PROFILE.UPDATE_ACTIVE_USER_OPTIONS_ERROR,
                     payload: error,
                  }),
               ),
            ),
         ),
      );
   },
   );


   // Try to update active user options
   updateUserOptions$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(PROFILE.UPDATE_ACTIVE_USER_OPTIONS),
         map((action: ActionWithPayload<UserOptions>) => action.payload),
         switchMap(payload =>
            this.userOptionsService.updateOptionsForCurrentUser(payload).pipe(
               map((res: IRestResponse) => ({
                  type: PROFILE.UPDATE_ACTIVE_USER_OPTIONS_SUCCESS,
                  payload: res,
               })),
               catchError((error: IRestError) =>
                  of({
                     type: PROFILE.UPDATE_ACTIVE_USER_OPTIONS_ERROR,
                     payload: error,
                  }),
               ),
            ),
         ),
      );
   });

   updateUserActiveFilters = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILTER.ADD_FILTER_SUCCESS, FILTER.UPDATE_FILTER_SUCCESS, TRY_UPDATE_ACTIVE_USER_FILTERS),
         map((action: ActionWithPayload<Filter>) => action.payload),
         switchMap(filterData => 
            this.userWidgetService.updateActiveFiltersForCurrentUser(filterData).pipe(
               map((res: Filter) => ({
                  type: PROFILE.UPDATE_ACTIVE_USER_FILTERS_SUCCESS,
                  payload: res,
               })),
               catchError((error: IRestError) =>
                  of({
                     type: PROFILE.UPDATE_ACTIVE_USER_FILTERS_ERROR,
                     payload: error,
                  }),
               ),
            ),
         ),
      );
   });

   removeUserActiveFilter = createEffect(() => {
      return this.actions$.pipe(
         ofType(FILTER.DESELECT_FILTER),
         map((action: ActionWithPayload<{ filterName: FilterNameList; filter: Filter }>) => action.payload),
         switchMap(filterData =>
            this.userWidgetService.removeActiveFilterForCurrentUser(filterData.filter).pipe(
               map((res: Filter) => ({
                  type: PROFILE.REMOVE_ACTIVE_USER_FILTER,
                  payload: res,
               })),
            ),
         ),
      );
   });

   // Try to update active user walkthrough options
   updateUserWalkthroughOptions$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(PROFILE.UPDATE_ACTIVE_USER_WALKTHROUGH_OPTIONS),
         map((action: ActionWithPayload<{ moduleName: string; completed: boolean }>) => ({
            [action.payload.moduleName + 'WalkthroughCompleted']: action.payload.completed,
         })),
         map(walkthroughOptions => ({
            type: PROFILE.UPDATE_ACTIVE_USER_OPTIONS,
            payload: walkthroughOptions,
         })),
      );
   });

   resetUserWalkthroughOptions$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(PROFILE.RESET_ACTIVE_USER_WALKTHROUGH_OPTIONS),
         map(() => ({
            type: PROFILE.UPDATE_ACTIVE_USER_OPTIONS,
            payload: {
               dashboardWalkthroughCompleted: false,
               sidePanelWalkthroughCompleted: false,
               tasksWalkthroughCompleted: false,
               filesWalkthroughCompleted: false,
               processesWalkthroughCompleted: false,
               goalWalkthroughCompleted: false,
               evaluationsWalkthroughCompleted: false,
               basicDataWalkthroughCompleted: false,
               administrationWalkthroughCompleted: false,
            },
         })),
      );
   });

   hideUserWalkthrough$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(PROFILE.HIDE_ACTIVE_USER_WALKTHROUGH),
         map(() => ({
            type: PROFILE.UPDATE_ACTIVE_USER_OPTIONS,
            payload: {
               dashboardWalkthroughCompleted: true,
               sidePanelWalkthroughCompleted: true,
               tasksWalkthroughCompleted: true,
               filesWalkthroughCompleted: true,
               processesWalkthroughCompleted: true,
               goalWalkthroughCompleted: true,
               evaluationsWalkthroughCompleted: true,
               basicDataWalkthroughCompleted: true,
               administrationWalkthroughCompleted: true,
            },
         })),
      );
   });

   tokenTimeout$ = createEffect(
      () => {
         return this.actions$.pipe(
            ofType(PROFILE.TOKEN_TIMEOUT, PROFILE.TOKEN_INVALID, PROFILE.TOKEN_ISSUED),
            tap(() => {
               this.localStorage.clear('authenticationToken');
               this.sessionStorage.clear('authenticationToken');
               this.loginService.logout();
               this.socketVendor.disconnect();
            }),
         );
      },
      { dispatch: false },
   );

   tokenInvalidDialog$ = createEffect(
      () => {
         return this.actions$.pipe(
            ofType(PROFILE.TOKEN_ISSUED),
            tap(() => {
               const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
                  width: '500px',
               });
               dialogRef.componentInstance.confirmMessage = this.translate.instant('profile.tokenIssued.message');
               dialogRef.componentInstance.title = this.translate.instant('profile.tokenIssued.title');
               dialogRef.componentInstance.disableFalseButton = true;
            }),
         );
      },
      { dispatch: false },
   );

   constructor(
      private actions$: Actions,
      private userOptionsService: UserOptionsService,
      private store$: Store<fromRoot.State>,
      private loginService: LoginService,
      private socketVendor: JmsWebSocketConnectionVendor,
      private localStorage: LocalStorageService,
      private sessionStorage: SessionStorageService,
      private matSnackBar: MatSnackBar,
      private translate: TranslateService,
      private matDialog: MatDialog,
      private userWidgetService: UserWidgetService,
   ) {}
}
