import { Action } from '@ngrx/store';
import { DocumentStore, FileData } from '../document.model';
import { ActionWithPayload } from '../../../../../shared';
import { IRestResponse, IRestError } from 'app/core/models/rest.model';

export const SET_SELECTED_FILE_EDIT = '[FILE] Set selected document edit';

export const SET_SELECTED_FILE_NEW = '[FILE] Set selected document new';

export const ENABLE_UPLOAD_MODE = '[FILE] Enable edit mode for detail view';
export const DISABLE_UPLOAD_MODE = '[FILE] Disable edit mode for detail view';

export const TRY_GET_FILE_LIST = '[FILE] Try get document list';
export const GET_FILE_LIST_SUCCESS = '[FILE] Get document list success';
export const GET_FILE_LIST_ERROR = '[FILE] Get document list error';

export const TRY_GET_FILE_NEXT_PAGE = '[FILE] Try get document next page';
export const GET_FILE_NEXT_PAGE_SUCCESS = '[FILE] Try get document next page success';
export const GET_FILE_NEXT_PAGE_ERROR = '[FILE] Try get document next page error';

export const TRY_ADD_FILE = '[FILE] Try Add document';
export const ADD_FILE_SUCCESS = '[FILE] Add document success';
export const ADD_FILE_ERROR = '[FILE] Add document error';

export const TRY_UPDATE_FILE = '[FILE] Try Update document';
export const UPDATE_FILE_SUCCESS = '[FILE] Update document success';
export const UPDATE_FILE_ERROR = '[FILE] Update document error';

export const TRY_REMOVE_FILE = '[FILE] Try Remove document';
export const REMOVE_FILE_SUCCESS = '[FILE] Remove document success';
export const REMOVE_FILE_ERROR = '[FILE] Remove document error';

export const TRY_LOAD_SORTED_FILE_LIST = '[FILE] Try load sorted file list';

export const PREVIEW_FILE = '[FILE] Preview document';
export const FOLLOW_LINK = '[FILE] Follow link';

// Find document
export const TRY_FIND_FILE = '[FILE] Try find document';
export const FIND_FILE_SUCCESS = '[FILE] Find document success';
export const FIND_FILE_ERROR = '[FILE] Find document error';

export const CLEAR_SELECTED_FILE = '[FILE] Clear document selection';

export const SET_PARENT_DOCUMENT = '[FILE] Set parent document';

export const RESET_ORDER_STATE = '[FILE On Destroy] Restart order state';

// Document history
export const TRY_FIND_DOCUMENT_HISTORY = '[FILE] Try fetch document history';
export const FIND_DOCUMENT_HISTORY_SUCCESS = '[FILE] Fetch document history success';
export const FIND_DOCUMENT_HISTORY_ERROR = '[FILE] Fetch document history error';

export const CLEAR_HISTORY_QUERY_PARAMS = '[FILE] Clear history query params';
export const CLEAR_HISTORY_LIST = '[FILE] Clear history list';

// Undo document change
export const TRY_UNDO_DOCUMENT_CHANGE = '[FILE] Try undo document change';
export const UNDO_DOCUMENT_CHANGE_SUCCESS = '[FILE] Undo document change success';
export const UNDO_DOCUMENT_CHANGE_ERROR = '[FILE] Undo document change error';

// Undo document change
export const TRY_ADD_NEW_DOCUMENT_VERSION = '[FILE] Try add new document version';
export const ADD_NEW_DOCUMENT_VERSION_SUCCESS = '[FILE] Add new document version success';
export const ADD_NEW_DOCUMENT_VERSION_ERROR = '[FILE] Add new document version error';
export const NAVIGATE_TO_PATH = '[FILE] Navigate to file path';

export const TRY_DOWNLOAD_FILE = '[FILE] Try download file.';
export const DOWNLOAD_FILE_SUCCESS = '[FILE] Download file success.';
export const DOWNLOAD_FILE_ERROR = '[FILE] Download file error.';

export const TRY_START_EDIT_TEXT_FILE =
  '[File manager text content list item double click] Try to start edit mode for text content file.';
export const START_EDIT_TEXT_FILE_SUCCESS =
  '[File manager text content list item double click] Success start edit mode for text content file.';
export const START_EDIT_TEXT_FILE_ERROR =
  '[File manager text content list item double click] Error start edit mode for text content file.';
export const STOP_EDIT_TEXT_CONTENT =
  '[File manager edit text content close button] Stop edit mode for text content file.';

export const TRY_UPDATE_TEXT_CONTENT_FILE = '[File manager edit text content] Try update text content file';
export const UPDATE_TEXT_CONTENT_FILE_SUCCESS = '[Api Response File manager] Success update text content file';
export const UPDATE_TEXT_CONTENT_FILE_ERROR = '[Api Response File manager] Error update text content file';

export const START_TRACKING_LANG_CHANGES = '[File manager On Init] Start tracking lang changes.';
export const STOP_TRACKING_LANG_CHANGES = '[File manager On Destroy] Stop tracking lang changes.';

export const TRY_DRAG_DOCUMENT = '[File manager Drop document on parent folder] Try update document parent folder';
export const DRAG_DOCUMENT_SUCCESS = '[File manager Api Response] Update document parent folder success';
export const DRAG_DOCUMENT_ERROR = '[File manager Api Response] Update document parent folder error';

export class StartTrackingLangChanges implements Action {
  readonly type = START_TRACKING_LANG_CHANGES;
}

export class StopTrackingLangChanges implements Action {
  readonly type = STOP_TRACKING_LANG_CHANGES;
}
export class TryUpdateTextContentDocument implements ActionWithPayload<any> {
  readonly type = TRY_UPDATE_TEXT_CONTENT_FILE;
  constructor(public payload: any) {}
}

export class UpdateTextContentDocumentSuccess implements ActionWithPayload<DocumentStore> {
  readonly type = UPDATE_TEXT_CONTENT_FILE_SUCCESS;
  constructor(public payload: DocumentStore) {}
}

export class UpdateTextContentDocumentError implements Action {
  readonly type = UPDATE_TEXT_CONTENT_FILE_ERROR;
}

export class TryStartEditTextContent implements Action {
  readonly type = TRY_START_EDIT_TEXT_FILE;
}

export class StartEditTextContentSuccess implements ActionWithPayload<DocumentStore> {
  readonly type = START_EDIT_TEXT_FILE_SUCCESS;
  constructor(public payload: DocumentStore) {}
}

export class StartEditTextContentError implements Action {
  readonly type = START_EDIT_TEXT_FILE_ERROR;
}

export class StopEditTextContent implements Action {
  readonly type = STOP_EDIT_TEXT_CONTENT;
}

export class FollowLink implements ActionWithPayload<DocumentStore> {
  readonly type = FOLLOW_LINK;
  constructor(public payload: DocumentStore) {}
}

export class TryDownloadFile implements ActionWithPayload<string> {
  readonly type = TRY_DOWNLOAD_FILE;
  constructor(public payload: string) {}
}

export class DownloadFileSuccess implements ActionWithPayload<DocumentStore> {
  readonly type = DOWNLOAD_FILE_SUCCESS;
  constructor(public payload: DocumentStore) {}
}

export class DownloadFileError implements Action {
  readonly type = DOWNLOAD_FILE_ERROR;
}
export class ResetOrderState implements Action {
  readonly type = RESET_ORDER_STATE;
}
export class NavigateToPath implements ActionWithPayload<string> {
  readonly type = NAVIGATE_TO_PATH;
  constructor(public payload: string) {}
}

/* set selected document */
export class SetSelectedDocumentEdit implements ActionWithPayload<DocumentStore> {
  readonly type = SET_SELECTED_FILE_EDIT;
  constructor(public payload: DocumentStore) {}
}

/* document edit mode */
export class EnableUploadMode implements Action {
  readonly type = ENABLE_UPLOAD_MODE;
}

export class DisableUploadMode implements Action {
  readonly type = DISABLE_UPLOAD_MODE;
}
/* get document list */
export class TryGetDocumentList implements Action {
  readonly type = TRY_GET_FILE_LIST;
}
export class GetDocumentListSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_FILE_LIST_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class GetDocumentListError implements ActionWithPayload<IRestError> {
  readonly type = GET_FILE_LIST_ERROR;
  constructor(public payload: IRestError) {}
}

/* try get document next page */
export class TryGetDocumentNextPage implements Action {
  readonly type = TRY_GET_FILE_NEXT_PAGE;
}
export class GetDocumentNextPageSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_FILE_NEXT_PAGE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class GetDocumentNextPageError implements ActionWithPayload<IRestError> {
  readonly type = GET_FILE_NEXT_PAGE_ERROR;
  constructor(public payload: IRestError) {}
}

/* add document  */
export class TryAddDocument implements Action {
  readonly type = TRY_ADD_FILE;
  constructor(public payload: DocumentStore[]) {}
}
export class AddDocumentSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = ADD_FILE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class AddDocumentError implements ActionWithPayload<IRestError> {
  readonly type = ADD_FILE_ERROR;
  constructor(public payload: IRestError) {}
}

/* update document  */
export class TryUpdateDocument implements ActionWithPayload<DocumentStore> {
  readonly type = TRY_UPDATE_FILE;
  constructor(public payload: DocumentStore) {}
}
export class UpdateDocumentSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = UPDATE_FILE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class UpdateDocumentError implements ActionWithPayload<IRestError> {
  readonly type = UPDATE_FILE_ERROR;
  constructor(public payload: IRestError) {}
}

/* delete document  */
export class TryRemoveDocument implements ActionWithPayload<string> {
  readonly type = TRY_REMOVE_FILE;
  constructor(public payload: string) {}
}
export class RemoveDocumentSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = REMOVE_FILE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class RemoveDocumentError implements ActionWithPayload<IRestError> {
  readonly type = REMOVE_FILE_ERROR;
  constructor(public payload: IRestError) {}
}

/* Find document */
export class TryFindDocument implements ActionWithPayload<string> {
  readonly type = TRY_FIND_FILE;
  constructor(public payload: string) {}
}
export class FindDocumentSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = FIND_FILE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class FindDocumentError implements ActionWithPayload<IRestError> {
  readonly type = FIND_FILE_ERROR;
  constructor(public payload: IRestError) {}
}

/* Sorted file list*/
export class TryLoadSortedFileList implements Action {
  readonly type = TRY_LOAD_SORTED_FILE_LIST;
}

export class PreviewFile implements ActionWithPayload<{ id: string; type: string }> {
  readonly type = PREVIEW_FILE;
  constructor(public payload: { id: string; type: string }) {}
}

export class SetParentDocument implements ActionWithPayload<DocumentStore> {
  readonly type = SET_PARENT_DOCUMENT;
  constructor(public payload: DocumentStore) {}
}

/* Document history */
export class TryFindDocumentHistory implements ActionWithPayload<string> {
  readonly type = TRY_FIND_DOCUMENT_HISTORY;
  constructor(public payload: string) {}
}

export class FindDocumentHistorySuccess implements ActionWithPayload<IRestResponse> {
  readonly type = FIND_DOCUMENT_HISTORY_SUCCESS;
  constructor(public payload: IRestResponse) {}
}

export class FindDocumentHistoryError implements ActionWithPayload<IRestError> {
  readonly type = FIND_DOCUMENT_HISTORY_ERROR;
  constructor(public payload: IRestError) {}
}

export class ClearHistoryQueryParams implements Action {
  readonly type = CLEAR_HISTORY_QUERY_PARAMS;
}

export class ClearDocumentHistoryList implements Action {
  readonly type = CLEAR_HISTORY_LIST;
}

export class TryUndoDocumentChange implements ActionWithPayload<string> {
  readonly type = TRY_UNDO_DOCUMENT_CHANGE;
  constructor(public payload: string) {}
}

export class UndoDocumentChangeSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = UNDO_DOCUMENT_CHANGE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}

export class UndoDocumentChangeError implements ActionWithPayload<IRestError> {
  readonly type = UNDO_DOCUMENT_CHANGE_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryAddNewDocumentVersion implements ActionWithPayload<FileData> {
  readonly type = TRY_ADD_NEW_DOCUMENT_VERSION;
  constructor(public payload: FileData) {}
}

export class AddNewDocumentVersionSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = ADD_NEW_DOCUMENT_VERSION_SUCCESS;
  constructor(public payload: IRestResponse) {}
}

export class AddNewDocumentVersionError implements ActionWithPayload<IRestError> {
  readonly type = ADD_NEW_DOCUMENT_VERSION_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryDragDocument implements ActionWithPayload<{ targetFolderId: string; documentId: string }> {
  readonly type = TRY_DRAG_DOCUMENT;
  constructor(public payload: { targetFolderId: string; documentId: string }) {}
}

export class DragDocumentSuccess implements ActionWithPayload<{ targetFolderId: string; documentId: string }> {
  readonly type = DRAG_DOCUMENT_SUCCESS;
  constructor(public payload: { targetFolderId: string; documentId: string }) {}
}

export class DragDocumentError implements Action {
  readonly type = DRAG_DOCUMENT_ERROR;
}
export type FILEActions =
  | SetSelectedDocumentEdit
  | EnableUploadMode
  | DisableUploadMode
  | TryGetDocumentList
  | GetDocumentListSuccess
  | GetDocumentListError
  | TryAddDocument
  | AddDocumentSuccess
  | AddDocumentError
  | TryUpdateDocument
  | UpdateDocumentSuccess
  | UpdateDocumentError
  | TryRemoveDocument
  | RemoveDocumentSuccess
  | RemoveDocumentError
  | TryLoadSortedFileList
  | TryGetDocumentNextPage
  | StartTrackingLangChanges
  | StopTrackingLangChanges
  | GetDocumentNextPageSuccess
  | GetDocumentNextPageError
  | TryFindDocument
  | FindDocumentSuccess
  | FindDocumentError
  | SetParentDocument
  | TryFindDocumentHistory
  | FindDocumentHistorySuccess
  | FindDocumentHistoryError
  | ClearHistoryQueryParams
  | ClearDocumentHistoryList
  | TryUndoDocumentChange
  | UndoDocumentChangeSuccess
  | UndoDocumentChangeError
  | TryAddNewDocumentVersion
  | ResetOrderState
  | AddNewDocumentVersionSuccess
  | AddNewDocumentVersionError
  | TryLoadSortedFileList
  | FollowLink
  | NavigateToPath
  | TryStartEditTextContent
  | StopEditTextContent
  | TryUpdateTextContentDocument
  | UpdateTextContentDocumentSuccess
  | UpdateTextContentDocumentError
  | StartEditTextContentSuccess
  | StartEditTextContentError
  | TryDragDocument
  | DragDocumentSuccess
  | DragDocumentError;
