import { Component, Injector, ViewContainerRef } from '@angular/core';
import { FuseSplashScreenService } from './core/services/splash-screen.service';
import { TranslateService } from '@ngx-translate/core';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { environment } from './environments/environment';

import { JmsWebSocketConnectionVendor } from './core/services/socket/jms-socket-connection-vendor.service';
import { DEFAULT_LANGUAGE, LANGUAGES } from './shared//language/language.constants';
import { PrivacySettingsService } from './main/content/apps/authentication/login/privacy-policy-dialog/privacy-policy.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { EmbeddedTrackerService } from './core/services/embedded-tracker.service';
import { TitleHelperService } from './shared';
import { ThemeService } from './theme.service';
import { RouterOutlet } from '@angular/router';

export let AppInjector: Injector;

registerLocaleData(localeDe);
@Component({
  selector: 'fuse-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor(
    private injector: Injector,
    private fuseSplashScreen: FuseSplashScreenService,
    private translate: TranslateService,
    private jmsVendor: JmsWebSocketConnectionVendor,
    public viewContainerRef: ViewContainerRef,
    private privacyPolicy: PrivacySettingsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private embeddedTracker: EmbeddedTrackerService,
    private titleHelperService: TitleHelperService,
    private themeService: ThemeService,
  ) {
    if (environment.debug) {
      // eslint-disable-next-line no-console
      console.log('## DEBUG_INFO_ENABLED ##');
    }

    AppInjector = injector;
    // Add languages
    this.translate.addLangs(LANGUAGES);

    // Set the default language
    this.translate.setDefaultLang(DEFAULT_LANGUAGE);
    // this.translate.use(DEFAULT_LANGUAGE);

    this.matIconRegistry.addSvgIcon(
      'output_requirement',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons/output_requirement.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'input_requirement',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons/input_requirement.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'strategy_canvas',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons/strategy.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'qmcloud',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons/miner-icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'goals',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons/goals.svg'),
    );
  }
}
