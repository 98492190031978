import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { FilterService } from '../filter.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../app.reducer';
import * as FILTER from './filter.actions';
import { map, switchMap, catchError, first, filter, withLatestFrom } from 'rxjs/operators';
import { ActionWithPayload } from 'app/shared';
import { of } from 'rxjs';
import { Filter } from '../filter.model';
import { FilterModuleName, FilterNameList } from '../filter-modules-list';
import { WidgetType } from '../../dashboard/widgets/widget/widget-type.model';

@Injectable()
export class FilterEffects {
  getMetadata$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(FILTER.TRY_GET_METADATA),
      concatLatestFrom(() => this.store$.select(fromRoot.getMetadataLoaded)),
      switchMap(([action, metaDataLoaded]) => {
        if (metaDataLoaded) return of({ type: FILTER.METADATA_WAS_LOADED });

        return this.filterService.getFilterLibrary().pipe(
          map((metaData) => {
            if (metaData)
              { return {
                type: FILTER.GET_METADATA_SUCCESS,
                payload: metaData?.filters || [],
              }; }
            return { type: FILTER.GET_METADATA_ERROR };
          }),
          catchError(() => of({ type: FILTER.GET_METADATA_ERROR })),
        );
      }),
    ); },
  );

  getFilters$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(FILTER.TRY_GET_FILTER_LIST_FOR_MODULE),
      map((action: ActionWithPayload<FilterNameList>) => action.payload),
      switchMap((filterName) =>
        this.filterService.getFiltersForComponent(filterName).pipe(
          map((filterList: Filter[]) => ({ filterName, filterList })),
          map((data: { filterName: FilterNameList; filterList: Filter[] }) => {
            return {
              type: FILTER.GET_FILTER_LIST_FOR_MODULE_SUCCESS,
              payload: data,
            };
          }),
          catchError(() =>
            of({
              type: FILTER.GET_FILTER_LIST_FOR_MODULE_ERROR,
              payload: filterName,
            }),
          ),
        ),
      ),
    ); },
  );

  getFiltersForCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FILTER.TRY_GET_FILTER_FOR_USER),
      map((action: ActionWithPayload<{filterName: FilterNameList}>) => action.payload),
      withLatestFrom(this.store$.select(fromRoot.getActiveUser)),
      switchMap(([{filterName}, user]) =>
        this.filterService.getFiltersForCurrentUser(filterName, user.id).pipe(
          map((filterList: Filter[]) => ({ filterName, filterList })),
          map((data: { filterName: FilterNameList; filterList: Filter[] }) => {
            return {
              type: FILTER.GET_FILTER_LIST_FOR_MODULE_SUCCESS,
              payload: data,
            };
          }),
          catchError(() =>
            of({
              type: FILTER.GET_FILTER_LIST_FOR_MODULE_ERROR,
              payload: filterName,
            }),
          ),
        ),
      ),
    );
  }
  );

  addFilter$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(FILTER.TRY_ADD_FILTER),
      map((action: ActionWithPayload<Filter>) => action.payload),
      switchMap((filt) =>
        this.filterService.create(filt).pipe(
          map((savedFilter: Filter) => {
            return { type: FILTER.ADD_FILTER_SUCCESS, payload: savedFilter };
          }),
          catchError(() => of({ type: FILTER.ADD_FILTER_ERROR })),
        ),
      ),
    ); },
  );

  updateFilter$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(FILTER.TRY_UPDATE_FILTER),
      map((action: ActionWithPayload<Filter>) => action.payload),
      switchMap((filt) =>
        this.filterService.update(filt).pipe(
          map((updatedFilter: Filter) => {
            return {
              type: FILTER.UPDATE_FILTER_SUCCESS,
              payload: updatedFilter,
            };
          }),
          catchError(() => of({ type: FILTER.UPDATE_FILTER_ERROR })),
        ),
      ),
    );},
  );

  deleteFilter$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(FILTER.TRY_DELETE_FILTER),
      map((action: ActionWithPayload<Filter>) => action.payload),
      concatLatestFrom(() => this.store$.select(fromRoot.getFilterList)),
      switchMap(([filter, filterList]) => {

        return this.filterService.delete(filter.id).pipe(
          map(() => ({
            type: FILTER.DELETE_FILTER_SUCCESS,
            payload: { filterName: filter.filterName , filterId: filter.id},
          })),
          catchError(() => of({ type: FILTER.DELETE_FILTER_ERROR })),
        );
      }),
    ); },
  );

  updateViewOnlyMode = createEffect(() =>
    { return this.actions$.pipe(
      ofType(FILTER.UPDATE_VIEW_ONLY_MODE),
      switchMap(() => this.store$.select(fromRoot.getHasViewOnlyLicense).pipe(filter((viewOnly) => viewOnly != null))),
      map((viewOnly) => ({
        type: FILTER.LOAD_VIEW_ONLY_MODE,
        payload: viewOnly,
      })),
    ); },
  );

  selectFilters$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(FILTER.SELECT_FILTER_LIST_FOR_MODULE),
      map((action: ActionWithPayload<string>) => action.payload),
      switchMap((moduleName: FilterModuleName) =>
        this.store$.select((fromRoot.getFiltersLoaded(moduleName))).pipe(
          first(),
          map((isLoaded: boolean) => {
            if (!isLoaded)
              { return {
                type: FILTER.TRY_GET_FILTER_LIST_FOR_MODULE,
                payload: moduleName,
              }; }
            return {
              type: FILTER.MODULE_FILTERS_WERE_LOADED,
              payload: moduleName,
            };
          }),
        ),
      ),
    ); },
  );



  constructor(
    private actions$: Actions,
    private filterService: FilterService,
    private store$: Store<fromRoot.State>,
  ) {}
}
