import { produce } from 'immer';
import { Tool } from '../model/tool.model';
import { ToolActions } from './tool.actions';
import * as PA from './tool.actions';
import { Textstore } from 'app/shared/lang-dialog/textstore.model';

export interface State {
  tools: Tool[];
  loading: boolean;
  selectedToolId: string;
  page: number;
  creatingTool: Tool;
}

const initialState: State = {
  tools: [],
  loading: false,
  selectedToolId: null,
  page: 0,
  creatingTool: null,
};

export const toolReducer = produce((draft: State, action: ToolActions) => {
  switch (action.type) {
    case PA.DESELECT_TOOL:
      draft.selectedToolId = null;

      return;
    case PA.TRY_GET_FILTERED_TOOL_LIST:
    case PA.TRY_GET_TOOL_LIST:
      draft.page = initialState.page;
      draft.tools = initialState.tools;
      draft.selectedToolId = initialState.selectedToolId;

      return;
    case PA.GET_TOOLS_SUCCESS:
      draft.loading = false;
      if (action.payload?.length > 0) draft.page += 1;
      draft.tools.push(...action.payload);

      return;
    case PA.START_TOOL_CREATION:
      draft.selectedToolId = null;
      draft.creatingTool = {
        title: Textstore.withInitatedLanguages(),
        description: Textstore.withInitatedLanguages(),
        nestCount: 1,
      };

      return;
    case PA.CREATE_TOOL_SUCCESS:
      draft.loading = false;
      draft.tools.unshift(action.payload);
      draft.creatingTool = null;
      draft.selectedToolId = action.payload.id;

      return;
    case PA.FIND_TOOL_SUCCESS:
      draft.loading = false;
      draft.creatingTool = null;

      const foundToolIndex = draft.tools.findIndex((part) => part.id === action.payload.id);
      if (foundToolIndex < 0) return;

      draft.tools[foundToolIndex] = action.payload;
      draft.selectedToolId = action.payload.id;

      return;
    case PA.UPDATE_TOOL_SUCCESS:
      draft.loading = false;

      const changedToolIndex = draft.tools.findIndex((part) => part.id === action.payload.id);
      if (changedToolIndex < 0) return;

      draft.tools[changedToolIndex] = action.payload;

      return;
    case PA.REMOVE_SELECTED_TOOL_SUCCESS:
      draft.loading = false;
      draft.tools = draft.tools.filter((part) => part.id !== action.payload);

      return;
    case PA.RESET_STATE:
      Object.assign(draft, initialState);

      return;
    case PA.TRY_GET_NEXT_TOOL_PAGE:
    case PA.TRY_CREATE_TOOL:
    case PA.TRY_UPDATE_TOOL:
    case PA.TRY_REMOVE_SELECTED_TOOL:
    case PA.TRY_FIND_TOOL:
      draft.loading = true;

      return;
    case PA.GET_TOOLS_ERROR:
    case PA.CREATE_TOOL_ERROR:
    case PA.UPDATE_TOOL_ERROR:
    case PA.REMOVE_SELECTED_TOOL_ERROR:
    case PA.FIND_TOOL_ERROR:
      draft.loading = false;
  }
}, initialState);

export const getTools = (state: State) => state.tools;

export const getToolsLoading = (state: State) => state.loading;

export const getSelectedToolId = (state: State) => state.selectedToolId;

export const getToolsPage = (state: State) => state.page;

export const getToolBeingCreated = (state: State) => state.creatingTool;
