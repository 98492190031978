import { WidgetType } from '../dashboard/widgets/widget/widget-type.model';

export enum FilterModuleName {
  TASK = 'TASK',
  FILE = 'FILE',
  PROCESS = 'PROCESS',
  GOALS = 'GOALS',
  KPI = 'KPI',
  PROJECTS = 'PROJECT',
  MACHINE = 'MACHINE',
  ROLE = 'ROLE',
  PROCESS_RESULT = 'PROCESS_RESULT',
  INSPECTION_RESULT = 'INSPECTION_RESULT',
  GOALS_RESULT = 'GOALS_RESULT',
  KPI_RESULT = 'KPI_RESULT',
  PART = 'PART',
  CATALOG = 'CATALOG',
  USER = 'USER',
  INSPECTION_EQUIPMENT = 'INSPECTION_EQUIPMENT',
  INSPECTION_PLAN = 'INSPECTION_PLAN',
  USER_DIALOG = 'USER_DIALOG',
  PART_DIALOG = 'PART_DIALOG',
  TOOL = 'TOOL',
  TOOL_DIALOG = 'TOOL_DIALOG',
  PROCESS_INSTANCE_SELECTION_WITH_FLOW = 'PROCESS_INSTANCE_SELECTION_WITH_FLOW', // only for filter
  
}

export type FilterNameList = WidgetType | FilterModuleName;


// Generic which allow only fields which are
// filter module names (FilterModuleName)
// to be placed with passed generic values
export interface FilterNameRelated<T> {
  moduleName?: { [name in FilterNameList]: T };
}
