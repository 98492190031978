import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { SERVER_API_URL } from '../../app.constants';

@Injectable()
export class AuthServerProvider {
  constructor(
    private http: HttpClient,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
  ) {}

  getToken(): string {
    return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
  }

  fetchToken(): Observable<{ token: string }> {
    return this.http.get<{ token: string }>(SERVER_API_URL + 'api/token');
  }

  login(credentials): Observable<{ idToken: string }> {
    const data = {
      username: credentials.username,
      password: credentials.password,
      rememberMe: credentials.rememberMe,
    };

    return this.http
      .post(SERVER_API_URL + 'api/authenticate', data, { observe: 'response' })
      .pipe(map(authenticateSuccess.bind(this)));

    function authenticateSuccess(resp) {
      const bearerToken = resp.headers.get('Authorization');
      if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
        const jwt = bearerToken.slice(7, bearerToken.length);
        this.storeAuthenticationToken(jwt, credentials.rememberMe);
        return jwt;
      }
    }
  }

  loginWithToken(jwt, rememberMe) {
    if (jwt) {
      this.storeAuthenticationToken(jwt, rememberMe);
      return Promise.resolve(jwt);
    }
    return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
  }

  storeAuthenticationToken(jwt, rememberMe) {
    if (rememberMe) this.$localStorage.store('authenticationToken', jwt);
    this.$sessionStorage.store('authenticationToken', jwt);

    const date = new Date();
    date.setHours(date.getHours() + 5);
  }

  logout(): void {
    this.$localStorage.clear('authenticationToken');
    this.$sessionStorage.clear('authenticationToken');
    // UGLY HOTFIX FOR TRIGGERING SESSION EVENT ON INDEX.HTML
    localStorage.setItem('clearAuthenticationToken', 'foobar');
    localStorage.removeItem('clearAuthenticationToken');
  }
}
