export enum WidgetType {
  TASK = 'TASK',
  DOCUMENT = 'DOCUMENT',
  PERSON = 'PERSON',
  CLOCK = 'CLOCK',
  WEB = 'WEB',
  CAMERA = 'CAMERA',
  STEP_DISPLAYER = 'STEP_DISPLAYER',
  SINGLE_PARAM = 'SINGLE_PARAM',
  INPUT_CHARACTERISTIC_VALUE = 'INPUT_CHARACTERISTIC_VALUE',
  DASHBOARD_NAVIGATION = 'DASHBOARD_NAVIGATION',
  CHARACTERISTIC_NOTES = 'CHARACTERISTIC_NOTES',
  SELECT_NEXT_CHARACTERISTIC = 'SELECT_NEXT_CHARACTERISTIC',
  SELECT_PREVIOUS_CHARACTERISTIC = 'SELECT_PREVIOUS_CHARACTERISTIC',
  CHARACTERISTIC_OVERVIEW = 'CHARACTERISTIC_OVERVIEW',
  INSPECTION_PROGRESS = 'INSPECTION_PROGRESS',
  INSTANCE_STATUS = 'INSTANCE_STATUS',
  PROCESS_INSTANCE_SELECTION = 'PROCESS_INSTANCE_SELECTION',
  GOAL_LIST_PROGRESS = 'GOAL_LIST_PROGRESS',
  TASK_BURN_UP = 'TASK_BURN_UP',
  TASK_STATUS_COUNT = 'TASK_STATUS_COUNT',
  TEAM_MEMBERS = 'TEAM_MEMBERS',
  HISTOGRAM = 'HISTOGRAM',
  EDITING_CHARACTERISTIC = 'EDITING_CHARACTERISTIC',
  MY_BRIDGES = 'MY_BRIDGES',
  ASSIGN_BRIDGE = 'ASSIGN_BRIDGE',
  INSTANCE_DECISION = 'INSTANCE_DECISION',
  IDENTITY_CHECK = 'IDENTITY_CHECK',
  NEXT_SAMPLE = 'NEXT_SAMPLE',
  CPK = 'CPK',
  CANDLE = 'CANDLE',
  INSPECTION_PROTOCOL = 'INSPECTION_PROTOCOL',
  SYNC_STATUS = 'SYNC_STATUS',
  PROBABILITY_NET = 'PROBABILITY_NET',
  BATCH_NUMBER = 'BATCH_NUMBER',
  S_GRAPH = 'S_GRAPH',
  R_GRAPH = 'R_GRAPH',
  C_GRAPH = 'C_GRAPH',
  P_GRAPH = 'P_GRAPH',
  // deprecated
  XQUER_S_GRAPH = 'XQUER_S_GRAPH',
  XQUER_R_GRAPH = 'XQUER_R_GRAPH',
  FINISH_PROCESS = 'FINISH_PROCESS',
}

export interface FilterWidgetRelated<T> {
  widgetType?: { [name in WidgetType]: T };
}

