import { UserInfo } from '../../../../shared/user/user.model';
import { DocumentStore } from '../file-manager/document.model';
import { Textstore } from '../../../../shared/lang-dialog/textstore.model';
import { BaseModel } from '../../../../core/base.model';
import { Label } from 'app/core/components/color-labels/label.model';
import { CommentData } from 'app/core/components/comments/comment.model';
import { CheckListItemData } from 'app/core/components/check-list/check-list.model';

export const taskFormFieldTypes = {
  deleted: 'boolean',
  privateTask: 'privateTask',
  subject: 'textstore',
  responsible: 'user',
  description: 'textstore',
  status: 'status',
  dueDate: 'date',
  checkList: 'checklist',
  labels: 'labels',
  documents: 'documents',
  comments: 'default',
  location: 'catalog',
  organization: 'catalog',
  actionType: 'catalog',
};

export class Task extends BaseModel {
  constructor(
    public id?: string,
    public seq?: number,
    public subject: Textstore = new Textstore(),
    public privateTask?: boolean,
    public description: Textstore = new Textstore(),
    public dueDate?: Date,
    public deleted = false,
    public status: TaskStatus = 'NEW',
    public source?: TaskSource,
    public sourceData?: TaskSourceData,
    public sourceId?: string,
    public checkList: CheckListItemData[] = [],
    public responsible?: UserInfo,
    public documents: DocumentStore[] = [],
    public comments: CommentData[] = [],
    public labels: Label[] = [],
    public commentCount?: number,
    public attachmentCount?: number,
    public location?: string,
    public organization?: string,
    public actionType?: string,
    public historyId?: string,
    public creator?: UserInfo,
    public createdOn?: Date,
    public activeCheckList = false,
    public checkEffectiveness = false,
    public effectivenessAnalysis = new TaskEffectivenessAnalysis(),
    public scheduled?: boolean,
    plantId?: string,
  ) {
    super(plantId);
  }
}

export class TaskDragDTO {
  constructor(
    public taskId: string,
    public newStatus: TaskStatus,
    public newPosition: number,
  ) {}
}

export class TaskHistoryEntry {
  constructor(
    public id: string,
    public timestamp: Date,
    public editor: UserInfo,
    public task: Task,
    public nextId: string,
  ) {}
}

export class TaskEffectivenessAnalysis {
  constructor(
    public responsible?: UserInfo,
    public effectivenessRate = 0,
    public comments: CommentData[] = [],
  ) {}
}

export type TaskSource =
  | 'NONE'
  | 'TASK'
  | 'INSPECTION'
  | 'TOLERANCE_VIOLATION'
  | 'PROJECT'
  | 'PROCESS_DESIGNER'
  | 'DOCUMENT_RELEASE_PROCESS';
export type TaskStatus = 'NEW' | 'IN_PROGRESS' | 'WAITING' | 'DONE' | 'ARCHIVED' | 'EFFECTIVENESS_CHECK' | 'RELEASED';

export type TaskUpdateData = Partial<Omit<Task, 'comments'> & { comments: CommentData }>;

export class TaskSourceData {
  id: string;
  _class: string;
}

export class InspectionTaskSourceData extends TaskSourceData {
  readonly _class = 'de.pickert.module.task.domain.sourcedata.InspectionTaskSourceData';

  constructor(
    public processDiagramId?: string,
    public processInstanceId?: string,
    public stepId?: string,
    public characteristicId?: string,
    public indexInSample?: number,
    public sourceText?: Textstore,
    public inspectionType?: 'INCOMING' | 'SPC' | 'OUTGOING' | 'PRODUCTION',
    public level?: 'INSTANCE' | 'STEP' | 'CHARACTERISTIC' | 'SAMPLE',
  ) {
    super();
    this.level = 'INSTANCE';

    if (stepId) this.level = 'STEP';
    if (characteristicId) this.level = 'CHARACTERISTIC';
    if (indexInSample) this.level = 'SAMPLE';
  }
}

export class InspectionDecisionTaskData extends TaskSourceData {
  readonly _class = 'de.pickert.module.task.domain.sourcedata.InspectionDecisionTaskData';

  constructor(
    public processDiagramId?: string,
    public processInstanceId?: string,
    public stepId?: string,
    public characteristicId?: string,
    public indexInSample?: number,
    public sourceText?: Textstore,
    public inspectionType?: 'INCOMING' | 'SPC' | 'OUTGOING' | 'PRODUCTION',
  ) {
    super();
  }
}

export class ProjectTaskSourceData extends TaskSourceData {
  readonly _class = 'de.pickert.module.task.domain.sourcedata.ProjectTaskSourceData';

  constructor(
    public projectId: string,
    public sourceText?: Textstore,
  ) {
    super();
  }
}
