import { Task, TaskStatus } from './task.model';

export class TaskWorkflow {
  public static readonly scrumboardColumnStatuses: TaskStatus[] = ['NEW', 'IN_PROGRESS', 'WAITING', 'DONE'];
  public static readonly statusesWithEffectivenessCheck: TaskStatus[] = [
    'NEW',
    'IN_PROGRESS',
    'WAITING',
    'DONE',
    'ARCHIVED',
  ];
  public static readonly statusesWithoutEffectivenessCheck: TaskStatus[] = [
    'NEW',
    'IN_PROGRESS',
    'WAITING',
    'DONE',
    'ARCHIVED',
  ];

  public static getStatuses(task: Task) {
    if (task == null) return [];

    const statuses: string[] = task.checkEffectiveness
      ? [...this.statusesWithEffectivenessCheck]
      : [...this.statusesWithoutEffectivenessCheck];

    if (!statuses.includes(task.status)) statuses.push(task.status);
    if (task.status !== 'ARCHIVED') return statuses.filter((status) => status !== 'ARCHIVED');

    return statuses;
  }
}
