import { Action } from '@ngrx/store';
import { ActionWithPayload } from '../../../../../../shared';
import { IRestResponse, IRestError } from 'app/core/models/rest.model';
import { ProcessInstance } from '../process-instance.model';

export const TRY_LOAD_INITIAL_INSTANCE_LIST = '[Process Result List Loading Parameters] Try load initial diagrams';
export const TRY_LOAD_FILTERED_INSTANCE_LIST = '[Process Result Filter/Search] Load filtered instance list';
export const TRY_LOAD_SORTED_INSTANCE_LIST = '[Process Result Filter/Search] Load sorted instance list';

export const TRY_GET_INSTANCE_NEXT_PAGE = '[Process Result List Infinite Scroll] Try get instance next page';
export const GET_INSTANCE_NEXT_PAGE_SUCCESS = '[Process Result Api Response] Get instnace next page success';
export const GET_INSTANCE_NEXT_PAGE_ERROR = '[Process Result Api Response] Get instance next page error';

export const SET_SELECTED_INSTANCE = '[Process Result List Selection] Set selected instance';

export const TRY_UPDATE_INSTANCE = '[Process Result Form Update and Toggle Deleted] Try Update instance';
export const UPDATE_INSTANCE_SUCCESS = '[Process Result Api Response] Update instance success';
export const UPDATE_INSTANCE_ERROR = '[Process Result Api Response] Update instance error';

export const TOGGLE_DIAGRAM_DELETED =
  '[Process Result Toggle Deleted Header Button] Updated deleted flag in selected instance';
export const TRY_REMOVE_INSTANCE = '[Process Result Details Remove Button] Try Remove instance';
export const REMOVE_INSTANCE_SUCCESS = '[Process Result Api Response] Remove instance success';
export const REMOVE_INSTANCE_ERROR = '[Process Result Api Response] Remove instance error';

export const TRY_ROUTE_UPDATE_INSTANCE_DATA = '[Process Result Route Change] Try load route instance';
export const ROUTE_UPDATE_INSTANCE_DATA_SUCCESS = '[Process Result Route Api Response] route instance success';
export const ROUTE_UPDATE_INSTANCE_DATA_ERROR = '[Process Result Route Api Response] route instance error';

export const TRY_START_PROCESS_INSTANCE = '[Process Result Play button] Try start process instance.';
export const START_PROCESS_INSTANCE_SUCCESS = '[Process Result Api response] Start process instance success.';
export const START_PROCESS_INSTANCE_ERROR = '[Process Result Api Response] Start process instance error.';

export const START_TRACKING_ROUTE_CHANGES = '[Process Result On Init] Start tracking route changes.';
export const STOP_TRACKING_ROUTE_CHANGES = '[Process Result On Destroy] Stop tracking route changes.';

export const START_TRACKING_LANG_CHANGES = '[Process Result On Init] Start tracking lang changes.';
export const STOP_TRACKING_LANG_CHANGES = '[Process Result On Destroy] Stop tracking lang changes.';

export const RESET_STATE = '[Process Result On Destroy] Restart state';

export const UPDATE_VIEW_ONLY_MODE = '[Process Result init] Update view only status';
export const LOAD_VIEW_ONLY_MODE = '[Process Resul Effects] Set if view only mode';

export class UpdateViewOnlyMode implements Action {
  readonly type = UPDATE_VIEW_ONLY_MODE;
}

export class LoadViewOnlyMode implements ActionWithPayload<boolean> {
  readonly type = LOAD_VIEW_ONLY_MODE;
  constructor(public payload: boolean) {}
}

export class ResetState implements Action {
  readonly type = RESET_STATE;
}

export class StartTrackingRouteChanges implements Action {
  readonly type = START_TRACKING_ROUTE_CHANGES;
}

export class StopTrackingRouteChanges implements Action {
  readonly type = STOP_TRACKING_ROUTE_CHANGES;
}

export class StartTrackingLangChanges implements Action {
  readonly type = START_TRACKING_LANG_CHANGES;
}

export class StopTrackingLangChanges implements Action {
  readonly type = STOP_TRACKING_LANG_CHANGES;
}

export class StartProcessInstanceSuccess implements ActionWithPayload<ProcessInstance> {
  readonly type = START_PROCESS_INSTANCE_SUCCESS;
  constructor(public payload: ProcessInstance) {}
}

export class TryToggleSelectedInstanceDeleted implements Action {
  readonly type = TOGGLE_DIAGRAM_DELETED;
}

export class TryStartProcessInstance implements Action {
  readonly type = TRY_START_PROCESS_INSTANCE;
}
export class StartProcessInstanceError implements Action {
  readonly type = START_PROCESS_INSTANCE_ERROR;
}

export class TryLoadInitialInstanceList implements Action {
  readonly type = TRY_LOAD_INITIAL_INSTANCE_LIST;
}
export class TryLoadFilteredInstanceList implements Action {
  readonly type = TRY_LOAD_FILTERED_INSTANCE_LIST;
}

export class TryLoadSortedInstanceList implements Action {
  readonly type = TRY_LOAD_SORTED_INSTANCE_LIST;
}

export class TryRouteUpdateInstanceData implements Action {
  readonly type = TRY_ROUTE_UPDATE_INSTANCE_DATA;
}
export class RouteUpdateInstanceDataSucces implements ActionWithPayload<string> {
  readonly type = ROUTE_UPDATE_INSTANCE_DATA_SUCCESS;
  constructor(public payload: string) {}
}

export class TryGetInstanceNextPage implements Action {
  readonly type = TRY_GET_INSTANCE_NEXT_PAGE;
}
export class GetInstanceNextPageSuccess implements ActionWithPayload<IRestResponse> {
  readonly type = GET_INSTANCE_NEXT_PAGE_SUCCESS;
  constructor(public payload: IRestResponse) {}
}
export class GetInstanceNextPageError implements ActionWithPayload<IRestError> {
  readonly type = GET_INSTANCE_NEXT_PAGE_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryUpdateInstance implements ActionWithPayload<ProcessInstance> {
  readonly type = TRY_UPDATE_INSTANCE;
  constructor(public payload: ProcessInstance) {}
}
export class UpdateInstanceSuccess implements ActionWithPayload<ProcessInstance> {
  readonly type = UPDATE_INSTANCE_SUCCESS;
  constructor(public payload: ProcessInstance) {}
}
export class UpdateInstanceError implements ActionWithPayload<IRestError> {
  readonly type = UPDATE_INSTANCE_ERROR;
  constructor(public payload: IRestError) {}
}

export class TryRemoveSelectedInstance implements Action {
  readonly type = TRY_REMOVE_INSTANCE;
}

export class RemoveInstanceSuccess implements Action {
  readonly type = REMOVE_INSTANCE_SUCCESS;
}
export class RemoveInstanceError implements ActionWithPayload<IRestError> {
  readonly type = REMOVE_INSTANCE_ERROR;
  constructor(public payload: IRestError) {}
}

export class SetSelectedInstance implements ActionWithPayload<string> {
  readonly type = SET_SELECTED_INSTANCE;
  constructor(public payload: string) {}
}

export type PDActions =
  | TryGetInstanceNextPage
  | GetInstanceNextPageSuccess
  | StartTrackingRouteChanges
  | StopTrackingRouteChanges
  | StartTrackingLangChanges
  | StopTrackingLangChanges
  | GetInstanceNextPageError
  | TryUpdateInstance
  | UpdateInstanceSuccess
  | UpdateInstanceError
  | TryRemoveSelectedInstance
  | RemoveInstanceSuccess
  | RemoveInstanceError
  | SetSelectedInstance
  | TryLoadInitialInstanceList
  | TryLoadFilteredInstanceList
  | TryLoadSortedInstanceList
  | TryRouteUpdateInstanceData
  | RouteUpdateInstanceDataSucces
  | TryStartProcessInstance
  | StartProcessInstanceSuccess
  | StartProcessInstanceError
  | TryToggleSelectedInstanceDeleted
  | UpdateViewOnlyMode
  | LoadViewOnlyMode
  | ResetState;
