<div id="error-404">
  <div class="content">
    <div class="error-code mat-headline-1">404</div>

    <div class="message mat-headline-3">Requested data not found</div>
    <br />

    <a class="back-link" [routerLink]="'/main-board'">{{ 'error.backToDashboard' | translate }}</a>
  </div>
</div>
