/* eslint-disable max-lines-per-function */
import {
  DashSettingsActions,
  CLEAR_SELECTED_DASHBOARD,
  GET_DASHBOARD_LIST_SUCCESS,
  GET_DASHBOARD_LIST_ERROR,
  TRY_ADD_DASHBOARD,
  ADD_DASHBOARD_SUCCESS,
  ADD_DASHBOARD_ERROR,
  UPDATE_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD_ERROR,
  REMOVE_SELECTED_DASHBOARD_SUCCESS,
  REMOVE_SELECTED_DASHBOARD_ERROR,
  TRY_UPDATE_DASHBOARD,
  TRY_GET_SEARCH_TEXT_DASHBOARD_LIST,
  TRY_GET_NEXT_DASHBOARD_PAGE,
  SET_SELECTED_DASHBOARD_FROM_ROUTE,
  SET_SELECTED_DASHBOARD_NEW,
  SELECTED_ROUTED_LIST_DASHBOARD,
  SELECTED_ROUTED_SAME_PARENT_DASHBOARD,
  SELECTED_ROUTED_DIFFERENT_PARENT_DASHBOARD,
  ROUTED_TO_ROOT_USER_DASHBOARDS,
  SELECT_PARENT_DASHBOARD,
  GET_BREADCRUMB_SUCCESS,
  EMPTY_BREADCRUMB,
  OPEN_FOLDER,
  DRAG_SORT_DASHBOARD_SUCCESS,
  TRY_DRAG_SORT_DASHBOARD,
  DRAG_SORT_DASHBOARD_ERROR,
  GET_ROOT_DASHBOARD_SUCCESS,
  TRY_COPY_SELECTED_DASHBOARD,
  COPY_SELECTED_DASHBOARD_ERROR,
  COPY_SELECTED_DASHBOARD_SUCCESS,
  IMPORT_DASHBOARDS_SUCCESS,
} from './board-settings.actions';

import { Dashboard } from '../../board/models/board.model';
import { ITEMS_PER_PAGE } from 'app/shared';
import { produce } from 'immer';

export interface State {
  dashboardList: Dashboard[];
  selectedDashboardId: string;
  activeFilter: 'shared' | 'user' | 'strategy-canvas';
  searchText: string;
  queryParams: any;
  loading: boolean;
  parentDashboardId: string;
  breadcrumb: Dashboard[];
  rootDashboardId: string;
}

const initialState: State = {
  dashboardList: [],
  selectedDashboardId: null,
  activeFilter: null,
  searchText: '',
  loading: false,
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: ['id,desc'],
  },
  parentDashboardId: undefined,
  breadcrumb: [],
  rootDashboardId: null,
};

export const dashboardSettingsReducer = produce((draft: State, action: DashSettingsActions) => {
  switch (action.type) {
    case GET_ROOT_DASHBOARD_SUCCESS:
      draft.rootDashboardId = action.payload.id;

      return;
    case SELECT_PARENT_DASHBOARD:
      draft.selectedDashboardId = null;
      draft.activeFilter = 'user';

      if (draft.parentDashboardId === action.payload) return;

      draft.loading = true;
      draft.queryParams.page = 0;
      draft.dashboardList = [];
      draft.parentDashboardId = action.payload;

      const selectedParentBcIndex = draft.breadcrumb.findIndex(({ id }) => id === draft.parentDashboardId);

      if (selectedParentBcIndex === -1) draft.breadcrumb = [];
      else draft.breadcrumb.length = selectedParentBcIndex + 1;

      return;
    case ROUTED_TO_ROOT_USER_DASHBOARDS:
      draft.selectedDashboardId = null;
      draft.activeFilter = 'user';
      draft.breadcrumb = [];

      if (draft.parentDashboardId == null || draft.parentDashboardId === draft.rootDashboardId) return;

      draft.parentDashboardId = null;
      draft.loading = true;
      draft.queryParams.page = 0;
      draft.dashboardList = [];
      draft.parentDashboardId = null;

      return;
    case GET_BREADCRUMB_SUCCESS:
      draft.breadcrumb = action.payload;

      return;
    case TRY_GET_SEARCH_TEXT_DASHBOARD_LIST:
      draft.loading = true;
      draft.queryParams.page = 0;
      draft.searchText = action.payload;
      draft.dashboardList = [];
      draft.selectedDashboardId = null;

      return;
    case SELECTED_ROUTED_LIST_DASHBOARD:
      draft.loading = false;
      draft.selectedDashboardId = action.payload;

      return;
    case OPEN_FOLDER:
      draft.breadcrumb.push(draft.dashboardList.find(({ id }) => id === action.payload));

      return;
    case SELECTED_ROUTED_SAME_PARENT_DASHBOARD:
      draft.loading = false;
      draft.selectedDashboardId = action.payload.id;

      return;
    case SELECTED_ROUTED_DIFFERENT_PARENT_DASHBOARD:
      draft.activeFilter = 'user';
      draft.selectedDashboardId = action.payload.id;
      draft.parentDashboardId = action.payload.parentId;
      draft.queryParams.page = 0;
      draft.dashboardList = [];

      const parentBcIndex = draft.breadcrumb.findIndex(({ id }) => id === draft.parentDashboardId);

      if (parentBcIndex === -1) draft.breadcrumb = [];
      else draft.breadcrumb.length = parentBcIndex + 1;

      return;
    case EMPTY_BREADCRUMB:
      draft.breadcrumb = [];

      return;
    case SET_SELECTED_DASHBOARD_NEW:
      draft.selectedDashboardId = null;
      return;

    case TRY_GET_NEXT_DASHBOARD_PAGE:
      draft.loading = true;

      return;
    case GET_DASHBOARD_LIST_SUCCESS:
      //TODO: EMRE - this is a temporary fix, we should fix the backend to not return duplicates
      const uniqueDashboards = action.payload.filter(
        (dashboard, index, self) => index === self.findIndex((t) => t.id === dashboard.id),
      );
      draft.dashboardList = [...draft.dashboardList, ...uniqueDashboards];

      draft.loading = false;
      if (action.payload?.length > 0) draft.queryParams.page += 1;

      return;
    case DRAG_SORT_DASHBOARD_SUCCESS:
      draft.loading = false;
      const movedDashboard = draft.dashboardList.find(({ id }) => id === action.payload.dashboardId);

      if (movedDashboard === null) return;

      draft.dashboardList = draft.dashboardList.filter(({ id }) => id !== action.payload.dashboardId);
      draft.dashboardList.splice(action.payload.index, 0, movedDashboard);

      return;
    case CLEAR_SELECTED_DASHBOARD:
      draft.selectedDashboardId = null;
      draft.loading = false;

      return;
    case ADD_DASHBOARD_SUCCESS:
      //TODO: EMRE - look at this again and refactor
      const immutableIndex = draft.dashboardList.findIndex((dash) => dash.isSystemData);
      const newDashboards = [
        ...draft.dashboardList.slice(0, immutableIndex),
        action.payload,
        ...draft.dashboardList.slice(immutableIndex),
      ];
      draft.dashboardList = newDashboards;
      draft.loading = false;

      return;
    case IMPORT_DASHBOARDS_SUCCESS:
      draft.loading = false;
      const parentDashboards = action.payload.filter((dash) => dash.isParent);
      draft.dashboardList = [...draft.dashboardList, ...parentDashboards];

      return;
    case SET_SELECTED_DASHBOARD_FROM_ROUTE:
      draft.selectedDashboardId = action.payload;

      return;
    case UPDATE_DASHBOARD_SUCCESS:
      draft.loading = false;
      draft.dashboardList = draft.dashboardList.map((item) => (item.id === action.payload.id ? action.payload : item));

      return;
    case TRY_COPY_SELECTED_DASHBOARD:
    case TRY_UPDATE_DASHBOARD:
    case TRY_ADD_DASHBOARD:
    case TRY_DRAG_SORT_DASHBOARD:
      draft.loading = true;
      return;
    case COPY_SELECTED_DASHBOARD_SUCCESS:
      //TODO: EMRE - look at this again and refactor
      const updatedImmutableIndex = draft.dashboardList.findIndex((dash) => dash.isSystemData);
      const updatedDashboards = [
        ...draft.dashboardList.slice(0, updatedImmutableIndex),
        action.payload,
        ...draft.dashboardList.slice(updatedImmutableIndex),
      ];
      draft.dashboardList = updatedDashboards;
      draft.loading = false;
      return;
    case COPY_SELECTED_DASHBOARD_ERROR:
    case UPDATE_DASHBOARD_ERROR:
    case REMOVE_SELECTED_DASHBOARD_ERROR:
    case GET_DASHBOARD_LIST_ERROR:
    case ADD_DASHBOARD_ERROR:
    case REMOVE_SELECTED_DASHBOARD_ERROR:
    case DRAG_SORT_DASHBOARD_ERROR:
      draft.loading = false;

      return;
    case REMOVE_SELECTED_DASHBOARD_SUCCESS:
      draft.dashboardList = draft.dashboardList.filter((dash) => action.payload !== dash.id);
      draft.loading = false;
  }
}, initialState);

export const getDashboardList = (state: State) => state.dashboardList;

export const getSelectedDashboardId = (state: State) => state.selectedDashboardId;

export const getActiveFilter = (state: State) => state.activeFilter;

export const getSearchText = (state: State) => state.searchText;

export const getQueryParams = (state: State) => state.queryParams;

export const getLoading = (state: State) => state.loading;

export const getParentDashboardId = (state: State) => state.parentDashboardId;

export const getBreadcrumb = (state: State) => state.breadcrumb;

export const getRootDashboardId = (state: State) => state.rootDashboardId;
