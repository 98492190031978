import {
  InspectionResultActions,
  UPDATE_INSTANCE_ERROR,
  REMOVE_INSTANCE_ERROR,
  UPDATE_INSTANCE_SUCCESS,
  REMOVE_INSTANCE_SUCCESS,
  SET_SELECTED_INSTANCE,
  TRY_GET_INSTANCE_NEXT_PAGE,
  GET_INSTANCE_NEXT_PAGE_SUCCESS,
  GET_INSTANCE_NEXT_PAGE_ERROR,
  TRY_LOAD_FILTERED_INSTANCE_LIST,
  RESET_STATE,
  TRY_LOAD_SORTED_INSTANCE_LIST,
  ROUTE_UPDATE_INSTANCE_DATA_SUCCESS,
  TRY_LOAD_INITIAL_INSTANCE_LIST,
  TRY_START_PROCESS_INSTANCE,
  START_PROCESS_INSTANCE_SUCCESS,
  START_PROCESS_INSTANCE_ERROR,
  LOAD_VIEW_ONLY_MODE,
} from './inspection-result.actions';

import { ITEMS_PER_PAGE } from '../../../../../../shared';
import { produce } from 'immer';
import { ProcessInstance } from '../../process-result/process-instance.model';

export interface State {
  inspectionInstanceList: ProcessInstance[];
  selectedInspectionInstance: ProcessInstance;
  loading: boolean;
  sortOrder: 'asc' | 'desc';
  queryParams: { page: number; size: number; sort: string[] };
  viewOnly: boolean;
}

const initialState: State = {
  inspectionInstanceList: [],
  selectedInspectionInstance: null,
  loading: false,
  sortOrder: 'asc',
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: ['number, asc', 'id,desc'],
  },
  viewOnly: true,
};

export const inspectionResultReducer = produce((draft: State, action: InspectionResultActions): void => {
  switch (action.type) {
    case LOAD_VIEW_ONLY_MODE:
      draft.viewOnly = action.payload;

      return;
    case TRY_LOAD_INITIAL_INSTANCE_LIST:
      draft.loading = true;
      draft.selectedInspectionInstance = null;
      draft.inspectionInstanceList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = initialState.sortOrder;

      return;
    case TRY_LOAD_SORTED_INSTANCE_LIST:
      draft.loading = true;
      draft.selectedInspectionInstance = null;
      draft.inspectionInstanceList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = draft.sortOrder === 'asc' ? 'desc' : 'asc';

      return;
    case TRY_LOAD_FILTERED_INSTANCE_LIST:
      draft.selectedInspectionInstance = null;
      draft.inspectionInstanceList = [];
      draft.queryParams.page = 0;

      return;
    case SET_SELECTED_INSTANCE:
      draft.selectedInspectionInstance = draft.inspectionInstanceList.find(
        (processResult) => processResult.id === action.payload,
      );

      return;
    case TRY_START_PROCESS_INSTANCE:
    case TRY_GET_INSTANCE_NEXT_PAGE:
      draft.loading = true;

      return;
    case ROUTE_UPDATE_INSTANCE_DATA_SUCCESS:
      draft.selectedInspectionInstance =
        action.payload && draft.inspectionInstanceList.find((processResult) => processResult.id === action.payload);

      return;
    case GET_INSTANCE_NEXT_PAGE_ERROR:
    case UPDATE_INSTANCE_ERROR:
    case REMOVE_INSTANCE_ERROR:
    case START_PROCESS_INSTANCE_SUCCESS:
    case START_PROCESS_INSTANCE_ERROR:
      draft.loading = false;

      return;
    case GET_INSTANCE_NEXT_PAGE_SUCCESS:
      draft.loading = false;
      draft.inspectionInstanceList.push(...action.payload.data);
      if (action.payload.data.length > 0) draft.queryParams.page += 1;

      return;
    case UPDATE_INSTANCE_SUCCESS:
      draft.inspectionInstanceList =
        draft.selectedInspectionInstance?.deleted === action.payload?.deleted
          ? draft.inspectionInstanceList.map((item) => (item.id === action.payload.id ? action.payload : item))
          : draft.inspectionInstanceList.filter((item) => item.id !== action.payload.id);

      draft.selectedInspectionInstance =
        draft.selectedInspectionInstance?.id !== action.payload.id
          ? draft.selectedInspectionInstance
          : draft.selectedInspectionInstance?.deleted === action.payload?.deleted
            ? action.payload
            : null;

      return;
    case REMOVE_INSTANCE_SUCCESS:
      draft.inspectionInstanceList = draft.inspectionInstanceList.filter(
        (item) => draft.selectedInspectionInstance.id !== item.id,
      );
      draft.selectedInspectionInstance = null;

      return;
    case RESET_STATE:
      Object.assign(draft, initialState);
  }
}, initialState);

export const getInspectionResultList = (state: State) => state.inspectionInstanceList;

export const getQueryParams = (state: State) => state.queryParams;

export const getSelectedInspectionResult = (state: State) => state.selectedInspectionInstance;

export const getInspectionResultLoading = (state: State) => state.loading;

export const getInspectionResultSortOrder = (state: State) => state.sortOrder;

export const getViewOnlyMode = (state: State) => state.viewOnly;
