/* eslint-disable max-lines-per-function */
import {
  TMActions,
  TRY_GET_TASK_LIST,
  GET_TASK_LIST_SUCCESS,
  GET_TASK_LIST_ERROR,
  ADD_TASK_SUCCESS,
  ADD_TASK_ERROR,
  UPDATE_TASK_ERROR,
  REMOVE_TASK_ERROR,
  TRY_GET_TASK_NEXT_PAGE,
  GET_TASK_NEXT_PAGE_SUCCESS,
  GET_TASK_NEXT_PAGE_ERROR,
  FIND_TASK_SUCCESS,
  FIND_TASK_ERROR,
  CLEAR_TASK_LIST,
  TRY_FIND_TASK,
  TRY_UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  TRY_REMOVE_TASK,
  FIND_TASK_HISTORY_SUCCESS,
  CLEAR_HISTORY_QUERY_PARAMS,
  CLEAR_HISTORY_LIST,
  SET_SELECTED_TASK_NEW,
  RESET_TASK_MANAGER,
  SKIP_TASK_UPDATE,
  UPDATE_TASK_FIELDS,
  UNDO_TASK_SUCCESS,
  REMOVE_TASK_SUCCESS,
  UPDATE_COMMENTS_SUCCESS,
  UPDATE_COMMENTS_ERROR,
  TRY_UPDATE_COMMENTS,
  ADD_RECIEVED_TASK,
  DELETE_RECIEVED_TASK,
  UPDATE_EFFECTIVENESS_COMMENTS_SUCCESS,
  TRY_GET_FILTERED_TASK_LIST,
  TRY_GET_ORDERED_TASK_LIST,
  TRY_MOVE_TASK_TO_DELETED,
  MOVE_TASK_TO_DELETED_SUCCESS,
  HIDE_PRIVATED_TASK,
  LOAD_SCHEDULED_JOB_SUCCESS,
  SCHEDULE_TASK_SUCCESS,
  UPDATE_SCHEDULE_JOB_SUCCESS,
  UNSET_SELECTED_TASK,
} from './task.actions';

import { Task } from './../task.model';
import { ITEMS_PER_PAGE } from '../../../../../shared';
import { Subscription } from 'rxjs';
import { OrderBy } from 'app/core/components/header-bar/header-buttons/order-by-list-button/order-by.model';
import { AppUtils } from 'app/core/utils/app.utils';
import { produce } from 'immer';
import { Project } from '../../project-manager/model/project.model';
import { ScheduledJob } from '@zero/angular-ui/zero-scheduler';

export interface State {
  taskList: Task[];
  selectedTask: Task;
  editMode: boolean;
  orderBy: OrderBy;
  totalItemsCount: number;
  queryParams: any;
  loading: boolean;
  notificationSubscription: Subscription;
  formSubscriptionId: string;
  lastTaskChanges: any;
  taskHistory: any[];
  historyQueryParams: any;
  historyTaskEntryCount: number;
  scheduledJob: ScheduledJob;
  sourceProject: Project;
}

const initialState: State = {
  taskList: [],
  selectedTask: null,
  editMode: true,
  orderBy: new OrderBy('dueDate', 'asc'),
  totalItemsCount: null,
  loading: false,
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: ['id,desc'],
  },
  historyQueryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: ['id,desc'],
  },
  notificationSubscription: null,
  formSubscriptionId: AppUtils.generateGUID(),
  lastTaskChanges: null,
  taskHistory: [],
  historyTaskEntryCount: null,
  scheduledJob: null,
  sourceProject: null,
};

export const taskReducer = produce((draft: State, action: TMActions) => {
  switch (action.type) {
    case UNSET_SELECTED_TASK:
      draft.loading = false;
      draft.selectedTask = null;
      draft.taskHistory = [];
      draft.scheduledJob = null;
      draft.queryParams = initialState.queryParams;
      draft.historyQueryParams = initialState.historyQueryParams;
      draft.editMode = true;
      draft.lastTaskChanges = null;
      draft.historyTaskEntryCount = null;

      return;
    case UPDATE_EFFECTIVENESS_COMMENTS_SUCCESS:
      if (action.payload.id === draft.selectedTask?.id) {
        draft.selectedTask.effectivenessAnalysis.comments = action.payload.comments;
      }

      return;
    case CLEAR_HISTORY_LIST:
      draft.taskHistory = [];

      return;
    case FIND_TASK_HISTORY_SUCCESS:
      draft.historyTaskEntryCount = action.payload.totalItems;
      draft.taskHistory.push(...action.payload.data);
      draft.historyQueryParams.page = draft.historyQueryParams.page + 1;

      return;
    case SKIP_TASK_UPDATE:
      return;
    case TRY_UPDATE_TASK:
    case TRY_UPDATE_COMMENTS:
    case TRY_UPDATE_TASK:
    case TRY_REMOVE_TASK:
      return;
    case RESET_TASK_MANAGER:
      draft = initialState;

      return;
    case CLEAR_TASK_LIST:
      draft.taskList = [];

      return;
    case SET_SELECTED_TASK_NEW:
      draft.selectedTask = new Task();
      draft.selectedTask.source = 'TASK';
      draft.editMode = false;

      return;
    case TRY_FIND_TASK:
      draft.loading = true;
      draft.editMode = true;
      draft.selectedTask = null;
      draft.scheduledJob = null;

      return;

    case GET_TASK_LIST_SUCCESS:
      draft.taskList = action.payload.data.map((task) =>
        draft.selectedTask && draft.selectedTask.id === task.id ? draft.selectedTask : task,
      );
      draft.totalItemsCount = action.payload.totalItems;
      draft.loading = false;

      return;
    case GET_TASK_LIST_ERROR:
    case GET_TASK_NEXT_PAGE_ERROR:
    case ADD_TASK_ERROR:
    case FIND_TASK_ERROR:
      draft.loading = false;

      return;
    case TRY_GET_TASK_NEXT_PAGE:
      draft.loading = true;

      return;
    case TRY_GET_TASK_LIST:
      draft.queryParams.page = 0;
      draft.taskList = [];
      draft.loading = true;

      return;
    case TRY_GET_FILTERED_TASK_LIST:
      draft.queryParams.page = 0;
      draft.taskList = [];
      draft.loading = true;
      draft.selectedTask = null;
      draft.scheduledJob = null;

      return;
    case TRY_GET_ORDERED_TASK_LIST:
      draft.queryParams.page = 0;
      draft.taskList = [];
      draft.loading = true;
      draft.selectedTask = null;
      draft.scheduledJob = null;
      draft.orderBy = action.payload;

      return;
    case GET_TASK_NEXT_PAGE_SUCCESS:
      draft.taskList.push(...action.payload.data);
      draft.loading = false;
      if (draft.taskList.length > 0) draft.queryParams.page += 1;
      draft.totalItemsCount = action.payload.totalItems;

      return;
    case ADD_TASK_SUCCESS:
      const existsInList = draft.taskList.some((t) => t.id === action.payload.id);
      draft.editMode = true;
      draft.selectedTask = { ...new Task(), ...action.payload };
      draft.totalItemsCount += existsInList ? 0 : 1;

      if (!existsInList) draft.taskList.unshift(action.payload);

      return;
    case ADD_RECIEVED_TASK:
      const wasRecieved = draft.taskList.some((t) => t.id === action.payload.id);
      draft.totalItemsCount += wasRecieved ? 0 : 1;
      if (!wasRecieved) draft.taskList.unshift(action.payload);

      return;
    case CLEAR_HISTORY_QUERY_PARAMS:
      draft.historyQueryParams.page = 0;

      return;
    case UPDATE_TASK_FIELDS:
      const taskIndex = draft.taskList.findIndex((task) => task.id === action.payload.id);
      if (taskIndex === -1) return;

      if (action.payload.id === draft.selectedTask?.id) {
        draft.selectedTask = {
          ...draft.selectedTask,
          ...action.payload,
          effectivenessAnalysis: {
            ...draft.selectedTask.effectivenessAnalysis,
            ...(action.payload || {}),
          },
        };
      }

      draft.taskList.splice(taskIndex, 1, {
        ...draft.taskList[taskIndex],
        ...action.payload,
        effectivenessAnalysis: {
          ...draft.taskList[taskIndex]?.effectivenessAnalysis,
          ...(action.payload || {}),
        },
      });

      if (action.payload.id === draft.selectedTask?.id) draft.lastTaskChanges = action.payload;

      return;
    case UPDATE_COMMENTS_SUCCESS:
      const updateCommentsIndex = draft.taskList.findIndex((task) => task.id === action.payload.id);
      if (updateCommentsIndex === -1) return { ...draft };
      draft.selectedTask.comments = [...action.payload.comments];

      draft.taskList.splice(updateCommentsIndex, 1, {
        ...draft.taskList[updateCommentsIndex],
        comments: [...action.payload.comments],
      });

      return;
    case UNDO_TASK_SUCCESS:
      const updateIndexUndo = draft.taskList.findIndex((task) => task.id === action.payload.id);
      if (updateIndexUndo === -1) return;

      if (action.payload.id === draft.selectedTask?.id) draft.lastTaskChanges = action.payload;

      draft.taskList.splice(updateIndexUndo, 1, {
        ...draft.taskList[updateIndexUndo],
        ...action.payload,
        effectivenessAnalysis: {
          ...draft.taskList[updateIndexUndo]?.effectivenessAnalysis,
          ...(action.payload?.effectivenessAnalysis || {}),
        },
      });

      return;
    case UPDATE_TASK_SUCCESS:
      const updateIndex = draft.taskList.findIndex((task) => task.id === action.payload.id);
      if (updateIndex === -1) return;
      draft.selectedTask =
        action.payload.id === draft.selectedTask?.id
          ? {
              ...draft.selectedTask,
              ...action.payload,
              effectivenessAnalysis: {
                ...draft.selectedTask?.effectivenessAnalysis,
                ...(action.payload?.effectivenessAnalysis || {}),
              },
            }
          : draft.selectedTask;

      draft.taskList.splice(updateIndex, 1, {
        ...draft.taskList[updateIndex],
        ...action.payload,
        effectivenessAnalysis: {
          ...draft.taskList[updateIndex]?.effectivenessAnalysis,
          ...(action.payload?.effectivenessAnalysis || {}),
        },
      });

      return;
    case TRY_MOVE_TASK_TO_DELETED:
      return;
    case HIDE_PRIVATED_TASK:
      draft.taskList = draft.taskList.filter((task) => task.id !== draft.selectedTask?.id);

      draft.selectedTask = null;
      return;
    case MOVE_TASK_TO_DELETED_SUCCESS:
      draft.selectedTask = null;
      draft.taskList = draft.taskList.filter((item) => action.payload.id !== item.id);

      return;
    case UPDATE_COMMENTS_ERROR:
    case UPDATE_TASK_ERROR:
    case REMOVE_TASK_ERROR:
      draft.loading = false;

      return;
    case REMOVE_TASK_SUCCESS:
    case DELETE_RECIEVED_TASK:
      draft.totalItemsCount -= draft.taskList.some((t) => action.payload === t.id) ? 0 : 1;
      draft.taskList = draft.taskList.filter((item) => action.payload !== item.id);
      draft.selectedTask = draft.selectedTask?.id === action.payload ? null : draft.selectedTask;
      draft.loading = false;

      return;
    case LOAD_SCHEDULED_JOB_SUCCESS:
    case SCHEDULE_TASK_SUCCESS:
    case UPDATE_SCHEDULE_JOB_SUCCESS:
      draft.scheduledJob = action.payload;

      return;
    case FIND_TASK_SUCCESS:
      draft.loading = false;
      draft.editMode = true;

      if (!action.payload) {
        draft.selectedTask = null;
        return;
      }
      draft.selectedTask = { ...new Task(), ...action.payload };

      draft.taskList = draft.taskList.map((item: Task) => (item.id === action.payload.id ? action.payload : item));
  }
}, initialState);

export const getFormSubscriptionId = (state: State) => state.formSubscriptionId;

export const getTaskList = (state: State) => state.taskList;

export const getSelectedTask = (state: State) => state.selectedTask;

export const getEditMode = (state: State) => state.editMode;

export const getOrderBy = (state: State) => state.orderBy;

export const getTotalItemsCount = (state: State) => state.totalItemsCount;

export const getQueryParams = (state: State) => state.queryParams;

export const getLoading = (state: State) => state.loading;

export const getLastTaskChanges = (state: State) => state.lastTaskChanges;

export const getTaskHistory = (state: State) => state.taskHistory;

export const getHistoryQueryParams = (state: State) => state.historyQueryParams;

export const getHistoryTaskEntryCount = (state: State) => state.historyTaskEntryCount;

export const getSelectedScheduledJob = (state: State) => state.scheduledJob;
