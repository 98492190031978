/* eslint-disable max-lines-per-function */
import {
  CAT_Actions,
  TRY_ADD_CATALOG,
  ADD_CATALOG_SUCCESS,
  TRY_UPDATE_CATALOG,
  UPDATE_CATALOG_SUCCESS,
  TRY_REMOVE_CATALOG,
  REMOVE_CATALOG_SUCCESS,
  SELECT_CATALOG,
  TRY_GET_CATALOG_NEXT_PAGE,
  GET_CATALOG_NEXT_PAGE_SUCCESS,
  GET_CATALOG_NEXT_PAGE_ERROR,
  SET_DISPLAY_MODE,
  TRY_FIND_CATALOG_CHILDREN,
  TRY_GET_CATALOG_CHILDREN_NEXT_PAGE,
  GET_CATALOG_CHILDREN_NEXT_PAGE_SUCCESS,
  GET_CATALOG_CHILDREN_NEXT_PAGE_ERROR,
  SET_SELECTED_CATALOG_NEW,
  SET_SELECTED_CATALOG_NEW_FROM_SELECTED,
  ADD_CATALOG_ERROR,
  REMOVE_CATALOG_ERROR,
  RESET_CATALOG_LIST_VIEW,
  SELECT_CHILD_CATALOG,
  ADD_CATALOG_CHILDREN_SUCCESS,
  SELECT_CATALOG_PARENT_SUCCESS,
  TRY_SELECT_CATALOG_PARENT,
  SELECT_CATALOG_PARENT_ERROR,
  TRY_GET_SEARCHED_CATALOG_LIST,
  TRY_GET_SORTED_CATALOGS,
  FIND_CATALOG_LIBRARY_ITEM_SUCCESS,
  NAVIGATE_TO_BREADCRUMB_ITEM_SUCCESS,
  TRY_NAVIGATE_TO_BREADCRUMB_ITEM,
  NAVIGATE_TO_BREADCRUMB_ITEM_ERROR,
  TRY_DRAG_CATALOG,
  DRAG_CATALOG_SUCCESS,
  DRAG_CATALOG_ERROR,
  TRY_UPDATE_BRIDGE,
  UPDATE_BRIDGE_ERROR,
  UPDATE_BRIDGE_SUCCESS,
  GET_SEARCHED_CATALOG_LIST_SUCCESS,
  GET_SEARCHED_CATALOG_LIST_ERROR,
  DESELECT_CATALOG,
  OPEN_CHILD_LIST_VIEW,
} from './catalog.actions';

import { ITEMS_PER_PAGE } from '../../../../../../shared';
import { Catalog } from '../catalog.model';
import { CatalogView } from '../catalog-views';
import { findActiveSortProperty } from './catalog.helper';

export interface State {
  catalogLibraryItem: any;
  rootCatalog: Catalog;
  catalogList: Catalog[];
  selectedCatalog: Catalog;
  editMode: boolean;
  searchText: string;
  queryParams: any;
  loading: boolean;
  sortParam: {
    value: string;
    label: string;
    direction: 'asc' | 'desc';
    isTextstore: boolean;
  };
  displayMode: 'catalog-children' | 'catalog-data';
}

const initialState: State = {
  catalogLibraryItem: null,
  rootCatalog: null,
  catalogList: [],
  selectedCatalog: null,
  editMode: true,
  searchText: '',
  loading: false,
  sortParam: null,
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
  },
  displayMode: CatalogView.DATA,
};

export function catReducer(state = initialState, action: CAT_Actions): State {
  switch (action.type) {
    case DESELECT_CATALOG:
      return {
        ...state,
        selectedCatalog: null,
      };
    case NAVIGATE_TO_BREADCRUMB_ITEM_SUCCESS:
      return {
        ...state,
        catalogList: [],
        selectedCatalog: action.payload,
        queryParams: initialState.queryParams,
        rootCatalog: action.payload.parent,
        editMode: true,
        loading: false,
      };
    case TRY_GET_SEARCHED_CATALOG_LIST:
      return {
        ...state,
        loading: true,
        selectedCatalog: null,
        catalogList: [],
        queryParams: initialState.queryParams,
        editMode: true,
      };
    case GET_SEARCHED_CATALOG_LIST_SUCCESS:
      return {
        ...state,
        catalogList: action.payload,
        loading: false,
        queryParams:
          action.payload.length > 0
            ? {
                ...state.queryParams,
                page: state.queryParams.page + 1,
              }
            : state.queryParams,
      };
    case TRY_GET_SORTED_CATALOGS:
      return {
        ...state,
        loading: true,
        selectedCatalog: null,
        catalogList: [],
        sortParam: action.payload,
        queryParams: initialState.queryParams,
        editMode: true,
      };
    case SELECT_CATALOG_PARENT_SUCCESS:
      return {
        ...state,
        selectedCatalog: action.payload,
        rootCatalog: action.payload?.parent,
        queryParams: initialState.queryParams,
        catalogList: [],
      };
    case SELECT_CHILD_CATALOG:
      return {
        ...state,
        rootCatalog: state.selectedCatalog,
        selectedCatalog: action.payload,
        catalogList: state.selectedCatalog.children,
        queryParams: state.queryParams,
      };
    case FIND_CATALOG_LIBRARY_ITEM_SUCCESS:
      return {
        ...initialState,
        catalogLibraryItem: action.payload,
        sortParam: findActiveSortProperty(action.payload),
      };
    case OPEN_CHILD_LIST_VIEW:
      return {
        ...state,
        displayMode: CatalogView.CHILDREN,
        selectedCatalog: action.payload,
        rootCatalog: state.selectedCatalog,
      };
    case GET_CATALOG_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        catalogList: [...state.catalogList, ...action.payload.data],
        loading: false,
        queryParams:
          action.payload.data.length > 0
            ? { ...state.queryParams, page: state.queryParams.page + 1 }
            : state.queryParams,
      };
    case ADD_CATALOG_SUCCESS:
      return {
        ...state,
        catalogList: [action.payload.data, ...state.catalogList],
        selectedCatalog: action.payload.data,
        displayMode: CatalogView.DATA,
        editMode: true,
        loading: false,
      };
    case ADD_CATALOG_CHILDREN_SUCCESS:
      return {
        ...state,
        selectedCatalog: action.payload.data,
        rootCatalog: action.payload.data.parent,
        catalogList: [],
        displayMode: CatalogView.DATA,
        editMode: true,
        loading: false,
        queryParams: initialState.queryParams,
      };
    case TRY_FIND_CATALOG_CHILDREN:
      return {
        ...state,
        selectedCatalog: {
          ...state.selectedCatalog,
          children: [],
        },
      };
    case TRY_SELECT_CATALOG_PARENT:
    case TRY_GET_CATALOG_CHILDREN_NEXT_PAGE:
      return {
        ...state,
      };
    case GET_CATALOG_CHILDREN_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        selectedCatalog: {
          ...state.selectedCatalog,
          children: [
            ...state.selectedCatalog.children,
            ...action.payload.data.filter(
              ({ id }) => !state.selectedCatalog.children.map(({ id: cid }) => cid).includes(id),
            ),
          ],
        },
        catalogList: state.displayMode === CatalogView.CHILDREN ? action.payload.data : state.catalogList,
      };
    case GET_SEARCHED_CATALOG_LIST_ERROR:
    case SELECT_CATALOG_PARENT_ERROR:
    case GET_CATALOG_CHILDREN_NEXT_PAGE_ERROR:
      return {
        ...state,
      };
    case TRY_REMOVE_CATALOG:
    case TRY_UPDATE_CATALOG:
    case TRY_ADD_CATALOG:
    case TRY_GET_CATALOG_NEXT_PAGE:
    case TRY_NAVIGATE_TO_BREADCRUMB_ITEM:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_CATALOG_ERROR:
    case DRAG_CATALOG_ERROR:
    case GET_CATALOG_NEXT_PAGE_ERROR:
    case ADD_CATALOG_ERROR:
    case NAVIGATE_TO_BREADCRUMB_ITEM_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SET_SELECTED_CATALOG_NEW_FROM_SELECTED:
      return {
        ...state,
        editMode: false,
        displayMode: CatalogView.DATA,
        selectedCatalog: {
          ...new Catalog(),
          hasChildren: action.payload.toLowerCase() === 'folder',
          parent: state.selectedCatalog,
          type: state.catalogLibraryItem.componentType,
        },
      };
    case SET_SELECTED_CATALOG_NEW:
      return {
        ...state,
        editMode: false,
        displayMode: CatalogView.DATA,
        selectedCatalog: {
          ...new Catalog(),
          hasChildren: action.payload.toLowerCase() === 'folder',
          parent: state.rootCatalog,
          type: state.catalogLibraryItem.componentType,
        },
      };
    case UPDATE_CATALOG_SUCCESS:
      return {
        ...state,
        catalogList: state.catalogList.map((item) => (item.id === action.payload.data.id ? action.payload.data : item)),
        selectedCatalog:
          state.selectedCatalog && state.selectedCatalog.id === action.payload.data.id
            ? {
                ...state.selectedCatalog,
                ...action.payload.data,
                children: state.selectedCatalog.children,
              }
            : state.selectedCatalog,
        loading: false,
      };
    case TRY_DRAG_CATALOG:
      const targetCatalog = state.selectedCatalog || state.rootCatalog || null;
      return {
        ...state,
        loading: true,
      };
    case DRAG_CATALOG_SUCCESS:
      return {
        ...state,
        catalogList: state.catalogList.filter((catalog) => catalog.id !== action.payload.data.id),
        selectedCatalog: state.selectedCatalog?.id !== action.payload.data.id ? state.selectedCatalog : null,
        loading: false,
      };
    case REMOVE_CATALOG_SUCCESS:
      return {
        ...state,
        catalogList: state.catalogList.filter((item) => state.selectedCatalog && state.selectedCatalog.id !== item.id),
        selectedCatalog: null,
        loading: false,
      };
    case RESET_CATALOG_LIST_VIEW:
      return {
        ...state,
        rootCatalog: null,
        selectedCatalog: null,
        catalogList: [],
        queryParams: initialState.queryParams,
        loading: false,
        editMode: true,
      };
    case SELECT_CATALOG:
      return {
        ...state,
        selectedCatalog: !action.payload
          ? null
          : !state.selectedCatalog || state.selectedCatalog.id !== action.payload.id
            ? action.payload
            : state.selectedCatalog,
        editMode: true,
        displayMode: CatalogView.DATA,
      };
    case SET_DISPLAY_MODE:
      return {
        ...state,
        displayMode: action.payload,
      };
    case TRY_UPDATE_BRIDGE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_BRIDGE_SUCCESS:
    case UPDATE_BRIDGE_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export const getCatLibItem = (state: State) => state.catalogLibraryItem;

export const getCatList = (state: State) => state.catalogList;

export const getSelectedCat = (state: State) => state.selectedCatalog;

export const getRootCat = (state: State) => state.rootCatalog;

export const getEditMode = (state: State) => state.editMode;

export const getSearchText = (state: State) => state.searchText;

export const getQueryParams = (state: State) => state.queryParams;

export const getDisplayMode = (state: State) => state.displayMode;

export const getLoading = (state: State) => state.loading;

export const getSortParam = (state: State) => state.sortParam;
