import { UserInfo } from '../../../../shared';
import { Textstore } from '../../../../shared/lang-dialog/textstore.model';
import { BaseModel } from 'app/core/base.model';

export const documentFileFieldTypes = {
  customName: 'textstore',
  description: 'textstore',
  documentType: 'catalog',
  responsible: 'user',
  status: 'status',
  organization: 'catalog',
  location: 'catalog',
  shared: 'boolean',
};

export type FileUIEvents =
  | 'copy_link'
  | 'download'
  | 'preview'
  | 'link'
  | 'change_history'
  | 'new_version'
  | 'version_history'
  | 'select'
  | 'delete';

export type DocumentStatus = 'NEW' | 'IN_RELEASE_PROCESS' | 'RELEASED' | 'INVALID';

/** Defines data model for documents */
export class DocumentStore extends BaseModel {
  constructor(
    public id?: string,
    public seq?: number,
    public fullOwnerName?: string,
    public fullResponsibleName?: string,
    public fullModifiedName?: string,
    public customName?: Textstore,
    public description?: Textstore,
    public shared = true,
    public versionControlled = false,
    public currentVersion: DocumentVersion = new DocumentVersion(),
    public documentVersions: DocumentVersion[] = [],
    public owner?: UserInfo,
    public responsible?: UserInfo,
    public ownerId?: string,
    public isFolder = false,
    public parent?: DocumentStore,
    public children: DocumentStore[] = [],
    public documentType?: string,
    public organization?: string,
    public location?: string,
    public organizationName?: Textstore,
    public lastChanged?: Date,
  ) {
    super();
  }
}

export class DocumentInfo {
  constructor(
    public id?: string,
    public customName?: Textstore,
    public documentDetails?: DocumentStore,
  ) {}
}

export class DocumentVersion {
  constructor(
    public id?: string,
    public version = '1',
    public status: DocumentStatus = 'NEW',
    public creationDate?: Date,
    public fileData = new FileData(),
  ) {}
}

export class FileData {
  constructor(
    public id?: string,
    public name?: string,
    public type?: string,
    public size?: number,
    public extension?: string,
    public doc?: any,
    public thumbnail?: any,
    public docContentType?: string,
    public linkName?: string,
  ) {}
}
