import { Injectable, RendererFactory2, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TitleHelperService {
  private renderer: Renderer2;

  constructor(
    private translateService: TranslateService,
    private rootRenderer: RendererFactory2,
    private titleService: Title,
    private router: Router,
  ) {
    this.renderer = this.rootRenderer.createRenderer(document.querySelector('html'), null);

    this.init();
  }

  private get rootSnapshot() {
    return this.router.routerState.snapshot.root;
  }

  /**
   * Update the window title using params in the following
   * order:
   * 1. titleKey parameter
   * 2. $state.$current.data.pageTitle (current state page title)
   * 3. 'global.title'
   */
  updateTitle(titleKey?: string): void {
    const pageTitle = this.getPageTitle(this.rootSnapshot);

    if (!titleKey && !pageTitle) return;

    this.translateService.get(titleKey ?? pageTitle).subscribe((title) => this.titleService.setTitle(title));
  }

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot, lastTitle = ''): string {
    lastTitle = routeSnapshot.data?.pageTitle ? routeSnapshot.data['pageTitle'] : lastTitle;
    if (!routeSnapshot.firstChild) return lastTitle;
    return this.getPageTitle(routeSnapshot.firstChild, lastTitle) || lastTitle;
  }

  private init(): void {
    this.translateService.onLangChange.subscribe(() => {
      this.renderer.setAttribute(document.querySelector('html'), 'lang', this.translateService.currentLang);
      this.updateTitle();
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.updateTitle();
    });
  }
}
