
/**
 * Defines properties by which user may be selected in the user list
 */
export class UserCriteria {
  constructor(
    public email?: string,
    public firstName?: string,
    public lastName?: string,
    public searchText?: string,
    public location?: string,
    public organisation?: string,
    public important?: boolean,
    public includeSystemUsers?: boolean,
    public role?: string,
  ) {}

  setActiveFilter(
    searchText?: string,
    criteria?: {
      email?: string;
      location?: string;
      organisation?: string;
      includeSystemUsers?: boolean;
      firstName?: string;
      lastName?: string;
      role?: string;
    },
  ): void {
    if (searchText !== '') this.searchText = searchText;

    this.email = criteria?.email;
    this.location = criteria?.location;
    this.organisation = criteria?.organisation;
    this.firstName = criteria?.firstName;
    this.lastName = criteria?.lastName;
    this.includeSystemUsers = criteria?.includeSystemUsers;
    this.role = criteria?.role;
  }
}
