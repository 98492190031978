import { Textstore } from '../../../../../shared/lang-dialog/textstore.model';

export class Role {
  constructor(
    public id?: string,
    public title?: Textstore,
    public description?: Textstore,
    public authorities?: Authority[],
    public isImmutable?: boolean,
  ) {}
}

export enum Authority {
  DASHBOARD_CREATE = 'DASHBOARD_CREATE',
  DASHBOARD_READ = 'DASHBOARD_READ',
  DASHBOARD_UPDATE = 'DASHBOARD_UPDATE',
  DASHBOARD_DELETE = 'DASHBOARD_DELETE',
  DASHBOARD_RELEASE = 'DASHBOARD_RELEASE',
  DASHBOARD_APPROVE = 'DASHBOARD_APPROVE',
  DASHBOARD_LOCK = 'DASHBOARD_LOCK',

  TASK_CREATE = 'TASK_CREATE',
  TASK_READ = 'TASK_READ',
  TASK_UPDATE = 'TASK_UPDATE',
  TASK_DELETE = 'TASK_DELETE',
  TASK_RELEASE = 'TASK_RELEASE',
  TASK_APPROVE = 'TASK_APPROVE',
  TASK_LOCK = 'TASK_LOCK',

  DOCUMENT_CREATE = 'DOCUMENT_CREATE',
  DOCUMENT_READ = 'DOCUMENT_READ',
  DOCUMENT_UPDATE = 'DOCUMENT_UPDATE',
  DOCUMENT_DELETE = 'DOCUMENT_DELETE',
  DOCUMENT_RELEASE = 'DOCUMENT_RELEASE',
  DOCUMENT_APPROVE = 'DOCUMENT_APPROVE',
  DOCUMENT_LOCK = 'DOCUMENT_LOCK',

  // CAQ.360 MODULES
  INSPECTION_CREATE = 'INSPECTION_CREATE',
  INSPECTION_READ = 'INSPECTION_READ',
  INSPECTION_UPDATE = 'INSPECTION_UPDATE',
  INSPECTION_DELETE = 'INSPECTION_DELETE',
  INSPECTION_RELEASE = 'INSPECTION_RELEASE',
  INSPECTION_APPROVE = 'INSPECTION_APPROVE',
  INSPECTION_LOCK = 'INSPECTION_LOCK',

  WE_INSPECTION_CREATE = 'WE_INSPECTION_CREATE',
  WE_INSPECTION_READ = 'WE_INSPECTION_READ',
  WE_INSPECTION_UPDATE = 'WE_INSPECTION_UPDATE',
  WE_INSPECTION_DELETE = 'WE_INSPECTION_DELETE',
  WE_INSPECTION_RELEASE = 'WE_INSPECTION_RELEASE',
  WE_INSPECTION_APPROVE = 'WE_INSPECTION_APPROVE',
  WE_INSPECTION_LOCK = 'WE_INSPECTION_LOCK',

  SPC_INSPECTION_CREATE = 'SPC_INSPECTION_CREATE',
  SPC_INSPECTION_READ = 'SPC_INSPECTION_READ',
  SPC_INSPECTION_UPDATE = 'SPC_INSPECTION_UPDATE',
  SPC_INSPECTION_DELETE = 'SPC_INSPECTION_DELETE',
  SPC_INSPECTION_RELEASE = 'SPC_INSPECTION_RELEASE',
  SPC_INSPECTION_APPROVE = 'SPC_INSPECTION_APPROVE',
  SPC_INSPECTION_LOCK = 'SPC_INSPECTION_LOCK',

  PRODUCTION_INSPECTION_CREATE = 'PRODUCTION_INSPECTION_CREATE',
  PRODUCTION_INSPECTION_READ = 'PRODUCTION_INSPECTION_READ',
  PRODUCTION_INSPECTION_UPDATE = 'PRODUCTION_INSPECTION_UPDATE',
  PRODUCTION_INSPECTION_DELETE = 'PRODUCTION_INSPECTION_DELETE',
  PRODUCTION_INSPECTION_RELEASE = 'PRODUCTION_INSPECTION_RELEASE',
  PRODUCTION_INSPECTION_APPROVE = 'PRODUCTION_INSPECTION_APPROVE',
  PRODUCTION_INSPECTION_LOCK = 'PRODUCTION_INSPECTION_LOCK',

  OUTGOING_INSPECTION_CREATE = 'OUTGOING_INSPECTION_CREATE',
  OUTGOING_INSPECTION_READ = 'OUTGOING_INSPECTION_READ',
  OUTGOING_INSPECTION_UPDATE = 'OUTGOING_INSPECTION_UPDATE',
  OUTGOING_INSPECTION_DELETE = 'OUTGOING_INSPECTION_DELETE',
  OUTGOING_INSPECTION_RELEASE = 'OUTGOING_INSPECTION_RELEASE',
  OUTGOING_INSPECTION_APPROVE = 'OUTGOING_INSPECTION_APPROVE',
  OUTGOING_INSPECTION_LOCK = 'OUTGOING_INSPECTION_LOCK',

  INSPECTION_EQUIPMENT_CREATE = 'INSPECTION_EQUIPMENT_CREATE',
  INSPECTION_EQUIPMENT_READ = 'INSPECTION_EQUIPMENT_READ',
  INSPECTION_EQUIPMENT_UPDATE = 'INSPECTION_EQUIPMENT_UPDATE',
  INSPECTION_EQUIPMENT_DELETE = 'INSPECTION_EQUIPMENT_DELETE',
  INSPECTION_EQUIPMENT_RELEASE = 'INSPECTION_EQUIPMENT_RELEASE',
  INSPECTION_EQUIPMENT_APPROVE = 'INSPECTION_EQUIPMENT_APPROVE',
  INSPECTION_EQUIPMENT_LOCK = 'INSPECTION_EQUIPMENT_LOCK',

  INSPECTION_PLAN_CREATE = 'INSPECTION_PLAN_CREATE',
  INSPECTION_PLAN_READ = 'INSPECTION_PLAN_READ',
  INSPECTION_PLAN_UPDATE = 'INSPECTION_PLAN_UPDATE',
  INSPECTION_PLAN_DELETE = 'INSPECTION_PLAN_DELETE',
  INSPECTION_PLAN_RELEASE = 'INSPECTION_PLAN_RELEASE',
  INSPECTION_PLAN_APPROVE = 'INSPECTION_PLAN_APPROVE',
  INSPECTION_PLAN_LOCK = 'INSPECTION_PLAN_LOCK',

  // MASTER DATA AND SETTINGS
  CATALOG_CREATE = 'CATALOG_CREATE',
  CATALOG_READ = 'CATALOG_READ',
  CATALOG_UPDATE = 'CATALOG_UPDATE',
  CATALOG_DELETE = 'CATALOG_DELETE',

  GLOBAL_OPTIONS_CREATE = 'GLOBAL_OPTIONS_CREATE',
  GLOBAL_OPTIONS_READ = 'GLOBAL_OPTIONS_READ',
  GLOBAL_OPTIONS_UPDATE = 'GLOBAL_OPTIONS_UPDATE',
  GLOBAL_OPTIONS_DELETE = 'GLOBAL_OPTIONS_DELETE',

  USER_CREATE = 'USER_CREATE',
  USER_DELETE = 'USER_DELETE',
  USER_READ = 'USER_READ',
  USER_UPDATE = 'USER_UPDATE',

  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_READ = 'ROLE_READ',
  ROLE_UPDATE = 'ROLE_UPDATE',
  ROLE_DELETE = 'ROLE_DELETE',

  LICENSE_CREATE = 'LICENSE_CREATE',
  LICENSE_READ = 'LICENSE_READ',
  LICENSE_UPDATE = 'LICENSE_UPDATE',
  LICENSE_DELETE = 'LICENSE_DELETE',

  BRIDGE_CREATE = 'BRIDGE_CREATE',
  BRIDGE_READ = 'BRIDGE_READ',
  BRIDGE_UPDATE = 'BRIDGE_UPDATE',
  BRIDGE_DELETE = 'BRIDGE_DELETE',

  // OTHER SPECIAL AUTHORITIES
  API_DOCUMENTATION = 'API_DOCUMENTATION',
  PROTOTYPE = 'PROTOTYPE',
  DEV = 'DEV',
}
