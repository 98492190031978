import { Action } from '@ngrx/store';
import { ActionWithPayload } from 'app/shared';
import { GlobalOptions } from '../global-options.model';

export const TRY_LOAD_GLOBAL_OPTIONS = '[Global Options] Try load global options';
export const LOAD_GLOBAL_OPTIONS_SUCCESS = '[Global Options] Load global options success';
export const LOAD_GLOBAL_OPTIONS_ERROR = '[Global Options] Load global options error';

export const TRY_UPDATE_GLOBAL_OPTIONS = '[Global Options] Try update global options';
export const UPDATE_GLOBAL_OPTIONS_SUCCESS = '[Global Options] Update global options success';
export const UPDATE_GLOBAL_OPTIONS_ERROR = '[Global Options] Update global options error';

export const RESET_STATE = '[Global Options] Reset global options state';

export class TryLoadGlobalOptions implements Action {
  readonly type = TRY_LOAD_GLOBAL_OPTIONS;
}

export class LoadGlobalOptionsSuccess implements ActionWithPayload<GlobalOptions> {
  readonly type = LOAD_GLOBAL_OPTIONS_SUCCESS;
  constructor(public payload: GlobalOptions) {}
}

export class LoadGlobalOptionsError implements Action {
  readonly type = LOAD_GLOBAL_OPTIONS_ERROR;
}

export class TryUpdateGlobalOptions implements ActionWithPayload<any> {
  readonly type = TRY_UPDATE_GLOBAL_OPTIONS;
  constructor(public payload: any) {}
}

export class UpdateGlobalOptionsSuccess
  implements
    ActionWithPayload<{
      options: GlobalOptions;
      translationsStyleChanged: boolean;
    }>
{
  readonly type = UPDATE_GLOBAL_OPTIONS_SUCCESS;
  constructor(
    public payload: {
      options: GlobalOptions;
      translationsStyleChanged: boolean;
    },
  ) {}
}

export class UpdateGlobalOptionsError implements Action {
  readonly type = UPDATE_GLOBAL_OPTIONS_ERROR;
}

export class ResetState implements Action {
  readonly type = RESET_STATE;
}

export type GlobalOptionsActions =
  | TryLoadGlobalOptions
  | LoadGlobalOptionsSuccess
  | LoadGlobalOptionsError
  | TryUpdateGlobalOptions
  | UpdateGlobalOptionsSuccess
  | UpdateGlobalOptionsError
  | ResetState;
