export enum ValidatorNames {
  KPI_ID_DUPLICATE = 'kpiIdDuplicate',
  RESULT_ID_DUPLICATE = 'resultIdDuplicate',
  REQUIRED = 'required',
  MIN = 'max',
  MAX = 'min',
  EMAIL = 'email',
  PATTERN = 'pattern',
  NUMBER = 'number',
  MINLENGTH = 'minlength',
  MAXLENGTH = 'maxlength',
  MINBYTES = 'minbytes',
  MAXBYTES = 'maxbytes',
  DATE_TIME_LOCAL = 'datetimelocal',
  POSTITIVE_INTEGER = 'positiveIntegerError',
  MIN_TEXT_LENGTH = 'minTextLengthErrorr',
  NON_EMPTY_STRING = 'nonEmptyStringErrorr',
  DASHBOARD_TITLE_NOT_UNIQUE = 'dashboardTitleNotUnique',
  USER_EMAIL_NOT_UNIQUE = 'userEmailNotUnique',
  CHECKPPOINT_ID = 'checkpointIdNotUnique',
}

export type IValidatorNamesEnumTpProp<T> = {
  [key in keyof typeof ValidatorNames]?: T;
};
