import { Characteristic, CharacteristicInspectionFlow } from 'app/core/components/characteristic/characteristic.model';
import { CharacteristicDataType } from 'app/main/content/apps/designers/shared/step-requirements/model/characteristic-type.model';

export function findBlockEndingCharacteristics(characteristics: Characteristic[]): string[] {
  if (!characteristics) return [];

  const charBlocks = [];

  for (let i = 0; i < characteristics.length; i += 1) {
    if (!characteristics[i].sampleSize) continue;

    if (
      characteristics[i].inspectionFlow === CharacteristicInspectionFlow.PARALLEL ||
      characteristics[i].inspectionFlow === CharacteristicInspectionFlow.TEXT
    ) {
      charBlocks.push([characteristics[i].id]);
    }

    if (characteristics[i].inspectionFlow !== CharacteristicInspectionFlow.SERIAL) continue;

    let j = i;
    charBlocks.push([]);

    while (characteristics[j]?.inspectionFlow === CharacteristicInspectionFlow.SERIAL) {
      charBlocks[charBlocks.length - 1].push(characteristics[j].id);
      j += 1;
    }

    i = j - 1;
  }

  return charBlocks.map((charBlock) => charBlock[charBlock.length - 1]);
}

export function detectBinaryBlockCharacteristics(characteristics: Characteristic[], characteristicId: string) {
  const currentChar = characteristics?.find(({ id }) => id === characteristicId);


  if (!currentChar || currentChar.inspectionFlow != CharacteristicInspectionFlow.SERIAL || currentChar.data.type != CharacteristicDataType.BINARY) return [];

  const charIndex = characteristics?.findIndex(({ id }) => id === characteristicId);

  let startIndex = charIndex;
  let endIndex = charIndex + 1;

  while ( characteristics[startIndex - 1]?.inspectionFlow === CharacteristicInspectionFlow.SERIAL && characteristics[startIndex - 1]?.data.type === CharacteristicDataType.BINARY) {
    startIndex -= 1;
  }

  while (characteristics[endIndex]?.inspectionFlow === CharacteristicInspectionFlow.SERIAL && characteristics[endIndex]?.data.type === CharacteristicDataType.BINARY) {
    endIndex += 1;
  }

  return characteristics.slice(startIndex, endIndex).filter(({ sampleSize }) => sampleSize > 0);
}
