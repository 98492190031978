/* eslint-disable max-lines-per-function */
import {
  FILEActions,
  SET_SELECTED_FILE_EDIT,
  ENABLE_UPLOAD_MODE,
  DISABLE_UPLOAD_MODE,
  TRY_GET_FILE_LIST,
  GET_FILE_LIST_SUCCESS,
  GET_FILE_LIST_ERROR,
  TRY_ADD_FILE,
  ADD_FILE_SUCCESS,
  ADD_FILE_ERROR,
  TRY_UPDATE_FILE,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_ERROR,
  TRY_REMOVE_FILE,
  REMOVE_FILE_SUCCESS,
  TRY_GET_FILE_NEXT_PAGE,
  GET_FILE_NEXT_PAGE_SUCCESS,
  GET_FILE_NEXT_PAGE_ERROR,
  SET_PARENT_DOCUMENT,
  TRY_FIND_DOCUMENT_HISTORY,
  FIND_DOCUMENT_HISTORY_SUCCESS,
  FIND_DOCUMENT_HISTORY_ERROR,
  CLEAR_HISTORY_QUERY_PARAMS,
  CLEAR_HISTORY_LIST,
  TRY_UNDO_DOCUMENT_CHANGE,
  UNDO_DOCUMENT_CHANGE_SUCCESS,
  UNDO_DOCUMENT_CHANGE_ERROR,
  TRY_ADD_NEW_DOCUMENT_VERSION,
  ADD_NEW_DOCUMENT_VERSION_SUCCESS,
  ADD_NEW_DOCUMENT_VERSION_ERROR,
  NAVIGATE_TO_PATH,
  TRY_FIND_FILE,
  FIND_FILE_SUCCESS,
  FIND_FILE_ERROR,
  TRY_START_EDIT_TEXT_FILE,
  STOP_EDIT_TEXT_CONTENT,
  UPDATE_TEXT_CONTENT_FILE_SUCCESS,
  TRY_UPDATE_TEXT_CONTENT_FILE,
  START_EDIT_TEXT_FILE_SUCCESS,
  TRY_LOAD_SORTED_FILE_LIST,
  RESET_ORDER_STATE,
  TRY_DRAG_DOCUMENT,
  DRAG_DOCUMENT_SUCCESS,
  DRAG_DOCUMENT_ERROR,
} from './file-manager.actions';

import { DocumentStore } from './../document.model';
import { ITEMS_PER_PAGE } from '../../../../../shared';
import { produce } from 'immer';

export interface State {
  parentDocument: DocumentStore;
  fileList: DocumentStore[];
  selectedFile: DocumentStore;
  uploadMode: boolean;
  totalItemsCount: any;
  sortOrder: 'asc' | 'desc';
  queryParams: any;
  loading: boolean;
  uploading: boolean;
  documentHistory: any[];
  historyDocumentEntryCount: number;
  historyQueryParams: any;
  textEditMode: boolean;
}

const initialState: State = {
  parentDocument: null,
  fileList: [],
  uploading: false,
  selectedFile: null,
  uploadMode: false,
  loading: false,
  totalItemsCount: null,
  sortOrder: 'asc',
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: ['is_folder,desc', 'id,desc'],
  },
  documentHistory: [],
  historyDocumentEntryCount: null,
  historyQueryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: ['id,desc'],
  },
  textEditMode: false,
};

export const fileManagerReducer = produce((draft: State, action: FILEActions) => {
  switch (action.type) {
    case RESET_ORDER_STATE:
      draft.queryParams = initialState.queryParams;
      draft.fileList = initialState.fileList;

      return;
    case DRAG_DOCUMENT_SUCCESS:
      draft.fileList = draft.fileList.filter((diagram) => diagram.id !== action.payload.documentId);
      draft.loading = false;

      return;
    case TRY_START_EDIT_TEXT_FILE:
      draft.loading = true;
      return;
    case START_EDIT_TEXT_FILE_SUCCESS:
      draft.loading = false;
      draft.textEditMode = true;
      draft.selectedFile = action.payload;
      return;
    case STOP_EDIT_TEXT_CONTENT:
      draft.textEditMode = false;
      return;
    case NAVIGATE_TO_PATH:
      draft.totalItemsCount = 0;

      let navigatedDoc = draft.selectedFile;

      while (navigatedDoc && navigatedDoc?.id !== action.payload) {
        navigatedDoc = navigatedDoc.parent;
      }

      draft.selectedFile = navigatedDoc;
      draft.parentDocument = navigatedDoc?.parent;
      draft.textEditMode = false;
      draft.queryParams.page = 0;

      return;
    case SET_SELECTED_FILE_EDIT:
      draft.selectedFile = action.payload ? { ...new DocumentStore(), ...action.payload } : null;
      return;
    case ENABLE_UPLOAD_MODE:
      draft.uploadMode = true;
      return;
    case DISABLE_UPLOAD_MODE:
      draft.uploadMode = false;
      return;
    case TRY_GET_FILE_LIST:
      draft.totalItemsCount = 0;
      draft.queryParams.page = 0;
      draft.loading = true;
      return;
    case GET_FILE_LIST_SUCCESS:
      draft.fileList = action.payload.data;
      draft.totalItemsCount = action.payload.totalItems;
      if (action.payload.data.length > 0) draft.queryParams.page += 1;
      if (draft.selectedFile) {
        const index = draft.fileList?.findIndex((file) => file.id === draft.selectedFile.id);
        if (index !== -1)
          { draft.selectedFile = {
            ...new DocumentStore(),
            ...draft.fileList[index],
          }; }
      }
      draft.loading = false;
      return;
    case GET_FILE_NEXT_PAGE_SUCCESS:
      draft.fileList.push(...action.payload.data);
      draft.totalItemsCount = action.payload.totalItems;
      if (action.payload.data.length > 0) draft.queryParams.page += 1;
      draft.loading = false;
      return;
    case GET_FILE_NEXT_PAGE_ERROR:
    case GET_FILE_LIST_ERROR:
    case ADD_NEW_DOCUMENT_VERSION_ERROR:
    case UNDO_DOCUMENT_CHANGE_ERROR:
    case UPDATE_FILE_ERROR:
    case FIND_FILE_ERROR:
    case DRAG_DOCUMENT_ERROR:
    case FIND_DOCUMENT_HISTORY_ERROR:
      draft.loading = false;
      return;
    case TRY_FIND_FILE:
    case TRY_FIND_DOCUMENT_HISTORY:
    case TRY_UPDATE_TEXT_CONTENT_FILE:
    case TRY_GET_FILE_NEXT_PAGE:
    case TRY_DRAG_DOCUMENT:
      draft.loading = true;
      return;
    case TRY_ADD_FILE:
      draft.uploading = true;
      return;
    case ADD_FILE_SUCCESS:
      draft.fileList.unshift(...action.payload.data);
      draft.selectedFile = {
        ...new DocumentStore(),
        ...action.payload.data[0],
      };
      draft.uploading = false;
      draft.textEditMode = draft.selectedFile.currentVersion.fileData.type === 'text-content';

      return;
    case ADD_FILE_ERROR:
      draft.uploading = false;
      return;
    case TRY_ADD_NEW_DOCUMENT_VERSION:
    case TRY_UNDO_DOCUMENT_CHANGE:
    case TRY_UPDATE_FILE:
      draft.loading = true;
      return;
    case UPDATE_TEXT_CONTENT_FILE_SUCCESS:
      const textContentUpdatedDoc = {
        ...new DocumentStore(),
        ...action.payload,
      };
      const textContentChangedDoc = draft.fileList.find((item) => item.id === action.payload.id);

      textContentUpdatedDoc.fullOwnerName = action.payload.owner
        ? `${action.payload.owner.firstName} ${action.payload.owner.lastName}`
        : '';
      textContentUpdatedDoc.fullResponsibleName = action.payload.responsible
        ? `${action.payload.responsible.firstName} ${action.payload.responsible.lastName}`
        : '';

      if (!textContentUpdatedDoc?.currentVersion && textContentChangedDoc) {
        textContentUpdatedDoc.currentVersion = textContentChangedDoc.currentVersion;
      }

      draft.selectedFile =
        draft.selectedFile && draft.selectedFile.id === textContentUpdatedDoc.id
          ? textContentUpdatedDoc
          : draft.selectedFile;
      draft.fileList = draft.fileList.map((item) => (item.id === action.payload.id ? textContentUpdatedDoc : item));
      draft.loading = false;

      return;
    case ADD_NEW_DOCUMENT_VERSION_SUCCESS:
    case UNDO_DOCUMENT_CHANGE_SUCCESS:
    case UPDATE_FILE_SUCCESS:
      const updatedDoc: DocumentStore = {
        ...new DocumentStore(),
        ...action.payload.data,
      };
      const changedDoc = draft.fileList.find((item) => item.id === action.payload.data.id);

      updatedDoc.fullModifiedName = action.payload.data.modified
        ? `${action.payload.data.modified.firstName} ${action.payload.data.modified.lastName}`
        : '';
      updatedDoc.fullOwnerName = action.payload.data.owner
        ? `${action.payload.data.owner.firstName} ${action.payload.data.owner.lastName}`
        : '';
      updatedDoc.fullResponsibleName = action.payload.data.responsible
        ? `${action.payload.data.responsible.firstName} ${action.payload.data.responsible.lastName}`
        : '';

      if (!updatedDoc?.currentVersion && changedDoc) {
        updatedDoc.currentVersion = changedDoc.currentVersion;
      }

      draft.selectedFile =
        draft.selectedFile && draft.selectedFile.id === updatedDoc.id ? updatedDoc : draft.selectedFile;
      draft.fileList = draft.fileList.map((item) => {
        return item.id === action.payload.data.id ? updatedDoc : item;
      });
      draft.loading = false;
      return;
    case TRY_REMOVE_FILE:
      draft.textEditMode = false;
      return;
    case REMOVE_FILE_SUCCESS:
      draft.fileList = (draft.fileList || []).filter((item) => draft.selectedFile?.id !== item?.id);
      draft.selectedFile = null;
      return;
    case TRY_LOAD_SORTED_FILE_LIST:
      draft.totalItemsCount = 0;
      draft.queryParams.page = 0;
      draft.sortOrder = draft.sortOrder === 'asc' ? 'desc' : 'asc';
      draft.loading = true;
      return;
    case SET_PARENT_DOCUMENT:
      draft.parentDocument = action.payload;
      draft.queryParams.page = 0;
      return;
    case FIND_FILE_SUCCESS:
      draft.selectedFile = action.payload.data;
      draft.loading = false;
      return;
    case FIND_DOCUMENT_HISTORY_SUCCESS:
      draft.historyDocumentEntryCount = action.payload.totalItems;
      draft.documentHistory.push(...action.payload.data);
      draft.historyQueryParams.page = draft.historyQueryParams.page + 1;
      draft.loading = false;
      return;
    case CLEAR_HISTORY_LIST:
      draft.documentHistory = [];
      return;
    case CLEAR_HISTORY_QUERY_PARAMS:
      draft.historyQueryParams.page = 0;
      
  }
}, initialState);

export const getFileList = (state: State) => state.fileList;

export const getSelectedFile = (state: State) => state.selectedFile;

export const getUploadMode = (state: State) => state.uploadMode;

export const getTotalItemsCount = (state: State) => state.totalItemsCount;

export const getQueryParams = (state: State) => state.queryParams;

export const getLoading = (state: State) => state.loading;

export const getUploading = (state: State) => state.uploading;

export const getParentDocument = (state: State) => state.parentDocument;

export const getDocumentHistory = (state: State) => state.documentHistory;

export const getHistoryDocumentEntryCount = (state: State) => state.historyDocumentEntryCount;

export const getHistoryQueryParams = (state: State) => state.historyQueryParams;

export const getFileListSortOrder = (state: State) => state.sortOrder;

export const getTextEditMode = (state: State) => state.textEditMode;
