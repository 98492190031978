import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from '../../app.constants';
import { BuildInfo } from './build-info.model';

@Injectable({ providedIn: 'root' })
export class BuildInfoService {
  private resourceUrl = SERVER_API_URL + 'api/build-info';

  constructor(private http: HttpClient) {}

  getBuildInformation(): Observable<BuildInfo> {
    return this.http.get<BuildInfo>(this.resourceUrl);
  }
}
