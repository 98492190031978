import { User } from '../../../shared';

export class NotificationZD {
  constructor(
    public id?: string,
    public message?: string,
    public messageRead?: boolean,
    public moduleName?: string,
    public entityId?: string,
    public taskId?: number,
    public creator?: User,
    public recipient?: User,
    public createdDate?: Date,
  ) {}
}
