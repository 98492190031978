<div id="view-content-wrapper">
  <div #cont id="img-container">
    @if (!hideClose) {
      <button mat-icon-button class="mat-icon-button" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    }
    <img (load)="revokeBlobUrl()" [src]="url" [ngStyle]="{ maxHeight: maxHeight, maxWidth: maxWidth }" />
  </div>
</div>
