import { User } from 'app/shared';

/**
 * Defines properties by which files may be selected in the file list
 */
export class FileCriteria {
  constructor(
    public searchText?: string,
    public name?: string,
    public fileName?: string,
    public type?: string,
    public parent?: string,
    public owner?: User,
    public responsiblePerson?: User,
    public documentType?: string,
    public statuses?: string[],
    public organization?: string,
    public location?: string,
    public versionStatus?: string,
    public includeSharedDocuments?: boolean,
  ) {}

  setActiveFilter(activeFilter?: any) {
    if (activeFilter) {
      // this.searchText = activeFilter.searchText && activeFilter.searchText !== '' ? activeFilter.searchText : null;
      this.name = activeFilter.name ? activeFilter.name : null;
      this.fileName = activeFilter.fileName ? activeFilter.fileName : null;
      this.type = activeFilter.type ? activeFilter.type : null;
      this.owner = activeFilter.owner || null;
      this.responsiblePerson =
        typeof activeFilter.responsiblePerson === 'string'
          ? activeFilter.responsiblePerson
          : activeFilter.responsiblePerson?.id;
      this.documentType = activeFilter.documentType || null;
      this.organization = activeFilter.organization || null;
      this.location = activeFilter.location || null;
      this.versionStatus = activeFilter.versionStatus ? activeFilter.versionStatus : null;
      this.includeSharedDocuments = activeFilter.includeSharedDocuments ? activeFilter.includeSharedDocuments : null;

      this.statuses = [];
      if (activeFilter.status && activeFilter.status.length > 0) {
        activeFilter.status.forEach((element) => this.statuses.push(element));
      }
    }
  }
}
