import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { IRestError } from 'app/core/models/rest.model';
import { RestUtils } from 'app/core/utils/rest.utils';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/app.reducer';
import { DocumentStore, FileData } from 'app/main/content/apps/file-manager/document.model';
import { FileHandlerService } from 'app/main/content/apps/file-manager/file-download.service';
import { from, Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { VerticalNavbarComponent } from './navbar-vertical.component';
import { TenantDataService } from 'app/core/services/tenant-data.service';

@Injectable()
export class VerticalNavbarService implements OnDestroy {
  private _navBarRef: VerticalNavbarComponent;
  private documentUploadSubscription: Subscription;
  private _tenantLogo$ = new Subject<FileData>();
  private _viewMode$: Observable<boolean>;

  constructor(
    private fileUploadService: FileHandlerService,
    private store: Store<fromRoot.State>,
    private http: HttpClient,
    private tenantDataService: TenantDataService,
  ) {
    this._viewMode$ = this.store.select(fromRoot.getHasViewOnlyLicense);
  }

  set navbar(ref) {
    this._navBarRef = ref;
  }

  get navbar() {
    return this._navBarRef;
  }

  get tenantLogo$(): Observable<FileData> {
    return this._tenantLogo$.asObservable();
  }

  get viewMode$(): Observable<boolean> {
    return this._viewMode$;
  }

  ngOnDestroy(): void {
    this.documentUploadSubscription?.unsubscribe();
  }

  getTenantLogo() {
    return this.tenantDataService
      .getTenantLogo()
      .pipe(catchError((err) => throwError(() => RestUtils.formRestErrorObject(err))))
      .subscribe((response) => {
        this._tenantLogo$.next(response);
      });
  }

  startUploadProcess(uploadedFile: File) {
    this.documentUploadSubscription?.unsubscribe();

    const { files, hasErrors } = this.fileUploadService.validateUploadFiles([uploadedFile]);
    if (hasErrors) return;

    this.documentUploadSubscription = from(this.fileUploadService.prepareUploadFiles(files))
      .pipe(
        map((documents) => documents[0]),
        catchError((err) => {
          const error: IRestError = RestUtils.formRestErrorObject(err);
          return throwError(error);
        }),
      )
      .subscribe((document: DocumentStore) => {
        this.updateTenantLogo(document);
      });
  }

  private updateTenantLogo(imageFile: DocumentStore) {
    return this.http
      .post<FileData>('api/tenant/logo', imageFile)
      .pipe(
        catchError((err) => {
          const error: IRestError = RestUtils.formRestErrorObject(err);
          return throwError(error);
        }),
      )
      .subscribe((response) => {
        this._tenantLogo$.next(response);
      });
  }
}
