import {
  CatalogDialogActions,
  FIND_CATALOG_LIBRARY_ITEM_SUCCESS,
  RESET_CATALOG_DIALOG_STATE,
  TRY_GET_SEARCH_TEXT_FILTERED_CATALOGS,
  TRY_NAVIGATE_TO_BREADCRUMB_CATALOG,
  TRY_NAVIGATE_TO_BREADCRUMB_BASE,
  TRY_GET_SORTED_CATALOG_LIST,
  GET_CATALOG_LIST_SUCCESS,
  SET_ROOT_CATALOG,
  LOAD_CATALOG_CHILDREN,
  TRY_FIND_CATALOG_NEXT_PAGE,
  GET_CATALOG_CHILDREN_NEXT_PAGE_SUCCESS,
  TRY_GET_CATALOG_CHILDREN_NEXT_PAGE,
  TRY_FIND_CATALOG_LIBRARY_ITEM,
} from './catalog-dialog.actions';

import { ITEMS_PER_PAGE } from '../../../../../../shared';
import { produce } from 'immer';
import { Catalog } from '../catalog.model';
import { findActiveSortProperty } from '../store/catalog.helper';

export interface State {
  catalogList: any[];
  selectedCat: Catalog;
  rootCat: Catalog;
  catalogLibItem: any;
  searchText: string;
  loading: boolean;
  sortParam: {
    value: string;
    label: string;
    direction: 'asc' | 'desc';
    isTextstore: boolean;
  };
  queryParams: { page; size; sort };
}

const initialState: State = {
  catalogList: [],
  selectedCat: null,
  rootCat: null,
  catalogLibItem: null,
  searchText: '',
  loading: false,
  sortParam: null,
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: 'id,desc',
  },
};

export const catDialogReducer = produce((draft: State, action: CatalogDialogActions) => {
  switch (action.type) {
    case RESET_CATALOG_DIALOG_STATE:
      Object.assign(draft, initialState);
      return;

    case TRY_NAVIGATE_TO_BREADCRUMB_BASE:
      draft.loading = true;
      draft.catalogList = initialState.catalogList;
      draft.queryParams = initialState.queryParams;
      draft.rootCat = null;
      return;
    case TRY_FIND_CATALOG_NEXT_PAGE:
      draft.loading = true;
      return;
    case SET_ROOT_CATALOG:
      draft.rootCat = action.payload?.hasChildren ? action.payload : initialState.rootCat;
      return;
    case TRY_FIND_CATALOG_LIBRARY_ITEM:
      draft.loading = true;
      return;
    case FIND_CATALOG_LIBRARY_ITEM_SUCCESS:
      draft.sortParam = findActiveSortProperty(action.payload);
      draft.catalogLibItem = { ...action.payload };
      return;

    case GET_CATALOG_LIST_SUCCESS:
      draft.catalogList = [...draft.catalogList, ...action.payload.data];
      draft.queryParams.page = action.payload.data.length > 0 ? draft.queryParams.page + 1 : draft.queryParams.page;
      draft.loading = false;
      return;
    case LOAD_CATALOG_CHILDREN:
      draft.loading = true;
      draft.catalogList = initialState.catalogList;
      draft.queryParams = initialState.queryParams;
      draft.rootCat = action.payload;
      return;
    case TRY_GET_SEARCH_TEXT_FILTERED_CATALOGS:
      draft.loading = true;
      draft.catalogList = initialState.catalogList;
      draft.queryParams = initialState.queryParams;
      draft.searchText = action.payload;
      return;
    case TRY_NAVIGATE_TO_BREADCRUMB_CATALOG:
      draft.loading = true;
      draft.catalogList = initialState.catalogList;
      draft.queryParams = initialState.queryParams;
      draft.rootCat = action.payload;
      return;
    case TRY_GET_CATALOG_CHILDREN_NEXT_PAGE:
      draft.loading = true;
      return;
    case GET_CATALOG_CHILDREN_NEXT_PAGE_SUCCESS:
      if (action.payload.data.length === 0) {
        draft.loading = false;
        return;
      }
      draft.catalogList = [...draft.catalogList, ...action.payload.data];
      const parentCatalogId = action.payload.data[0].parent.id;
      const parentCatalog = draft.catalogList.find((catalog) => catalog.id === parentCatalogId);
      parentCatalog.children = action.payload.data;
      draft.loading = false;
      return;
    case TRY_GET_SORTED_CATALOG_LIST:
      draft.loading = true;
      draft.catalogList = initialState.catalogList;
      draft.queryParams = initialState.queryParams;
      draft.sortParam = action.payload;
  }
}, initialState);

export const getCatalogDialogLibItem = (state: State) => state.catalogLibItem;

export const getCatalogList = (state: State) => state.catalogList;

export const getCatalogDialogSearchText = (state: State) => state.searchText;

export const getSelectedCatalogDialog = (state: State) => state.selectedCat;

export const getCatalogDialogSortParam = (state: State) => state.sortParam;

export const getCatalogDialogQueryParams = (state: State) => state.queryParams;

export const getRootCatalogDialog = (state: State) => state.rootCat;

export const getCatalogDialogLoading = (state: State) => state.loading;
