import { DocumentStore, DocumentVersion, FileData } from './document.model';
import { LANGUAGES } from 'app/shared/language/language.constants';
import { Textstore } from 'app/shared/lang-dialog/textstore.model';
import { User } from 'app/shared/user/user.model';

import * as isEmpty from 'lodash.isempty';

const releaseProcessTaskSubjectText = {
  en: 'Release Document',
  de: 'Dokument freigeben',
};

const releaseProcessTaskDescriptionText = {
  en: 'asks you to release this document',
  de: 'bittet Sie, dieses Dokument freizugeben',
};

const documentNameText = {
  en: 'Document name',
  de: 'Dokumentname',
};

const versionText = {
  en: 'Version',
  de: 'Version',
};

export class FileManagerUtil {
  public static createReleaseProcessTaskSubject(customName: Textstore): Textstore {
    const releaseProcessTaskSubject: Textstore = Textstore.withInitatedLanguages();
    for (const lang of LANGUAGES) {
      releaseProcessTaskSubject.translations[lang].text =
        releaseProcessTaskSubjectText[lang] + ': ' + customName.translations[lang].text;
      releaseProcessTaskSubject.translations[lang].translated = true;
    }
    return releaseProcessTaskSubject;
  }

  public static prepareLinkForDescription(link: string, text: string): string {
    return '<a href="' + link + '">' + text + '</a>';
  }

  public static prepareLineForDescription(text: string): string {
    return '<p>' + text + '</p>';
  }

  public static createReleaseProcessTaskDescription(user: User, file: DocumentStore, link: string): Textstore {
    const releaseProcessTaskDescription: Textstore = Textstore.withInitatedLanguages();
    for (const lang of LANGUAGES) {
      const leadLine = user.firstName + ' ' + user.lastName + ' ' + releaseProcessTaskDescriptionText[lang] + '.';
      const secondLine = this.prepareLineForDescription(
        documentNameText[lang] + ': ' + this.prepareLinkForDescription(link, file.customName.translations[lang].text),
      );
      // const thirdLine = this.prepareLineForDescription('Filename: ' + (file?.currentVersion?.fileData?.name || ''));
      const lastLine = this.prepareLineForDescription(versionText[lang] + ': ' + (file?.currentVersion?.version || ''));
      // releaseProcessTaskDescription.translations[lang].text = leadLine + secondLine + thirdLine + lastLine;
      releaseProcessTaskDescription.translations[lang].text = leadLine + secondLine + lastLine;
      releaseProcessTaskDescription.translations[lang].translated = true;
    }
    return releaseProcessTaskDescription;
  }

  public static convertObjectToDocumentVersion(documentVersionObject): DocumentVersion {
    if (!documentVersionObject || isEmpty(documentVersionObject)) return null;

    const newDocumentVersion = Object.assign(new DocumentVersion(), documentVersionObject);
    newDocumentVersion.fileData = Object.assign(new FileData(), documentVersionObject?.fileData);

    return newDocumentVersion;
  }

  public static convertObjectToDocument(documentObject): DocumentStore {
    if (!documentObject || isEmpty(documentObject)) return null;

    const newDocumentFile: DocumentStore = Object.assign(new DocumentStore(), documentObject);
    newDocumentFile.currentVersion = this.convertObjectToDocumentVersion(documentObject?.currentVersion);

    const newDocVersions: DocumentVersion[] = [];
    newDocumentFile.documentVersions?.forEach((versionObject) =>
      newDocVersions.push(this.convertObjectToDocumentVersion(versionObject)),
    );
    newDocumentFile.documentVersions = newDocVersions;

    return newDocumentFile;
  }
}
