import { Directive, OnInit, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName]',
  standalone: true,
})
export class FormControlAttachNativeElementDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private control: NgControl,
  ) {}

  ngOnInit() {
    if (!this.control.control) return;
    (this.control.control as any).nativeElement = this.el.nativeElement;
  }
}
