import { Observable, throwError } from 'rxjs';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { SERVER_API_URL } from '../../app.constants';
import { AppUtils } from '../utils/app.utils';
import { Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { TokenTimeout } from 'app/main/content/apps/profile/store/profile.actions';

export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !request ||
      !request.url ||
      /api\/authenticate/.test(request.url) ||
      (/^http/.test(request.url) && !(SERVER_API_URL && request.url.startsWith(SERVER_API_URL)))
    ) {
      return next.handle(request);
    }

    const token =
      this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');

    if (!token) {
      return next.handle(request);
    }

    try {
      const tokenInfo = AppUtils.jwtData(token);

      if (new Date() > tokenInfo.exp) {
        const store: Store = this.injector.get(Store);
        store.dispatch(new TokenTimeout());

        return throwError(() => 'Expired token');
      }
    } catch {
      const store: Store = this.injector.get(Store);
      store.dispatch(new TokenTimeout());

      return throwError(() => 'Invalid token');
    }

    request = request.clone({
      setHeaders: { Authorization: 'Bearer ' + token },
    });

    return next.handle(request);
  }
}
