import { Action } from '@ngrx/store';
import { Dashboard } from '../../board/models/board.model';
import { WidgetData, GridsterData } from '../../board/models/widget.model';

import { ActionWithPayload } from '../../../../../../shared';

export const DESELECT_DASHBOARD = '[Dashboard Settings On selected dashboard removed] Remove dashboard selection';

export const SET_CONFIG_MODE = '[Dashboard Header Toggle Config Mode Button Click] Set config mode';

export const NAVIGATED_TO_ROOT = '[Dashboard Navigate to root] Try to redirect to some folder';
export const FOUND_FIRST_FOLDER = '[Dashboard API Repsonse] Find a folder to reroute';
export const INIT_USER_DASHBOARDS = '[Dashboard API Response] No folder to reroute was found';
export const INIT_USER_DASHBOARDS_SUCCESS = '[Dashboard API Response] New folder was initied for user';

export const TRY_GET_DASHBOARD_CHILDREN_LIST = '[Dashboard Parent Id Route Navigate] Try get dashboard children list';
export const GET_DASHBOARD_LIST_SUCCESS = '[Dashboard Effects API Response] Get dashboard list success';
export const GET_DASHBOARD_LIST_ERROR = '[Dashboard Effects API Response] Get dashboard list error';

export const UNLOAD_WIDGETS_FOR_SELECTED_DASHBOARD = '[DASH] Unload widgets for selected dashboard.';

export const TRY_UPDATE_WIDGET_DETAILS = '[Dashboard Widget Form Submit] Try update current widget details';
export const TRY_UPDATE_WIDGET_COLUMN_SIZES =
  '[Dashboard Widget ColumnSize Submit] Try update current widget column sizes';
export const TRY_ADD_WIDGET = '[Dashboard Add Widget Menu] Try add new widget';
export const TRY_REMOVE_WIDGET = '[Dashboard Widget Header] Try remove widget.';
export const TRY_UPDATE_GRID_POSITION = '[Dashboard Widget Placement Change] On widget appended';

export const UPDATE_DASHBOARD_SUCCESS = '[Dashboard Effects API Response] Update dashboard success';
export const UPDATE_DASHBOARD_ERROR = '[Dashboard Effects API Response] Update dashboard error';

export const UNLOAD_DASHBOARD_LIST = '[Dashboard On Destroy] Unload dashboard list';

export const UPDATE_VIEW_ONLY_MODE = '[Dashboard Init] Update view only status';
export const LOAD_VIEW_ONLY_MODE = '[Dashboard Effects] Set if view only mode';

export const SELECT_FIRST_DASHBOARD = '[Dashboard Effects List Loaded] Select first dashboard';
// TODO: unused
export const SELECT_LAST_SELECTED_DASHBOARD = '[Dashboard Effects] Select last selected dashboard';

export const SELECT_DASHBOARD = '[Dashboard Tab] Select the clicked dashboard.';
export const ON_DASHBOARD_ROUTE_CHANGE = '[Dashboard Route Change] Change dashboard selection.';

export const OPEN_DASHBOARD_SETTINGS = '[Dashboard Header Button] Open dashboard settings.';

export const LOAD_STATIC_DASHBOARD = '[Dashboard navigate to static dashboard] Load static dashboard';

export const PROCEED_WITH_FLOW = '[Dashboard Flow Navigation click] Reroute to the next dashboard';
export const INSPECT_INSPECTION_SELECTION = '[Dashboard Process Selection Inspect] Reroute to the next dashboard';

export const TRY_SAVE_SEARCH_CRITERIA =
  '[Dashboard Process-Instance-Selection table search criteria] Save search criteria';

export const DASHBOARD_FLOW_NAVIGATION = '[Dashboard Effects] Flow route changed';

export class DashboardFlowNavigation implements ActionWithPayload<string> {
  readonly type = DASHBOARD_FLOW_NAVIGATION;
  constructor(public payload: string) {}
}

export class ProceedWithFlow implements Action {
  readonly type = PROCEED_WITH_FLOW;
}

export class InspectInspectionSelection implements Action {
  readonly type = INSPECT_INSPECTION_SELECTION;
}

export class LoadStaticDashboard implements ActionWithPayload<string> {
  readonly type = LOAD_STATIC_DASHBOARD;
  constructor(public payload: string) {}
}

export class OpenDashboardSettings implements Action {
  readonly type = OPEN_DASHBOARD_SETTINGS;
}

export class DashboardRouteChange implements ActionWithPayload<string> {
  readonly type = ON_DASHBOARD_ROUTE_CHANGE;
  constructor(public payload: string) {}
}

export class SelectDashboard implements ActionWithPayload<string> {
  readonly type = SELECT_DASHBOARD;
  constructor(public payload: string) {}
}

export class TryGetDashboardChildrenList implements ActionWithPayload<string> {
  readonly type = TRY_GET_DASHBOARD_CHILDREN_LIST;
  constructor(public payload: string) {}
}

export class TrySelectFirstDashboard implements Action {
  readonly type = SELECT_FIRST_DASHBOARD;
}

export class TrySelectLastSelectedDashboard implements Action {
  readonly type = SELECT_LAST_SELECTED_DASHBOARD;
}

export class UpdateViewOnlyMode implements Action {
  readonly type = UPDATE_VIEW_ONLY_MODE;
}

export class LoadViewOnlyMode implements ActionWithPayload<boolean> {
  readonly type = LOAD_VIEW_ONLY_MODE;
  constructor(public payload: boolean) {}
}

export class DeselectDashboard implements Action {
  readonly type = DESELECT_DASHBOARD;
}

export class UnloadDashboards implements Action {
  readonly type = UNLOAD_DASHBOARD_LIST;
}

export class TryAddWidget implements ActionWithPayload<WidgetData[]> {
  readonly type = TRY_ADD_WIDGET;
  constructor(public payload: WidgetData[]) {}
}

export class TryUpdateGridPositions implements ActionWithPayload<GridsterData[]> {
  readonly type = TRY_UPDATE_GRID_POSITION;
  constructor(public payload: GridsterData[]) {}
}

export class TryRemoveWidget implements ActionWithPayload<string> {
  readonly type = TRY_REMOVE_WIDGET;
  constructor(public payload: string) {}
}

export class UnloadWidgetsForSelected implements Action {
  readonly type = UNLOAD_WIDGETS_FOR_SELECTED_DASHBOARD;
}

export class SetConfigMode implements ActionWithPayload<boolean> {
  readonly type = SET_CONFIG_MODE;
  constructor(public payload: boolean) {}
}

export class TryUpdateWidgetDetails implements ActionWithPayload<{ config: any; instanceId: string }> {
  readonly type = TRY_UPDATE_WIDGET_DETAILS;
  constructor(public payload: any) {}
}

export class TryUpdateWidgetColumnSizes
  implements
    ActionWithPayload<{
      config: any;
      instanceId: string;
      propertyName: string;
    }>
{
  readonly type = TRY_UPDATE_WIDGET_COLUMN_SIZES;
  constructor(public payload: { config: any; instanceId: string; propertyName: string }) {}
}

export class NavigatedToRoot implements Action {
  readonly type = NAVIGATED_TO_ROOT;
}

export class FoundFirstFolder implements ActionWithPayload<string> {
  readonly type = FOUND_FIRST_FOLDER;
  constructor(public payload: string) {}
}

export class InitUserDashboards implements Action {
  readonly type = INIT_USER_DASHBOARDS;
}

export class InitUserDashboardsSuccess implements ActionWithPayload<string> {
  readonly type = INIT_USER_DASHBOARDS_SUCCESS;
  constructor(public payload: string) {}
}

export class GetDashboardListSuccess
  implements ActionWithPayload<{ dashboards: Dashboard[]; parentDashboard: Dashboard }>
{
  readonly type = GET_DASHBOARD_LIST_SUCCESS;
  constructor(public payload: { dashboards: Dashboard[]; parentDashboard: Dashboard }) {}
}

export class GetDashboardListError implements Action {
  readonly type = GET_DASHBOARD_LIST_ERROR;
}

export class UpdateDashboardSuccess implements ActionWithPayload<Dashboard> {
  readonly type = UPDATE_DASHBOARD_SUCCESS;
  constructor(public payload: Dashboard) {}
}

export class UpdateDashboardError implements Action {
  readonly type = UPDATE_DASHBOARD_ERROR;
}

export class TrySaveSearchCriteria implements ActionWithPayload<{ config: any; instanceId: string }> {
  readonly type = TRY_SAVE_SEARCH_CRITERIA;
  constructor(public payload: { config: any; instanceId: string }) {}
}

export type DASHActions =
  | ProceedWithFlow
  | InspectInspectionSelection
  | LoadStaticDashboard
  | OpenDashboardSettings
  | DashboardRouteChange
  | SelectDashboard
  | TryGetDashboardChildrenList
  | TryUpdateWidgetDetails
  | TryUpdateWidgetColumnSizes
  | GetDashboardListSuccess
  | GetDashboardListError
  | UpdateDashboardSuccess
  | UpdateDashboardError
  | SetConfigMode
  | UnloadWidgetsForSelected
  | TryRemoveWidget
  | TryAddWidget
  | TryUpdateGridPositions
  | TrySaveSearchCriteria
  | UnloadDashboards
  | DeselectDashboard
  | UpdateViewOnlyMode
  | LoadViewOnlyMode
  | TrySelectFirstDashboard
  | NavigatedToRoot
  | FoundFirstFolder
  | InitUserDashboards
  | InitUserDashboardsSuccess
  | DashboardFlowNavigation;
