import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Store } from '@ngrx/store';

import * as PROFILE from 'app/main/content/apps/profile/store/profile.actions';
import * as echarts from 'echarts/core';
import * as fromRoot from 'app/app.reducer';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, BehaviorSubject } from 'rxjs';
import { ProductLine } from './shared/license/licensedetails.model';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class ThemeService {
   private _activeProductLine$ = signal<ProductLine>(ProductLine.CAQ360);
   private _activeTheme$ = new BehaviorSubject<'dark' | 'light'>(this.startingTheme);
   private _activeThemeWithProductLine$ = new BehaviorSubject<string>(null);

   constructor(
      private store: Store,
      private localStorageService: LocalStorageService,
      private httpClient: HttpClient,
   ) {
      this.registerEChartThemes();
      this._activeProductLine$.set(toSignal(this.store.select(fromRoot.getProductLine))());
   }

   get activeTheme$(): Observable<'dark' | 'light'> {
      return this._activeTheme$.asObservable();
   }

   get activeThemeWithProductLine$(): Observable<string> {
      return this._activeThemeWithProductLine$.asObservable();
   }

   get activeTheme() {
      return this._activeTheme$.value;
   }

   get activeThemeWithProductLine(): string {
      return `${this._activeProductLine$()}-${this._activeTheme$.value}`;
   }


   set productLine(nextProductLine: ProductLine) {
      this._activeProductLine$.set(nextProductLine);
      this.applyTheme(this._activeTheme$.value);
   }

   set theme(nextTheme: 'light' | 'dark') {
      this.store.dispatch(new PROFILE.UpdateActiveUserOptions({ theme: nextTheme ?? 'dark' }));
      this._activeTheme$.next(nextTheme ?? 'dark');
      this.applyTheme(nextTheme ?? 'dark');
   }

   private get startingTheme(): 'dark' | 'light' {
      const lsTheme = this.localStorageService.retrieve('theme');
      if (lsTheme) return lsTheme.replaceAll('"', '');

      return 'dark';
   }

   toggleTheme(): void {
      const nextTheme = this._activeTheme$.value === 'dark' ? 'light' : 'dark';
      this.theme = nextTheme;
   }

   private initializeTheme(): void {
      const storedTheme = this.localStorageService.retrieve('theme') ?? 'dark';
      this._activeTheme$.next(storedTheme);
   }

   registerEChartThemes(): void {
      this.httpClient.get('/assets/api/echart-themes.json').subscribe(data => {
         Object.entries(data).forEach(([theme, data]) => echarts.registerTheme(theme, data));
      });
   }

   private applyTheme(theme: 'dark' | 'light'): void {
      const currentProductLine = this._activeProductLine$() ?? ProductLine.CAQ360;
      //hotfix for theme not being set correctly
      if(theme != ('dark') && theme != ('light')){ 
         theme = 'dark';
      }
      this.localStorageService.store('theme', theme);
      this.localStorageService.store('productLine', currentProductLine);
      this._activeThemeWithProductLine$.next(`${currentProductLine}-${theme}`);

      document.body.className = ''; // clear existing classes
      document.body.classList.add('mat-typography');
      document.body.classList.add(`${currentProductLine}-${theme}`);

      
   }
}
