import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from '../../../../app.constants';
import { Filter } from './filter.model';
import { FilterModuleName, FilterNameList } from './filter-modules-list';
import { WidgetType } from '../dashboard/widgets/widget/widget-type.model';

/**
 * Defines methods used by multiple module components as well as common data and utility
 */
@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private resourceUrl = SERVER_API_URL + 'api/filters';
  private filterLibPath = '/assets/api/filters.json';

  constructor(private http: HttpClient) {}

  getFilterLibrary() {
    return this.http.get<any>(this.filterLibPath);
  }

  getFiltersForComponent(filterName: string): Observable<Filter[]> {
    return this.http.get<Filter[]>(`${this.resourceUrl}/list/${filterName}`);
  }

  getFiltersForCurrentUser(filterName: FilterNameList, userId: string): Observable<Filter[]> {
    return this.http.get<Filter[]>(`${this.resourceUrl}/list/${filterName}/${userId}`);
  }

  create(filter: Filter): Observable<Filter> {
    return this.http.post<Filter>(this.resourceUrl, filter);
  }

  update(filter: Filter): Observable<Filter> {
    return this.http.put<Filter>(this.resourceUrl, filter);
  }

  find(id: string): Observable<Filter> {
    return this.http.get<Filter>(`${this.resourceUrl}/${id}`);
  }

  delete(id: string): Observable<unknown> {
    return this.http.delete(`${this.resourceUrl}/${id}`);
  }

  getFilterModuleType(filterList: Filter[], filterId: string): FilterNameList {
    const foundFilter = filterList.find((filter) => filter.id === filterId);
    return foundFilter && foundFilter.filterName;
  }
}
