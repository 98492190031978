import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SERVER_API_URL } from '../../../app.constants';
import { createRequestOption } from '../../../shared';

import { NotificationZD } from './notification.model';
import { NotificationsCriteria } from './notification.criteria';
import { IRestResponse, RestResponse, IRestError } from 'app/core/models/rest.model';
import { RestUtils } from 'app/core/utils/rest.utils';

@Injectable()
export class NotificationZDService {
  onRefreshNotificationList: Subject<void> = new Subject();
  onNewSearch: Subject<void> = new Subject();

  private resourceUrl = SERVER_API_URL + 'api/notifications';

  constructor(private http: HttpClient) {}

  create(inspectionOrder: NotificationZD): Observable<NotificationZD> {
    const copy = this.convert(inspectionOrder);
    return this.http
      .post(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res) => this.convertItemFromServer(res.body)));
  }

  update(inspectionOrder: NotificationZD): Observable<NotificationZD> {
    const copy = this.convert(inspectionOrder);
    return this.http.put(this.resourceUrl, copy).pipe(map((res) => this.convertItemFromServer(res)));
  }

  find(id: string): Observable<NotificationZD> {
    return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => this.convertItemFromServer(res)));
  }

  markAsRead(login: string, entityId: string, date: Date) {
    const data = { login, entityId, notificationDate: date };
    return this.http.post(this.resourceUrl + '/read', data, { observe: 'response' }).pipe(
      map((res) => {
        const result: any = res;
        const response: IRestResponse = new RestResponse();
        response.data = result;
        return response;
      }),
      catchError((err) => {
        console.error('HTTP Error', err);
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  query(req?: any, searchText?: any, creatorId?: any, messageRead?: any): Observable<HttpResponse<Object>> {
    const criteria = new NotificationsCriteria();

    criteria.searchText = '';
    if (searchText !== '') {
      criteria.searchText = searchText;
    }

    criteria.creatorId = null;
    if (creatorId !== null && creatorId !== '') {
      criteria.creatorId = creatorId;
    }

    criteria.messageRead = null;
    if (messageRead !== null) {
      criteria.messageRead = messageRead;
    }

    const reqParams = createRequestOption(req);
    return this.http.post(this.resourceUrl + '/filter', criteria, {
      observe: 'response',
      params: reqParams,
    });
  }

  delete(id: string): any {
    return this.http.delete(`${this.resourceUrl}/${id}`);
  }

  // Convert a returned JSON object to NotificationZD.
  private convertItemFromServer(json: any): NotificationZD {
    const entity: NotificationZD = Object.assign(new NotificationZD(), json);
    return entity;
  }

  // Convert a NotificationZD to a JSON which can be sent to the server.
  private convert(inspectionOrder: NotificationZD): NotificationZD {
    const copy: NotificationZD = { ...inspectionOrder };
    return copy;
  }
}
