import {
  PDActions,
  UPDATE_INSTANCE_ERROR,
  REMOVE_INSTANCE_ERROR,
  UPDATE_INSTANCE_SUCCESS,
  REMOVE_INSTANCE_SUCCESS,
  SET_SELECTED_INSTANCE,
  TRY_GET_INSTANCE_NEXT_PAGE,
  GET_INSTANCE_NEXT_PAGE_SUCCESS,
  GET_INSTANCE_NEXT_PAGE_ERROR,
  TRY_LOAD_FILTERED_INSTANCE_LIST,
  RESET_STATE,
  TRY_LOAD_SORTED_INSTANCE_LIST,
  ROUTE_UPDATE_INSTANCE_DATA_SUCCESS,
  TRY_LOAD_INITIAL_INSTANCE_LIST,
  TRY_START_GOAL_INSTANCE,
  START_GOAL_INSTANCE_SUCCESS,
  START_GOAL_INSTANCE_ERROR,
} from './goal-result.actions';

import { ITEMS_PER_PAGE } from '../../../../../../shared';
import { produce } from 'immer';
import { ProcessInstance } from '../../process-result/process-instance.model';
import { ProcessDiagram } from '../../../designers/process/model/process-diagram.model';

export interface State {
  goalResultList: ProcessInstance[];
  rootGoalDiagram: ProcessDiagram;
  selectedGoalResult: ProcessInstance;
  rootGoalResutId: string;
  loading: boolean;
  sortOrder: 'asc' | 'desc';
  queryParams: { page: number; size: number; sort: string[] };
}

const initialState: State = {
  goalResultList: [],
  rootGoalDiagram: null,
  selectedGoalResult: null,
  rootGoalResutId: undefined,
  loading: false,
  sortOrder: 'asc',
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: ['number, asc', 'id,desc'],
  },
};

export const goalResultReducer = produce((draft: State, action: PDActions): void => {
  switch (action.type) {
    case RESET_STATE:
      Object.assign(draft, initialState);

      return;
    case TRY_LOAD_INITIAL_INSTANCE_LIST:
      draft.loading = true;
      draft.selectedGoalResult = null;
      draft.goalResultList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = initialState.sortOrder;

      return;
    case TRY_LOAD_SORTED_INSTANCE_LIST:
      draft.loading = true;
      draft.selectedGoalResult = null;
      draft.goalResultList = [];
      draft.queryParams.page = 0;
      draft.sortOrder = draft.sortOrder === 'asc' ? 'desc' : 'asc';

      return;
    case TRY_LOAD_FILTERED_INSTANCE_LIST:
      draft.selectedGoalResult = null;
      draft.goalResultList = [];

      return;
    case SET_SELECTED_INSTANCE:
      draft.selectedGoalResult = draft.goalResultList.find((processResult) => processResult.id === action.payload);

      return;
    case TRY_START_GOAL_INSTANCE:
      draft.loading = true;

      return;
    case START_GOAL_INSTANCE_SUCCESS:
    case START_GOAL_INSTANCE_ERROR:
      draft.loading = false;

      return;
    case TRY_GET_INSTANCE_NEXT_PAGE:
      draft.loading = true;

      return;
    case ROUTE_UPDATE_INSTANCE_DATA_SUCCESS:
      draft.selectedGoalResult =
        action.payload && draft.goalResultList.find((processResult) => processResult.id === action.payload);

      return;
    case GET_INSTANCE_NEXT_PAGE_ERROR:
    case UPDATE_INSTANCE_ERROR:
    case REMOVE_INSTANCE_ERROR:
      draft.loading = false;

      return;
    case GET_INSTANCE_NEXT_PAGE_SUCCESS:
      draft.loading = false;
      draft.goalResultList.push(...action.payload.data);
      if (action.payload.data.length > 0) draft.queryParams.page += 1;

      return;
    case UPDATE_INSTANCE_SUCCESS:
      draft.goalResultList = draft.goalResultList.map((item) =>
        item.id === action.payload.id ? action.payload : item,
      );

      draft.selectedGoalResult =
        draft.selectedGoalResult?.id === action.payload.id ? action.payload : draft.selectedGoalResult;

      return;
    case REMOVE_INSTANCE_SUCCESS:
      draft.goalResultList = draft.goalResultList.filter((item) => draft.selectedGoalResult.id !== item.id);
      draft.selectedGoalResult = null;

      
  }
}, initialState);

export const getGoalResultList = (state: State) => state.goalResultList;

export const getQueryParams = (state: State) => state.queryParams;

export const getSelectedGoalResult = (state: State) => state.selectedGoalResult;

export const getGoalResultLoading = (state: State) => state.loading;

export const getRootGoalResultId = (state: State) => state.rootGoalResutId;

export const getGoalResultSortOrder = (state: State) => state.sortOrder;

export const getRootGoalResultDiagram = (state: State) => state.rootGoalDiagram;
