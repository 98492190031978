import * as isEqual from 'lodash.isequal';
import * as transform from 'lodash.transform';
import * as moment from 'moment';

export namespace DifferentiatorUtils {
  export function differentiate(oldData, newData) {
    const diff: any = {};

    if (!newData || !oldData) return diff;

    Object.keys(newData)
      .filter((field) => deepCompare(newData[field], oldData[field]) === false)
      .forEach((filteredField) => (diff[filteredField] = newData[filteredField]));

    return diff;
  }

  export function historyDiff(object: any, base: any) {
    return transform(object, (result, value, key) => {
      if (isEqual(value, base[key])) return;
      result[key] = isObject(value) && isObject(base[key]) ? historyDiff(value, base[key]) : [base[key], value, base];
    });
  }

  function isObject(variable) {
    return !!variable && typeof variable === 'object';
  }

  function deepCompare(objectA: any, objectB: any) {
    // eslint-disable-next-line eqeqeq
    if (objectA == objectB) return true;
    if ((objectA && !objectB) || (!objectA && objectB)) return false;
    if (!isObject(objectA)) return objectA === objectB;

    if (Array.isArray(objectA)) {
      if (objectA.length !== objectB?.length) return false;
      return objectA.every((arrValue, arrIndex) => deepCompare(arrValue, objectB[arrIndex]));
    }

    if (moment.isMoment(objectA) || moment.isMoment(objectB)) {
      return moment(objectA).isSame(moment(objectB));
    }

    return (
      Object.keys(objectA).every((field) => deepCompare(objectA[field], objectB[field])) &&
      Object.keys(objectB).every((field) => deepCompare(objectA[field], objectB[field]))
    );
  }
}
