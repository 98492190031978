import { ITEMS_PER_PAGE } from 'app/shared';
import { ProcessProcessDiagram } from '../../../model/process-diagram.model';

import { produce } from 'immer';
import {
  GET_DIAGRAM_LIST_NEXT_PAGE_ERROR,
  GET_DIAGRAM_LIST_NEXT_PAGE_SUCCESS,
  PDSActions,
  RESTART_STATE,
  TRY_GET_BREADCRUMB_DIAGRAM_CHILDREN_LIST,
  TRY_GET_DIAGRAM_LIST_NEXT_PAGE,
  TRY_GET_PARENT_DIAGRAM_CHILDREN_LIST,
} from './process-diagram-selection.actions';

export interface State {
  processDiagramList: ProcessProcessDiagram[];
  rootProcessDiagramId: string;
  loading: boolean;
  queryParams: { page; size; sort };
  breadcrumb: ProcessProcessDiagram[];
}

const initialState: State = {
  processDiagramList: [],
  rootProcessDiagramId: undefined,
  loading: false,
  queryParams: {
    page: 0,
    size: ITEMS_PER_PAGE,
    sort: 'id,desc',
  },
  breadcrumb: [],
};

export const processDiagramSelectionReducer = produce((draft: State, action: PDSActions): void => {
  switch (action.type) {
    case RESTART_STATE:
      Object.assign(draft, initialState);

      return;
    case TRY_GET_BREADCRUMB_DIAGRAM_CHILDREN_LIST:
      draft.rootProcessDiagramId = action.payload;
      draft.queryParams.page = 0;
      draft.loading = true;
      const clickedDiagramIndex = draft.breadcrumb.findIndex((diagram) => diagram.id === action.payload);
      draft.breadcrumb = draft.breadcrumb.slice(0, clickedDiagramIndex + 1);
      draft.processDiagramList = [];

      return;
    case TRY_GET_PARENT_DIAGRAM_CHILDREN_LIST:
      draft.rootProcessDiagramId = action.payload;
      draft.queryParams.page = 0;
      draft.loading = true;
      draft.breadcrumb.push(draft.processDiagramList.find((diagram) => diagram.id === action.payload));
      draft.processDiagramList = [];

      return;
    case TRY_GET_DIAGRAM_LIST_NEXT_PAGE:
      draft.loading = true;
      return;
    case GET_DIAGRAM_LIST_NEXT_PAGE_SUCCESS:
      draft.loading = false;
      draft.processDiagramList.push(...action.payload);
      if (action.payload.length > 0) draft.queryParams.page += 1;
      return;
    case GET_DIAGRAM_LIST_NEXT_PAGE_ERROR:
      draft.loading = false;
      
  }
}, initialState);

export const getProcessDiagramList = (state: State) => state.processDiagramList;

export const getQueryParams = (state: State) => state.queryParams;

export const getLoading = (state: State) => state.loading;

export const getRootProcessDiagramId = (state: State) => state.rootProcessDiagramId;

export const getProcessDiagramBreadcrumb = (state: State) => state.breadcrumb;
