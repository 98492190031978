import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Router, RouterLink, RouterModule } from '@angular/router';

@Component({
  selector: 'notlicensed',
  templateUrl: './error-not-licensed.component.html',
  styleUrls: ['./error-not-licensed.component.scss'],
  standalone: true,
  imports: [RouterLink, TranslateModule, RouterModule],
})
export class FuseErrorNotLicensedComponent {}
