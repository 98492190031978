import { of as of } from 'rxjs';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as CAT from './catalog-dialog.actions';
import * as fromRoot from '../../../../../../app.reducer';

import { CatalogService } from '../catalog.service';
import { ActionWithPayload } from '../../../../../../shared';
import { IRestError, IRestResponse } from 'app/core/models/rest.model';
import { CatalogLibraryService } from '../../catalog-library.service';

import { Catalog } from '../catalog.model';

@Injectable({
  providedIn: 'root',
})
export class CatalogEffectsDialog {
  updateCatalogList$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(
        CAT.FIND_CATALOG_LIBRARY_ITEM_SUCCESS,
        CAT.LOAD_CATALOG_CHILDREN,
        CAT.TRY_GET_SEARCH_TEXT_FILTERED_CATALOGS,
        CAT.TRY_NAVIGATE_TO_BREADCRUMB_CATALOG,
        CAT.TRY_NAVIGATE_TO_BREADCRUMB_BASE,
        CAT.TRY_GET_SORTED_CATALOG_LIST,
        CAT.TRY_FIND_CATALOG_NEXT_PAGE,
      ),
      withLatestFrom(
        this.store$.select(fromRoot.getCatalogDialogSearchText),
        this.store$.select(fromRoot.getCatalogDialogSortedQueryParams),
        this.store$.select(fromRoot.getCatalogDialogRoot),
        this.store$.select(fromRoot.getCatalogDialogLibItem),
      ),
      switchMap(([action, searchText, queryParams, rootCatalog, libItem]) =>
        this.catalogService.query(queryParams, searchText, rootCatalog, libItem?.componentType).pipe(
          map((res: any) => ({
            type: CAT.GET_CATALOG_LIST_SUCCESS,
            payload: res,
          })),
          catchError((error: IRestError) => of({ type: CAT.GET_CATALOG_LIST_ERROR, payload: error })),
        ),
      ),
    ); },
  );

  tryLoadCatalogChildrenNextPage$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_GET_CATALOG_CHILDREN_NEXT_PAGE),
      map((action: ActionWithPayload<Catalog>) => action.payload),
      switchMap((action) =>
        this.catalogService.queryChildren(null, action.id).pipe(
          map((res: IRestResponse<Catalog[]>) => ({
            type: CAT.GET_CATALOG_CHILDREN_NEXT_PAGE_SUCCESS,
            payload: res,
          })),
          catchError((error: IRestError) =>
            of({
              type: CAT.GET_CATALOG_CHILDREN_NEXT_PAGE_ERROR,
              payload: error,
            }),
          ),
        ),
      ),
    ); },
  );

  tryLoadCatalogChildren$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_LOAD_CATALOG_CHILDREN),
      map((action: ActionWithPayload<Catalog>) => action.payload),
      map((catalog) => {
        if (catalog.hasChildren) return { type: CAT.LOAD_CATALOG_CHILDREN, payload: catalog };
        return { type: CAT.LOAD_CATALOG_CHILDREN_BREAK };
      }),
    ); },
  );

  setCatalogLibraryItem$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CAT.TRY_FIND_CATALOG_LIBRARY_ITEM),
      map((action: ActionWithPayload<string>) => action.payload),
      switchMap((catalogType) => {
        return this.catalogLibraryService.getCatalogByType(catalogType).pipe(
          map(
            (res) => ({
              type: CAT.FIND_CATALOG_LIBRARY_ITEM_SUCCESS,
              payload: res || { componentType: catalogType },
            }),
            catchError(() => of({ type: CAT.FIND_CATALOG_LIBRARY_ITEM_ERROR })),
          ),
        );
      }),
    ); },
  );

  constructor(
    private actions$: Actions,
    private catalogService: CatalogService,
    private catalogLibraryService: CatalogLibraryService,
    private store$: Store<fromRoot.State>,
  ) {}
}
