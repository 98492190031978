<h1 matDialogTitle>{{ title | translate }}</h1>
<div mat-dialog-content>{{ confirmMessage | translate }}</div>
<div mat-dialog-actions class="pt-24 button-wrapper">
  @if (!disableFalseButton){

    <button mat-button (click)="dialogRef.close(false)">
      {{ falseButton | translate }}
    </button>
  }
  <button mat-raised-button color="primary" (click)="dialogRef.close(true)">
    {{ trueButton | translate }}
  </button>
</div>
