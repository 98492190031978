import { ActionWithPayload } from 'app/shared';
import { Filter } from '../filter.model';
import { Action } from '@ngrx/store';
import { WidgetType } from '../../dashboard/widgets/widget/widget-type.model';
import { FilterNameList } from "../filter-modules-list";


export const SET_SEARCH_TEXT = '[Filters] Set search text';
export const SET_SELECTED_FILTER = '[Filters] Set selected filter';
export const SET_ACTIVE_FILTER = '[Filters] Set active filter';
export const DESELECT_FILTER = '[Filters] Deselect filter';

export const TRY_DELETE_FILTER = '[Filters] Try delete filter';
export const DELETE_FILTER_SUCCESS = '[Filters] Delete filter success';
export const DELETE_FILTER_ERROR = '[Filters] Delete filter error';

export const SELECT_FILTER_LIST_FOR_MODULE = '[Filters] Select filter list for module';
export const MODULE_FILTERS_WERE_LOADED = '[Filters] Module filters were loaded';

export const TRY_GET_FILTER_FOR_USER = '[Filters] Get filter list for user';
export const TRY_GET_FILTER_LIST_FOR_MODULE = '[Filters] Try get filter list for module';
export const GET_FILTER_LIST_FOR_MODULE_SUCCESS = '[Filters] Get filter list for module success';
export const GET_FILTER_LIST_FOR_MODULE_ERROR = '[Filters] Get filter list for module error';



export const SET_FILTER_FOR_WIDGET = '[Filters] Set filter for widget';


export const TRY_ADD_FILTER = '[Filters] Try add filter';
export const ADD_FILTER_SUCCESS = '[Filters] Add filter success';
export const ADD_FILTER_ERROR = '[Filters] Add filter error';

export const TRY_GET_METADATA = '[Filters] Try get metadata';
export const GET_METADATA_SUCCESS = '[Filters] Get metadata success';
export const GET_METADATA_ERROR = '[Filters] Get metadata error';
export const METADATA_WAS_LOADED = '[Filters] Metadata was loaded';

export const TRY_UPDATE_FILTER = '[Filters] Try update filter';
export const UPDATE_FILTER_SUCCESS = '[Filters] Update filter success';
export const UPDATE_FILTER_ERROR = '[Filters] Update filter error';

export const UPDATE_VIEW_ONLY_MODE = '[Dashboard init] Update view only status';
export const LOAD_VIEW_ONLY_MODE = '[Dashboard Effects] Set if view only mode';

export class UpdateViewOnlyMode implements Action {
  readonly type = UPDATE_VIEW_ONLY_MODE;
}

export class LoadViewOnlyMode implements ActionWithPayload<boolean> {
  readonly type = LOAD_VIEW_ONLY_MODE;
  constructor(public payload: boolean) {}
}

export class SetSearchText implements ActionWithPayload<{ filterName: FilterNameList; text: string }> {
  readonly type = SET_SEARCH_TEXT;
  constructor(public payload: { filterName: FilterNameList; text: string }) {}
}

export class TryUpdateFilter implements ActionWithPayload<Filter> {
  readonly type = TRY_UPDATE_FILTER;
  constructor(public payload: Filter) {}
}

export class UpdateFilterSuccess implements ActionWithPayload<Filter> {
  readonly type = UPDATE_FILTER_SUCCESS;
  constructor(public payload: Filter) {}
}

export class UpdateFilterError implements Action {
  readonly type = UPDATE_FILTER_ERROR;
}

export class TryGetMetadata implements Action {
  readonly type = TRY_GET_METADATA;
}

export class MetadataWasLoaded implements Action {
  readonly type = METADATA_WAS_LOADED;
}

export class GetMetadataSuccess implements ActionWithPayload<any[]> {
  readonly type = GET_METADATA_SUCCESS;
  constructor(public payload: any[]) {}
}

export class GetMetadataError implements Action {
  readonly type = GET_METADATA_ERROR;
}

export class TryAddFilter implements ActionWithPayload<Filter> {
  readonly type = TRY_ADD_FILTER;
  constructor(public payload: Filter) {}
}

export class AddFilterSuccess implements ActionWithPayload<Filter> {
  readonly type = ADD_FILTER_SUCCESS;
  constructor(public payload: Filter) {}
}

export class AddFilterError implements Action {
  readonly type = ADD_FILTER_ERROR;
}

export class SetSelectedFilter implements ActionWithPayload<{ filterName: FilterNameList; filter: Filter }> {
  readonly type = SET_SELECTED_FILTER;
  constructor(public payload: { filterName: FilterNameList; filter: Filter }) {}
}

export class SetActiveFilter implements ActionWithPayload<{ filterName: FilterNameList; filter: Filter }> {
  readonly type = SET_ACTIVE_FILTER;
  constructor(public payload: { filterName: FilterNameList; filter: Filter}) {}
}
export class SetFilterForWidget implements ActionWithPayload<{ widgetType: WidgetType; id:string , filter:Filter[] }> {
  readonly type = SET_FILTER_FOR_WIDGET;
  constructor(public payload: { widgetType: WidgetType; id:string , filter:Filter[] }) {}
}
export class TryDeleteFilter implements ActionWithPayload<Filter> {
  readonly type = TRY_DELETE_FILTER;
  constructor(public payload: Filter) {}
}

export class DeleteFilterSuccess implements ActionWithPayload<{ filterId: string; filterName: FilterNameList }> {
  readonly type = DELETE_FILTER_SUCCESS;
  constructor(public payload: { filterName: FilterNameList; filterId: string }) {}
}

export class DeleteFilterError implements Action {
  readonly type = DELETE_FILTER_ERROR;
}

export class SelectFilterListForModule implements ActionWithPayload<FilterNameList> {
  readonly type = SELECT_FILTER_LIST_FOR_MODULE;
  constructor(public payload: FilterNameList) {}
}

export class TryGetFilterListForModule implements ActionWithPayload<FilterNameList> {
  readonly type = TRY_GET_FILTER_LIST_FOR_MODULE;
  constructor(public payload: FilterNameList) {}
}

export class GetFilterListForModuleSuccess
  implements ActionWithPayload<{ filterName: FilterNameList; filterList: Filter[] }>
{
  readonly type = GET_FILTER_LIST_FOR_MODULE_SUCCESS;
  constructor(public payload: { filterName: FilterNameList; filterList: Filter[] }) {}
}

export class ModuleFiltersWereCached implements ActionWithPayload<FilterNameList> {
  readonly type = MODULE_FILTERS_WERE_LOADED;
  constructor(public payload: FilterNameList) {}
}

export class GetFilterListForModuleError implements ActionWithPayload<FilterNameList> {
  readonly type = GET_FILTER_LIST_FOR_MODULE_ERROR;
  constructor(public payload: FilterNameList) {}
}

export class TryGetFilterForUser implements ActionWithPayload<{ filterName: FilterNameList}> {
  readonly type = TRY_GET_FILTER_FOR_USER;
  constructor(public payload: { filterName: FilterNameList}) {}
}

export class DeselectFilter implements ActionWithPayload<{ filterName: FilterNameList; filter: Filter }> {
  readonly type = DESELECT_FILTER;
  constructor(public payload: { filterName: FilterNameList; filter: Filter }) {}
}

export type FilterActions =
  | TryGetFilterListForModule
  | GetFilterListForModuleSuccess
  | GetFilterListForModuleError
  | TryGetFilterForUser
  | SelectFilterListForModule
  | ModuleFiltersWereCached
  | SetSelectedFilter
  | SetActiveFilter
  | DeselectFilter
  | TryDeleteFilter
  | DeleteFilterSuccess
  | DeleteFilterError
  | TryAddFilter
  | AddFilterSuccess
  | AddFilterError
  | TryUpdateFilter
  | UpdateFilterSuccess
  | UpdateFilterError
  | SetSearchText
  | TryGetMetadata
  | GetMetadataSuccess
  | GetMetadataError
  | UpdateViewOnlyMode
  | LoadViewOnlyMode
  | MetadataWasLoaded;
